/* eslint-disable camelcase */
import * as React from 'react';

import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';

import {
  COUNTRYLIST,
  STATELIST,
  CITYLIST,
  CLIENTADD,
  GETCLIENTDATA,
  UPDATECLIENTS,
} from '../../../API_SERVICE_LAYER/ApiNames';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import { SwichInputBRH } from '../../../components/forms/SwichInputBRH';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import { DatePickerInputBRH } from '../../../components/forms/DatePickerInputBRH';
import { ToastBRH } from '../../../components/toasts/ToastBRH';
import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';
import { FormSkeleton } from '../../../components/skeleton/FormSkeleton';
import { MobileInputBRH } from '../../../components/forms/MobileInputBRH';
import { GmailInputBRH } from '../../../components/forms/GmailInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';
import { MoneyInputBRH } from '../../../components/forms/MoneyInputBRH';
import CancelAlertBRH from '../../../components/forms/CancelAlertBRH';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/toastTimings';

export default function AddProspect() {
  const { id: prospectID } = useParams();

  const [prospectData, setProspectData] = useState({
    isFetched: false,
    isLoading: false,
    isSelectDataloadedCountry: true,
    company_name: null,
    countryList: [],
    stateList: [],
    cityList: [],
    country_id: 101,
    state_id: null,
    city_id: null,
    countryObject: null,
    stateObject: null,
    cityObject: null,
    company_address: null,
    contact_person_name: null,
    contact_person_email: null,
    contact_person_number: null,
    special_instrucions: null,
    estimated_date_of_closure: new Date(),
    estimated_value_of_deal: 0,
    conversion_probability: null,
    pincode: null,
    status: 1,
    // is_prospect: 1,
  });
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (prospectID) {
      API.getData(`${GETCLIENTDATA}/${prospectID}`)
        .then((resPro) => {
          if (resPro?.data?.status === 'success') {
            const dataProspect = resPro?.data?.results;
            API.getData(COUNTRYLIST)
              .then((resCountry) => {
                if (resCountry?.data?.status === true) {
                  const dataCountry = resCountry?.data?.data;
                  let apiCountryObject;
                  dataCountry?.forEach((cou) => {
                    if (cou.id === dataProspect?.country_id) {
                      apiCountryObject = cou;
                    }
                  });
                  API.getData(`${STATELIST}/${dataProspect?.country_id}`)
                    .then((resSt) => {
                      if (resSt?.data?.status === true) {
                        const dataState = resSt?.data?.data;
                        let apiStateObject;
                        dataState?.forEach((st) => {
                          if (st.id === dataProspect?.state_id) {
                            apiStateObject = st;
                          }
                        });
                        API.getData(`${CITYLIST}/${dataProspect?.state_id}`)
                          .then((resCT) => {
                            if (resCT?.data?.status === true) {
                              const dataCity = resCT?.data?.data;
                              let apiCityObject;
                              dataCity?.forEach((ct) => {
                                if (ct.id === dataProspect?.city_id) {
                                  apiCityObject = ct;
                                }
                              });
                              setProspectData({
                                ...prospectData,
                                isFetched: true,
                                company_name: dataProspect?.company_name,
                                company_address: dataProspect?.company_address,
                                contact_person_name: dataProspect?.contact_person_name,
                                contact_person_email: dataProspect?.contact_person_email,
                                contact_person_number: dataProspect?.contact_person_number,
                                special_instrucions: dataProspect?.special_instrucions,
                                estimated_date_of_closure: new Date(dataProspect?.estimated_date_of_closure),
                                estimated_value_of_deal: dataProspect?.estimated_value_of_deal,
                                conversion_probability: dataProspect?.conversion_probability,
                                pincode: dataProspect?.pincode,
                                status: dataProspect?.status,
                                countryList: dataCountry,
                                country_id: dataProspect?.country_id,
                                countryObject: apiCountryObject,
                                stateList: dataState,
                                stateObject: apiStateObject,
                                state_id: dataProspect?.state_id,
                                cityList: dataCity,
                                cityObject: apiCityObject,
                                city_id: dataProspect?.city_id,
                              });
                            }
                          })
                          .catch((errCT) => {
                            console.log('API-CITYLIST', errCT);
                            setProspectData({
                              ...prospectData,
                              isFetched: true,
                              company_name: dataProspect?.company_name,
                              company_address: dataProspect?.company_address,
                              contact_person_name: dataProspect?.contact_person_name,
                              contact_person_email: dataProspect?.contact_person_email,
                              contact_person_number: dataProspect?.contact_person_number,
                              special_instrucions: dataProspect?.special_instrucions,
                              estimated_date_of_closure: new Date(dataProspect?.estimated_date_of_closure),
                              estimated_value_of_deal: dataProspect?.estimated_value_of_deal,
                              conversion_probability: dataProspect?.conversion_probability,
                              pincode: dataProspect?.pincode,
                              status: dataProspect?.status,
                              countryList: dataCountry,
                              country_id: dataProspect?.country_id,
                              countryObject: apiCountryObject,
                              stateList: dataState,
                              stateObject: apiStateObject,
                              state_id: dataProspect?.state_id,
                            });
                          });
                      }
                    })
                    .catch((errSt) => {
                      console.log('API-STATELIST', errSt);
                      setProspectData({
                        ...prospectData,
                        isFetched: true,
                        company_name: dataProspect?.company_name,
                        company_address: dataProspect?.company_address,
                        contact_person_name: dataProspect?.contact_person_name,
                        contact_person_email: dataProspect?.contact_person_email,
                        contact_person_number: dataProspect?.contact_person_number,
                        special_instrucions: dataProspect?.special_instrucions,
                        estimated_date_of_closure: new Date(dataProspect?.estimated_date_of_closure),
                        estimated_value_of_deal: dataProspect?.estimated_value_of_deal,
                        conversion_probability: dataProspect?.conversion_probability,
                        pincode: dataProspect?.pincode,
                        status: dataProspect?.status,
                        countryList: dataCountry,
                        country_id: dataProspect?.country_id,
                        countryObject: apiCountryObject,
                      });
                    });
                }
              })
              .catch((errCountry) => {
                console.log('API-COUNTRYLIST', errCountry);
                setProspectData({
                  ...prospectData,
                  isFetched: true,
                  company_name: dataProspect?.company_name,
                  company_address: dataProspect?.company_address,
                  contact_person_name: dataProspect?.contact_person_name,
                  contact_person_email: dataProspect?.contact_person_email,
                  contact_person_number: dataProspect?.contact_person_number,
                  special_instrucions: dataProspect?.special_instrucions,
                  estimated_date_of_closure: new Date(dataProspect?.estimated_date_of_closure),
                  estimated_value_of_deal: dataProspect?.estimated_value_of_deal,
                  conversion_probability: dataProspect?.conversion_probability,
                  pincode: dataProspect?.pincode,
                  status: dataProspect?.status,
                });
              });
          }
        })
        .catch((errPro) => {
          console.log('API-GETCLIENTDATA', errPro);
          setProspectData({
            ...prospectData,
            isFetched: true,
          });
        });
    } else {
      API.getData(`${COUNTRYLIST}`)
        .then((resultCountry) => {
          if (resultCountry?.data?.status === true) {
            const dataCountry = resultCountry?.data?.data;
            API.getData(`${STATELIST}/${prospectData.country_id}`)
              .then((resultState) => {
                if (resultState?.data?.status === true) {
                  const dataState = resultState?.data?.data;
                  setProspectData({
                    ...prospectData,
                    countryList: dataCountry,
                    countryObject: dataCountry[0],
                    stateList: dataState,
                    isFetched: true,
                  });
                }
              })
              .catch((errState) => {
                console.log('API-STATElIST', errState);
                setProspectData({
                  ...prospectData,
                  countryList: dataCountry,
                  countryObject: dataCountry[0],
                  isFetched: true,
                });
              });
          }
        })
        .catch((errCountry) => {
          console.log('API-COUNTRYLIST', errCountry);
          setProspectData({
            ...prospectData,
            isFetched: true,
          });
        });
    }

    // getProspectDetails(prospectID);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProspectData({ ...prospectData, isLoading: true });
    if (!prospectData.isLoading) {
      const formData = {
        company_name: prospectData.company_name,
        country_id: prospectData.country_id,
        state_id: prospectData.state_id,
        city_id: prospectData.city_id,
        company_address: prospectData.company_address,
        contact_person_name: prospectData.contact_person_name,
        contact_person_email: prospectData.contact_person_email,
        contact_person_number: Number(prospectData.contact_person_number),
        special_instrucions: prospectData.special_instrucions,
        estimated_date_of_closure: moment(prospectData.estimated_date_of_closure).format('YYYY-MM-DD'),
        estimated_value_of_deal: Number(prospectData.estimated_value_of_deal),
        conversion_probability: Number(prospectData.conversion_probability),
        pincode: Number(prospectData.pincode),
        status: prospectData.status,
        is_prospect: 1,
      };
      // console.log('submitted', { ...formData });
      if (prospectID) {
        formData.id = Number(prospectID);
        API.updateMethod(UPDATECLIENTS, formData)
          .then((res) => {
            if (res?.data?.status === 'success') {
              ToastBRH('success', 'Prospect Updated Successsfully', TOAST_SUCCESS);
              setTimeout(() => navigate(routeNames.PROSPECTS), 2000);
            }
          })
          .catch((err) => {
            ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
            setProspectData((prevData) => ({ ...prevData, isLoading: false }));
            console.log('API-UPDATECLIENTS', err);
          });
      } else {
        API.postMethod(CLIENTADD, formData)
          .then((res) => {
            console.log(res);
            if (res?.data?.status === 'success') {
              ToastBRH('success', 'Prospect Added Successsfully', TOAST_SUCCESS);
              setTimeout(() => navigate(routeNames.PROSPECTS), TOAST_SUCCESS);
            }
          })
          .catch((err) => {
            ToastBRH('error', 'Something went wrong try Again', TOAST_ERROR);
            setProspectData((prevData) => ({ ...prevData, isLoading: false }));
            console.log(err);
          });
      }
    }
  };

  const handleCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setProspectData({ ...prospectData, isSelectDataloadedCountry: false });
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setProspectData({
              ...prospectData,
              country_id: id,
              countryObject: newValue,
              stateList: res.data.data,
              stateObject: null,
              cityList: [],
              cityObject: null,
              isSelectDataloadedCountry: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setProspectData({
            ...prospectData,
            country_id: id,
            stateList: [],
            isSelectDataloadedCountry: true,
          });
        });
    }
  };
  const handleStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    if (name === 'name') {
      setProspectData({ ...prospectData, isSelectDataloadedState: false });
      API.getData(`${CITYLIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            console.log('API-CITYLIST');
            setProspectData({
              ...prospectData,
              isSelectDataloadedState: true,
              stateObject: newValue,
              state_id: id,
              cityList: res.data.data,
              cityObject: null,
            });
          }
        })
        .catch((err) => {
          console.log('API-CITYLIST', err);
          ToastBRH('error', `${err?.response?.data?.message}`, '_', false);
          setProspectData({ ...prospectData, isSelectDataloadedState: true, cityList: [], state_id: id });
        });
    }

    // setCompanyMasterData({ ...companyMasterData, state_id1: id });
  };
  const handleCityChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    setProspectData({ ...prospectData, city_id: id, cityObject: newValue });
  };
  const handleMoneyChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    value = String(value).replace(/,/g, '');
    setProspectData({
      ...prospectData,
      [name]: value,
    });
  };
  const handleChangeEvent = (event) => {
    const { name, value } = event.target;
    // console.log(' name, value', name, value);
    setProspectData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onToggleButton = (e) => {
    const { checked } = e.target;
    return checked === true
      ? setProspectData({ ...prospectData, status: 1 })
      : setProspectData({ ...prospectData, status: 0 });
  };
  // const handleCancel = () => {
  //   console.log('clicked');
  // };
  return (
    <>
      {prospectData.isFetched ? (
        <>
          <>
            <Helmet>
              <title> {prospectID ? 'Edit' : 'Add'} Prospects | Contract Management </title>
            </Helmet>
            <Container>
              <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
                <Button onClick={() => navigate(routeNames.INDEX)} color="secondary">
                  Dashboard
                </Button>
                <Button onClick={() => navigate(routeNames.PROSPECTS)} color="secondary">
                  Master
                </Button>
                <Typography color="primary" variant="button">
                  {prospectID ? 'Edit' : 'Add'} Prospects
                </Typography>
              </Breadcrumbs>

              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h4" gutterBottom>
                  {prospectID ? 'Edit' : 'Add'} Prospect
                </Typography>
              </Stack>

              <Card sx={{ p: 2 }}>
                <Scrollbar>
                  <Box sx={{ flexGrow: 1, m: 4 }}>
                    <form onSubmit={handleSubmit}>
                      <FormControl sx={{ flexGrow: 1, width: '100%' }}>
                        <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                          <Grid item xs={12} sm={12} md={12}>
                            <TextInputBRH
                              label="Prospect Name"
                              name="company_name"
                              key="Prospect Name"
                              value={prospectData.company_name}
                              handleChange={handleChangeEvent}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <AutoCompleteInputBRH
                              label="Country"
                              name="name"
                              value={prospectData.countryObject}
                              options={prospectData.countryList}
                              handleChange={handleCountryChange}
                              isSelectDataloaded={prospectData.isSelectDataloadedCountry}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <AutoCompleteInputBRH
                              label="State"
                              name="name"
                              value={prospectData.stateObject}
                              options={prospectData.stateList}
                              handleChange={handleStateChange}
                              isSelectDataloaded={prospectData.isSelectDataloadedState}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            {' '}
                            <AutoCompleteInputBRH
                              label="City"
                              name="name"
                              value={prospectData.cityObject}
                              options={prospectData.cityList}
                              handleChange={handleCityChange}
                              required
                            />{' '}
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextInputBRH
                              label="Prospect Address"
                              name="company_address"
                              value={prospectData.company_address}
                              handleChange={handleChangeEvent}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <NumberInputBRH
                              label="Pincode"
                              name="pincode"
                              value={prospectData.pincode}
                              handleChange={handleChangeEvent}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextInputBRH
                              label="Contact Person Name"
                              name="contact_person_name"
                              value={prospectData.contact_person_name}
                              handleChange={handleChangeEvent}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <GmailInputBRH
                              label="Contact Person Email"
                              name="contact_person_email"
                              value={prospectData.contact_person_email}
                              handleChange={handleChangeEvent}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <MobileInputBRH
                              label="Contact Person Number"
                              name="contact_person_number"
                              value={prospectData.contact_person_number}
                              handleChange={handleChangeEvent}
                              // isTypeNumber="true"
                              type="number"
                              required
                              sx={{ color: 'red' }}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextInputBRH
                              label="Special Instructions"
                              name="special_instrucions"
                              value={prospectData.special_instrucions}
                              handleChange={handleChangeEvent}
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <DatePickerInputBRH
                              label="Estimated Date of Closure *"
                              value={prospectData.estimated_date_of_closure}
                              handleChange={(newValue) =>
                                setProspectData({ ...prospectData, estimated_date_of_closure: newValue })
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <MoneyInputBRH
                              label="Estimated Value of Deal"
                              name="estimated_value_of_deal"
                              value={prospectData.estimated_value_of_deal}
                              // value="123,45"
                              handleChange={handleMoneyChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <NumberInputBRH
                              label="Conversion Probability"
                              name="conversion_probability"
                              value={prospectData.conversion_probability}
                              handleChange={handleChangeEvent}
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} sx={{ display: 'none' }}>
                            <SwichInputBRH
                              name="status"
                              label="Status"
                              value={prospectData.status}
                              handleChange={onToggleButton}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'end',
                              }}
                            >
                              {/* <CancelButtonBRH cancelOpen={cancelOpen} setCancelOpen={setCancelOpen} label="Cancel" /> */}
                              <Button
                                color="secondary"
                                onClick={() => {
                                  setCancelOpen(true);
                                }}
                                variant="outlined"
                                sx={{ marginRight: '24px' }}
                              >
                                Cancel
                              </Button>
                              <LoadingButtonBRH
                                label={prospectID ? 'Update' : 'Add'}
                                type="submit"
                                isLoading={prospectData?.isLoading}
                              />
                            </Box>
                            <CancelAlertBRH
                              cancelOpen={cancelOpen}
                              setCancelOpen={setCancelOpen}
                              routeName={routeNames.PROSPECTS}
                            />
                          </Grid>

                          {/* <Stack sx={{ my: 2, mx: -6, width: '100%' }}>
                          
                            <Button
                              type="submit"
                              sx={{ width: '20%', height: '2.5rem', alignSelf: 'end' }}
                              variant="contained"
                            >
                              {prospectID ? 'Update' : 'Add'}
                            </Button>
                          </Stack> */}
                        </Grid>
                      </FormControl>
                    </form>
                  </Box>
                </Scrollbar>
              </Card>
            </Container>
          </>
        </>
      ) : (
        <>
          <FormSkeleton />
        </>
      )}
    </>
  );
}
