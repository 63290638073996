import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';

export const TimeInputBRH = ({ name, label, value, handleChange, disabled }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label={label}
          name={name}
          value={value}
          onChange={handleChange}
          sx={{ width: '100%' }}
          disabled={disabled}
        />{' '}
        {/* views={['hours']} */}
      </LocalizationProvider>
    </>
  );
};
