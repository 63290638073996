import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';

import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { FormSkeleton } from '../../components/skeleton/FormSkeleton';
import { AutoCompleteInputBRH } from '../../components/forms/AutoCompleteInputBRH';
import { CITYLIST, COMPANYLIST, COUNTRYLIST, SCOUTPROPERTYLIST, STATELIST } from '../../API_SERVICE_LAYER/ApiNames';
import { TextInputBRH } from '../../components/forms/TextInputBRH';
import { MoneyInputBRH } from '../../components/forms/MoneyInputBRH';
import { NumberInputBRH } from '../../components/forms/NumberInputBRH';
import { DatePickerInputBRH } from '../../components/forms/DatePickerInputBRH';
import { contractContext } from '../../components/context/AppContext';
import { ToastBRH } from '../../components/toasts/ToastBRH';
import TextDivider from '../../components/text-divider/TextDivider';
import { TextFieldSelectBRH } from '../../components/forms/TextFieldSelectBRH';
import { FileInputBRH } from '../../components/forms/FileInputBRH';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
import CancelAlertBRH from '../../components/forms/CancelAlertBRH';
import { LoadingButtonBRH } from '../../components/forms/LoadingButtonBRH';

export const PrsAdd = () => {
  const { state } = useLocation();
  const PrsId = state?.updatedId;
  const isUpdated = state?.isUpdated;
  const { propertyList } = useContext(contractContext);
  const { id: prsId } = useParams();
  const [cancelOpen, setCancelOpen] = React.useState(false);

  const [prsData, setPrsData] = useState({
    isFetched: false,
    isLoading: false,
    // mgName: null,
    landlordType: 1,

    scoutList: [],
    companyMasterData: [],
    property_name: null,
    isSelected: true,
    countryList: [],
    country: null,
    stateList: [],
    state: null,
    cityList: [],
    city: null,
    clientName: null,
    mgAddressOptions: [],
    mgAddress: null,
    landlordName: null,
    landlordAddress: null,
    landlordCountry: null,
    landlordCountryList: [],
    landlordState: null,
    landlordStateList: [],
    landlordCity: null,
    landlordCityList: [],
    landlordAccNumber: null,
    landlordAccName: null,
    landlordIfscCode: null,
    dateOfAgreement: new Date(),
    propertyTotalSquareFeet: null,
    propertyTerraceSquareFeet: null,
    // buildingName: null,
    floorNo: null,
    floorhiring: null,
    yearOnYearIncrement: null,
    floorFile: null,
    effectiveDate: new Date(),
    lockedInPeriod: null,
    renewalTerm: null,
    fitoutPeriod: null,
    fitoutPeriodStartDate: new Date(),
    securityDeposit: null,
    annualEscalation: null,
    refundableSecurityDeposit: null,
    rent: null,
    rentPerAmount: null,
    numberOfBikeParking: null,
    numberOfCarParking: null,
    maintanceChargesPerSqft: null,
    maintanceChargesPerTotal: null,
    powerBackup: null,
    eastBoundary: null,
    westBoundary: null,
    southBoundary: null,
    northBoundary: null,
  });
  // DERIVED STATE VALUES
  const mgName = prsData.companyMasterData[0]?.company_name;
  const mgAddressLine = prsData.companyMasterData[0]?.company_addresses?.find(
    (ele) => ele.id === prsData.mgAddress
  )?.address;
  const mgSignature = prsData.companyMasterData[0]?.company_addresses?.find(
    (ele) => ele.id === prsData.mgAddress
  )?.signatory;
  const fileName = prsData.floorFile?.name;
  // const mgAddressOptions = prsData.companyMasterData[0]?.company_addresses?.map((address) => {
  //   const stObj = prsData.stateList.find((st) => st.id === Number(address.state_id));
  //   return { ...address, stateName: stObj?.name };
  // });

  // console.log(stObj);
  // console.log(mgAddressOptions);
  const propertyName = prsData.property_name?.contact_person;
  const propertyAddress = prsData.property_name?.address1;
  // const propertyTotalSquareFeet = prsData.property_name?.total_sq_ft;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('submitted', { ...prsData });
  };
  useEffect(() => {
    let apiCompanyMasterData = [];
    let apiCountryList = [];
    let apiStateList = [];
    Promise.allSettled([API.getData(COMPANYLIST), API.getData(COUNTRYLIST), API.getData(`${STATELIST}/101`)])
      .then((res) => {
        res.forEach((ele, i, arr) => {
          const { status, value } = ele;
          if (status === 'fulfilled') {
            if (i === 0) {
              if (value.data.status === 'success') {
                console.log(value?.data?.results);
                apiCompanyMasterData = value?.data?.results;
              }
            } else if (i === 1) {
              if (value.data.status === true) {
                apiCountryList = value?.data?.data;
              }
            } else if (i === 2) {
              if (value.data.status === true) {
                apiStateList = value?.data?.data;
              }
            }
          } else {
            throw new Error(`i`);
          }
        });
        const tempMgAddressOptions = apiCompanyMasterData[0]?.company_addresses?.map((address) => {
          const stObj = apiStateList.find((st) => st.id === Number(address.state_id));
          return { ...address, stateName: stObj?.name };
        });
        console.log(tempMgAddressOptions);
        // SET THE VALUES IN STATE
        setPrsData({
          ...prsData,
          isFetched: true,
          companyMasterData: apiCompanyMasterData,
          countryList: apiCountryList,
          landlordCountryList: apiCountryList,
          stateList: apiStateList,
          landlordStateList: apiStateList,
          mgAddressOptions: tempMgAddressOptions,
          // country: apiCountryList[0].find((country) => country.id === apiCompanyMasterData[0].country_id),
          country: apiCountryList[0],
          landlordCountry: apiCountryList[0],
        });
      })
      .catch((err) => {
        console.error(err);
        setPrsData({
          ...prsData,
          isFetched: true,
        });
      });
    // API.getData(COMPANYLIST)
    //   .then((res) => {
    //     if (res?.data?.status === 'success') {
    //       const data = res?.data?.results[0];
    //       console.log('laksh', data.company_name);
    //       setPrsData({
    //         ...prsData,
    //         isFetched: true,
    //         companyMasterData: data,
    //         // mgName: data?.company_name,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(' API-SCOUTPROPERTYLIST', err);
    //     setPrsData({ ...prsData, isFetched: true });
    //   });
  }, []);
  const handleAutoCompleteChange = (event, newValue) => {
    console.log(newValue);
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    console.log(name, id);
    if (name === 'property_name') {
      //   setPrsData({ ...prsData, isSelectDataLoaded: true });
      let tempCountry = null;
      let tempState = null;
      let tempCity = null;
      tempCountry = prsData.countryList.find((ele) => ele.id === newValue.country_id);
      API.getData(`${STATELIST}/${newValue.country_id}`)
        .then((res) => {
          if (res.data.status === true) {
            tempState = res.data.data?.find((ele) => ele.id === newValue.state_id);
            API.getData(`${CITYLIST}/${newValue.state_id}`)
              .then((resCity) => {
                if (resCity?.data?.status === true) {
                  tempCity = resCity?.data?.data?.find((ele) => ele.id === newValue.city_id);
                  setPrsData({
                    ...prsData,
                    [name]: newValue,
                    country: tempCountry,
                    state: tempState,
                    city: tempCity,
                    floorNo: newValue.floor_no,
                    totalSquareFeet: newValue.propertyTotalSquareFeet,
                    // landlordAddress: newValue.address1,
                  });
                }
              })
              .catch((errCity) => console.log(errCity));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setPrsData({ ...prsData, isSelected: false });
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setPrsData({
              ...prsData,
              country: newValue,
              isSelected: true,
              stateList: res.data.data,
              // stateObject: null,
              // cityList: [],
              // cityObject: null,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setPrsData({
            ...prsData,
            // country_id: id,
            // stateList: [],
            isSelectDataloadedCountry: true,
          });
        });
    }
  };
  const handleLandlordCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setPrsData({ ...prsData, isSelected: false });
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setPrsData({
              ...prsData,
              landlordCountry: newValue,
              isSelected: true,
              landlordStateList: res.data.data,
              // stateObject: null,
              // cityList: [],
              // cityObject: null,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setPrsData({
            ...prsData,
            // country_id: id,
            // stateList: [],
            isSelected: true,
          });
        });
    }
  };
  const handleStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    if (name === 'name') {
      setPrsData({ ...prsData, isSelected: false });
      API.getData(`${CITYLIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            console.log('API-CITYLIST');
            setPrsData({
              ...prsData,
              isSelected: true,
              state: newValue,
              cityList: res.data.data,
              // cityObject: null,
              // state_id: id,
            });
          }
        })
        .catch((err) => {
          console.log('API-CITYLIST', err);
          ToastBRH('error', `${err?.response?.data?.message}`, '_', false);
          setPrsData({ ...prsData, isSelected: true, cityList: [] });
        });
    }

    // setCompanyMasterData({ ...companyMasterData, state_id1: id });
  };
  const handleLandlordStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    if (name === 'name') {
      setPrsData({ ...prsData, isSelected: false });
      API.getData(`${CITYLIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            console.log('API-CITYLIST');
            setPrsData({
              ...prsData,
              isSelected: true,
              landlordState: newValue,
              landlordCityList: res.data.data,
              // cityObject: null,
              // state_id: id,
            });
          }
        })
        .catch((err) => {
          console.log('API-CITYLIST', err);
          ToastBRH('error', `${err?.response?.data?.message}`, '_', false);
          setPrsData({ ...prsData, isSelected: true, landlordCityList: [] });
        });
    }

    // setCompanyMasterData({ ...companyMasterData, state_id1: id });
  };
  const handleCityChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    setPrsData({ ...prsData, city: newValue });
  };
  const handleLandlordCityChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    setPrsData({ ...prsData, landlordCity: newValue });
  };
  const handleMoneyChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    value = String(value).replace(/,/g, '');
    setPrsData({
      ...prsData,
      [name]: value,
    });
  };
  const handleDateChange = (name, newValue) => {
    setPrsData({ ...prsData, [name]: newValue });
  };
  const handleFileUpload = (e) => {
    // setContractsData({ ...contractsData, file: e.target.files[0] });
    setPrsData({ ...prsData, floorFile: e.target.files[0] });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrsData({ ...prsData, [name]: value });
  };

  return (
    <>
      {prsData.isFetched ? (
        <>
          <Helmet>
            <title> PRS | BRH </title>
          </Helmet>
          <Container>
            <form onSubmit={handleSubmit}>
              {/* page title */}
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h4" gutterBottom>
                  {PrsId ? 'Edit' : 'Add'} PRS
                </Typography>
              </Stack>
              {/* page body */}
              <Card sx={{ p: 2 }}>
                <Box sx={{ flexGrow: 1, m: 4 }}>
                  <form onSubmit={handleSubmit}>
                    <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                      <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                        {/* Landlord Type */}
                        <Grid item xs={6} sm={6} md={12}>
                          <FormControl>
                            <FormLabel id="landlordType-radio-buttons-group">Contract Type</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="landlordType-radio-buttons-group"
                              name="landlordType"
                              value={prsData.landlordType}
                              onChange={handleChange}
                            >
                              <FormControlLabel value={1} control={<Radio />} label="Company" />
                              <FormControlLabel value={2} control={<Radio />} label="Individual" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {/* property_name */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label={`${Number(prsData.landlordType) === 1 ? 'Company' : 'Individual'} Name`}
                            name="clientName"
                            value={prsData.clientName}
                            required
                            handleChange={handleChange}
                          />
                        </Grid>
                        {/* Date of Agreement) */}
                        <Grid item xs={12} sm={12} md={6}>
                          <DatePickerInputBRH
                            label="Date of Agreement *"
                            value={prsData.dateOfAgreement}
                            handleChange={(value) => {
                              handleDateChange('dateOfAgreement', value);
                            }}
                            isAutoPopulate={1}
                          />
                        </Grid>
                        {/* divider */}
                        <Grid item sm={12} md={12}>
                          <TextDivider>landlord details</TextDivider>
                        </Grid>
                        {/* landlordName */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Name"
                            name="landlordName"
                            value={prsData.landlordName}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* landlordAddress */}
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Address"
                            name="landlordAddress"
                            value={prsData.landlordAddress}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>

                        {/* country */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="Country"
                            name="name"
                            value={prsData.landlordCountry}
                            options={prsData.landlordCountryList}
                            handleChange={handleCountryChange}
                            isSelectDataloaded={prsData.isSelected}
                            required
                          />
                        </Grid>
                        {/* state */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="State"
                            name="name"
                            value={prsData.landlordState}
                            options={prsData.landlordStateList}
                            handleChange={handleLandlordStateChange}
                            isSelectDataloaded={prsData.isSelected}
                            required
                          />
                        </Grid>
                        {/* city */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="City"
                            name="name"
                            value={prsData.landlordCity}
                            options={prsData.landlordCityList}
                            handleChange={handleLandlordCityChange}
                            isSelectDataloaded={prsData.isSelected}
                            required
                          />
                        </Grid>
                        {/* Landlord Account Number  */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label="Bank Account Number"
                            name="landlordAccNumber"
                            value={prsData.landlordAccNumber}
                            handleChange={handleChange}
                          />
                        </Grid>
                        {/* // Landlord Account Name */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Bank Account Name"
                            name="landlordAccName"
                            value={prsData.landlordAccName}
                            handleChange={handleChange}
                          />
                        </Grid>
                        {/* // IFSC CODE */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="IFSC CODE"
                            name="landlordIfscCode"
                            value={prsData.landlordIfscCode}
                            handleChange={handleChange}
                          />
                        </Grid>
                        {/* divider */}
                        <Grid item sm={12} md={12}>
                          <TextDivider>property details</TextDivider>
                        </Grid>
                        {/* propertyName */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="Name"
                            name="property_name"
                            value={prsData.property_name}
                            handleChange={handleAutoCompleteChange}
                            options={propertyList}
                            isSelectDataloaded={prsData.isSelectDataLoaded}
                            required
                          />
                        </Grid>
                        {/* propertyAddress */}
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Address"
                            name="propertyAddress"
                            value={propertyAddress}
                            handleChange={handleChange}
                            readOnly
                          />
                        </Grid>

                        {/* country */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="Country"
                            name="name"
                            value={prsData.country}
                            options={prsData.countryList}
                            handleChange={handleCountryChange}
                            isSelectDataloaded={prsData.isSelected}
                            required
                            readOnly
                          />
                        </Grid>
                        {/* state */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="State"
                            name="name"
                            value={prsData.state}
                            options={prsData.stateList}
                            handleChange={handleStateChange}
                            isSelectDataloaded={prsData.isSelected}
                            required
                            readOnly
                          />
                        </Grid>
                        {/* city */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="City"
                            name="name"
                            value={prsData.city}
                            options={prsData.cityList}
                            handleChange={handleCityChange}
                            isSelectDataloaded={prsData.isSelected}
                            required
                            readOnly
                          />
                        </Grid>
                        {/* Total Square-feet */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label=" Total Square-feet"
                            value={prsData.propertyTotalSquareFeet}
                            handleChange={handleChange}
                            name="propertyTotalSquareFeet"
                            required
                            InputProps={{
                              endAdornment: <InputAdornment position="end">sq.ft</InputAdornment>,
                            }}
                            // readOnly
                            sx={{ backgroundColor: 'red' }}
                            // isAutoPopulate={1}
                            readOnly
                          />
                        </Grid>
                        {/* Terrace Square-feet */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label=" Terrace Square-feet (if Applicable)"
                            value={prsData.propertyTerraceSquareFeet}
                            handleChange={handleChange}
                            name="propertyTerraceSquareFeet"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">sq.ft</InputAdornment>,
                            }}
                            readOnly
                            sx={{ backgroundColor: 'red' }}
                            // isAutoPopulate={1}
                          />
                        </Grid>
                        {/* Building Name */}
                        {/* <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Building Name"
                            name="buildingName"
                            value={prsData.buildingName}
                            handleChange={handleChange}
                            // isAutoPopulate={1}
                          />
                        </Grid> */}
                        {/* floor no */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label=" Floor no "
                            value={prsData.floorNo}
                            handleChange={handleChange}
                            name="floorNo"
                            required
                            readOnly
                            sx={{ backgroundColor: 'red' }}
                            // isAutoPopulate={1}
                          />
                        </Grid>
                        {/* Number of bike parking included */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label="No of Bike Parking"
                            name="numberOfBikeParking"
                            value={prsData.numberOfBikeParking}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Number of car parking included */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label="No of Car Parking "
                            name="numberOfCarParking"
                            value={prsData.numberOfCarParking}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Floor hiring */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label=" Square-feet of Floor Hiring"
                            name="floorhiring"
                            value={prsData.floorhiring}
                            handleChange={handleChange}
                            required
                            InputProps={{
                              endAdornment: <InputAdornment position="end">sq.ft</InputAdornment>,
                            }}
                            // readOnly
                          />
                        </Grid>

                        {/* floor plan */}
                        <Grid item sm={12} md={6}>
                          <FileInputBRH
                            handleFileUpload={handleFileUpload}
                            buttonText={fileName ?? 'Upload floor document'}
                            color="secondary"
                          />
                        </Grid>

                        {/* divider */}
                        <Grid item sm={12} md={12}>
                          <TextDivider>SCHEDULE PREMISES</TextDivider>
                        </Grid>
                        {/* North Boundary */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="North Boundary"
                            name="northBoundary"
                            value={prsData.northBoundary}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* South Boundary */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="South Boundary"
                            name="southBoundary"
                            value={prsData.southBoundary}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* West Boundary */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="West Boundary"
                            name="westBoundary"
                            value={prsData.westBoundary}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* east Boundary */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="East Boundary"
                            name="eastBoundary"
                            value={prsData.eastBoundary}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* divider */}
                        <Grid item sm={12} md={12}>
                          <TextDivider>Commercial details</TextDivider>
                        </Grid>
                        {/*  Lease Commencement Date(Effective Date) */}
                        <Grid item xs={12} sm={12} md={6}>
                          <DatePickerInputBRH
                            label="Lease Commencement Date(Effective Date) *"
                            value={prsData.effectiveDate}
                            handleChange={(value) => {
                              handleDateChange('effectiveDate', value);
                            }}
                            isAutoPopulate={1}
                          />
                        </Grid>
                        {/* Lock-In Period */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label="Lock-In Period"
                            value={prsData.lockedInPeriod}
                            handleChange={handleChange}
                            name="lockedInPeriod"
                            required
                            InputProps={{
                              endAdornment: <InputAdornment position="end">months</InputAdornment>,
                            }}
                          />
                        </Grid>
                        {/* Renewal term */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Renewal Term"
                            name="renewalTerm"
                            value={prsData.clientAddress}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Fitout Period */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label="Fitout Period"
                            value={prsData.fitoutPeriod}
                            handleChange={handleChange}
                            name="fitourPeriod"
                            required
                            InputProps={{
                              endAdornment: <InputAdornment position="end">days</InputAdornment>,
                            }}
                          />
                        </Grid>
                        {/* fitout period-Starting from */}
                        <Grid item xs={12} sm={12} md={6}>
                          <DatePickerInputBRH
                            label="Fitout period-Starting from *"
                            value={prsData.fitoutPeriodStartDate}
                            handleChange={(value) => {
                              handleDateChange('fitourPeriodStartDate', value);
                            }}
                            isAutoPopulate={1}
                          />
                        </Grid>
                        {/* Rent */}
                        <Grid item xs={12} sm={12} md={6}>
                          <MoneyInputBRH
                            label="Rent"
                            name="rent"
                            value={prsData.rent}
                            handleChange={handleMoneyChange}
                            required
                          />
                        </Grid>
                        {/* security deposit */}
                        <Grid item xs={12} sm={12} md={6}>
                          <MoneyInputBRH
                            label="Security Deposit"
                            name="securityDeposit"
                            value={prsData.securityDeposit}
                            handleChange={handleMoneyChange}
                            required
                          />
                        </Grid>
                        {/* YOY increment */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label=" YOY Increment"
                            name="yearOnYearIncrement"
                            value={prsData.yearOnYearIncrement}
                            handleChange={handleChange}
                            required
                            InputProps={{
                              endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            // readOnly
                            sx={{ backgroundColor: 'red' }}
                          />
                        </Grid>
                        {/* Annual Escalation */}
                        <Grid item xs={12} sm={12} md={6}>
                          <MoneyInputBRH
                            label="Annual Escalation"
                            name="annualEscalation"
                            value={prsData.annualEscalation}
                            handleChange={handleMoneyChange}
                            required
                          />
                        </Grid>
                        {/* Refundable Security Deposit */}
                        <Grid item xs={12} sm={12} md={6}>
                          <MoneyInputBRH
                            label="Refundable Security Deposit"
                            name="refundableSecurityDeposit"
                            value={prsData.refundableSecurityDeposit}
                            handleChange={handleMoneyChange}
                            required
                          />
                        </Grid>
                        {/* maintanceChargesPerSqft */}
                        <Grid item xs={12} sm={12} md={6}>
                          <MoneyInputBRH
                            label="Maintanance charges"
                            name="maintanceChargesPerSqft"
                            value={prsData.maintanceChargesPerSqft}
                            handleChange={handleMoneyChange}
                            endAdornmentText="sq. ft."
                            required
                          />
                        </Grid>
                        {/* maintanceChargesPerTotal */}
                        <Grid item xs={12} sm={12} md={6}>
                          <MoneyInputBRH
                            label="Total Maintanance charges"
                            name="maintanceChargesPerTotal"
                            value={prsData.maintanceChargesPerTotal}
                            handleChange={handleMoneyChange}
                            required
                          />
                        </Grid>
                        {/* Power BackUp */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label="Power and Backup"
                            name="powerBackup"
                            value={prsData.powerBackup}
                            handleChange={handleChange}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">KVA</InputAdornment>,
                            }}
                            required
                          />
                        </Grid>
                        {/* divider */}
                        <Grid item sm={12} md={12}>
                          <TextDivider>mikro grafeio</TextDivider>
                        </Grid>
                        {/* company name(MG) */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH label="Name" name="mgName" value={mgName} required readOnly />
                        </Grid>
                        {/* MG address */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextFieldSelectBRH
                            label="State"
                            name="mgAddress"
                            value={prsData.mgAddress}
                            options={prsData.mgAddressOptions}
                            handleChange={(e, newValue) => setPrsData({ ...prsData, mgAddress: e.target.value })}
                            renderLabel="stateName"
                            renderValue="id"
                            required
                          />
                        </Grid>
                        {/* adressLine */}
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Address"
                            name="mgAddressLine"
                            value={mgAddressLine}
                            // handleChange={handleChange}
                            readOnly
                          />
                        </Grid>
                        {/* signature */}
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Authorized Signatory"
                            name="mgSignature"
                            value={mgSignature}
                            // handleChange={handleChange}
                            readOnly
                          />
                        </Grid>
                        {/* buttons */}
                        <Grid item xs={12} sm={12} md={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'end',
                            }}
                          >
                            {/* <CancelButtonBRH cancelOpen={cancelOpen} setCancelOpen={setCancelOpen} label="Cancel" /> */}
                            <Button
                              color="secondary"
                              onClick={() => {
                                setCancelOpen(true);
                              }}
                              variant="outlined"
                              sx={{ marginRight: '24px' }}
                            >
                              Cancel
                            </Button>
                            <LoadingButtonBRH
                              label={prsId ? 'Update' : 'Add'}
                              type="submit"
                              isLoading={prsData?.isLoading}
                            />
                          </Box>
                          <CancelAlertBRH
                            cancelOpen={cancelOpen}
                            setCancelOpen={setCancelOpen}
                            routeName={routeNames.PRS}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  </form>
                </Box>
              </Card>
            </form>
          </Container>
        </>
      ) : (
        <>
          <FormSkeleton />
        </>
      )}
    </>
  );
};
