import { Box, Divider, Grid } from '@mui/material';
import React, { useContext } from 'react';
import moment from 'moment';
import styles from './Notification.module.css';
import { contractContext } from '../../components/context/AppContext';
// import { TextFieldSelectBRH } from '../../components/forms/TextFieldSelectBRH';
import Iconify from '../../components/iconify';
import palette from '../../theme/palette';

// const STATUS_CONTACTED_CLIENT = 1;
// const STATUS_AWAITING_CLIENT_STATUS = 2;
// const STATUS_RENEWAL_CONTRACT = 3;
// const STATUS_RENEWAL_CANCELLED = 4;

// const statusOptions = [
//   {
//     label: 'Contacted Client',
//     value: STATUS_CONTACTED_CLIENT,
//   },
//   {
//     label: 'Awaiting Client Status',
//     value: STATUS_AWAITING_CLIENT_STATUS,
//   },
//   {
//     label: 'Renewal Contract',
//     value: STATUS_RENEWAL_CONTRACT,
//   },
//   {
//     label: 'Renewal Cencelled',
//     value: STATUS_RENEWAL_CANCELLED,
//   },
// ];

export default function NotificationItem({ contract }) {
  const { propertyList } = useContext(contractContext);
  // const [notificationItemState, setNotificationItemState] = useState({
  //   status: null,
  // });
  console.log(contract ,"mylistcontract");
  const startDate = new Date(contract?.agreement_start_date);
  const endDate = new Date(contract?.agreement_end_date);
  // const finalDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 60, 0, 0, 0);
  // const finalDate = new Date(contract.finalDate);
  const today = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const numOfDays = Math.round(Math.abs((endDate - today) / oneDay));
  const propertyId = contract.property_id;
  const propertyName =contract?.propertyName;
  const contractName=contract?.contract_client?.company_name;
  //  propertyList?.find((property) => property.id === propertyId)?.property_name;
  return (
    <li>
      <Box sx={{ marginBottom: '10px' }}>
        <Grid container rowSpacing={1}>
          {/* <Grid item md={12}>
          <span className={styles.notificationTitle}>expiring soon</span>
        </Grid> */}
          {/* <Grid item md={12}>
          <p className={styles.notificationSummary}>contract is about to expire and take necessary action.</p>
        </Grid> */}
          <Grid item md={8}>
            <span style={{ marginRight: '16px' }}>
              <Iconify icon="fluent:arrow-step-in-right-28-filled" color={palette.primary.dark} />
            </span>
            <span className={styles.notificationDetail}>
              {/* <span>contract - </span> */}
             {contractName} {contract?.contractMou?.is_mou_accept===0 && <>  ( MOU )</> } | {propertyName} | {moment(startDate).format('DD - MMM - YYYY')} |{' '}
              {moment(endDate).format('DD - MMM - YYYY')} (expires in {numOfDays} days)  
            </span>
            {/* <p className={styles.notificationDetail}>
            <span>status : </span>Awaiting for action
          </p> */}
          </Grid>
          {/* <Grid item md={4}>
            <TextFieldSelectBRH
              label="Status"
              name="status"
              value={notificationItemState.approvedStatus}
              options={statusOptions}
              handleChange={(e) => setNotificationItemState({ ...notificationItemState, status: e.target.value })}
              renderLabel="label"
              renderValue="value"
              required
              size="small"
            />
          </Grid> */}
          <Grid item md={12} marginTop={2}>
            <Divider color="primary" />
          </Grid>
        </Grid>
        {/* <Grid container marginTop={2} rowSpacing={2}>
        <Grid item md={3}>
          <button type="button">Contacted Client</button>
        </Grid>
        <Grid item md={3}>
          <button type="button">Awaiting Client Status</button>
        </Grid>
        <Grid item md={3}>
          <button type="button"> Renewal Confirmed</button>
        </Grid>
        <Grid item md={3}>
          <button type="button">Renewal Cancelled</button>
        </Grid>
        <Grid item md={12} marginTop={2}>
          <Divider color="primary" />
        </Grid>
      </Grid> */}
      </Box>
    </li>
  );
}
