import React, { useState, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio,
} from '@mui/material';
import TextDivider from '../../../components/text-divider/TextDivider';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { TextFieldSelectBRH } from '../../../components/forms/TextFieldSelectBRH';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';

const CommonAreaFacilityInput = [
  { id: 1, commonArea: 'Lift', value: 'Lift' },
  { id: 2, commonArea: 'Emergency Exit', value: 'Emergency Exit' },
  { id: 3, commonArea: 'Food Court/ Pantry Area', value: 'Food Court/ Pantry Area' },
  { id: 4, commonArea: '24 hrs Security', value: '24 hrs Security' },
  { id: 5, commonArea: 'CC TV', value: 'CC TV' },
  { id: 6, commonArea: 'Available Power Load / LT or HT', value: 'Available Power Load / LT or HT' },
  { id: 7, commonArea: 'Water', value: 'Water' },
  { id: 8, commonArea: 'Sewage', value: 'Sewage' },
];

const Amenities = () => {
  const [expanded, setExpanded] = React.useState('commonArea');
  const [amentiesData, setAmenitiesData] = useState({
    CommonArea: CommonAreaFacilityInput.map((Area) => ({
      commonArea: Area.value,
      status: 0,
    })),
    parkingArea: '',
    electric_capacity: '',
    ups_capacity: '',
    capacity_dg: '',
    lan_cable: null,
    internetServiceProvider: '',
    audio_video: null,
    access_control: null,
    specificfacilities: '',
    assets_building: '',
  });

  useEffect(() => {
    console.log(amentiesData);
  }, [amentiesData]);

  const handleChangeCommonArea = (value, e) => {
    setAmenitiesData((prevData) => ({
      ...prevData,
      CommonArea: prevData.CommonArea.map((item) =>
        item.commonArea === value ? { ...item, status: e.target.checked ? 1 : 0 } : item
      ),
    }));
  };

  const handleChangeLanCable = (e) => {
    console.log(e.target);
    const value = e.target.value === 'Yes' ? 1 : 0;
    setAmenitiesData((prevData) => ({
      ...prevData,
      lan_cable: value,
    }));
  };
  const handleChangeAudio = (e) => {
    const value = e.target.value === 'Yes' ? 1 : 0;
    setAmenitiesData((prevData) => ({
      ...prevData,
      audio_video: value,
    }));
  };
  const handleChangeAccessControl = (e) => {
    const value = e.target.value === 'Yes' ? 1 : 0;
    setAmenitiesData((prevData) => ({
      ...prevData,
      access_control: value,
    }));
  };
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(amentiesData);
  };
  const handleParkingAreaChange = (event) => {
    const selectedValues = event.target.value;
    console.log('selec', selectedValues, event);
    setAmenitiesData((prevDetails) => ({
      ...prevDetails,
      parkingArea: selectedValues,
    }));
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAmenitiesData({ ...amentiesData, [name]: value });
  };
  return (
    <>
      <Box sx={{ flexGrow: 1, m: 1 }}>
        <form noValidate={false} onSubmit={handleSubmit}>
          <Grid item sm={12} md={12}>
            <TextDivider>Common Area Facility</TextDivider>
          </Grid>
          <FormControl sx={{ flexGrow: 1, width: '100%' }}>
            <FormGroup>
              <Grid container>
                {amentiesData.CommonArea?.map((Area) => (
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <div
                          style={{
                            display: 'flex',

                            alignItems: 'center',
                            marginLeft: '20px',
                            height: '8vh',
                            marginRight: '1vw',
                          }}
                        >
                          <Checkbox
                            checked={Area.status === 1}
                            onChange={(e) => handleChangeCommonArea(Area.commonArea, e)}
                            name={Area.commonArea}
                          />
                        </div>
                      }
                      label={Area.commonArea}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
            <Grid item sm={12} md={12}>
              <TextDivider>Other Requirements</TextDivider>
            </Grid>
            <FormGroup>
              <Grid container rowSpacing={2.5} columnSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextFieldSelectBRH
                    label="Type of Parking area"
                    name="parkingArea"
                    value={amentiesData.parkingArea}
                    handleChange={(e) => handleParkingAreaChange(e)}
                    options={[
                      { id: 'Basement', name: 'Basement' },
                      { id: 'Stilt', name: 'Stilt' },
                      { id: 'Open', name: 'Open' },
                    ]}
                    renderLabel="name"
                    renderValue="id"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInputBRH
                    label="Main Electricity Capacity"
                    name="electric_capacity"
                    value={amentiesData.electric_capacity}
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInputBRH
                    label="UPS Capacity"
                    value={amentiesData.ups_capacity}
                    handleChange={handleChange}
                    name="ups_capacity"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInputBRH
                    label="Capacity of DG Backup"
                    value={amentiesData.capacity_dg}
                    name="capacity_dg"
                    handleChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <div>
                    <span>
                      Lan Cable <span style={{ color: 'red' }}>*</span>
                    </span>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={amentiesData.lan_cable === 1 ? 'Yes' : amentiesData.lan_cable === 0 ? 'No' : ''}
                      onChange={handleChangeLanCable}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <div>
                    <span>
                      Audio Video <span style={{ color: 'red' }}>*</span>
                    </span>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={amentiesData.audio_video === 1 ? 'Yes' : amentiesData.audio_video === 0 ? 'No' : ''}
                      onChange={handleChangeAudio}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <div>
                    <span>
                      Access Control <span style={{ color: 'red' }}>*</span>
                    </span>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={amentiesData.access_control === 1 ? 'Yes' : amentiesData.access_control === 0 ? 'No' : ''}
                      onChange={handleChangeAccessControl}
                    >
                      <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                      <FormControlLabel value="No" control={<Radio />} label="No" />
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInputBRH
                    label="Available Internet Service Provider"
                    value={amentiesData.internetServiceProvider}
                    handleChange={handleChange}
                    name="internetServiceProvider"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInputBRH
                    label="Any other specific facilities (Specify)"
                    value={amentiesData.specificfacilities}
                    handleChange={handleChange}
                    name="specificfacilities"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInputBRH
                    label="Assets Available in the Building"
                    value={amentiesData.assets_building}
                    handleChange={handleChange}
                    name="assets_building"
                  />
                </Grid>
              </Grid>
            </FormGroup>

            <div style={{ display: 'flex', alignSelf: 'flex-end', marginTop: '30px' }}>
              <LoadingButtonBRH variant="containeds" label="Save & Next" type="submit" />
            </div>
          </FormControl>
        </form>
      </Box>
    </>
  );
};

export default Amenities;
