// common
export const COUNTRYLIST = 'user-management/api/v1/country?limit=300';
export const STATELIST = 'user-management/api/v1/state';
export const CITYLIST = 'user-management/api/v1/city';

// prospects
export const CLIENTADD = 'contract-management/api/v1/save-client';

// prospect Property
export const PROVIDERLIST = 'api/provider';
export const PROVIDERTYPELIST = 'api/propertytype';

// companymaster
export const COMPANYADD = 'contract-management/api/v1/save-company';
export const COMPANYLIST = 'contract-management/api/v1/get-all-company';
export const COMPANYSHOW = 'contract-management/api/v1/show-company';
export const COMPANYEDIT = 'contract-management/api/v1/update-company';

// clients
export const GETCLIENTS = 'contract-management/api/v1/get-all-client';
export const UPDATECLIENTS = 'contract-management/api/v1/update-client';
export const GETCLIENTDATA = 'contract-management/api/v1/show-client';

// crs

export const PROPERTYLISTCOUNTS = 'api/propertyCounts';

export const SCOUTPROPERTYLIST = 'api/property-type-properties/9';
export const BRHPROPERTYLIST = 'api/property-type-properties/4';
export const COWORKINGPROPERTYLIST = 'api/property-type-properties/1';
export const PROPERTYLIST = 'api/property';
export const PROPERTYTIMINGS = 'api/property-timings';
export const AMENTITIESLIST = 'api/amenities';
export const PROPERTYAMENTITY = 'api/propertyamenities';
export const PROPERTYRESOURCE = 'api/propertyresource';
export const CRSLIST = 'contract-management/api/v1/get-all-contract';
export const CRSALLLIST = 'contract-management/api/v1/get-all-renewal-contract';
export const ADDCRS = 'contract-management/api/v1/save-contract';
export const DELETECRS = 'contract-management/api/v1/delete-contract';
export const EDITCRS = 'contract-management/api/v1/update-contract';
export const SHOWCRS = 'contract-management/api/v1/show-contract';
export const GENERATEMOU = 'contract-management/api/v1/generate-mou';
export const INCLUSIONEXCLUSIONMASTER = 'api/inclusionexclusion?limit=10000';
export const KYCFILEUPLOAD = 'contract-management/api/v1/post-kyc-document';

// mou
export const MOULIST = 'contract-management/api/v1/get-all-mou';
export const MOUUPDATE = 'contract-management/api/v1/update-mou';

// contracts
export const CONTRACTFILEUPLOAD = 'contract-management/api/v1/post-document';
export const CONTRACTMOUFILEUPLOAD = 'contract-management/api/v1/post-mou-document';

// reminders
export const REMINDERDAYS = 'contract-management/api/v1/expiring-reminder';


