import { Button, Card, Container, Stack, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { TableSkeleton } from '../../components/skeleton/TableSkeleton';
import { NormalTable } from '../../components/tables/NormalTable';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
import Iconify from '../../components/iconify';
import palette from '../../theme/palette';
import { SCOUTPROPERTYLIST } from '../../API_SERVICE_LAYER/ApiNames';
import { contractContext } from '../../components/context/AppContext';

export const PrsList = () => {
  const navigate = useNavigate();

  const [prsData, setPrsData] = useState({
    prsListData: [],
    propertyList: [],
    isFetched: false,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'property_name',
        header: 'Property Name',
        size: 150,
      },
      {
        accessorKey: 'contact_person',
        header: 'Contact Person Name',
        size: 150,
      },

      {
        accessorKey: 'email',
        header: 'Email',
        size: 150,
      },
      {
        accessorKey: 'address2',
        header: 'Address',
        size: 150,
      },
    ],
    []
  );

  useEffect(() => {
    // AFTER CONTEXT
    API.getData(SCOUTPROPERTYLIST)
      .then((res) => {
        if (res?.data?.status === 'success') {
          const data = res?.data?.result;
          const sortedData = data.toReversed();
          setPrsData({
            ...prsData,
            isFetched: true,
            prsListData: sortedData,
          });
        }
      })
      .catch((err) => {
        console.log(' API-SCOUTPROPERTYLIST', err);
        setPrsData({ ...prsData, isFetched: true });
      });
  }, []);
  return (
    <>
      {prsData.isFetched ? (
        <>
          <Helmet>
            <title> Contracts | BRH </title>
          </Helmet>
          <Container>
            {/* page titls */}
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                PRS
              </Typography>
              <Button
                onClick={() => navigate(`${routeNames.PRSADD}`)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New PRS
              </Button>
            </Stack>
            {/* page body */}
            <Card sx={{ p: 2 }}>
              {/* <Scrollbar>
          <MaterialReactTable columns={columns} data={data} />
        </Scrollbar> */}
              <NormalTable columns={columns} data={prsData.prsListData} />
            </Card>
          </Container>
        </>
      ) : (
        <>
          <TableSkeleton />
        </>
      )}
    </>
  );
};
