import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Iconify from '../../../components/iconify/Iconify';

const IOSSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#f29a0e',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })
);

export default function EditClient() {
  return (
    <>
      <Helmet>
        <title> Edit Client | BRH </title>
      </Helmet>
      <Container>
        <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="/masters/client">
            Masters
          </Link>
          <Typography color="text.primary">Edit Client</Typography>
        </Breadcrumbs>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Edit Client
          </Typography>
        </Stack>

        <Card sx={{ p: 2 }}>
          <Scrollbar>
            <Box sx={{ flexGrow: 1, m: 4 }}>
              <FormControl sx={{ flexGrow: 1, width: '100%' }}>
                <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="filled-basic"
                      label="Company Name (Client)"
                      variant="outlined"
                    />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField sx={{ width: '100%' }} id="filled-basic" label="City" variant="outlined" />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField sx={{ width: '100%' }} id="filled-basic" label="Company Address" variant="outlined" />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="filled-basic"
                      label="Contact Person Name"
                      variant="outlined"
                    />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="filled-basic"
                      label="Contact Person Email"
                      variant="outlined"
                    />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="filled-basic"
                      label="Contact Person Number"
                      variant="outlined"
                    />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="filled-basic"
                      label="Contact Person Number"
                      variant="outlined"
                    />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="filled-basic"
                      label="Special Instructions"
                      variant="outlined"
                    />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker label="Estimated Date of Closure" sx={{ width: '100%' }} />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="filled-basic"
                      label="Estimated Value of Deal"
                      variant="outlined"
                    />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      sx={{ width: '100%' }}
                      id="filled-basic"
                      label="Conversion Probability"
                      variant="outlined"
                    />{' '}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControlLabel control={<IOSSwitch sx={{ m: 1 }} defaultChecked />} label="Status" />{' '}
                  </Grid>

                  <Stack sx={{ my: 2, mx: -6, width: '100%' }}>
                    <Button sx={{ width: '20%', height: '2.5rem', alignSelf: 'end' }} variant="contained">
                      Submit
                    </Button>
                  </Stack>
                </Grid>
              </FormControl>
            </Box>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
