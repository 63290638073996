import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useMemo, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { Helmet } from 'react-helmet-async';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';
import { SwichInputBRH } from '../../../components/forms/SwichInputBRH';
import { TimeInputBRH } from '../../../components/forms/TimeInputBRH';
import { CheckBoxInputBRH } from '../../../components/forms/CheckBoxInputBRH';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Iconify from '../../../components/iconify/Iconify';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { MoneyInputBRH } from '../../../components/forms/MoneyInputBRH';

const PlansAdd = () => {
  const data = [
    {
      name: {
        firstName: 'John',
        lastName: 'Doe',
      },
      address: '261 Erdman Ford',
      city: 'East Daphne',
      state: 'Kentucky',
    },
    {
      name: {
        firstName: 'Jane',
        lastName: 'Doe',
      },
      address: '769 Dominic Grove',
      city: 'Columbus',
      state: 'Ohio',
    },
    {
      name: {
        firstName: 'Joe',
        lastName: 'Doe',
      },
      address: '566 Brakus Inlet',
      city: 'South Linda',
      state: 'West Virginia',
    },
    {
      name: {
        firstName: 'Kevin',
        lastName: 'Vandy',
      },
      address: '722 Emie Stream',
      city: 'Lincoln',
      state: 'Nebraska',
    },
  ];
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name.firstName',
        header: 'First Name',
        size: 150,
      },
      {
        accessorKey: 'name.lastName',
        header: 'Last Name',
        size: 150,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 200,
      },
      {
        accessorKey: 'city',
        header: 'City',
        size: 150,
      },
      {
        accessorKey: 'state',
        header: 'State',
        size: 150,
      },
    ],
    []
  );
  return (
    <>
      <Accordion sx={{ background: 'light', padding: '20px' }}>
        <AccordionSummary
          sx={{ backgroundColor: '#f9fafb' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {' '}
          <Typography sx={{ background: '#f9fafb', width: '100%' }}>Add Resources Plan</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>

              </Typography>
            </Stack> */}
            <Card sx={{ flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1, m: 1 }}>
                <form noValidate={false}>
                  <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                    <Grid
                      container
                      sx={{ marginTop: '10px', justifyContent: 'center' }}
                      columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                    >
                      <Grid container rowSpacing={2.5} xs={12} sm={12} md={6}>
                        <Grid item xs={12} sm={12} md={11}>
                          <AutoCompleteInputBRH label="Create Plan For" name="create_plan" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <AutoCompleteInputBRH label="Resource Type" name="res_type" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH label="Access Period" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                          <AutoCompleteInputBRH label="Select Period" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <MoneyInputBRH label="Price" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <AutoCompleteInputBRH label="Invoice Template" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <NumberInputBRH label="Advance %" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <AutoCompleteInputBRH label="Sort Order" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <SwichInputBRH label="Enable Plan" />
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={2.5} xs={12} sm={12} md={6}>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH label="Plan Desription" multiline rows={4.5} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH label="Plan Name" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH label="HSN" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <MoneyInputBRH label="Price" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH label="Extra Information" multiline rows={5} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <SwichInputBRH label="Request Only Plan" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: '20px' }} rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TimeInputBRH
                          label="Working Hours From"
                          name="workingHoursStart"
                          // value={basicDetails.workingHoursStart}
                          // handleChange={(e) => setBascisDetails({ ...basicDetails, workingHoursStart: e })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TimeInputBRH
                          label="Working Hours To"
                          name="workingHoursEnd"
                          // value={basicDetails.workingHoursEnd}
                          // handleChange={(e) => setBascisDetails({ ...basicDetails, workingHoursEnd: e })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid container sx={{ border: '1px solid #ccc', padding: '5px 10px' }}>
                          <Grid item xs={12}>
                            <Typography>Working Days</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Sun"
                              name="workingDaySun"
                              // value={basicDetails.workingDaySun}
                              // handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Mon"
                              name="workingDayMon"
                              // value={basicDetails.workingDayMon}
                              // handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Tue"
                              name="workingDayTue"
                              // value={basicDetails.workingDayTue}
                              // handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Wed"
                              name="workingDayWed"
                              // value={basicDetails.workingDayWed}
                              // handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Thu"
                              name="workingDayThu"
                              // value={basicDetails.workingDayThu}
                              // handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Fri"
                              name="workingDayFri"
                              // value={basicDetails.workingDayFri}
                              // handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Sat"
                              name="workingDaySat"
                              // value={basicDetails.workingDaySat}
                              // handleChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                        }}
                        item
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        <Button sx={{ height: '2.5rem', width: 150, mx: 1 }} variant="contained">
                          Add Plans
                        </Button>
                        <Button sx={{ height: '2.5rem', width: 150, mx: 1 }} variant="contained">
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </FormControl>{' '}
                </form>
              </Box>
            </Card>
          </Container>
        </AccordionDetails>
      </Accordion>
      <Container sx={{ marginTop: '20px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Plan List
          </Typography>
        </Stack>
        <Card sx={{ p: 2 }}>
          <Scrollbar>
            <MaterialReactTable columns={columns} data={data} />
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
};

export default PlansAdd;
