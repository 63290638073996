import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { EDITCRS, KYCFILEUPLOAD } from '../../API_SERVICE_LAYER/ApiNames';
import { ToastBRH } from '../../components/toasts/ToastBRH';
import { FileInputBRH } from '../../components/forms/FileInputBRH';
import palette from '../../theme/palette';

export const KycAlert = ({
  children,
  open,
  onSetOpen,
  isApprove,
  contractId,
  onUpdate,
  KYC_ACCEPTED,
  NO_KYC ,
  KYC_REJECTED,
}) => {
  const [file, setFile] = useState(null);
  const [rejectedReason, setRejectedReason] = useState(null);
  const [isReasonFilled, setIsReasonFilled] = useState(true);
  const [isFileSelected, setIsFileSelected] = useState(true);

  console.log(isApprove,"MNJKL")

  const handleValidation = () => {
    if (!file) {
      setIsFileSelected(false);
      setTimeout(() => setIsFileSelected(true), 3000);
      return -2;
    }
    if (!rejectedReason) {
      setIsReasonFilled(false);
      setTimeout(() => setIsReasonFilled(true), 3000);
      return -1;
    }
    setIsReasonFilled(true);
    return 1;
    // else {
    // }
  };

  const handleReject = (status) => {
    const isValid = handleValidation();

    const formData = new FormData();
    // formData.append('id', contractId);
    // formData.append('kyc_document', file);
    // formData.append('kyc_status', status);
    // formData.append('kyc_reason', rejectedReason);
    if (isValid === 1) {
      formData.append('id', contractId);
      formData.append('file', file);
      formData.append('kyc_reason', rejectedReason);
      API.fileupload(KYCFILEUPLOAD, formData)
        .then((res) => {
          if (res?.data?.status === 'success') {
            ToastBRH('success', 'KYC Rejected Successfully', '_', false);
            onUpdate(true);
            onSetOpen();
          }
        })
        .catch((err) => {
          ToastBRH('warning', 'Something Went Wrong! Please Try Again', '_', false);
          console.log(err);
        });
      // API.updateMethod(EDITCRS, formData)
      //   .then((res) => {
      //     if (res?.data?.status === 'success') {
      //       ToastBRH('success', 'KYC Rejected Successfully', '_', false);
      //       onUpdate(true);
      //       onSetOpen();
      //     }
      //   })
      //   .catch((err) => {
      //     ToastBRH('warning', 'Something Went Wrong! Please Try Again', '_', false);
      //     console.log(err);
      //   });
    }
  };


  const handleNoKYC = () => {
    // const isValid = handleValidation();

    const formData = new FormData();
    // formData.append('id', contractId);
    // formData.append('kyc_document', file);
    // formData.append('kyc_status', status);
    // formData.append('kyc_reason', rejectedReason);
    // if (isValid === 1) {
      formData.append('id', contractId);
      formData.append('file', file);
      // formData.append('kyc_reason', rejectedReason);
      API.fileupload(KYCFILEUPLOAD, formData)
        .then((res) => {
          if (res?.data?.status === 'success') {
            ToastBRH('success', 'Submitted Successfully', '_', false);
            onUpdate(true);
            onSetOpen();
          }
        })
        .catch((err) => {
          ToastBRH('warning', 'Something Went Wrong! Please Try Again', '_', false);
          console.log(err);
        });
  
    }
  


  const handleAccept = (status) => {
    if (!file) {
      setIsFileSelected(false);
      setTimeout(() => setIsFileSelected(true), 3000);
      return;
    }
    const formData = new FormData();
    formData.append('id', contractId);
    formData.append('file', file);
    API.fileupload(KYCFILEUPLOAD, formData)
      .then((res) => {
        if (res?.data?.status === 'success') {
          onUpdate(true);
          ToastBRH('success', 'KYC Accepted Successfully', '_', false);
          onSetOpen();
        }
      })
      .catch((err) => {
        ToastBRH('warning', 'Something Went Wrong! Please Try Again', '_', false);
        console.log(err);
      });
    // formData.append('id', contractId);
    // formData.append('kyc_document', file);
    // formData.append('kyc_status', status);
    // API.updateMethod(EDITCRS, formData)
    //   .then((res) => {
    //     if (res?.data?.status === 'success') {
    //       onUpdate(true);
    //       ToastBRH('success', 'KYC Accepted Successfully', '_', false);
    //       onSetOpen();
    //     }
    //   })
    //   .catch((err) => {
    //     ToastBRH('warning', 'Something Went Wrong! Please Try Again', '_', false);
    //     console.log(err);
    //   });
  };
  return (
    <>
    {isApprove===5 ?
    <form >

<Dialog open={open}>
          <DialogTitle>KYC</DialogTitle>
          <DialogContent>
            <DialogContentText>{`Do you want No KYC option`} </DialogContentText>
            <Box sx={{ margin: '16px 0px', minWidth: '400px' }}>
              
              {/* {isApprove === 0 ? (
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Rejection Reason"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={rejectedReason}
                  onChange={(e) => setRejectedReason(e.target.value)}
                  sx={{ marginTop: '8px' }}
                  required
                />
              ) : (
                <></>
              )} */}
            </Box>
            <Box sx={{ display: isFileSelected === true && isReasonFilled === true ? 'none' : 'block' }}>
              <Typography
                sx={{
                  color: palette.error.dark,
                  fontStyle: 'italic',
                  fontWeight: '600',
                  marginTop: '16px',
                  paddingLeft: '8px',
                }}
              >
                Do you want No KYC option
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onSetOpen} variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() =>  handleNoKYC(NO_KYC)
                // (isApprove ? handleAccept(KYC_ACCEPTED) : handleReject(KYC_REJECTED))
              }
              variant="contained"
            >
              Yes, I confirm
            </Button>
          </DialogActions>
        </Dialog>
    </form>
    :
  
      <form>
        <Dialog open={open}>
          <DialogTitle>KYC</DialogTitle>
          <DialogContent>
            <DialogContentText>{`Do you want to ${isApprove ? 'Approve' : 'Reject'} KYC`} </DialogContentText>
            <Box sx={{ margin: '16px 0px', minWidth: '400px' }}>
              <FileInputBRH
                buttonText={file?.name ?? 'Upload KYC document'}
                handleFileUpload={(e) => setFile(e.target.files[0])}
                color="secondary"
              />
              {isApprove === 0 ? (
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Rejection Reason"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={rejectedReason}
                  onChange={(e) => setRejectedReason(e.target.value)}
                  sx={{ marginTop: '8px' }}
                  required
                />
              ) : (
                <></>
              )}
            </Box>
            <Box sx={{ display: isFileSelected === true && isReasonFilled === true ? 'none' : 'block' }}>
              <Typography
                sx={{
                  color: palette.error.dark,
                  fontStyle: 'italic',
                  fontWeight: '600',
                  marginTop: '16px',
                  paddingLeft: '8px',
                }}
              >
                {!isFileSelected
                  ? 'Please Upload KYC File'
                  : !isReasonFilled
                  ? 'Please fill Rejection Reason before Rejecting'
                  : 'KK'}
                {/* Please fill Rejection Reason before Rejecting */}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onSetOpen} variant="outlined" color="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => (isApprove ? handleAccept(KYC_ACCEPTED) : handleReject(KYC_REJECTED))}
              variant="contained"
            >
              {isApprove ? 'Approve' : 'Reject'} KYC
            </Button>
          </DialogActions>
        </Dialog>
      </form>
}
    </>
  );
};
