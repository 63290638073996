import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { KYCFILEUPLOAD } from '../../API_SERVICE_LAYER/ApiNames';
import { ToastBRH } from '../../components/toasts/ToastBRH';
import { FileInputBRH } from '../../components/forms/FileInputBRH';
import palette from '../../theme/palette';
import { TextInputBRH } from '../../components/forms/TextInputBRH';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
import { TOAST_SUCCESS } from '../../utils/toastTimings';

export const KycReApproveAlert = ({ open, onSetOpen, contractId, onUpdate }) => {
  const [reApproveRemarks, setSetReApproveRemarks] = useState(null);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', contractId);
    formData.append('remark', reApproveRemarks);
    API.fileupload(KYCFILEUPLOAD, formData)
      .then((res) => {
        if (res.data.status === 'success') {
          ToastBRH('success', 'KYC Re-Approved Successfully', TOAST_SUCCESS);
          setTimeout(() => navigate(routeNames.CRS), TOAST_SUCCESS);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>KYC</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>Do you want to Re-Approve KYC</DialogContentText>
            <Box sx={{ margin: '16px 0px', minWidth: '400px' }}>
              <TextInputBRH
                name=""
                label="Remarks for Re-Approve"
                value={reApproveRemarks}
                handleChange={(e) => setSetReApproveRemarks(e.target.value)}
                required
              />
            </Box>
          </DialogContent>
          <DialogActions sx={{ paddingBottom: '16px' }}>
            <Button variant="outlined" color="secondary" type="button" onClick={onSetOpen}>
              Go Back
            </Button>
            <Button variant="contained" type="submit">
              Re-Approve
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
