import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  TextareaAutosize,
  Typography,
  stepContentClasses,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';
import { MobileInputBRH } from '../../../components/forms/MobileInputBRH';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { CheckBoxInputBRH } from '../../../components/forms/CheckBoxInputBRH';

import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';
import { ToastBRH } from '../../../components/toasts/ToastBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { CommonAlert } from '../../../components/toasts/CommonAlert';

const Others = ({ propertyId, onChange }) => {
  const [othersData, setOthersData] = useState({
    socialInfra: [],
    propertyInfra: [],
    propertyBank: '',
    branch_name: '',
    ifsc_code: '',
    account_name: '',
    account_no: '',
    documents: '',
    gst_registration_num: null,
    is_draft: '',
    cctv_link: '',
    mandateData: [
      ' Mandate Vaccination Certificate on Check In',
      ' Mandate Temperature on Check In',
      ' Mandate ID Proof Verification on Check In ',
    ],
    mandateStatus: [],
  });
  const [distances, setDistances] = useState({});
  const [draftopen, setDraftOpen] = useState(false);
  const handleDraftBack = () => {
    setDraftOpen(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    console.log(propertyId);
    const id = propertyId;
    API.getData('api/socialinfra?limit=100')
      .then((res) => {
        console.log(res.data.data);
        if (res?.data?.status === 'success') {
          const apiData = res?.data?.data?.data;
          setOthersData((prevDetails) => ({
            ...prevDetails,
            socialInfra: apiData,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });

    API.getData(`api/propertiessocialinfra/${id}`).then((result) => {
      const apiData = result?.data?.data;
      setOthersData((prevDetails) => ({
        ...prevDetails,
        propertyInfra: apiData,
      }));
    });
    API.getData(`api/propertybankdetails/${id}`)
      .then((resBank) => {
        console.log(resBank);
        console.log(resBank.data.status);
        if (resBank?.data?.status === 'success') {
          setOthersData((prevDetails) => ({
            ...prevDetails,
            propertyBank: resBank.data,
            branch_name: resBank?.data?.data?.branch_name,
            ifsc_code: resBank?.data?.data?.ifsc_code,
            account_name: resBank?.data?.data?.account_name,
            account_no: resBank?.data?.data?.account_no,
          }));
        }
      })
      .catch((errBank) => {
        console.log(errBank);
      });

    API.getData(`api/propertydocuments/${id}`)
      .then((resDoc) => {
        console.log(resDoc);
        if (resDoc?.data?.status === 'success') {
          setOthersData((prevDetails) => ({
            ...prevDetails,
            documents: resDoc.data.data,
          }));
        }
      })
      .catch((errDoc) => {
        console.log(errDoc);
      });

    API.getData(`api/property/${id}`).then((resProp) => {
      console.log(resProp);
      if (resProp?.data?.status === 'success') {
        // console.log(resProp?.data?.data?.gst_registration_num);
        setOthersData((prevDetails) => ({
          ...prevDetails,
          gst_registration_num: resProp?.data?.data?.gst_registration_num,
          is_draft: resProp?.data?.data?.is_draft,
          cctv_link: resProp?.data?.data?.cctv_link,
        }));
      }
    });
  }, [propertyId]);
  // bank Details handle
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setOthersData({ ...othersData, [name]: value });
  };

  // Mandate Handle
  const handleMandateChange = (index, isChecked) => {
    const updatedMandateData = [...othersData.mandateStatus];
    updatedMandateData[index].status = isChecked ? 1 : 0;
    setOthersData((prev) => ({ ...prev, mandateStatus: updatedMandateData }));
  };
  console.log(othersData.mandateStatus, othersData.documents);
  // Function to initialize mandateData with default status
  const initializeMandateData = () => {
    const initialMandateData = othersData?.mandateData?.map((mandate, index) => ({
      mandate_id: `${index + 1}`, // Assuming mandate_id is derived from index
      status: othersData.documents.includes(index + 1) ? 1 : 0, // Default status when documents are empty
    }));
    setOthersData((prev) => ({ ...prev, mandateStatus: initialMandateData }));
  };

  // Call initializeMandateData when component mounts
  useEffect(() => {
    initializeMandateData();
  }, [othersData.mandateData, othersData.documents]);
  console.log(othersData.propertyInfra);

  // HandlePropertyTabs

  const handlePropertyTabs = (Tabs) => {
    const tabsdata = {
      property_id: propertyId,
      tabs: Tabs,
    };

    API.postMethod('api/propertytabs', tabsdata).then((result) => {
      console.log(result.data.status, 'staysu');
      if (result.data.status === 'success') {
        // window.location.reload();
        navigate(routeNames.PROSPECTPROPERTYLIST);
      }
    });
  };
  // handle Previous propertyTabs
  const handlePreviousPropertyTabs = (Tabs) => {
    const tabsdata = {
      property_id: propertyId,
      tabs: Tabs,
    };

    API.postMethod('api/propertytabs', tabsdata).then((result) => {
      console.log(result.data.status, 'staysu');
      if (result.data.status === 'success') {
        window.location.reload();
        // navigate(routeNames.PROSPECTPROPERTYLIST);
      }
    });
  };

  // Handle Draft
  const handleSaveAsDraft = () => {
    const data = {
      property_id: propertyId,
      account_no: othersData.account_no,
      account_name: othersData.account_name,
      branch_name: othersData.branch_name,
      ifsc_code: othersData.ifsc_code,
      status: 1,
      cctv_link: othersData.cctv_link,

      gst_registration_num: othersData.gst_registration_num,
      hsc_checkin: othersData.mandateStatus,
      is_draft: 1,
    };

    API.postMethod('api/bankdetails', data).then((result) => {
      if (result.data.status === 'success') {
        localStorage.setItem('message', result.message);
        ToastBRH('success', result.data.message, '___', false);
        handlePropertyTabs('others'); // window.location.href = "/property-list";
      }
      console.log(result);
    });
  };
  // handleSubmitForm
  const handleFormSubmit = (e) => {
    e.preventDefault();

    const data = {
      property_id: propertyId,
      account_no: othersData.account_no,
      account_name: othersData.account_name,
      branch_name: othersData.branch_name,
      ifsc_code: othersData.ifsc_code,
      status: 1,
      cctv_link: othersData.cctv_link,

      gst_registration_num: othersData.gst_registration_num,
      hsc_checkin: othersData.mandateStatus,
      is_draft: 0,
    };
    API.postMethod('api/bankdetails', data).then((result) => {
      console.log(result);
      if (result.data.status === 'success') {
        localStorage.setItem('message', result.data.message);
        ToastBRH('success', result.data.message, '___', false);
        handlePropertyTabs('basicdetails');
        // window.location.href = "/property-list";
      }
    });
  };
  const handleDistanceChange = (event, propertyId, infra, othersData, setOthersData) => {
    const newDistance = event.target.value;
    // console.log(event.target);
    const data = {
      property_id: propertyId,
      social_infra_id: event.target.id,
      distance: event.target.value,
    };
    setDistances((prevDistances) => ({
      ...prevDistances,
      [infra.id]: newDistance,
    }));

    API.postMethod('api/propertysocialinfra', data).then((result) => {
      console.log(result, 're');
    });
    // Find the index of the matching propertyInfra element
    // const indexOfMatchingElement = othersData.propertyInfra.findIndex((values) => {
    //   return values.social_infra_id === infra.id && values.property_id === Number(propertyId);
    // });

    // // If a matching element is found, update its distance
    // if (indexOfMatchingElement !== -1) {
    //   const updatedPropertyInfra = [...othersData.propertyInfra]; // Create a copy
    //   updatedPropertyInfra[indexOfMatchingElement].distance = newDistance;

    //   // Update the state with the modified propertyInfra array
    //   // Example using useState:
    //   setOthersData((prev) => ({ ...prev, propertyInfra: updatedPropertyInfra }));
    // }
  };
  console.log(othersData?.socialInfra, othersData.propertyInfra);
  useEffect(() => {
    // After fetching the data, update distances based on propertyInfra
    othersData?.socialInfra.forEach((value) => {
      const matchingPropertyInfra = othersData.propertyInfra?.find((values) => {
        return values.social_infra_id === value.id && values.property_id === Number(propertyId);
      });
      console.log('q', matchingPropertyInfra);
      if (matchingPropertyInfra) {
        setDistances((prevDistances) => ({
          ...prevDistances,
          [value.id]: matchingPropertyInfra.distance,
        }));
      } else {
        setDistances((prevDistances) => ({
          ...prevDistances,
          [value.id]: '',
        }));
      }
    });
  }, [othersData?.socialInfra, othersData.propertyInfra, propertyId]);
  console.log('d', distances);
  return (
    <>
      <Container>
        <Box sx={{ flexGrow: 1, m: 2 }}>
          <Typography sx={{ background: '#f9fafb', width: '100%', mb: 2 }}>Social Infra Details</Typography>

          <form noValidate={false} onSubmit={(e) => handleFormSubmit(e)}>
            <Card sx={{ p: 2 }}>
              <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                  {othersData?.socialInfra?.map((infra, index) => {
                    // socialinfra handle
                    // const matchingPropertyInfra = othersData.propertyInfra?.find((values) => {
                    //   //  console.log(values.property_id, propertyId);
                    //   return values.social_infra_id === infra.id && values.property_id === Number(propertyId);
                    // });
                    // const distance = matchingPropertyInfra ? matchingPropertyInfra.distance : '';
                    // console.log(distance === '', infra.id);
                    const distance = distances[infra.id] || '';
                    return (
                      <Grid item xs={12} sm={12} md={6}>
                        <NumberInputBRH
                          label={`${infra.social_infra}(Km)`}
                          value={distance}
                          id={infra.id}
                          handleChange={(e) => handleDistanceChange(e, propertyId, infra, othersData, setOthersData)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </FormControl>
            </Card>
            <Typography sx={{ background: '#f9fafb', width: '100%', mt: 4 }}>Bank Details</Typography>

            <Card sx={{ p: 2, mt: 2 }}>
              <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextInputBRH
                      label="Bank Account Name"
                      value={othersData.account_name}
                      name="account_name"
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <NumberInputBRH
                      label="Bank Account Number"
                      value={othersData.account_no}
                      name="account_no"
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextInputBRH
                      label="Branch Name"
                      value={othersData.branch_name}
                      name="branch_name"
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextInputBRH
                      label="IFSC Code"
                      value={othersData.ifsc_code}
                      name="ifsc_code"
                      handleChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Card>
            <Typography sx={{ background: '#f9fafb', width: '100%', mt: 4 }}>
              Mandatory Setup for HSE & Check In
            </Typography>
            <Card sx={{ p: 2, mt: 2 }}>
              {othersData.mandateData?.map((i, index) => {
                console.log('st', othersData.mandateStatus[index]?.status === 1);
                return (
                  <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                    <Grid container columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <div
                              style={{
                                display: 'flex',

                                alignItems: 'center',

                                height: '10vh',
                                marginRight: '1vw',
                              }}
                            >
                              <Checkbox
                                // checked={freeAmenities.selectedFreeAmentities?.includes(`${ame.id}`)}
                                // defaultChecked={selectedAmenities.some(
                                //   (selected) => selected.amentity_id === item.id
                                // )}
                                checked={othersData.mandateStatus[index]?.status === 1}
                                onChange={(e) => handleMandateChange(index, e.target.checked)}
                              />
                            </div>
                          }
                          label={i}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                );
              })}
            </Card>
            <Typography sx={{ background: '#f9fafb', width: '100%', mt: 4 }}>Other Details</Typography>
            <Card sx={{ p: 2, mt: 2 }}>
              <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextInputBRH
                      label="GST Registration Number"
                      name="gst_registration_num"
                      value={othersData.gst_registration_num}
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextInputBRH
                      label="Property CCTV Link"
                      name="cctv_link"
                      value={othersData.cctv_link}
                      handleChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Card>
            <Grid
              sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', mt: 4 }}
              item
              xs={12}
              sm={12}
              md={6}
            >
              <Button
                sx={{
                  height: '2.5rem',
                  width: 150,
                  mx: 1,
                  backgroundColor: 'grey',
                  color: 'white',
                  ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
                }}
                variant="contained"
                onClick={() => navigate(routeNames.PROSPECTPROPERTYLIST)}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  height: '2.5rem',
                  width: 150,
                  mx: 1,
                  backgroundColor: 'grey',
                  color: 'white',
                  ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
                }}
                variant="contained"
                onClick={onChange}
              >
                Previous
              </Button>
              <Button
                sx={{
                  height: '2.5rem',
                  width: 150,
                  mx: 1,
                  backgroundColor: 'grey',
                  color: 'white',
                  ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
                }}
                variant="contained"
                onClick={() => setDraftOpen(true)}
              >
                Save as Draft
              </Button>
              {localStorage.getItem('verified') === '1' || localStorage.getItem('verified') === '2' ? (
                <LoadingButtonBRH variant="containeds" label="Update" type="submit" />
              ) : (
                <LoadingButtonBRH variant="containeds" label="Save & Next" type="submit" />
              )}
            </Grid>
          </form>
        </Box>
      </Container>
      <CommonAlert
        open={draftopen}
        setKycOpen={setDraftOpen}
        message="All the form Data will be save as draft, do you wish to Proceed ?"
        proceedFunction={handleSaveAsDraft}
        goBackFunction={handleDraftBack}
      />
    </>
  );
};

export default Others;
