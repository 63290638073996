import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import MaterialReactTable from 'material-react-table';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/material/Icon';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';

import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import TextDivider from '../../../components/text-divider/TextDivider';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import { ToastBRH } from '../../../components/toasts/ToastBRH';

const PropertyCommercials = () => {
  const [state, setState] = React.useState({
    ManagerCabins: false,
    disRooms: false,
    confRooms: false,
    trainingRooms: false,
    serverRooms: false,
    storeRooms: false,
  });

  const handleChangeCheckbox = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { ManagerCabins, disRooms, confRooms, trainingRooms, serverRooms, storeRooms } = state;
  // const error = [ManagerCabins, disRooms, confRooms, trainingRooms].filter((v) => v).length !== 2;
  const [commerCialsData, setCommercialsData] = useState({
    // property commercials
    rental: '',
    total_rental: '',
    cam_charges: '',
    Cost_for_Refurbishment: '',
    Rentals_for_Refurbishment: '',

    // fitouts
    noOfWorkStations: '',
    workStationSize: '',
    typeofWorkStation: '',
    managerCabins: '',
    resourceNameObj: null,
    Quantity: '',
    Capacity: '',
    disRooms: '',
    confRooms: '',
    trainingRooms: '',
    serverRooms: '',
    storeRooms: '',
    receptionArea: '',
    pantryArea: '',
    noCollabArea: '',
    labArea: '',
    ResourceArray: [],
  });
  const handleResourceNameChange = (e, newValue) => {
    setCommercialsData((prev) => ({ ...prev, resourceNameObj: newValue }));
  };
  const columns = useMemo(() => [
    {
      accessorKey: 'resourceName',
      header: 'Resource Name',
      Cell: ({ cell, row }) => {
        return <span> {cell.getValue().resource_name}</span>;
      },
      Edit: ({ cell, row }) => {
        return <TextField value={cell.getValue().resource_name} variant="standard" disabled />;
      },
    },
    {
      accessorKey: 'Quantity',
      header: 'Quantity',
      Cell: ({ cell, row }) => {
        return <span> {cell.getValue()}</span>;
      },
    },

    {
      accessorKey: 'Capacity',
      header: 'Capacity',
      Cell: ({ cell, row }) => {
        return <span>{cell.getValue()}</span>;
      },
    },
  ]);
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    //  if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
    commerCialsData.ResourceArray[row.index] = values;
    console.log(values);
    // send/receive api updates here
    setCommercialsData((prev) => ({ ...prev, ResourceArray: [...commerCialsData.ResourceArray] }));

    exitEditingMode(); //  required to exit editing mode
  };
  console.log(commerCialsData.ResourceArray);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setCommercialsData({ ...commerCialsData, [name]: value });
  };
  const handleChangePropertyComercials = (e) => {
    const { name, value, type, checked } = e.target;
    setCommercialsData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddresource = () => {
    const { resourceNameObj, Capacity, Quantity } = commerCialsData;
    if (resourceNameObj === null || Capacity === '' || Quantity === '') {
      ToastBRH('warning', 'Please provide all details', '__', false);
      return;
    }
    setCommercialsData((prev) => ({
      ...prev,
      ResourceArray: [...prev.ResourceArray, { resourceName: resourceNameObj, Capacity: Capacity, Quantity: Quantity }],
      resourceNameObj: null,
      Quantity: '',
      Capacity: '',
    }));
  };

  const handlePropertyCommercials = (e) => {
    e.preventDefault();

    const data = {
      rental: commerCialsData.rental,
      total_rental: commerCialsData.total_rental,
      cam_charges: commerCialsData.cam_charges,
      Cost_for_Refurbishment: commerCialsData.Cost_for_Refurbishment,
      Rentals_for_Refurbishment: commerCialsData.Rentals_for_Refurbishment,
      no_workStations: commerCialsData.noOfWorkStations,
      workStation_size: commerCialsData.workStationSize,
      type_workStation: commerCialsData.typeofWorkStation,
      manager_cabins: commerCialsData.managerCabins,
      disRooms: commerCialsData.disRooms,
      confRooms: commerCialsData.confRooms,
      trainingRooms: commerCialsData.trainingRooms,
      serverRooms: commerCialsData.serverRooms,
      storeRooms: commerCialsData.storeRooms,
      receptionArea: commerCialsData.receptionArea,
      pantryArea: commerCialsData.pantryArea,
      noCollabArea: commerCialsData.noCollabArea,
      labArea: commerCialsData.labArea,
    };
    console.log(data);
  };

  return (
    <form onSubmit={handlePropertyCommercials}>
      <Grid item sm={12} md={12}>
        <TextDivider> Property Commercials</TextDivider>
      </Grid>
      <div>
        <Grid container rowSpacing={2.5} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Rental"
              name="rental"
              value={commerCialsData.rental}
              handleChange={handleChangePropertyComercials}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="CAM Charges"
              name="cam_charges"
              required
              value={commerCialsData.cam_charges}
              handleChange={handleChangePropertyComercials}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Total Rental"
              name="total_rental"
              required
              value={commerCialsData.total_rental}
              handleChange={handleChangePropertyComercials}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Cost for Refurbishment"
              name="Cost_for_Refurbishment"
              value={commerCialsData.Cost_for_Refurbishment}
              handleChange={handleChangePropertyComercials}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Rentals for Refurbishment"
              name="Rentals_for_Refurbishment"
              value={commerCialsData.Rentals_for_Refurbishment}
              handleChange={handleChangePropertyComercials}
            />
          </Grid>
        </Grid>
      </div>

      <Grid item sm={12} md={12}>
        <TextDivider> Fitouts</TextDivider>
      </Grid>

      <FormControl sx={{ flexGrow: 1, width: '100%' }} sub="true">
        <Grid container rowSpacing={2.5} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="No of WorkStations"
              name="noOfWorkStations"
              value={commerCialsData.noOfWorkStations}
              handleChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Work Station Size"
              name="workStationSize"
              value={commerCialsData.workStationSize}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Type of WorkStation"
              name="typeofWorkStation"
              value={commerCialsData.typeofWorkStation}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Server Room"
              name="serverRooms"
              handleChange={handleChange}
              value={commerCialsData.serverRooms}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              control={<Checkbox checked={ManagerCabins} onChange={handleChangeCheckbox} name="ManagerCabins" />}
              label="Manager Cabins"
              //   style={{ marginTop: ManagerCabins ? '-10px' : '5px' }}
            />
            {ManagerCabins && (
              <NumberInputBRH
                label="Capacity"
                name="managerCabins"
                value={commerCialsData.managerCabins}
                handleChange={handleChange}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              control={<Checkbox checked={disRooms} onChange={handleChangeCheckbox} name="disRooms" />}
              label="Discussions Rooms"
              //  style={{ marginTop: disRooms ? '-10px' : '5px' }}
            />
            {disRooms && (
              <NumberInputBRH
                label="Capacity"
                name="disRooms"
                value={commerCialsData.disRooms}
                handleChange={handleChange}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              control={<Checkbox checked={confRooms} onChange={handleChangeCheckbox} name="confRooms" />}
              label="Conference Rooms"
              //   style={{ marginTop: confRooms ? '-10px' : '5px' }}
            />
            {confRooms && (
              <NumberInputBRH
                label="Capacity"
                name="confRooms"
                value={commerCialsData.confRooms}
                handleChange={handleChange}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              control={<Checkbox checked={trainingRooms} onChange={handleChangeCheckbox} name="trainingRooms" />}
              label="Training Rooms"
              //  style={{ marginTop: trainingRooms ? '-10px' : '5px' }}
            />
            {trainingRooms && (
              <NumberInputBRH
                label="Capacity"
                name="trainingRooms"
                value={commerCialsData.trainingRooms}
                handleChange={handleChange}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Server Room"
              name="serverRooms"
              handleChange={handleChange}
              value={commerCialsData.serverRooms}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Store Room"
              name="storeRooms"
              value={commerCialsData.storeRooms}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Reception Area"
              name="receptionArea"
              value={commerCialsData.receptionArea}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <NumberInputBRH
              label="Pantry Area"
              name="pantryArea"
              value={commerCialsData.pantryArea}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <NumberInputBRH
              label="No. Collaboration area"
              name="noCollabArea"
              value={commerCialsData.noCollabArea}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH label="Lab Area" name="labArea" value={commerCialsData.labArea} handleChange={handleChange} />
          </Grid>
        </Grid>
        <span style={{ fontSize: '20px', letterSpacing: 1, marginTop: '5px', marginBottom: '5px' }}>Resource</span>

        <Grid container rowSpacing={2.5} columnSpacing={2} className="d-flex align-items-center">
          <Grid item xs={12} sm={12} md={5}>
            <AutoCompleteInputBRH
              label="Resource name"
              name="resource_name"
              value={commerCialsData.resourceNameObj}
              handleChange={handleResourceNameChange}
              options={[
                { id: 1, resource_name: 'Manager cabin' },
                { id: 2, resource_name: 'Manager ' },
              ]}
              required
            />{' '}
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <NumberInputBRH
              label="Quantity"
              name="Quantity"
              value={commerCialsData.Quantity}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <NumberInputBRH
              label="Capacity"
              name="Capacity"
              value={commerCialsData.Capacity}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={1}>
            <Button
              component="label"
              variant="contained"
              onClick={handleAddresource}
              fullWidth
              startIcon={<AddCircleOutlineIcon color="green" />}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        {commerCialsData.ResourceArray.length > 0 && (
          <MaterialReactTable
            columns={columns}
            data={commerCialsData.ResourceArray}
            editingMode="row"
            enableEditing
            onEditingRowSave={handleSaveRow}
          />
        )}
      </FormControl>

      <div style={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end', marginTop: '30px' }}>
        <LoadingButtonBRH variant="containeds" label="Save & Next" type="submit" />
      </div>
    </form>
  );
};

export default PropertyCommercials;
