import React, { useState } from 'react';
import { Card, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material';
import TextDivider from '../../../components/text-divider/TextDivider';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';

import { ToastBRH } from '../../../components/toasts/ToastBRH';

const ComplianceSafety = () => {
  const [formData, setFormData] = useState({
    NOCEnquiry: null,
    OCEnquiry: null,
    FireHydrantSystemEnquiry: null,
    radioFireExtingushers: null,
    radioCCTV: null,
    radioAccessControl: null,
    smokeDetectorSystem: null,
    sprinler: null,
    sprinklerAndSmoke: '',
    fireExtinguishers: '',
    otherClearances: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value === 'Yes' ? 1 : value === 'No' && 0,
    }));
  };
  const handleChangeDesc = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.NOCEnquiry === null ||
      formData.OCEnquiry === null ||
      formData.FireHydrantSystemEnquiry === null ||
      formData.radioFireExtingushers === null ||
      formData.radioCCTV === null ||
      formData.radioAccessControl === null ||
      formData.sprinler === null ||
      formData.smokeDetectorSystem === null
    ) {
      ToastBRH('warning', 'Please Answer All required Questions', '___', false);
      return;
    }

    console.log(formData);
  };
  return (
    <form onSubmit={handleSubmit}>
      <FormControl sx={{ flexGrow: 1, width: '100%' }} sub="true">
        <Grid item sm={12} md={12}>
          <TextDivider>COMPLIANCE, SAFETY & SECURITY SYSTEMS</TextDivider>
        </Grid>
        <Card style={{ padding: '20px 40px', marginBottom: '20px' }}>
          <Grid container rowSpacing={2.5} columnSpacing={2}>
            <Grid
              container
              className="d-flex flex-row align-items-center  mt-3"
              xs={12}
              sm={12}
              md={6}
              // style={{ marginLeft: '20px' }}
            >
              <FormControl required>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: 'black', fontWeight: 500 }}>
                  <span style={{ fontWeight: 500 }}>1.</span> Is the Fire NOC in Place for the Building?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="NOCEnquiry"
                  value={formData.NOCEnquiry === 0 ? 'No' : formData.NOCEnquiry === 1 && 'Yes'}
                  onChange={handleChange}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              container
              className="d-flex flex-row align-items-center  mt-3"
              xs={12}
              sm={12}
              md={6}
              // style={{ marginLeft: '20px' }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: 'black', fontWeight: 500 }}>
                  <span style={{ fontWeight: 500 }}>2.</span> Is the Occupancy Certificate in Place for the Building?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="OCEnquiry"
                  value={formData.OCEnquiry === 0 ? 'No' : formData.OCEnquiry === 1 && 'Yes'}
                  onChange={handleChange}
                  aria-required
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              container
              className="d-flex flex-row align-items-center  mt-3"
              xs={12}
              sm={12}
              md={6}
              // style={{ marginLeft: '20px' }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: 'black', fontWeight: 500 }}>
                  <span style={{ fontWeight: 500 }}>3.</span> Is the Building Equipped with Fire Hydrant Systems?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="FireHydrantSystemEnquiry"
                  value={
                    formData.FireHydrantSystemEnquiry === 0 ? 'No' : formData.FireHydrantSystemEnquiry === 1 && 'Yes'
                  }
                  onChange={handleChange}
                  aria-required
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              container
              className="d-flex flex-row align-items-center  mt-3"
              xs={12}
              sm={12}
              md={6}
              // style={{ marginLeft: '20px' }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: 'black', fontWeight: 500 }}>
                  <span style={{ fontWeight: 500 }}>4.</span>Is the Building Equipped with Sprinkler?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="sprinler"
                  value={formData.sprinler === 0 ? 'No' : formData.sprinler === 1 && 'Yes'}
                  onChange={handleChange}
                  aria-required
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              container
              className="d-flex flex-row align-items-center  mt-3"
              xs={12}
              sm={12}
              md={6}
              // style={{ marginLeft: '20px' }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: 'black', fontWeight: 500 }}>
                  <span style={{ fontWeight: 500 }}>5.</span>Is the Building Equipped with Smoke detector Systems?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="smokeDetectorSystem"
                  value={formData.smokeDetectorSystem === 0 ? 'No' : formData.smokeDetectorSystem === 1 && 'Yes'}
                  onChange={handleChange}
                  aria-required
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              container
              className="d-flex flex-row align-items-center  mt-3"
              xs={12}
              sm={12}
              md={6}
              // style={{ marginLeft: '20px' }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: 'black', fontWeight: 500 }}>
                  <span style={{ fontWeight: 500 }}>6.</span>Is the Building Equipped with Fire Extinguishers?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="radioFireExtingushers"
                  value={formData.radioFireExtingushers === 0 ? 'No' : formData.radioFireExtingushers === 1 && 'Yes'}
                  onChange={handleChange}
                  aria-required
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              container
              className="d-flex flex-row align-items-center  mt-3"
              xs={12}
              sm={12}
              md={6}
              // style={{ marginLeft: '20px' }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: 'black', fontWeight: 500 }}>
                  <span style={{ fontWeight: 500 }}>7.</span> Is the Building Equipped with CCTV?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="radioCCTV"
                  value={formData.radioCCTV === 0 ? 'No' : formData.radioCCTV === 1 && 'Yes'}
                  onChange={handleChange}
                  aria-required
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              container
              className="d-flex flex-row align-items-center  mt-3"
              xs={12}
              sm={12}
              md={6}
              // style={{ marginLeft: '20px' }}
            >
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: 'black', fontWeight: 500 }}>
                  <span style={{ fontWeight: 500 }}>8.</span> Is the Building Equipped with Access Control Systems?
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="radioAccessControl"
                  value={formData.radioAccessControl === 0 ? 'No' : formData.radioAccessControl === 1 && 'Yes'}
                  onChange={handleChange}
                  aria-required
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Card>
        <Grid container rowSpacing={2.5} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <FormLabel htmlFor="SC" style={{ color: 'black', fontWeight: 500, marginBottom: '10px' }}>
              Any Other Specific Clearances Obtained?{' '}
            </FormLabel>
            <TextInputBRH
              id="SC"
              label="Your response..."
              name="otherClearances"
              required
              multiline
              rows={4}
              value={formData.otherClearances}
              handleChange={handleChangeDesc}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '30px' }}
          >
            <LoadingButtonBRH variant="containeds" label="Save & Next" type="submit" />
          </Grid>
        </Grid>
      </FormControl>
    </form>
  );
};

export default ComplianceSafety;
