import {
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    Dialog,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    Slider,
    Stack,
    TextField,
    Tooltip,
    Typography,
  } from '@mui/material';
  import { useLocation, useNavigate, useParams } from 'react-router-dom';
  import { SyncLoader } from 'react-spinners';
  import React, { useState, useEffect, useRef } from 'react';
  import moment from 'moment';
  import { Helmet } from 'react-helmet-async';
  import SelectAllIcon from '@mui/icons-material/SelectAll';
  import DeselectIcon from '@mui/icons-material/Deselect';
import { Col, Modal, Row } from 'react-bootstrap';
  
  import Scrollbar from '../../components/scrollbar/Scrollbar';
  import { AutoCompleteInputBRH } from '../../components/forms/AutoCompleteInputBRH';
  import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
  import { FormSkeleton } from '../../components/skeleton/FormSkeleton';
  import {
    GETCLIENTS,
    GETCLIENTDATA,
    AMENTITIESLIST,
    PROPERTYAMENTITY,
    PROPERTYRESOURCE,
    ADDCRS,
    INCLUSIONEXCLUSIONMASTER,
    COMPANYSHOW,
    STATELIST,
    GENERATEMOU,
    SHOWCRS,
    EDITCRS,
    SCOUTPROPERTYLIST,
    BRHPROPERTYLIST,
    COWORKINGPROPERTYLIST,
    PROPERTYTIMINGS,
  } from '../../API_SERVICE_LAYER/ApiNames';
  import palette from '../../theme/palette';
  import arrowForward from '../../assets/arrow forward.png';
  import arrowBack from '../../assets/arrow back.png';
  import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
  import { DatePickerInputBRH } from '../../components/forms/DatePickerInputBRH';
  import { TextInputBRH } from '../../components/forms/TextInputBRH';
  import { CheckBoxInputBRH } from '../../components/forms/CheckBoxInputBRH';
  import { TimeInputBRH } from '../../components/forms/TimeInputBRH';
  import { NumberInputBRH } from '../../components/forms/NumberInputBRH';
  import { MoneyInputBRH } from '../../components/forms/MoneyInputBRH';
  import './crs.css';
  import { ToastBRH } from '../../components/toasts/ToastBRH';
  import { CommonAlert } from '../../components/toasts/CommonAlert';
  import { KYC_ACCEPTED, KYC_REQUESTED, KycBox } from './KycBox';
  import TextDivider from '../../components/text-divider/TextDivider';
  import { ErrorMessageBRH } from '../../components/forms/ErrorMessageBRH';
  import { LoadingButtonBRH } from '../../components/forms/LoadingButtonBRH';
  import { TOAST_SUCCESS, TOAST_ERROR, TOAST_WARNING } from '../../utils/toastTimings';
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 250,
  //     },
  //   },
  // };
  
  const DEDICATED_DESK_GROUP_ID = 16;
  const FLEXI_DESK_GROUP_ID = 8;
  const PAID_PARKING_GROUP = 21;
  
  // function monthsBetweenDates(date1, date2) {
  //   const days = Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
  //   const months = Math.round((days / 30) * 10) / 10;
  //   return months;
  // }
  
  
  const monthsBetweenDates = (startDate, endDate) => {
    // Ensure startDate is before endDate
    if (startDate > endDate) {
      return 0;
    }
  
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const startDay = startDate.getDate();
  
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
    const endDay = endDate.getDate();
  
    // Calculate full months difference
    let fullMonths = (endYear - startYear) * 12 + (endMonth - startMonth);
  
    // Calculate remaining days
    let remainingDays = endDay - startDay;
  
    // Handle case when end day is earlier in the month than start day
    if (remainingDays < 0) {
      fullMonths -= 1;
      const previousMonth = new Date(endYear, endMonth, 0);
      remainingDays += previousMonth.getDate();
    }
  
    // Calculate the number of days in the month of the end date
    const daysInEndMonth = new Date(endYear, endMonth + 1, 0).getDate();
  
    // Calculate the fraction of the month
    const fractionOfMonth = remainingDays / daysInEndMonth;
  
    // Total months including fraction
    const totalMonths = fullMonths + fractionOfMonth;
  
    return parseFloat(totalMonths.toFixed(2));
  };
  
 
  
  
  export default function AddRenewalContract() {
    const[Drent,setDrent]=useState();
    const[Frent,setFrent]=useState();

    const [show, setShow] = useState(false);

    const [ReneweReive, setReneweReive] = useState(false);


    const handleActualReview =()=>{
      setShow(false);
      setReneweReive(true);
    }

    const handleMMClose = () =>{
      //  handleCheckboxChange();
       setShow(false)};
    const handleShow = () => setShow(true);
    const loadingButtonRef = useRef(null);

    const [sliderValues, setSliderValues] = useState({});
    const [prices, setPrices] = useState({});
    const [updatedPrices, setupdatedPrices] = useState({});
    const[unitsels,setUnitsels]=useState();
    const [usualcontracts, setusualContracts] = useState({});
    const navigate = useNavigate();
    const [lakshFile, setLakshFile] = useState();
    const [cancelOpen, setCancelOpen] = React.useState(false);
    const [kycOpen, setKycOpen] = useState(false);
    const [checking, setchecking] = useState(false);
    const [resolveCheck, setresolveCheck] = useState(false);
    const [iskycUpdated, setIskycUpdated] = useState(false);
    // v1
    const { state } = useLocation();
  
    // const contractId = state?.updatedId;
    // const isUpdated = state?.isUpdated;
    const isRenewal = state?.isRenewal;
    // v1
    // v2

    
    const { id: contractId } = useParams();
    // v2
    const[PPamenity,SetPPamenity]=useState()
  
    const[updateunitPrice,SetupdateunitPrice]=useState()
    const[UpdateunitSelect,SetUpdateunitSelect]=useState()
  
   
    const userData = useRef(null);
    const renewalYOY = useRef(null);
    const[alltotal,setAllTotal]=useState()

 

    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [Defaction, setDefaction] = useState(false);
    const [paidcheck, setPaidcheck] = useState(false);
    const [freeFacilities, setfreeFacilities] = useState([]);
    const [paidFacilities, setpaidFacilities] = useState([]);
    const refDate = new Date(); // Assuming refDate is today's date

    const [contractDetails, setContractDetails] = useState({
      isOpenDialog: false,
      userId: null,
      client_id: null,
      // for laksh
      clientList: [],
      clientId: '',
      company_name: null,
      // dateOfAgreement: '',
      dateOfAgreement: new Date(
        refDate.getFullYear(),
        refDate.getMonth() ,
        // 1,
        refDate.getDate(),
        refDate.getHours(),
        refDate.getMinutes(),
        refDate.getSeconds()
      ),
      clientAddress: '',
      clientSignatory: '',
      clientCPName: '',
      // agreeStartDate: new Date(
      //   refDate.getFullYear(),
      //   refDate.getMonth() + 1,
      //   1,
      //   refDate.getHours(),
      //   refDate.getMinutes(),
      //   refDate.getSeconds()
      // ),
      paidparkingprice: {},
      agreeStartDate: new Date(
        refDate.getFullYear(),
        refDate.getMonth() + 1,
        1,
        refDate.getHours(),
        refDate.getMinutes(),
        refDate.getSeconds()
      ),
      agreeEndDate: new Date(
        refDate.getFullYear(),
        refDate.getMonth() + 7,
        1,
        refDate.getHours(),
        refDate.getMinutes(),
        refDate.getSeconds()
      ),
      numberOfMonths: '',
      lockedInPeriod: '',
      noticePeriod: '',
      rentStartDate: new Date(
        refDate.getFullYear(),
        refDate.getMonth() + 2,
        1,
        refDate.getHours(),
        refDate.getMinutes(),
        refDate.getSeconds()
      ),
      rentFree: '',
      yearOnYearIncrement: '',
      numberOfCarParking: '',
      numberOfBikeParking: '',
      workingHoursFrom: new Date(2023, 1, 1, 10, 0, 0),
      workingHoursTo: new Date(2023, 1, 1, 18, 0, 0),
      refundableSecurityAmount: '',
      monthlyServiceCharges: '',
      monthlyServiceChargesTax: 18,
      intrestRate: '',
      anyServices: '',
      workingDayMon: false,
      workingDayTue: false,
      workingDayWed: false,
      workingDayThu: false,
      workingDayFri: false,
      workingDaySat: false,
      workingDaySun: false,
      contactPersonsList: [],
      stateList: [],
      company_address: null,
      representativeSignatoryId: null,
      version: '',
  
      propertiesList: [],
      amentitiesList: [],
      resourceList: [],
      propertyResourcesList: [],
      propertyFreeAmentitiesList: [],
      propertyPaidAmentitiesList: [],
      property_name: null,
      propertyId: '',
      isFetched: false,
      isSelectDataloaded: true,
      isRadioDataLoaded: true,
      selectedAmentities: [],
      selectedFreeAmentities: [],
      selectedPaidAmentities: [],
      selectedResources: [],
      selectedAmentitiesId: [],
      isDirectContract: false,
      isDgSelected: 1,
      isWaterSelected: 1,
      isInternetSelected: 1,
      isElectricitySelected: 1,
      isTeaCoffeSelected: 1,
  
      inclusionsAndExclusionsMaster: [],
      inclusionsAndExclusionsChecked: [],
  
      selectedInclusionList: [],
      selectedInclusionListChecked: [],
      selectedExclusionsList: [],
      selectedExclusionsListChecked: [],
      propertyType: 4, // scout-9,BRH-4,co-work-1
      isKycChecked: false,
      ContractType: 1, // client-1,indivitual-2
      contractTypeClientList: [], // USE THIS LIST FOR FILTERING
      flexiSeatsSelected: 1,
      paidparkingSeatsSelected: 1,
      flexiSeatPrice: null,
      // paidparkingprice: null,
      dedicatedSeatsSelected: 1,
      dedicatedSeatPrice: null,
      //  FOR VALIDATIONS PURPOSE
      // isIncExcEmpty: true,
      isOfficeDetailsSelected: true,
      isAmentitySelected: true,
      kycStatus: 0,
      kycReason: null,
      kycFile: null,
      propertyWorkingDays: [],
      clientWorkingDays: [],
      propertyStartTime: null,
      propertyEndTime: null,
    });
  

    const[newStart,setNewStart]=useState( new Date(
      refDate.getFullYear(),
      refDate.getMonth() + 1,
      // 1,
      refDate.getDate(),
      refDate.getHours(),
      refDate.getMinutes(),
      refDate.getSeconds()
    ),)
    const[newEnd,setNewEnd]=useState( new Date(
      refDate.getFullYear(),
      refDate.getMonth() + 1,
      // 1,
      refDate.getDate(),
      refDate.getHours(),
      refDate.getMinutes(),
      refDate.getSeconds()
    ),)

    
  
    console.log(contractDetails?.dateOfAgreement, "datee");
  
  
  
    const handleCheckboxChange = () => {
      setContractDetails((prevDetails) => {
        const newValue = !prevDetails.isDirectContract;
        // console.log('Updated isDirectContract:', newValue ? 1 : 0);
        return { ...prevDetails, isDirectContract: newValue };
      });
      handleShow();
      console.log(contractDetails,"cntdt")
      
    };
    // DERIVED STATE VALUES
    // if(contractDetails.workingHoursFrom,contractDetails.workingHoursTo)
  
    const kycKey = contractDetails?.kycStatus;
    const dedicatedList = contractDetails.propertyResourcesList.filter(
      (ele) => ele.resource_group_id === DEDICATED_DESK_GROUP_ID
    );
    const flexiList = contractDetails.propertyResourcesList.filter(
      (ele) => ele.resource_group_id === FLEXI_DESK_GROUP_ID
    );
  
    const PaidParkList = contractDetails.propertyPaidAmentitiesList.filter(
      (ele) => ele.id === PAID_PARKING_GROUP
    );
    ///
    // ### LOGIC FOR RESET INITIAL VALUES AFTER CHANGE RADIO OR SELECT
    // #PROPERTY
    const resetPropertyInit = {
      property_name: null,
      propertyId: null,
      propertyFreeAmentitiesList: [],
      propertyPaidAmentitiesList: [],
      propertyResourcesList: [],
      selectedFreeAmentities: [],
      selectedPaidAmentities: [],
      selectedResources: [],
    };
    const resetClientDetails = {
      company_name: null,
      clientId: null,
      clientAddress: '',
      workingDaySun: false,
      workingDayMon: true,
      workingDayTue: true,
      workingDayWed: true,
      workingDayThu: true,
      workingDayFri: true,
      workingDaySat: false,
      workingHoursFrom: new Date(),
      workingHoursTo: new Date(),
    };
  
  
    // second time change
  
    const handleCheckBoxGroupChange = (e, varName) => {
      // AmountTotalcal();
      const value = e.target.value;
      const isChecked = e.target.checked;
  
      const isSelectedPaidAmentity = varName === 'selectedPaidAmentities' && isChecked;
  
      // Additional action based on whether it's a selectedPaidAmentity
      if (isSelectedPaidAmentity) {
        setresolveCheck(true)
        // Perform action if it's part of selectedPaidAmentities
        console.log(`${value} is selected as a paid amenity.`);
      } else if (varName === 'selectedPaidAmentities' && !isChecked) {
        // Perform action if it's part of selectedPaidAmentities but is being unchecked
        setresolveCheck(true)
       
        console.log(`${value} is unselected as a paid amenity.`);
      }
    
      setContractDetails(prevDetails => {
        const newDetails = {
          ...prevDetails,
          [varName]: isChecked
            ? [...prevDetails[varName], value]
            : prevDetails[varName].filter(ame => ame !== value)
        };
    
        // Reset flexiSeatsSelected or dedicatedSeatsSelected if their respective checkbox is unchecked
        if (!isChecked) {
          if (value === `${flexiList[0]?.id}`) {
            newDetails.flexiSeatsSelected = 0;
            // setchecking(false)
          }
          if (value === `${dedicatedList[0]?.id}`) {
            newDetails.dedicatedSeatsSelected = 0;
            // setchecking(false)
          }
        }
    
        return newDetails;
      });
  
      // console.log("muj")
    
      // Reset slider value if checkbox is unchecked
      if (!isChecked) {
        setSliderValues(prevValues => ({
          ...prevValues,
          [value]: 0 // Reset slider value for unchecked item
        }));
      }
    };
  
  
    // const handleCheckBoxGroupChange = (e, varName) => {
  
  
    //   const index = contractDetails[varName].indexOf(e.target.value);
  
    //   if (index === -1) {
  
    //     // console.log(contractDetails.selectedAmentities,"ft");
    //     const tempDetails = {
    //       ...contractDetails,
    //       [varName]: [...contractDetails[varName], e.target.value],
    //     }
    //     const isParkingSelected = tempDetails[varName].includes('21');
    //     setPaidcheck(isParkingSelected)
    //     setContractDetails(tempDetails);
  
    //   } else {
  
    //     const tempDetails = {
    //       ...contractDetails,
    //       [varName]: contractDetails[varName].filter((ame) => ame !== e.target.value),
    //     }
    //     const isParkingSelected = tempDetails[varName].includes('21');
    //     setPaidcheck(isParkingSelected)
  
  
  
  
    //     // setSkills(skills.filter((skill) => skill !== e.target.value))
    //     setContractDetails(tempDetails);
    //   }
    // };
  
    function isSpecificSeatSelected(type = 'flexi') {
      let isSelected = false;
      const dedicatedDeskList = contractDetails.propertyResourcesList.filter(
        (ele) => ele.resource_group_id === DEDICATED_DESK_GROUP_ID
      );
      const flexiDeskList = contractDetails.propertyResourcesList.filter(
        (ele) => ele.resource_group_id === FLEXI_DESK_GROUP_ID
      );
      // setchecking(false)
  
  
  
  
      const list = type === 'flexi' ? flexiDeskList : dedicatedDeskList;
      list.forEach((ele) => {
        if (contractDetails.selectedResources.some((res) => ele.id === Number(res))) {
          isSelected = true;
        }
      });
      return isSelected;
    }
  
    // const handleSliderChange = (ameId, event, newValue) => {
    //   setSliderValues((prevValues) => ({
    //     ...prevValues,
    //     [ameId]: newValue,
    //   }));
    // };
  
    const handleChangeInput = (ameId, paidUnit, event) => {
      const newValue = Number(event.target.value);
      setresolveCheck(true);
      // Ensure the value is within the min and max range
      if (newValue >= 0 && newValue <= paidUnit) {
        // setchecking(false)
        setSliderValues((prevValues) => ({
          ...prevValues,
          [ameId]: newValue,
        }));
      }
    };
  
    function isSpecificPaidAmenities(id) {
      return contractDetails.selectedPaidAmentities?.includes(id);
    }
  
  
    // console.log(contractDetails.propertyPaidAmentitiesList, "lllll")
  const findCalculation =()=>{
    const keys = Object.keys(sliderValues).map(d => d);
    const multiplied = keys.map((v) => {
      const val = sliderValues[v] * prices[v];
      const obj = {};
      obj[v] = val;
      return obj
    })
    let totalSum = 0;
    multiplied.forEach(obj => {
    // Extract the value from the object and add it to the totalSum
    const value = Object.values(obj)[0]; // Get the first (and only) value in the object
    totalSum += Number(value); // Convert to Number and add to the sum
  });
  setAllTotal(totalSum);
  
    console.log(totalSum,'mmmmmm');
  }
  useEffect(()=>{
      console.log(sliderValues,prices,"???????")
      findCalculation();
   },[sliderValues,prices])
  
  
    // const isParkingSelected = PaiListSpecifi('parking');
  
  
  
    const handleSelectAll = (listName, varName) => {
      const listAll = contractDetails[listName].map((ele) => String(ele.id));
      setContractDetails({ ...contractDetails, [varName]: listAll });
    };
    const handleDeSelectAll = (name) => {
      // AmountTotalcal();
      setContractDetails({ ...contractDetails, [name]: [] });
    };
    const handleChange = (e) => {
      // AmountTotalcal();
      const { name, value } = e.target;
      if (
        name === 'workingDaySun' ||
        name === 'workingDayMon' ||
        name === 'workingDayTue' ||
        name === 'workingDayWed' ||
        name === 'workingDayThu' ||
        name === 'workingDayFri' ||
        name === 'workingDaySat' ||
        name === 'isKycChecked'
      ) {
        // finding day inside array
        const dayIndex =
          name === 'workingDaySun'
            ? 0
            : name === 'workingDayMon'
              ? 1
              : name === 'workingDayTue'
                ? 2
                : name === 'workingDayWed'
                  ? 3
                  : name === 'workingDayThu'
                    ? 4
                    : name === 'workingDayFri'
                      ? 5
                      : 6;
        const checkboxValue = !contractDetails[name];
        const changedWorkingDays = contractDetails.clientWorkingDays.map((day, index) =>
          index === dayIndex ? { ...day, is_selected: checkboxValue === true ? 1 : 0 } : day
        );
        setContractDetails({ ...contractDetails, clientWorkingDays: changedWorkingDays, [name]: checkboxValue });
      } else if (name === 'company_address') {
        let sign;
        contractDetails.contactPersonsList.forEach((cp) => {
          if (cp.id === value) {
            sign = cp.signatory;
          }
        });
        setContractDetails({ ...contractDetails, [name]: value, representativeSignatoryId: sign });
      } else if (name === 'propertyType') {
        setContractDetails({ ...contractDetails, isRadioDataLoaded: false });
        if (Number(value) === 9) {
          API.getData(SCOUTPROPERTYLIST).then((res) => {
            if (res.data.status !== 'success') {
              ToastBRH('warning', 'No Scouted Properties Found', '_', TOAST_ERROR);
              return setContractDetails({ ...contractDetails, isRadioDataLoaded: true });
            }
            return setContractDetails({
              ...contractDetails,
              isRadioDataLoaded: true,
              propertiesList: res?.data?.result,
              [name]: value,
              ...resetPropertyInit,
            });
          });
        } else if (Number(value) === 4) {
          API.getData(BRHPROPERTYLIST).then((res) => {
            if (res.data.status !== 'success') return setContractDetails({ ...contractDetails, isRadioDataLoaded: true });
            return setContractDetails({
              ...contractDetails,
              isRadioDataLoaded: true,
              propertiesList: res?.data?.result,
              [name]: value,
              ...resetPropertyInit,
            });
          });
        } else if (Number(value) === 1) {
          API.getData(COWORKINGPROPERTYLIST).then((res) => {
            if (res.data.status !== 'success') return setContractDetails({ ...contractDetails, isRadioDataLoaded: true });
            return setContractDetails({
              ...contractDetails,
              isRadioDataLoaded: true,
              propertiesList: res?.data?.result,
              [name]: value,
              ...resetPropertyInit,
            });
          });
        }
        // setContractDetails({ ...contractDetails, [name]: value });
      } else if (name === 'ContractType') {
        const filterdList = contractDetails.clientList.filter((cli) => cli.contract_document_type_id === Number(value));
        setContractDetails({
          ...contractDetails,
          [name]: value,
          contractTypeClientList: filterdList,
          ...resetClientDetails,
        });
      } else {
        setContractDetails({ ...contractDetails, [name]: value });
      }
    };
  
    const handleAutoCompleteChange = (event, newValue,pname) => {
      // setContractDetails({ ...contractDetails, isSelectDataloaded: false });
      const [name] = event?.target?.id.split('-');
      const value = newValue[name];
      const id = newValue.id;
      // setContractDetails({ ...contractDetails, [name]: value, propertyId: id });
      // for addiional data
      if (name === 'property_name'||pname) {
        setContractDetails({ ...contractDetails, isSelectDataloaded: false });
        API.getData(`${PROPERTYAMENTITY}/${id}`)
          .then((resAme) => {
            if (resAme?.data?.status) {
              const paidAmentities = [];
              const freeAmentities = [];
  
              // console.log(resAme, "resAme");
              setfreeFacilities(resAme.data.data.filter((c) => c.is_paid === 0))
              setpaidFacilities(resAme.data.data.filter((c) => c.is_paid === 1))
              // console.log(freeFacilities,"free")
              contractDetails.amentitiesList.forEach((ameList) => {
                resAme.data.data.forEach((ame) => {
  
                  if (ameList.id === ame.amenity_id && ame.is_paid === 0) {
                    // console.log(ame, "amelist");
                    freeAmentities.push({
                      id: ameList.id,
                      name: ameList.amenity_name,
                      paid_unit: ame.paid_units,
                      fee_per_unit: ame.fee_per_unit,
                      // quantity:
                    });
                    // console.log(freeAmentities, "mnmnmn")
                  } else if (ameList.id === ame.amenity_id && ame.is_paid === 1) {
                    // console.log(ameList, "paidamelist");
  
                    paidAmentities.push({
                      id: ameList.id,
                      name: ameList.amenity_name,
                      paid_unit: ame.paid_units,
                      fee_per_unit: ame.fee_per_unit,
                    });
                    // console.log(paidAmentities, "paidAmentities");
                  }
                });
              });
              API.getData(`${PROPERTYRESOURCE}/${id}`)
                .then((resPro) => {
                  if (resPro?.data?.success) {
                    const activeResources = resPro?.data?.data.filter((res) => res.status === 1);
                    const resources = activeResources?.map((res) => ({
                      id: res.id,
                      resource_group_id: res.resource_group_id,
                      name: res.resource_name,
                      quantity: res.quantity,
                    }));
                    API.getData(`${PROPERTYTIMINGS}/${id}`)
                      .then((resTime) => {
                        if (resTime.data.status === 'success') {
                          const dataPropTime = resTime.data.data;
                          const [tempPrstatrtHrs, tempPrstatrtMins] = newValue.start_at.split(':');
                          const [tempPrEndHrs, tempPrEndMins] = newValue.end_at.split(':');
                          const prStart = new Date(2023, 1, 1, tempPrstatrtHrs, tempPrstatrtMins, 0);
                          const prEnd = new Date(2023, 1, 1, tempPrEndHrs, tempPrEndMins, 0);
                          setContractDetails({
                            ...contractDetails,
                            [name]: newValue,
                            propertyStartTime: prStart,
                            propertyEndTime: prEnd,
                            propertyId: id,
                            propertyFreeAmentitiesList: freeAmentities,
                            propertyPaidAmentitiesList: paidAmentities,
                            propertyResourcesList: resources,
                            isSelectDataloaded: true,
                            propertyWorkingDays: dataPropTime,
                          });
                          // console.log(dataPropTime);
                        }
                      })
                      .catch((errPropTime) => console.error(errPropTime));
                  }
                })
                .catch((errPro) => {
                  console.error(errPro);
                  setContractDetails({ ...contractDetails, [name]: value, isSelectDataloaded: true });
                });
            }
          })
          .catch((errAme) => {
            console.error(errAme);
            setContractDetails({ ...contractDetails, [name]: newValue, isSelectDataloaded: true });
          });
      }
      if (name === 'company_name') {
        setContractDetails({ ...contractDetails, isSelectDataloaded: false });
        API.getData(`${GETCLIENTDATA}/${id}`)
          .then((res) => {
            if (res?.data?.results) {
              const data = res.data.results;
              const [startHours, startMinutes, startSeconds] = data?.from_time?.split(':');
              const [endHours, endminutes, endSeconds] = data?.to_time?.split(':');
              const startDate = new Date(2023, 1, 1, +startHours, +startMinutes, +startSeconds);
              const endDate = new Date(2023, 1, 1, +endHours, +endminutes, +endSeconds);
              setContractDetails({
                ...contractDetails,
                [name]: newValue,
                clientId: id,
                clientAddress: data.company_address,
                clientSignatory: data.company_signatory,
                clientCPName: data.contact_person_name,
                clientWorkingDays: data.client_timings,
                workingDaySun: data.client_timings[0]?.is_selected,
                workingDayMon: data.client_timings[1]?.is_selected,
                workingDayTue: data.client_timings[2]?.is_selected,
                workingDayWed: data.client_timings[3]?.is_selected,
                workingDayThu: data.client_timings[4]?.is_selected,
                workingDayFri: data.client_timings[5]?.is_selected,
                workingDaySat: data.client_timings[6]?.is_selected,
                workingHoursFrom: startDate ?? new Date(),
                workingHoursTo: endDate ?? new Date(),
  
                isSelectDataloaded: true,
              });
            }
          })
          .catch((err) => {
            console.error(err);
            setContractDetails({ ...contractDetails, [name]: value, isSelectDataloaded: true });
          });
      }
    };
  
    const handleDateChange = (name, newValue) => {
      setContractDetails({ ...contractDetails, [name]: newValue });
    };
    const handleMoneyChange = (e) => {
      // AmountTotalcal();
      // setchecking(false)
      const { name } = e.target;
      let { value } = e.target;
      value = String(value).replace(/,/g, '');
      setContractDetails({
        ...contractDetails,
        [name]: value,
      });
  
    };
  
    const handleMoneyChangeTwo = (id) => (e) => {
      // AmountTotalcal();
      setresolveCheck(true)
      // setchecking(false)
      const { value } = e.target;
      const formattedValue = String(value).replace(/,/g, ''); 
      // Update the prices state
      setPrices(prevPrices => ({
        ...prevPrices,
        [id]: formattedValue
      }));
  
      console.log(prices,"pricess")
      // Update contractDetails state
      setContractDetails(prevDetails => ({
        ...prevDetails,
        paidparkingprice: {
          ...prevDetails.paidparkingprice,
          [id]: formattedValue
        }
      }));
    };
  
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const inclusionAll = contractDetails.selectedInclusionList.map((ele) => ele.id);
      const exclusionAll = contractDetails.selectedExclusionsList.map((ele) => ele.id);
  
      
      const combinedAmenities = [...contractDetails.selectedPaidAmentities, ...contractDetails.selectedFreeAmentities];
  
  // Map over combinedAmenities to create the desired structure
  const amenityData = combinedAmenities.map(amenityId => ({
      amenity_id: amenityId,
      unit_counts:  sliderValues[amenityId] || "0", // Replace with actual logic if needed
      unit_amount: contractDetails.paidparkingprice[amenityId] // Replace with actual logic if needed
  }));
  console.log('33333');

  
      let updatedFormdata = {
        renewal_status:3,
        
        is_renewal_contract:1,
        date_of_agreement: moment(contractDetails.dateOfAgreement).format('YYYY-MM-DD'), // '2022-10-17'
        agreement_start_date: moment(contractDetails.agreeStartDate).format('YYYY-MM-DD'),
        agreement_end_date: moment(contractDetails.agreeEndDate).format('YYYY-MM-DD'),
        rent_start_date: moment(contractDetails.rentStartDate).format('YYYY-MM-DD'),
        contract_document_type_id: Number(contractDetails.ContractType),
        timings: [
          {
            day_code: 'Sun',
            day_id: 1,
            is_selected: contractDetails.workingDaySun ? 1 : 0,
          },
          {
            day_code: 'Mon',
            day_id: 2,
            is_selected: contractDetails.workingDayMon ? 1 : 0,
          },
          {
            day_code: 'Tue',
            day_id: 3,
            is_selected: contractDetails.workingDayTue ? 1 : 0,
          },
          {
            day_code: 'Wed',
            day_id: 4,
            is_selected: contractDetails.workingDayWed ? 1 : 0,
          },
          {
            day_code: 'Thu',
            day_id: 5,
            is_selected: contractDetails.workingDayThu ? 1 : 0,
          },
          {
            day_code: 'Fir',
            day_id: 6,
            is_selected: contractDetails.workingDayFri ? 1 : 0,
          },
          {
            day_code: 'Sat',
            day_id: 7,
            is_selected: contractDetails.workingDaySat ? 1 : 0,
          },
        ],
        is_dg: contractDetails.isDgSelected,
        is_water: contractDetails.isWaterSelected,
        is_internet: contractDetails.isInternetSelected,
        is_electricity: contractDetails.isElectricitySelected,
        is_teaorcoffee: contractDetails.isTeaCoffeSelected,
        is_mou: 0, // if MOU doc created or not
        is_contract: 0, // if Contract doc created or not
        is_draft: 0,
        company_address_id: 1, // contractDetails.companyAddressId,
        created_by_user_id: Number(contractDetails.userId),
        status: 1,
  
        no_of_years: contractDetails.numberOfMonths ? Number(contractDetails.numberOfMonths) : 0,
        lock_in_period_months: contractDetails.numberOfMonths ? Number(contractDetails.lockedInPeriod) : 0,
        notice_in_period_months: contractDetails.numberOfMonths ? Number(contractDetails.noticePeriod) : 0,
        year_on_year_increment: contractDetails.yearOnYearIncrement ? Number(contractDetails.yearOnYearIncrement) : 0,
        no_of_car_parking: contractDetails.numberOfCarParking ? Number(contractDetails.numberOfCarParking) : 0,
        no_of_bike_parking: contractDetails.numberOfBikeParking ? Number(contractDetails.numberOfBikeParking) : 0,
        refunable_security_amount: contractDetails.refundableSecurityAmount
          ? Number(contractDetails.refundableSecurityAmount)
          : 0,
        monthly_rent: contractDetails.monthlyServiceCharges ? Number(contractDetails.monthlyServiceCharges) : 0,
        tax_percentage: contractDetails.monthlyServiceChargesTax ? Number(contractDetails.monthlyServiceChargesTax) : 0,
        tax_amount: contractDetails.monthlyServiceChargesTax
          ? Math.ceil(
            Number(contractDetails.monthlyServiceCharges) + Number(contractDetails.monthlyServiceChargesTax) / 100
          )
          : 0,
        interest_rate_if_failed_to_pay_mg: contractDetails.intrestRate ? Number(contractDetails.intrestRate) : 0,
        direct_contract: contractDetails.isDirectContract ? 1 : 0,
        kyc_status: contractDetails.isKycChecked,
        amenity_id:amenityData,
        // amenity_id: [...contractDetails.selectedFreeAmentities, ...contractDetails.selectedPaidAmentities],
        inclusion_id: inclusionAll,
        exclusion_id: exclusionAll,
        property_type_id: Number(contractDetails.propertyType),
      };
      // updatedFormdata = isUpdated === true ? { ...updatedFormdata, id: contractId } : { ...updatedFormdata };---v1
      updatedFormdata = contractId ? { ...updatedFormdata, id: contractId } : { ...updatedFormdata };
  
      updatedFormdata =
        contractDetails.clientId > 0
          ? { ...updatedFormdata, contract_client_id: contractDetails.clientId }
          : { ...updatedFormdata };
      updatedFormdata =
        contractDetails.propertyId > 0
          ? { ...updatedFormdata, property_id: contractDetails.propertyId }
          : { ...updatedFormdata };
      updatedFormdata = contractDetails.anyServices
        ? { ...updatedFormdata, any_other_services: contractDetails.anyServices }
        : { ...updatedFormdata };
      // list logics
      updatedFormdata =
        contractDetails.selectedResources?.length > 0
          ? { ...updatedFormdata, resource_id: contractDetails.selectedResources }
          : { ...updatedFormdata };
      updatedFormdata =
        contractDetails.selectedFreeAmentities?.length > 0
          ? {
            ...updatedFormdata,
            amentities_id: [...contractDetails.selectedFreeAmentities, ...contractDetails.selectedPaidAmentities],
          }
          : { ...updatedFormdata };
      updatedFormdata =
        contractDetails.selectedResources?.length > 0
          ? { ...updatedFormdata, inclusion_id: inclusionAll }
          : { ...updatedFormdata };
      updatedFormdata =
        contractDetails.selectedResources?.length > 0
          ? { ...updatedFormdata, exclusion_id: exclusionAll }
          : { ...updatedFormdata };
  
          console.log('1111');
      const formData = {
        contract_client_id: contractDetails.clientId,
        property_id: contractDetails.propertyId,
        company_address_id: 1, // contractDetails.companyAddressId,
        created_by_user_id: Number(contractDetails.userId),
        // company_signatories_id: contractDetails.clientId,
        date_of_agreement: moment(contractDetails.dateOfAgreement).format('YYYY-MM-DD'), // '2022-10-17'
        agreement_start_date: moment(contractDetails.agreeStartDate).format('YYYY-MM-DD'),
        agreement_end_date: moment(contractDetails.agreeEndDate).format('YYYY-MM-DD'),
        rent_start_date: moment(contractDetails.rentStartDate).format('YYYY-MM-DD'),
        no_of_years: Number(contractDetails.numberOfMonths),
        lock_in_period_months: Number(contractDetails.lockedInPeriod),
        notice_in_period_months: Number(contractDetails.noticePeriod),
        year_on_year_increment: Number(contractDetails.yearOnYearIncrement),
        no_of_car_parking: Number(contractDetails.numberOfCarParking),
        no_of_bike_parking: Number(contractDetails.numberOfBikeParking),
        refunable_security_amount: Number(contractDetails.refundableSecurityAmount),
        monthly_rent: Number(contractDetails.monthlyServiceCharges),
        tax_percentage: Number(contractDetails.monthlyServiceChargesTax),
        tax_amount: Math.ceil(
          Number(contractDetails.monthlyServiceCharges) + Number(contractDetails.monthlyServiceChargesTax) / 100
        ),
        interest_rate_if_failed_to_pay_mg: Number(contractDetails.intrestRate),
        any_other_services: contractDetails.anyServices,
        is_dg: contractDetails.isDgSelected,
        is_water: contractDetails.isWaterSelected,
        is_internet: contractDetails.isInternetSelected,
        is_electricity: contractDetails.isElectricitySelected,
        is_teaorcoffee: contractDetails.isTeaCoffeSelected,
        is_mou: 0, // if MOU doc created or not
        is_contract: 0, // if Contract doc created or not
        is_draft: 0,
        status: 1, // 1-active ,0 - inactive , 2- save as draft
        direct_contract: contractDetails.isDirectContract ? 1 : 0,
        kyc_status: contractDetails.isKycChecked ? 1 : 0,
        timings: [
          {
            day_code: 'Sun',
            day_id: 1,
            is_selected: contractDetails.workingDaySun ? 1 : 0,
          },
          {
            day_code: 'Mon',
            day_id: 2,
            is_selected: contractDetails.workingDayMon ? 1 : 0,
          },
          {
            day_code: 'Tue',
            day_id: 3,
            is_selected: contractDetails.workingDayTue ? 1 : 0,
          },
          {
            day_code: 'Wed',
            day_id: 4,
            is_selected: contractDetails.workingDayWed ? 1 : 0,
          },
          {
            day_code: 'Thu',
            day_id: 5,
            is_selected: contractDetails.workingDayThu ? 1 : 0,
          },
          {
            day_code: 'Fir',
            day_id: 6,
            is_selected: contractDetails.workingDayFri ? 1 : 0,
          },
          {
            day_code: 'Sat',
            day_id: 7,
            is_selected: contractDetails.workingDaySat ? 1 : 0,
          },
        ],
        resource_id: contractDetails.selectedResources,
        // amenity_id: [...contractDetails.selectedFreeAmentities, ...contractDetails.selectedPaidAmentities],
        amenity_id:amenityData,
        inclusion_id: inclusionAll,
        exclusion_id: exclusionAll,
        property_type_id: Number(contractDetails.propertyType),
        contract_document_type_id: Number(contractDetails.ContractType),
        // amentities_id: contractDetails.selectedAmentities,
        // inclusion_id: inclusions.map((ele) => ele.id),
        // exclusion_id: exclusions.map((ele) => ele.id),
      };
      // console.log(formData);
      if (!contractDetails.clientId) {
        ToastBRH('warning', 'Please select client', TOAST_WARNING);
      } else if (contractId) {
        // isUpdated === true---v1
        API.updateMethod(EDITCRS, updatedFormdata)
          .then((res) => {
            if (res?.data?.status === 'success') {
              ToastBRH('success', 'Contract Updated Successfully', TOAST_SUCCESS);
              setTimeout(() => navigate(routeNames.CRS), TOAST_SUCCESS);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        API.postMethod(ADDCRS, formData)
          .then((res) => {
            if (res?.data?.status === 'success') {
              ToastBRH('success', 'Contract Addded Successfully', TOAST_SUCCESS);
              setTimeout(() => navigate(routeNames.CRS), TOAST_SUCCESS);
            }
          })
          .catch((err) => {
            ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
            console.error(err);
          });
      }
    };
    const handleValidations = () => {
      let isValid = 0;
      // if (!(contractDetails.inclusionsAndExclusionsMaster?.length === 0)) {
      //   setContractDetails({
      //     ...contractDetails,
      //     isIncExcEmpty: false,
      //   });
      //   isValid = -1;
      // } else
      if (contractDetails.selectedResources?.length === 0) {
        setContractDetails({
          ...contractDetails,
          // isIncExcEmpty: true,
          isOfficeDetailsSelected: false,
        });
        isValid = -1;
      } else {
        setContractDetails({
          ...contractDetails,
          // isIncExcEmpty: true,
          isOfficeDetailsSelected: true,
        });
        isValid = 1;
      }
      return isValid;
    };
    const handleSubmitMOU = (e, isConfirm = false) => {
      e.preventDefault();
      setIsButtonLoading(true);
      if (isButtonLoading) return;
      const isValid = handleValidations();
      if (isValid !== 1) return;
      const inclusionAll = contractDetails.selectedInclusionList.map((ele) => ele.id);
      const exclusionAll = contractDetails.selectedExclusionsList.map((ele) => ele.id);
      // LOGIC FOR RESOURCES
      const tempResources = contractDetails.selectedResources.map((id) => {
        const tempResourceId = Number(id);
        const resourceObject = {
          resource_id: tempResourceId,
          no_of_seats: null,
          price: null,
        };
        // LOGIC FOR DEDICATED DESK
        if (dedicatedList.some((ele) => ele.id === Number(id))) {
          resourceObject.no_of_seats = Number(contractDetails.dedicatedSeatsSelected);
          resourceObject.price = Number(contractDetails.dedicatedSeatPrice);
        }
        // LOGIC FOR FLEXI DESK
        if (flexiList.some((ele) => ele.id === Number(id))) {
          resourceObject.no_of_seats = Number(contractDetails.flexiSeatsSelected);
          resourceObject.price = Number(contractDetails.flexiSeatPrice);
        }
        return resourceObject;
      });
  
      // const PAmenities=[...contractDetails.selectedPaidAmentities];
      // const FAmenities=[...contractDetails.selectedFreeAmentities];
  
      const combinedAmenities = [...contractDetails.selectedPaidAmentities, ...contractDetails.selectedFreeAmentities];
  
  // Map over combinedAmenities to create the desired structure
  const amenityData = combinedAmenities.map(amenityId => ({
      amenity_id: amenityId,
      unit_counts:  sliderValues[amenityId] || "0", // Replace with actual logic if needed
      unit_amount: contractDetails.paidparkingprice[amenityId] // Replace with actual logic if needed
  }));

  // console.log('2222');

  
      const formData = {
        // renewal_status:3,
        is_renewal_contract:1,
        old_contract_id:Number(contractId),
        contract_client_id: contractDetails.clientId,
        property_id: contractDetails.propertyId,
        company_address_id: 1, // contractDetails.companyAddressId,
        created_by_user_id: Number(contractDetails.userId),
        // company_signatories_id: contractDetails.clientId,
        date_of_agreement: moment(contractDetails.dateOfAgreement).format('YYYY-MM-DD'), // '2022-10-17'
        agreement_start_date: moment(contractDetails.agreeStartDate).format('YYYY-MM-DD'),
        agreement_end_date: moment(contractDetails.agreeEndDate).format('YYYY-MM-DD'),
        no_of_years: Number(contractDetails.numberOfMonths),
        lock_in_period_months: Number(contractDetails.lockedInPeriod),
        notice_in_period_months: Number(contractDetails.noticePeriod),
        rent_start_date: moment(contractDetails.rentStartDate).format('YYYY-MM-DD'),
        year_on_year_increment: Number(contractDetails.yearOnYearIncrement),
        no_of_car_parking: Number(contractDetails.numberOfCarParking),
        no_of_bike_parking: Number(contractDetails.numberOfBikeParking),
        refunable_security_amount: Number(contractDetails.refundableSecurityAmount),
        monthly_rent: Number(contractDetails.monthlyServiceCharges),
        tax_percentage: Number(contractDetails.monthlyServiceChargesTax),
        tax_amount: Math.ceil(
          Number(contractDetails.monthlyServiceCharges) + Number(contractDetails.monthlyServiceChargesTax) / 100
        ),
        interest_rate_if_failed_to_pay_mg: Number(contractDetails.intrestRate),
        any_other_services: contractDetails.anyServices ? contractDetails.anyServices : null,
        is_dg: contractDetails.isDgSelected,
        is_water: contractDetails.isWaterSelected,
        is_internet: contractDetails.isInternetSelected,
        is_electricity: contractDetails.isElectricitySelected,
        is_teaorcoffee: contractDetails.isTeaCoffeSelected,
        is_mou: 0, // if MOU doc created or not
        is_contract: 0, // if Contract doc created or not
        is_draft: 1,
        status: 1, // 1-active ,0 - inactive , 2- save as draft
        kyc_status: contractDetails.kycStatus,
        timings: [
          {
            day_code: 'Sun',
            day_id: 1,
            is_selected: contractDetails.workingDaySun ? 1 : 0,
          },
          {
            day_code: 'Mon',
            day_id: 2,
            is_selected: contractDetails.workingDayMon ? 1 : 0,
          },
          {
            day_code: 'Tue',
            day_id: 3,
            is_selected: contractDetails.workingDayTue ? 1 : 0,
          },
          {
            day_code: 'Wed',
            day_id: 4,
            is_selected: contractDetails.workingDayWed ? 1 : 0,
          },
          {
            day_code: 'Thu',
            day_id: 5,
            is_selected: contractDetails.workingDayThu ? 1 : 0,
          },
          {
            day_code: 'Fir',
            day_id: 6,
            is_selected: contractDetails.workingDayFri ? 1 : 0,
          },
          {
            day_code: 'Sat',
            day_id: 7,
            is_selected: contractDetails.workingDaySat ? 1 : 0,
          },
        ],
        // resource_id: contractDetails.selectedResources,
        resource_id: tempResources,
        amenity_id:amenityData,
        //  [ ...contractDetails.selectedFreeAmentities, ...contractDetails.selectedPaidAmentities
          // {
          //   amenity: {...contractDetails.selectedFreeAmentities, ...contractDetails.selectedPaidAmentities},
          //   no_of_units:contractDetails.sliderValues,
          //   price:contractDetails.price,
  
          // }
        // ],
        inclusion_id: inclusionAll,
        exclusion_id: exclusionAll,
        property_type_id: Number(contractDetails.propertyType),
        contract_document_type_id: Number(contractDetails.ContractType), // client-1 individual-2
        direct_contract: contractDetails.isDirectContract ? 1 : 0,
        // is_confirm : 1,
      };
      if (isRenewal) formData.direct_contract = 1;
      // console.log(formData);
      if (isConfirm === false) {
        if (contractDetails.kycStatus === KYC_REQUESTED) {
          // ### KYC REQUEST
          // console.log('laksh-request', formData);
          API.postMethod(ADDCRS, formData)
            .then((res) => {
              if (res?.data?.status === 'success') {
                setIsButtonLoading(false);
                ToastBRH('success', 'KYC Requested Successfully', TOAST_SUCCESS);
                setTimeout(() => navigate(routeNames.CRS), TOAST_SUCCESS);
              }
            })
            .catch((err) => {
              console.error(err);
              setIsButtonLoading(false);
              ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
            });
        } else if (contractDetails.kycStatus === KYC_ACCEPTED) {
          // ### MOU | CONTRACT PREVIEW
          // console.log('laksh-preview', formData);
          API.pdfDownload(GENERATEMOU, formData)
            .then((response) => {
              // Create a Blob from the PDF Stream
              const file = new Blob([response.data], { type: 'application/pdf' });
              // Build a URL from the file
              const fileURL = URL.createObjectURL(file);
              // Open the URL on new Window
              // window.open(fileURL, '_blank');//working code
              const iframe = document.querySelector('iframe');
              if (iframe?.src) iframe.src = fileURL;
              setLakshFile(fileURL);
              setContractDetails({
                ...contractDetails,
                isOpenDialog: true,
              });
              setIsButtonLoading(false);
            })
            .catch((error) => {
              console.error(error);
              setIsButtonLoading(false);
            });
        }
      } else if (isConfirm === true) {
        // ###GENERATE MOU-CONTRACT
        // AFTER PREVIEW
        formData.is_confirm = 1;
        formData.id = Number(contractId);
        // FOR DIRECT CONTRACT (crs->contract)
        if (formData.direct_contract === 1) formData.is_newdirect = 1;
        // WHEN RENEWAL
        if (isRenewal) formData.is_newdirect = 0;
        if (isRenewal || contractDetails.isDirectContract) {
          formData.kyc_document = contractDetails.kycFile;
        }
        // console.log('laksh-generate', formData);
        API.pdfDownload(GENERATEMOU, formData)
          .then((resPdf) => {
            if (resPdf?.status === 200) {
              ToastBRH(
                'success',
                formData.direct_contract === 1 ? 'Contract Renewed Successfully' : 'MOU Generated Successfully',
                TOAST_SUCCESS
              );
              setTimeout(
                () => navigate(contractDetails.isDirectContract || isRenewal ? routeNames.CONTRACT : routeNames.MOU),
                TOAST_SUCCESS
              );
              // window.location.reload();
            }
          })
          .catch((errPdf) => {
            console.error(errPdf);
            ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
          });
      }
    };
    const handleKYCChange = () => {
      setKycOpen(true);
    };
    const handleKYCProceed = () => {
      setContractDetails({
        ...contractDetails,
        isKycChecked: !contractDetails.isKycChecked,
        kycStatus: 2,
      });
      setKycOpen(false);
    };
    const handleKYCBack = () => {
      setKycOpen(false);
    };
    const handleIncChange = (isInc, name, masterList, checkedList) => {
      const toIncluded = [];
      const toStay = [];
      const indexes = [];
  
      contractDetails[checkedList].forEach((ele) => {
        contractDetails[masterList].forEach((inc, i) => {
          if (Number(ele) === inc.id) {
            toIncluded.push(inc);
            indexes.push(i);
          }
        });
      });
  
      for (let index = 0; index < contractDetails[masterList].length; index += 1) {
        if (!indexes.includes(index)) {
          toStay.push(contractDetails[masterList][index]);
        }
      }
      if (isInc === 'true') {
        if (name === 'add') {
          setContractDetails({
            ...contractDetails,
            [masterList]: toStay,
            [checkedList]: [],
            selectedInclusionList: [...contractDetails.selectedInclusionList, ...toIncluded],
          });
        }
        if (name === 'delete') {
          setContractDetails({
            ...contractDetails,
            [masterList]: toStay,
            [checkedList]: [],
            inclusionsAndExclusionsMaster: [...contractDetails.inclusionsAndExclusionsMaster, ...toIncluded],
          });
        }
      }
      if (isInc === 'false') {
        if (name === 'add') {
          setContractDetails({
            ...contractDetails,
            [masterList]: toStay,
            [checkedList]: [],
            selectedExclusionsList: [...contractDetails.selectedExclusionsList, ...toIncluded],
          });
        }
        if (name === 'delete') {
          setContractDetails({
            ...contractDetails,
            [masterList]: toStay,
            [checkedList]: [],
            inclusionsAndExclusionsMaster: [...contractDetails.inclusionsAndExclusionsMaster, ...toIncluded],
          });
        }
      }
    };
    const handleClose = () => {
      setContractDetails({
        ...contractDetails,
        isOpenDialog: false,
        // setLakshFile(null)
      });
    };
    const calculateNumberOfMonths = (
      startDate = contractDetails.agreeStartDate,
      endDate = contractDetails.agreeEndDate
    ) => {
      let months;
      months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
      months -= startDate.getMonth();
      months += endDate.getMonth();
      months = months <= 0 ? 0 : months;
      return setContractDetails({ ...contractDetails, numberOfMonths: months });
    };
  
    const calculateNumberOfDaysAndMonths = (startDate, endDate, isMonth = 1) => {
      let months;
      let days;
      months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
      months -= startDate.getMonth();
      months += endDate.getMonth();
      days = isRenewal
        ? (endDate - startDate) / (1000 * 60 * 60 * 24)
        : Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
      months = months <= 0 ? 0 : months;
      days = days <= 0 ? 0 : days;
      return isMonth === 1 ? months : days;
    };
    // GET THE STATE VALUES
    useEffect(() => {
      // const initMonths = calculateNumberOfDaysAndMonths(contractDetails.agreeStartDate, contractDetails.agreeEndDate);
      const initMonths = monthsBetweenDates(
        new Date(contractDetails.agreeStartDate),
        new Date(contractDetails.agreeEndDate)
      );
      const initdays = calculateNumberOfDaysAndMonths(contractDetails.agreeStartDate, contractDetails.rentStartDate, 0);
      const user = localStorage.getItem('user');
      const userata = JSON.parse(user);
      const userId = userata?.id;
      userData.current = userata;
      // VARIBLES FOR API RESULT
      let filteredClients = [];
      let dataProperty = [];
      let dataAmentities = [];
      let incExcMaster = [];
      let dataCM = [];
      let dataStateList = [];
      // for edit
      let dataContract = {};
      let UserPropertTypeData = [];
      let startDate;
      let endDate;
      let apiClientObject;
      let apiPropertyObject;
      let apiPropertyTypeObject;
      let apiClientTimings;
      let resources;
      const paidAmentities = [];
      const freeAmentities = [];
      const apiSelectedPaidAmentities = [];
      const apiSelectedFreeAmentities = [];
      const apiSelectedresorces = [];
      let apiIncObjectList;
      let apiExcObjectList;
      let tempDedicatedRent = 0;
      let tempDedicatedSeats = 0;
      let tempFlexiRent = 0;
      let tempFlexiSeats = 0;
      // let PaidParkingRent= 0;
      Promise.allSettled([
        API.getData(BRHPROPERTYLIST),
        API.getData(AMENTITIESLIST),
        API.getData(GETCLIENTS),
        API.getData(INCLUSIONEXCLUSIONMASTER),
        API.getData(`${COMPANYSHOW}/${1}`),
        API.getData(STATELIST),
      ])
        .then((res) => {
          res.forEach((ele, i, arr) => {
            const { status, value } = ele;
            if (status === 'fulfilled') {
              if (i === 0) {
                // property
                if (value.data.status === 'success') {
                  dataProperty = value.data.result;
                }
              } else if (i === 1) {
                // amentities
                if (value.data.status === 'success') {
                  // console.log(value.data.data.data,"myseens")
                  dataAmentities = value.data.data.data;
                }
              } else if (i === 2) {
                // clients
                if (value.data.status === 'success') {
                  filteredClients = value.data.results?.flatMap((cli) => (cli.is_prospect === 0 ? cli : []));
                }
              } else if (i === 3) {
                // inc-exc-master
                if (value.data.status === 'success') {
                  incExcMaster = value.data.data.data;
                }
              } else if (i === 4) {
                // company-master
                if (value.data.status === 'success') {
                  dataCM = value.data.results;
                }
              } else if (i === 5) {
                // all states
                if (value.data.status === true) {
                  dataStateList = value.data.data;
                }
              }
            } else {
              throw new Error(`i`);
            }
          });
          // if (isUpdated === true) {
          if (contractId) {
            API.getData(`${SHOWCRS}/${contractId}`)
              .then((resContgract) => {
                if (resContgract?.data?.status !== 'success') {
                  throw new Error('showCrs error');
                } else {
  
                  console.log(resContgract?.data.results, "ffff")
                  dataContract = resContgract?.data?.results;
  
                  setusualContracts(resContgract?.data.results);
                  // useful contstants
                  const apiClientDetails = dataContract?.contract_client;
                  apiClientTimings = dataContract?.contract_timings;
                  // logic for clinet details
                  filteredClients?.forEach((eleCli) => {
                    if (eleCli?.id === apiClientDetails?.id) {
                      apiClientObject = eleCli;
                    }
                  });
                  const [startHours, startMinutes, startSeconds] = apiClientDetails?.from_time?.split(':');
                  const [endHours, endminutes, endSeconds] = apiClientDetails?.to_time?.split(':');
                  startDate = new Date(2023, 1, 1, +startHours, +startMinutes, +startSeconds);
                  endDate = new Date(2023, 1, 1, +endHours, +endminutes, +endSeconds);
                  // property logic
  
                  dataProperty?.forEach((elePro) => {
                    if (elePro?.id === dataContract?.property_id) {
                      apiPropertyObject = elePro;
                    }
                  });
                  const UserPropertType =
                    Number(dataContract?.property_type_id) === 1
                      ? COWORKINGPROPERTYLIST
                      : Number(dataContract?.property_type_id) === 4
                        ? BRHPROPERTYLIST
                        : SCOUTPROPERTYLIST;
                  // return API.getData(`${PROPERTYAMENTITY}/${dataContract?.property_id}`);
                  return API.getData(UserPropertType);
                }
              })
              .then((resUserPropertType) => {
                if (resUserPropertType?.data?.status !== 'success') {
                  throw new Error('userProperty error');
                } else {
                  UserPropertTypeData = resUserPropertType?.data.result;
                  // let apiPropertyTypeObject;
                  UserPropertTypeData?.forEach((eleProType) => {
                    if (eleProType?.id === dataContract?.property_id) {
                      apiPropertyTypeObject = eleProType;
                    }
                  });
                  return API.getData(`${PROPERTYAMENTITY}/${dataContract?.property_id}`);
                }
              })
              .then((resAmePro) => {
                if (resAmePro?.data?.status !== 'success') {
                  throw new Error('userAmentity error');
                } else {
  
                
  
                  // console.log(dataAmentities, "dtamenities")
                  dataAmentities?.forEach((ameList) => {
                    resAmePro.data.data?.forEach((ame) => {
                      if (ameList.id === ame.amenity_id && ame.is_paid === 0) {
                        freeAmentities.push({
                          id: ameList.id,
                          name: ameList.amenity_name,
                        });
                        // console.log(resAmePro,freeAmentities,"mnhhhh")
                      } 
                      else    if (ameList.id === ame.amenity_id && ame.is_paid === 1) {
                        // console.log(dataContract?.contract_amenities,"dataContractss")
  
                     
    
                     
                      }
                    });
                  });
  
                  if (dataContract?.property_amenities) {
                    const updatedSliderValues = {};
                    // const updatedPrices = {};
                    // const paidAmentities = [];
                  
                    console.log(dataContract?.property_amenities, "asd_amenities");
                  
                    // Filter property amenities where is_paid is 1 and map the data accordingly
                    dataContract?.property_amenities
                      .filter((element) =>  element.is_paid === 1)
                      .forEach((element) => {
                        updatedSliderValues[element.amenity_id] = element.unit_counts;
                        updatedPrices[element.amenity_id] = element.unit_amount;
                  
                        paidAmentities.push({
                          id: element.id,
                          name: element.amenity_name,
                          unit_counts: element.unit_counts,
                          unit_amount: element.unit_amount,
                          fee_per_unit: element.fee_per_unit,
                          paid_unit: element.paid_units,
  
                          
                        });
                      });
                  
                    // Set state with filtered data
                    setSliderValues(updatedSliderValues);
                    setPrices(updatedPrices);
  
                    // console.log(paidAmentities,"ssdfgh")
                  
                    setContractDetails((prevDetails) => ({
                      ...prevDetails,
                      propertyPaidAmentitiesList: paidAmentities,
                      selectedPaidAmentities: dataContract.selectedPaidAmentities,
                      paidparkingprice: prices ,
                    }));
  
                    console.log(dataContract.property_amenities,"aasssddd")
                    const PaidFacilitytotal = dataContract.property_amenities.reduce((sum, item) => {
                      const amountAsNumber = Number(item.unit_amount); // Convert string to number
                      const product = amountAsNumber * item.unit_counts; // Multiply by the other property
                      return sum + product; // Add to the running total
                    }, 0);
  
                    SetPPamenity(PaidFacilitytotal)
  
                    const OfcSpaceTotal = dataContract.contract_resources.reduce((sum, item) => {
                      // const amountAsNumber = Number(item.no_of_seats); // Convert string to number
                      const product = item.no_of_seats * item.price; // Multiply by the other property
                      return sum + product; // Add to the running total
                    }, 0);
  
                    const total = PaidFacilitytotal + OfcSpaceTotal;
                    setUnitsels(PaidFacilitytotal);
                    setchecking(true);
  
  // console.log(total); 
  
  setContractDetails(prevDetails => ({
    ...prevDetails,
    monthlyServiceCharges: total
  }));
  
  
                    // console.log(PaidFacilitytotal,OfcSpaceTotal,total,"totalam")
                  
                    // AmountCalculation(contractDetails.propertyPaidAmentitiesList);
                  }
                  // logic for selected amentities;
                  dataContract?.contract_amenities?.forEach((apiAme) => {
                    // console.log(dataContract?.contract_amenities,"cdsamentyu")
                    if (freeAmentities?.some((frAme) => frAme.id === apiAme?.amenity_id)) {
                      apiSelectedFreeAmentities.push(String(apiAme.amenity_id));
                    } else if (paidAmentities?.some((paidAme) =>paidAme.id === apiAme?.amenity_id)) {
                      // console.log(contractDetails.propertyPaidAmentitiesList,"mmnmnpp")
                      // console.log(paidAmentities,"paidAmentitiesdata")
                      // console.log(apiAme,"apime")
                      setContractDetails({
                        ...contractDetails,
                        propertyPaidAmentitiesList:[apiAme]
                      })
  
  
                      // setSliderValues(apiAme)
                      apiSelectedPaidAmentities.push(String(apiAme.amenity_id));
                      
                    }
                  });
                  return API.getData(`${PROPERTYRESOURCE}/${dataContract?.property_id}`);
                }
              })
              .then((resProEdit) => {
                if (resProEdit?.data?.success !== 'success') {
                  throw new Error('userPropertyResorce error');
                } else {
                  resources = resProEdit?.data?.data.map((res) => ({
                    resource_group_id: res.resource_group_id,
                    id: res.id,
                    name: res.resource_name,
                    quantity: res.quantity,
                  }));
                  const apiDedicatedList = resources?.filter((ele) => ele.resource_group_id === DEDICATED_DESK_GROUP_ID);
                  const apiFlexiList = resources.filter((ele) => ele.resource_group_id === FLEXI_DESK_GROUP_ID);
                  // logic for property resources
  
                  dataContract?.contract_resources?.forEach((apiRes) => {
                    // SETING SELECTED RESOURCES
                    if (resources?.some((ele) => ele?.id === apiRes?.resource_id)) {
                      apiSelectedresorces.push(String(apiRes?.resource_id));
                    }
                    // SETTING DEDICATED DESK PRICE AND SEATS
                    apiSelectedresorces?.forEach((ele) => {
                      if (apiDedicatedList.some((res) => res.id === Number(ele))) {
                        tempDedicatedSeats = dataContract?.contract_resources.find(
                          (dedicated) => dedicated.resource_id === Number(ele)
                        )?.no_of_seats;
                        tempDedicatedRent = dataContract?.contract_resources.find(
                          (dedicated) => dedicated.resource_id === Number(ele)
                        )?.price;
                      }
                      if (apiFlexiList.some((res) => res.id === Number(ele))) {
                        tempFlexiSeats = dataContract?.contract_resources.find(
                          (dedicated) => dedicated.resource_id === Number(ele)
                        )?.no_of_seats;
                        tempFlexiRent = dataContract?.contract_resources.find(
                          (dedicated) => dedicated.resource_id === Number(ele)
                        )?.price;
                      }
                    });
                  });
                  // logic for inclusions ad exclusions
                  const apiIncList = [];
                  const apiExcList = [];
                  dataContract?.contract_inc_exc?.forEach((ele) => {
                    if (ele.is_inclusion === 1 && ele.is_exclusion === 0) {
                      apiIncList.push(ele.inc_exc_id);
                    } else if (ele.is_inclusion === 0 && ele.is_exclusion === 1) {
                      apiExcList.push(ele.inc_exc_id);
                    }
                  });
                  apiIncObjectList = incExcMaster.flatMap((incExcEle) => {
                    let value;
                    if (apiIncList?.some((incEle) => incExcEle?.id === incEle)) {
                      value = incExcEle;
                    } else {
                      value = [];
                    }
                    return value;
                  });
                  apiExcObjectList = incExcMaster.flatMap((incExcEle) => {
                    let value;
                    if (apiExcList?.some((excEle) => incExcEle?.id === excEle)) {
                      value = incExcEle;
                    } else {
                      value = [];
                    }
                    return value;
                  });
                  return API.getData(`${PROPERTYTIMINGS}/${dataContract?.property_id}`);
                }
              })
              .then((resPropTime) => {
                if (resPropTime.data.status !== 'success') {
                  throw new Error('property api error');
                } else {
                  const dataPropTime = resPropTime.data.data;
                  const [tempPrstatrtHrs, tempPrstatrtMins] = apiPropertyTypeObject.start_at.split(':');
                  const [tempPrEndHrs, tempPrEndMins] = apiPropertyTypeObject.end_at.split(':');
                  const prStart = new Date(2023, 1, 1, tempPrstatrtHrs, tempPrstatrtMins, 0);
                  const prEnd = new Date(2023, 1, 1, tempPrEndHrs, tempPrEndMins, 0);
                  const filterdList = filteredClients.filter(
                    (cli) => cli.contract_document_type_id === Number(dataContract?.contract_document_type_id)
                  );
                  // setting remaining values in incexc master
                  const selectedIncExcMaster = [...apiIncObjectList, ...apiExcObjectList];
                  const tempIncExcMaster = incExcMaster.flatMap((incExc) => {
                    let value;
                    if (selectedIncExcMaster?.some((selectedIncExc) => selectedIncExc?.id === incExc.id)) {
                      value = [];
                    } else {
                      value = incExc;
                    }
                    return value;
                  });
                  const tempRepresentativeSignatoryId = dataCM?.company_addresses?.find(
                    (ele) => ele.id === dataContract?.company_address_id
                  )?.signatory;
                  renewalYOY.current = dataContract?.year_on_year_increment;
  
                  const newReneweStartDate=new Date(dataContract?.agreement_end_date);
                  // Convert the string to a Date object

          // Add one day to the date
            const NewR= newReneweStartDate.setDate(newReneweStartDate.getDate() + 1);

            setNewStart(NewR)
            // Assume no_of_months is the number of months you want to add
// const no_of_monthss = dataContract?.no_of_years; // Example: 6 months

// Add the specified number of months to the NewR date
const  NewRenddate = new Date(NewR);  // Create a copy of the NewR date
const NewRend= NewRenddate.setMonth(NewRenddate.getMonth() + dataContract?.no_of_years);
setNewEnd(NewRend)
console.log(NewRend);

                  // console.log("Tamil")
                  setContractDetails({
                    ...contractDetails,
                    isFetched: true,
                    userId,
                    clientList: filteredClients,
                    contractTypeClientList: filterdList,
                    amentitiesList: dataAmentities,
                    contactPersonsList: dataCM?.company_addresses,
                    stateList: dataStateList,
                    // update
                    company_name: apiClientObject,
                    propertiesList: UserPropertTypeData,
                    property_name: apiPropertyTypeObject,
                    clientAddress: apiClientObject?.company_address,
                    clientSignatory: apiClientObject?.company_signatory,
                    clientCPName: apiClientObject?.contact_person_name,
                    clientWorkingDays: apiClientTimings,
                    workingDaySun: apiClientTimings[0]?.is_selected,
                    workingDayMon: apiClientTimings[1]?.is_selected,
                    workingDayTue: apiClientTimings[2]?.is_selected,
                    workingDayWed: apiClientTimings[3]?.is_selected,
                    workingDayThu: apiClientTimings[4]?.is_selected,
                    workingDayFri: apiClientTimings[5]?.is_selected,
                    workingDaySat: apiClientTimings[6]?.is_selected,
                    workingHoursFrom: startDate || new Date(),
                    workingHoursTo: endDate || new Date(),
                    propertyFreeAmentitiesList: freeAmentities,
                    propertyPaidAmentitiesList: paidAmentities,
                    selectedFreeAmentities: apiSelectedFreeAmentities,
                    selectedPaidAmentities: apiSelectedPaidAmentities,
                    propertyResourcesList: resources,
                    selectedResources: apiSelectedresorces,
                    company_address: dataContract?.company_address_id,
                    representativeSignatoryId: tempRepresentativeSignatoryId,
                    selectedInclusionList: apiIncObjectList,
                    selectedExclusionsList: apiExcObjectList,
                    inclusionsAndExclusionsMaster: tempIncExcMaster,
                    // dateOfAgreement: new Date(dataContract?.date_of_agreement),
                    agreeStartDate: isRenewal
                      ? new Date(new Date('2024-02-01').getTime() + 8.64e7)
                      : NewR,
                    agreeEndDate: isRenewal ? null : NewRend,
                    numberOfMonths: isRenewal ? null : dataContract?.no_of_years,
                    lockedInPeriod: dataContract?.lock_in_period_months,
                    noticePeriod: dataContract?.notice_in_period_months,
                    rentStartDate: isRenewal
                      ? new Date(new Date('2024-02-01').getTime() + 8.64e7)
                      : NewR,
                    yearOnYearIncrement: dataContract?.year_on_year_increment,
                    numberOfCarParking: dataContract?.no_of_car_parking,
                    numberOfBikeParking: dataContract?.no_of_bike_parking,
                    refundableSecurityAmount: dataContract?.refunable_security_amount,
                    monthlyServiceCharges: dataContract?.monthly_rent,
                    monthlyServiceChargesTax: dataContract?.tax_percentage,
                    intrestRate: dataContract?.interest_rate_if_failed_to_pay_mg,
                    anyServices: dataContract?.any_other_services,
                    isDgSelected: dataContract?.is_dg,
                    isWaterSelected: dataContract?.is_water,
                    isInternetSelected: dataContract?.is_internet,
                    isElectricitySelected: dataContract?.is_electricity,
                    isTeaCoffeSelected: dataContract?.is_teaorcoffee,
                    isKycChecked: dataContract?.kyc_status,
                    isDirectContract: dataContract?.direct_contract,
                    clientId: dataContract?.contract_client_id,
                    propertyId: dataContract?.property_id,
                    propertyType: dataContract?.property_type_id,
                    ContractType: Number(dataContract.contract_document_type_id),
                    kycStatus: dataContract.kyc_status,
                    kycReason: dataContract.kyc_reason,
                    kycRemarks: dataContract.remark,
                    kycFile: dataContract.kyc_document,
                    dedicatedSeatPrice: tempDedicatedRent,
                    dedicatedSeatsSelected: tempDedicatedSeats,
                    flexiSeatPrice: tempFlexiRent,
                    // paidparkingprice: PaidParkingRent,
                    flexiSeatsSelected: tempFlexiSeats,
                    rentFree: isRenewal
                      ? 0
                      : calculateNumberOfDaysAndMonths(
                        new Date(dataContract.agreement_start_date),
                        new Date(dataContract.rent_start_date),
                        0
                      ),
                    // rentFree: 2,
                    propertyWorkingDays: dataPropTime,
                    propertyStartTime: prStart,
                    propertyEndTime: prEnd,
                    
                  });
            // console.log(contractDetails,"bnml")
  
                }
              })
              .catch((errUpdate) => {
                const filterdList = filteredClients.filter((cli) => cli.contract_document_type_id === 1);
                // console.log("Tamil1", usualcontracts)
  
                setContractDetails({
                  ...contractDetails,
                  isFetched: true,
                  userId,
                  clientList: filteredClients,
                  contractTypeClientList: filterdList,
                  propertiesList: dataProperty,
                  amentitiesList: dataAmentities,
                  inclusionsAndExclusionsMaster: incExcMaster,
                  contactPersonsList: dataCM?.company_addresses,
                  stateList: dataStateList,
                  numberOfMonths: initMonths,
                  rentFree: initdays,
                });
                // console.log(contractDetails,"abcd")
              });
          } else {
            const filterdList = filteredClients.filter((cli) => cli.contract_document_type_id === 1);
            // console.log("Tamil2")
  
            setContractDetails({
              ...contractDetails,
              isFetched: true,
              userId,
              clientList: filteredClients,
              contractTypeClientList: filterdList,
              propertiesList: dataProperty,
              amentitiesList: dataAmentities,
              inclusionsAndExclusionsMaster: incExcMaster,
              contactPersonsList: dataCM?.company_addresses,
              stateList: dataStateList,
              numberOfMonths: initMonths,
              rentFree: initdays,
            });
            // console.log(contractDetails,"abcd")
  
          }
        })
        .catch((err) => {
          console.error(err);
          // console.log("Tamil4")
  
          setContractDetails({
            ...contractDetails,
            isFetched: true,
          });
          // console.log(contractDetails,"abcd")
  
        });
    }, []);
    // UPDATE UI WHEN `11111111111111111111111111111111111111111`````````````````````````````````11`````````````````````````````````````````````````````` OR REJECTED
    useEffect(() => {
      if (iskycUpdated === true) {
        API.getData(`${SHOWCRS}/${contractId}`).then((resContgract) => {
          if (resContgract?.data?.status !== 'success') {
            throw new Error('showCrs error');
          } else {
            // console.log(resContgract,'resggg')
            const dataContract = resContgract?.data?.results;
            setContractDetails({
              ...contractDetails,
              kycStatus: dataContract.kyc_status,
              kycReason: dataContract.kyc_reason,
              kycFile: dataContract.kyc_document,
            });
            // console.log(dataContract,"mnbv")
          }
        });
      }
    }, [iskycUpdated]);
  
  
    // CALCULATING NUMBER OF MONTHS
    useEffect(() => {
      if ((contractDetails.agreeStartDate, contractDetails.agreeEndDate)) {
        const months = monthsBetweenDates(
          new Date(contractDetails.agreeStartDate),
          new Date(contractDetails.agreeEndDate)
        );
        setContractDetails((prevData) => ({ ...prevData, numberOfMonths: months }));
      }
    }, [contractDetails.agreeStartDate, contractDetails.agreeEndDate]);
    // CALCULATING RENT FREE DAYS
    useEffect(() => {
      if (contractDetails.agreeStartDate && contractDetails.rentStartDate) {
        const days = calculateNumberOfDaysAndMonths(
          new Date(contractDetails.agreeStartDate),
          new Date(contractDetails.rentStartDate),
          0
        );
        setContractDetails((prevData) => ({ ...prevData, rentFree: days }));
      }
    }, [contractDetails.agreeStartDate, contractDetails.rentStartDate]);
    // CALCULATE MONTHLY RENT
  
  
    useEffect(() => {
      let tempDedicatedRent = 0;
      let tempFlexiRent = 0;
      let PaidParkingRent = 0;
  
      if (contractDetails.dedicatedSeatPrice && contractDetails.dedicatedSeatsSelected) {
        tempDedicatedRent = Number(contractDetails.dedicatedSeatPrice) * Number(contractDetails.dedicatedSeatsSelected);
      }
   
      if (contractDetails.flexiSeatPrice && contractDetails.flexiSeatsSelected) {
        tempFlexiRent = Number(contractDetails.flexiSeatPrice) * Number(contractDetails.flexiSeatsSelected);
      }
  
      
      Object.keys(prices).forEach(id => {
        const unitPrice = contractDetails.paidparkingprice[id];
        const unitsSelected = sliderValues[id] || 0;
  
        SetupdateunitPrice(unitPrice);
        SetUpdateunitSelect(unitsSelected);
        // setUnitsels(unitsSelected); 
        if (unitPrice && unitsSelected) {
          
          PaidParkingRent += Number(unitPrice) * Number(unitsSelected);
          
        }
      });
  
      if(contractId && checking) {
        let monthlyServiceCharges = tempDedicatedRent + tempFlexiRent;
        if (resolveCheck) {
          monthlyServiceCharges += alltotal;
        } else {
          monthlyServiceCharges += PPamenity;
        }
        setContractDetails(prevDetails => ({
          ...prevDetails,
          monthlyServiceCharges
        }));
      } else {
        setContractDetails(prevDetails => ({
          ...prevDetails,
          monthlyServiceCharges:
            tempDedicatedRent + tempFlexiRent +    PaidParkingRent 
        }));
      }
  
      
      // setContractDetails(prevDetails => ({
      //   ...prevDetails,
      //   monthlyServiceCharges:
      //     tempDedicatedRent + tempFlexiRent + !unitsels ?  PaidParkingRent  : unitsels
      // }));
      // console.log(PaidParkingRent, unitsels, "ppsd") 
  
    }, [
      contractDetails.dedicatedSeatPrice,
      contractDetails.dedicatedSeatsSelected,
      contractDetails.flexiSeatPrice,
      contractDetails.flexiSeatsSelected,
      contractDetails.propertyPaidAmentitiesList,
      contractDetails.paidAmentities,
      prices,updatedPrices,
      contractDetails.paidparkingprice,
      contractDetails.paidAmentities,alltotal,
      contractDetails.monthlyServiceCharges,
      // PPamenity,
      // contractDetails,
      sliderValues // Ensure to add sliderValues to dependencies
      ]);
  
  console.log(resolveCheck,'resolve')
  

  const triggerLoadingButton = () => {
    if (loadingButtonRef.current) {
      loadingButtonRef.current.handleSubmit();
    }
  }
  

  const datestart = new Date(contractDetails?.workingHoursFrom);
const starttime = datestart.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
// console.log(starttime); 


const dateend = new Date(contractDetails?.workingHoursTo);
const endttime = dateend.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
// console.log(endttime); 

const matchingNames = contractDetails?.selectedResources.map(id => {
  const match = contractDetails?.propertyResourcesList.find(item => item.id === Number(id));
  return match ? match.name.trim() : null;  // Use trim() to remove any extra whitespace or newline characters
});


const matchingFreeAm = contractDetails?.selectedFreeAmentities.map(id => {
  const match = contractDetails?.propertyFreeAmentitiesList.find(item => item.id === Number(id));
  return match ? match.name.trim() : null;  // Use trim() to remove any extra whitespace or newline characters
});


// const lastKey = Object?.keys(alltotal).pop();
// console.log(lastKey,"mmllmlml");
const MatchingPaid = Object.keys(contractDetails.paidparkingprice).map(key => {
  const matchedService = contractDetails?.propertyPaidAmentitiesList.find(service => service.id.toString() === key);
// const lastKey = Object?.keys(alltotal).pop();
//  console.log(lastKey,"ccc")
  if (matchedService) {
      return {
          id: matchedService.id,
          name: matchedService.name,
          inputValue: contractDetails?.paidparkingprice[key],
          paid_unit: matchedService.paid_unit,
          fee_per_unit: matchedService.fee_per_unit
      };
  }
  return null;
}).filter(item => item !== null);

// console.log(MatchingPaid,"mmnnn"); 

// Create a Date object from the timestamp
const SRdate = new Date(newStart);
const SRdateend = new Date(newEnd);
// Format the date to YYYY-MM-DD
const newSRDate = SRdate.toDateString();
const newSREndDate = SRdateend.toDateString();
// console.log(newSRDate);
  
    return (
      <>
        {contractDetails.isFetched ? (
          <>
            <Helmet>
              {/* <title> {isUpdated === true ? 'Edit' : 'Add'} Contract | BRH </title>  ---v1 */}
              <title> {contractId ? 'Renewal' : 'Testing'} Contract | BRH </title>
            </Helmet>
            <Container sx={{ display: contractDetails.isOpenDialog ? 'none' : 'block' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h4" gutterBottom>
                  {/* {isUpdated === true ? 'Edit' : 'Add'} CRS ---v1 */}
                  {contractId ? 'Renewal' : 'Testing check'} Contract
                </Typography>
              </Stack>
  
              <Card sx={{ p: 2 }}>
                <Scrollbar>
                  <Box sx={{ flexGrow: 1, m: 4 }}>
                    <form >
                      <FormControl sx={{ flexGrow: 1, width: '100%' }}>
                        <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                          {/* choose client or indivitual */}
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl disabled={contractDetails.kycStatus > 0}>
                              <FormLabel id="ContractType-radio-buttons-group">Contract Type</FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="ContractType-radio-buttons-group"
                                name="ContractType"
                                value={contractDetails.ContractType}
                                onChange={handleChange}
                              >
                                <FormControlLabel value={1} control={<Radio />} label="Company" />
                                <FormControlLabel value={2} control={<Radio />} label="Individual" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          {/* Client Name */}
                          <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={1}>
                              <Grid item sx={10} sm={10} md={10}>
                                <AutoCompleteInputBRH
                                  label="Client Name"
                                  name="company_name"
                                  value={contractDetails.company_name}
                                  handleChange={handleAutoCompleteChange}
                                  options={contractDetails.contractTypeClientList}
                                  isSelectDataloaded={contractDetails.isSelectDataloaded}
                                  required
                                  readOnly={contractDetails.kycStatus > 0}
                                />
                              </Grid>
                              <Grid item sx={2} sm={2} md={2}>
                                <Button
                                  sx={{ fontSize: '1.5rem' }}
                                  variant="outlined"
                                  onClick={() => navigate(`${routeNames.CLIENTSADD}`)}
                                >
                                  +
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* Agreement Execution Date */}
                          <Grid item xs={12} sm={12} md={6}>
                            <DatePickerInputBRH
                              label="Agreement Execution Date *"
  
                              value={contractDetails.dateOfAgreement}
                              handleChange={(value) => {
                                handleDateChange('dateOfAgreement', value);
                              }}
                            />
                          </Grid>
                          {/* Client Address */}
                          <Grid item xs={12} sm={12} md={12}>
                            <TextInputBRH
                              label="Client Address"
                              name="clientAddress"
                              value={contractDetails.clientAddress}
                              handleChange={handleChange}
                              rows={10}
                              required
                              readOnly={contractDetails.kycStatus > 0}
                            />
                          </Grid>
                          {/* agreement dates */}
                          <Grid item xs={12} sm={12} md={12} spacing={1}>
                            <Grid container spacing={1}>
                              <Grid item md={4}>
                                <DatePickerInputBRH
                                  label="Agreement Start Date *"
                                  value={contractDetails.agreeStartDate}
                                  handleChange={(value) => {
                                    handleDateChange('agreeStartDate', value);
                                  }}
                                  readOnly={isRenewal}
                                />
                              </Grid>
                              <Grid item md={4}>
                                <DatePickerInputBRH
                                  label="Agreement End Date *"
                                  value={contractDetails.agreeEndDate}
                                  handleChange={(value) => handleDateChange('agreeEndDate', value)}
                                  // handleChange={handleDateChange}
                                  min={contractDetails.agreeStartDate}
                                />
                              </Grid>
                              <Grid item md={4}>
                                <NumberInputBRH
                                  label="No Of Months"
                                  name="numberOfMonths"
                                  value={contractDetails.numberOfMonths}
                                  handleChange={handleChange}
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">months</InputAdornment>,
                                  }}
                                  isTypeNumber="true"
                                  required
                                  readOnly="true"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
  
                          {/* Lock-In Period */}
                          <Grid item xs={12} sm={12} md={6}>
                            <NumberInputBRH
                              label="Lock-In Period"
                              value={contractDetails.lockedInPeriod}
                              handleChange={handleChange}
                              name="lockedInPeriod"
                              required
                              InputProps={{
                                endAdornment: <InputAdornment position="end">months</InputAdornment>,
                              }}
                            />
                          </Grid>
                          {/* notice period */}
                          <Grid item xs={12} sm={12} md={6}>
                            <NumberInputBRH
                              label="Notice Period"
                              value={contractDetails.noticePeriod}
                              handleChange={handleChange}
                              name="noticePeriod"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">months</InputAdornment>,
                              }}
                              required
                            />
                          </Grid>
                          {/* rent start date */}
                          <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={1}>
                              <Grid item md={8}>
                                <DatePickerInputBRH
                                  label="Rent Starting Date *"
                                  value={contractDetails.rentStartDate}
                                  handleChange={(value) => {
                                    handleDateChange('rentStartDate', value);
                                  }}
                                  readOnly={isRenewal}
                                />
                              </Grid>
                              <Grid item md={4}>
                                <NumberInputBRH
                                  label="Rent Free Days"
                                  value={contractDetails.rentFree}
                                  name="rentFree"
                                  handleChange={handleChange}
                                  required
                                  readOnly="true"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* Year on Year Increment */}
                          <Grid item xs={12} sm={12} md={6}>
                            <NumberInputBRH
                              label="Year on Year Increment"
                              value={contractDetails.yearOnYearIncrement}
                              handleChange={handleChange}
                              name="yearOnYearIncrement"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                              required
                            />
                          </Grid>
                          {/* Property Type */}
                          <Grid item xs={12} md={12}>
                            <FormControl disabled>
                              <FormLabel id="propertyType-radio-buttons-group">Property Type</FormLabel>
                              <RadioGroup 
                                row
                                aria-labelledby="propertyType-radio-buttons-group"
                                name="propertyType"
                                value={contractDetails.propertyType}
                                onChange={handleChange}
                              >
                                <FormControlLabel value={9} control={<Radio />} label="Scouted Properties" />
                                <FormControlLabel value={4} control={<Radio />} label="BRH Properties" />
                                <FormControlLabel value={1} control={<Radio />} label="Co-Working Properties" />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          {/* Select Property */}
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl sx={{ width: '100%' }}   >
                              <AutoCompleteInputBRH
                                label="Select Property"
                                name="property_name"
                                value={contractDetails.property_name}
                                handleChange={handleAutoCompleteChange}
                                options={contractDetails.propertiesList}
                                isSelectDataloaded={contractDetails.isSelectDataloaded}
                                required 
                                readOnly={isRenewal}
                              />
                            </FormControl>
                          </Grid>
  
                          {/*  new Working Days */}
                          <Grid item xs={12} sm={12} md={8}
                            sx={{ display: contractDetails.propertyResourcesList.length > 0 ? 'block' : 'none' }} >
                            <Grid container sx={{ border: '1px solid #ccc', padding: '5px 10px' }}>
                              <Grid item xs={12}>
                                <Typography>Working Days</Typography>
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <CheckBoxInputBRH
                                  label="Sun"
                                  name="workingDaySun"
                                  value={contractDetails.workingDaySun}
                                  handleChange={handleChange}
                                // disabled
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <CheckBoxInputBRH
                                  label="Mon"
                                  name="workingDayMon"
                                  value={contractDetails.workingDayMon}
                                  handleChange={handleChange}
                                // disabled
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <CheckBoxInputBRH
                                  label="Tue"
                                  name="workingDayTue"
                                  value={contractDetails.workingDayTue}
                                  handleChange={handleChange}
                                // disabled
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <CheckBoxInputBRH
                                  label="Wed"
                                  name="workingDayWed"
                                  value={contractDetails.workingDayWed}
                                  handleChange={handleChange}
                                // disabled
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <CheckBoxInputBRH
                                  label="Thu"
                                  name="workingDayThu"
                                  value={contractDetails.workingDayThu}
                                  handleChange={handleChange}
                                // disabled
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <CheckBoxInputBRH
                                  label="Fri"
                                  name="workingDayFri"
                                  value={contractDetails.workingDayFri}
                                  handleChange={handleChange}
                                // disabled
                                />
                              </Grid>
                              <Grid item xs={6} sm={4} md={3}>
                                <CheckBoxInputBRH
                                  label="Sat"
                                  name="workingDaySat"
                                  value={contractDetails.workingDaySat}
                                  handleChange={handleChange}
                                // disabled
                                />
                              </Grid>
                            </Grid>
                            {/* PROPERTY-CLIENT WORKING DAY VALIDATION */}
                            {contractDetails.clientWorkingDays && contractDetails.propertyId && (
                              <Box sx={{ display: 'flex' }}>
                                {contractDetails.clientWorkingDays?.map((clday) => {
                                  // FIXING BACKEND ISSUE IN DAY_CODE FOR FRIDAY
                                  if (clday.day_code === 'Fir') clday.day_code = 'Fri';
                                  let found;
                                  if (clday.is_selected === 1) {
                                    found = contractDetails.propertyWorkingDays?.find(
                                      (pr) => pr.day_id === clday.day_code.toLowerCase()
                                    );
                                  }
                                  return (
                                    <ErrorMessageBRH
                                      display={!found && clday.is_selected === 1}
                                      key={clday.day_code}
                                      // message={`${clday.day_code.toLowerCase()}day not available`}
                                      message={`Property not available on ${clday.day_code === 'Sun'
                                        ? 'Sunday'
                                        : clday.day_code === 'Mon'
                                          ? 'Monday'
                                          : clday.day_code === 'Tue'
                                            ? 'Tuesday'
                                            : clday.day_code === 'Wed'
                                              ? 'Wednesday'
                                              : clday.day_code === 'Thu'
                                                ? 'Thursday'
                                                : clday.day_code === 'Fri'
                                                  ? 'Friday'
                                                  : 'Saturday'
                                        }`}
                                    />
                                  );
                                })}
                              </Box>
                            )}
                          </Grid>
                          {/* new workingHours */}
                          <Grid item xs={12} sm={12} md={4}
                            sx={{ display: contractDetails.propertyResourcesList.length > 0 ? 'block' : 'none' }} >
                            <Grid container spacing={1} direction="column"     >
                              {/* workingHoursStart */}
                              <Grid item xs={12} sm={12} md={12}>
                                <TimeInputBRH
                                  label="Working Hours From *"
                                  name="workingHoursFrom"
                                  value={contractDetails.workingHoursFrom}
                                  handleChange={(e) => setContractDetails({ ...contractDetails, workingHoursFrom: e })}
                                // disabled
                                />
                              </Grid>
                              {/* workingHoursEnd */}
                              <Grid item xs={12} sm={12} md={12} style={{ marginTop: '1rem' }} >
                                <TimeInputBRH
                                  label="Working Hours To *"
                                  name="workingHoursTo"
                                  value={contractDetails.workingHoursTo}
                                  handleChange={(e) => setContractDetails({ ...contractDetails, workingHoursTo: e })}
                                // disabled
                                />
                              </Grid>
                              ,
                            </Grid>
                            {/* PROPERTY-CLIENT WORKING HOURS VALIDATION */}
  
                            {contractDetails.propertyStartTime && (
                              <ErrorMessageBRH
                                message={`property opens at ${moment(contractDetails.propertyStartTime).format(
                                  'hh:mm A'
                                )}`}
                                display={contractDetails.workingHoursFrom < contractDetails.propertyStartTime}
                              />
                            )}
                            {contractDetails.propertyEndTime && (
                              <ErrorMessageBRH
                                message={`property closes at ${moment(contractDetails.propertyEndTime).format(
                                  'hh:mm A'
                                )}`}
                                display={contractDetails.workingHoursTo > contractDetails.propertyEndTime}
                              />
                            )}
                          </Grid>
  
  
  
                          {/* new property resources  */}
                          <Grid item xs={12} sm={12} md={12}
                            sx={{ display: contractDetails.propertyResourcesList.length > 0 ? 'block' : 'none' }}
                          >
                            <Box
                              sx={{
                                border: '1px solid #ccc',
                                padding: '10px 15px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography color={palette.secondary.darker} variant="subtitle2">
                                  Office Space Details
                                </Typography>
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                  <IconButton
                                    onClick={() => {
                                      handleSelectAll('propertyResourcesList', 'selectedResources');
                                    }}
                                  >
                                    <SelectAllIcon />
                                  </IconButton>
                                  <Typography variant="caption">select all</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                  <IconButton
                                    onClick={() => {
                                      handleDeSelectAll('selectedResources');
                                    }}
                                  >
                                    <DeselectIcon />
                                  </IconButton>
                                  <Typography variant="caption">Deselect all</Typography>
                                </Stack>
                              </Box>
                              <FormGroup>
                                <Grid container>
                                  {contractDetails.propertyResourcesList.map((res) => (
                                    <Grid item xs={12} sm={6} md={4} key={res.id}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            size="small"
                                            checked={contractDetails.selectedResources?.includes(`${res.id}`)}
                                            onChange={(e) => {
                                              handleCheckBoxGroupChange(e, 'selectedResources');
                                            }}
                                          />
                                        }
                                        label={`${res.name} ${res.resource_group_id === DEDICATED_DESK_GROUP_ID ||
                                          res.resource_group_id === FLEXI_DESK_GROUP_ID
                                          ? `(${res.quantity})`
                                          : ''
                                          }`}
                                        value={res.id}
                                      />
                                    </Grid>
                                  ))}
                                </Grid>
                              </FormGroup>
                            </Box>
  
                          </Grid>
  
                          {/* number of seats-dedicated */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            sx={{
                              // display: contractDetails.selectedResources.some((ele) => Number(ele) === 16)
                              display: isSpecificSeatSelected('dedicated') ? 'block' : 'none',
                            }}
                          >
  
                            <Typography variant="caption">
                              Number of Dedicated Desk{' '}
                              <Typography color="primary" variant="caption">
                                ({contractDetails.dedicatedSeatsSelected})
                              </Typography>
                            </Typography>
                            <Slider
                              aria-label="Number of Dedicated Desk"
                              value={contractDetails.dedicatedSeatsSelected}
                              onChange={handleChange}
                              name="dedicatedSeatsSelected"
                              valueLabelDisplay="auto"
                              // required={dedicatedList?.[0]}
                              required={contractDetails.selectedResources.some(
                                (ele) => Number(ele) === dedicatedList[0]?.id
                              )}
                              step={1}
                              marks
                              min={1}
                              max={
                                // contractDetails.propertyResourcesList.filter((ele) => ele.resource_group_id === DEDICATED_DESK_GROUP_ID)?.[0]
                                dedicatedList?.[0]?.quantity
                              }
                            />
                          </Grid>
                          {/* price */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            sx={{
                              // display: contractDetails.selectedResources?.some((ele) => Number(ele) === 16)
                              display: isSpecificSeatSelected('dedicated') ? 'block' : 'none',
                            }}
                          >
                            <MoneyInputBRH
                              label="Dedicated Desk Price"
                              value={contractDetails.dedicatedSeatPrice}
                              name="dedicatedSeatPrice"
                              handleChange={handleMoneyChange}
                              required={contractDetails.selectedResources.some(
                                (ele) => Number(ele) === dedicatedList[0]?.id
                              )}
                              // required={dedicatedList?.[0]}
                              // readOnly
                              endAdornmentText="seat"
                            />
                          </Grid>
                          {/* number of seats-flexi */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            sx={{
                              display: isSpecificSeatSelected() ? 'block' : 'none',
                            }}
                          >
                            <Typography variant="caption">
                              Number of Flexi Desk{' '}
                              <Typography color="primary" variant="caption">
                                ({contractDetails.flexiSeatsSelected})
                              </Typography>
                            </Typography>
  
                            <Slider
                              aria-label="Number of Flexi Desk"
                              value={contractDetails.flexiSeatsSelected}
                              onChange={handleChange}
                              name="flexiSeatsSelected"
                              valueLabelDisplay="auto"
                              step={1}
                              marks
                              min={1}
                              max={
                                contractDetails.propertyResourcesList.filter(
                                  (ele) => ele.resource_group_id === FLEXI_DESK_GROUP_ID
                                )?.[0]?.quantity
                              }
                              required={contractDetails.selectedResources.some((ele) => Number(ele) === flexiList[0]?.id)}
                            />
                         
                          </Grid>
                          {/* price */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            sx={{
                              display: isSpecificSeatSelected() ? 'block' : 'none',
                            }}
                          >
                            <MoneyInputBRH
                              label="Flexi Desk Price"
                              value={contractDetails.flexiSeatPrice}
                              // readOnly
                              name="flexiSeatPrice"
                              handleChange={handleMoneyChange}
                              required={contractDetails.selectedResources.some((ele) => Number(ele) === flexiList[0]?.id)}
                              endAdornmentText="seat"
                            />
                          </Grid>
  
  
  
  
                          {/* new free amenities  */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={8}
                            sx={{ display: contractDetails.propertyFreeAmentitiesList.length > 0 ? 'block' : 'none' }}
                          >
                            <Box
                              sx={{
                                border: '1px solid #ccc',
                                padding: '10px 15px',
                                display: contractDetails.propertyFreeAmentitiesList ? 'block' : 'none',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography color={palette.secondary.darker} variant="subtitle2">
                                  Free Facilities
                                </Typography>
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                  <IconButton
                                    onClick={() => {
                                      handleSelectAll('propertyFreeAmentitiesList', 'selectedFreeAmentities');
                                    }}
                                  >
                                    <SelectAllIcon />
                                  </IconButton>
                                  <Typography variant="caption">select all</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="center" alignItems="center">
                                  <IconButton
                                    onClick={() => {
                                      handleDeSelectAll('selectedFreeAmentities');
                                    }}
                                  >
                                    <DeselectIcon />
                                  </IconButton>
                                  <Typography variant="caption">Deselect all</Typography>
                                </Stack>
                              </Box>
  
                              <FormGroup>
                                <Grid container item xs={12} sm={12} md={12}  >
                                  {contractDetails.propertyFreeAmentitiesList.map((ame) => (
  
                                    <Grid item xs={12} sm={12} md={6} key={ame.id}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={contractDetails.selectedFreeAmentities?.includes(`${ame.id}`)}
                                            onChange={(e) => {
                                              handleCheckBoxGroupChange(e, 'selectedFreeAmentities');
                                            }}
                                          />
                                        }
                                        label={ame.name}
                                        value={ame.id}
                                      />
                                    </Grid>
  
                                  ))}
                                </Grid>
  
                              </FormGroup>
  
                             
                            </Box>
                          </Grid>
  
                          {/* new free  parking */}
                          <Grid container item xs={12} sm={12} md={4}
                            sx={{ display: contractDetails.propertyResourcesList.length > 0 ? 'block' : 'none' }} >
                            <Grid container item xs={12} sm={12} md={12} >
  
                              <NumberInputBRH
                                label="No of Free Car Parking"
                                value={contractDetails.numberOfCarParking}
                                name="numberOfCarParking"
                                handleChange={handleChange}
                                required
                              />
                            </Grid>
  
                            <Grid item xs={12} sm={12} md={12} style={{ marginTop: '1rem' }} >
                              <NumberInputBRH
                                label="No of Free Bike Parking"
                                value={contractDetails.numberOfBikeParking}
                                name="numberOfBikeParking"
                                handleChange={handleChange}
                                required
                              />
                            </Grid>
  
                          </Grid>
  
  
                          {/* new paid amenities  */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{ display: contractDetails.propertyPaidAmentitiesList.length > 0 ? 'block' : 'none' }}
                          >
  
                            <Box
                              sx={{
                                border: '1px solid #ccc',
                                padding: '10px 15px',
                                display: contractDetails.propertyFreeAmentitiesList ? 'block' : 'none',
                              }}
                            >
  
                              <div
                                style={{
                                  display: contractDetails?.propertyPaidAmentitiesList.length > 0 ? 'block' : 'none',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography color={palette.secondary.darker} variant="subtitle2">
                                    Paid Facilities
                                  </Typography>
                                  <Stack direction="row" justifyContent="center" alignItems="center">
                                    <IconButton
                                      onClick={() => {
                                        handleSelectAll('propertyPaidAmentitiesList', 'selectedPaidAmentities');
                                      }}
                                    >
                                      <SelectAllIcon />
                                    </IconButton>
                                    <Typography variant="caption">select all</Typography>
                                  </Stack>
                                  <Stack direction="row" justifyContent="center" alignItems="center">
                                    <IconButton
                                      onClick={() => {
                                        handleDeSelectAll('selectedPaidAmentities');
                                      }}
                                    >
                                      <DeselectIcon />
                                    </IconButton>
                                    <Typography variant="caption">Deselect all</Typography>
                                  </Stack>
                                </Box>
                                <FormGroup>
                                  <Grid container>
                                    {contractDetails.propertyPaidAmentitiesList.map((ame) => (
                                      <Grid container key={ame.id}>
                                        <Grid item xs={12} sm={12} md={3}>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={contractDetails.selectedPaidAmentities?.includes(`${ame.id}`)}
                                                onChange={(e) =>{
                                                   handleCheckBoxGroupChange(e, 'selectedPaidAmentities')
                                                  }}
                                              />
                                            }
                                            label={`${ame.name} (${ame.paid_unit})`}
                                            value={ame.id}
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={3}
                                          sx={{ display: isSpecificPaidAmenities(`${ame.id}`) ? 'block' : 'none' }} // Conditionally show/hide
                                        >
                                         
                                          <div className='myinptvalues'>
                                          <TextField
                                            type="number"
                                            value={sliderValues[ame.id] || 0}
                                            onChange={(event) =>{
                                              handleChangeInput(ame.id, ame.paid_unit, event)
                                             }}
                                            inputProps={{
                                              min: 0,
                                              max: ame.paid_unit,
                                            }}
                                            required={contractDetails.selectedPaidAmentities?.some((ele) => Number(ele) === PaidParkList[0]?.id)}
                                            label={`Number of ${ame.name} (${sliderValues[ame.id] || 0})`}
                                            variant="outlined"
                                            fullWidth
                                          />
                                          </div>
                                        </Grid>
                                        <Grid   sx={{ display: isSpecificPaidAmenities(`${ame.id}`) ? 'block' : 'none' }} // Conditionally show/hide
                                      item xs={12} sm={12} md={3}   > 
                                          <div style={{ textAlign:'center' }} >
                                              <Typography style={{ fontSize:'10px' ,textAlign:'center' }}>  Per Unit{`(${ame.fee_per_unit})`} </Typography>
                                              </div>
                                           </Grid>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={3}
                                          sx={{ display: isSpecificPaidAmenities(`${ame.id}`) ? 'block' : 'none' }} // Conditionally show/hide
                                        >
                                          {/* <small style={{ color: "#d5803a" }}>{`${ame.name}/unit`} &nbsp; {`(${ame.fee_per_unit}.00 )`}</small> */}
                                          <div className='myinptvalues'>
                                            <TextField
                                            label={`${ame.name}`}
                                              variant="outlined" 
                                              value={prices[ame.id] || (contractDetails.paidparkingprice[ame.id] || 0)} // Use the price from state or fallback
                                              name={`paidparkingprice-${ame.id}`} // Unique name based on ID
                                              onChange={ 
                                                 handleMoneyChangeTwo(ame.id)}
                                              fullWidth
                                            />
                                          </div>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </FormGroup>
                              </div>
                            </Box>
                          </Grid>
                          <Grid item lg={12} >
  
                           <p style={{ textAlign:'end' }} > Total : {contractDetails.monthlyServiceCharges}</p>  
                           </Grid>
                  
                          <Grid
                            sx={{
                              display: 'grid',
                              columnGap: 3,
                              rowGap: 2,
                              gridTemplateColumns: 'repeat(5, 1fr)',
                              border: '1px solid #e8ebee',
                              px: 2,
                              py: 2,
                              my: 2,
                              ml: 2,
                              width: '100%',
                              borderRadius: '5px',
                            }}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            {/* DG */}
                            <FormControl>
                              <FormLabel id="dg-radio-buttons-group">DG</FormLabel>
                              <RadioGroup
                                aria-labelledby="dg-radio-buttons-group"
                                name="isDgSelected"
                                value={contractDetails.isDgSelected}
                                onChange={handleChange}
                              >
                                <FormControlLabel value={1} control={<Radio />} label="Included" />
                                <FormControlLabel value={2} control={<Radio />} label="Actuals" />
                                <FormControlLabel value={0} control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl>
                            {/* water */}
                            <FormControl>
                              <FormLabel id="water-radio-buttons-group">water</FormLabel>
                              <RadioGroup
                                aria-labelledby="water-radio-buttons-group"
                                name="isWaterSelected"
                                value={contractDetails.isWaterSelected}
                                onChange={handleChange}
                              >
                                <FormControlLabel value={1} control={<Radio />} label="Included" />
                                <FormControlLabel value={2} control={<Radio />} label="Actuals" />
                                <FormControlLabel value={0} control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl>
                            {/* Internet  */}
                            <FormControl>
                              <FormLabel id="internet-radio-buttons-group">Internet </FormLabel>
                              <RadioGroup
                                aria-labelledby="internet-radio-buttons-group"
                                name="isInternetSelected"
                                value={contractDetails.isInternetSelected}
                                onChange={handleChange}
                              >
                                <FormControlLabel value={1} control={<Radio />} label="Included" />
                                <FormControlLabel value={2} control={<Radio />} label="Actuals" />
                                <FormControlLabel value={0} control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl>
                            {/* Electricty  */}
                            <FormControl>
                              <FormLabel id="electricty-radio-buttons-group">Electricty </FormLabel>
                              <RadioGroup
                                aria-labelledby="electricty-radio-buttons-group"
                                name="isElectricitySelected"
                                value={contractDetails.isElectricitySelected}
                                onChange={handleChange}
                              >
                                <FormControlLabel value={1} control={<Radio />} label="Included" />
                                <FormControlLabel value={2} control={<Radio />} label="Actuals" />
                                <FormControlLabel value={0} control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl>
                            {/* Tea/Coffee   */}
                            <FormControl>
                              <FormLabel id="teaCoffee-radio-buttons-group">Tea/Coffee </FormLabel>
                              <RadioGroup
                                aria-labelledby="teaCoffee-radio-buttons-group"
                                name="isTeaCoffeSelected"
                                value={contractDetails.isTeaCoffeSelected}
                                onChange={handleChange}
                              >
                                <FormControlLabel value={1} control={<Radio />} label="Included" />
                                <FormControlLabel value={2} control={<Radio />} label="Actuals" />
                                <FormControlLabel value={0} control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl>
                            {/* dynamic resources */}
                            
                          </Grid>
  
                          {/* inclusions exclusions  */}
                          <Box md={12} sx={{ m: 2, p: 1, width: '100%' ,  display: contractDetails.propertyResourcesList.length > 0 ? 'block' : 'none'  }}>
                             <Grid container border  
                            >
                              <Grid item sm={12} md={5}>
                                <Box
                                  sx={{
                                    border: '1px solid #ccc',
                                    padding: '10px 15px',
                                    height: '505px',
                                    overflowY: 'auto',
                                  }}
                                >
                                  <Typography color={palette.secondary.darker} variant="subtitle2">
                                    Inclusions & Exclusions
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                      <IconButton
                                        onClick={() => {
                                          handleSelectAll(
                                            'inclusionsAndExclusionsMaster',
                                            'inclusionsAndExclusionsChecked'
                                          );
                                        }}
                                      >
                                        <SelectAllIcon />
                                      </IconButton>
                                      <Typography variant="caption">select all</Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                      <IconButton
                                        onClick={() => {
                                          handleDeSelectAll('inclusionsAndExclusionsChecked');
                                        }}
                                      >
                                        <DeselectIcon />
                                      </IconButton>
                                      <Typography variant="caption">Deselect all</Typography>
                                    </Stack>
                                  </Box>
                                  <FormGroup>
                                    <Grid container>
                                      {contractDetails.inclusionsAndExclusionsMaster.map((res) => {
                                        return (
                                          <Grid item xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  size="small"
                                                  checked={contractDetails.inclusionsAndExclusionsChecked?.includes(
                                                    `${res.id}`
                                                  )}
                                                  onChange={(e) =>
                                                    handleCheckBoxGroupChange(e, 'inclusionsAndExclusionsChecked')
                                                  }
                                                />
                                              }
                                              label={res.description}
                                              value={res.id}
                                            />
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </FormGroup>
                                </Box>
                              </Grid>
                              <Grid item sm={12} md={1} justifyContent="center">
                                <Box
                                  sx={{
                                    height: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Box>
                                    <Box sx={{ textAlign: 'center' }}>
                                      <Tooltip title="Add Inclusions" placement="top">
                                        <IconButton
                                          onClick={() => {
                                            handleIncChange(
                                              'true',
                                              'add',
                                              'inclusionsAndExclusionsMaster',
                                              'inclusionsAndExclusionsChecked'
                                            );
                                          }}
                                        >
                                          <img src={arrowForward} alt="arrowsForward" style={{ width: '24px' }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                    <Box sx={{ textAlign: 'center' }}>
                                      <Tooltip title="Remove Inclusions" placement="bottom">
                                        <IconButton
                                          onClick={() => {
                                            handleIncChange(
                                              'true',
                                              'delete',
                                              'selectedInclusionList',
                                              'selectedInclusionListChecked'
                                            );
                                          }}
                                        >
                                          <img src={arrowBack} alt="arrowBack" style={{ width: '24px' }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    height: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Box>
                                    <Box sx={{ textAlign: 'center' }}>
                                      <Tooltip title="Add Exclusions" placement="top">
                                        <IconButton
                                          onClick={() => {
                                            handleIncChange(
                                              'false',
                                              'add',
                                              'inclusionsAndExclusionsMaster',
                                              'inclusionsAndExclusionsChecked'
                                            );
                                          }}
                                        >
                                          <img src={arrowForward} alt="arrowsForward" style={{ width: '24px' }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                    <Box sx={{ textAlign: 'center' }}>
                                      <Tooltip title="Remove Exclusions" placement="bottom">
                                        <IconButton
                                          onClick={() => {
                                            handleIncChange(
                                              'false',
                                              'delete',
                                              'selectedExclusionsList',
                                              'selectedExclusionsListChecked'
                                            );
                                          }}
                                        >
                                          <img src={arrowBack} alt="arrowBack" style={{ width: '24px' }} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item sm={12} md={5}>
                                <Stack direction="column">
                                  <Box
                                    sx={{
                                      border: '1px solid #ccc',
                                      padding: '10px 15px',
                                      height: '250px',
                                      overflowY: 'auto',
                                      // paddingBottom: contractDetails.selectedInclusionList.length === 0 ? '50%' : '0px',
                                      // height: contractDetails.selectedInclusionList.length === 0 ? '50%' : 'auto',
                                      marginBottom: '8px',
                                    }}
                                  >
                                    {/* <Typography color={palette.secondary.darker} variant="subtitle2">
                                        Inclusions
                                      </Typography> */}
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography color={palette.secondary.darker} variant="subtitle2">
                                        Inclusions
                                      </Typography>
                                      <Stack direction="row" justifyContent="center" alignItems="center">
                                        <IconButton
                                          onClick={() => {
                                            handleSelectAll('selectedInclusionList', 'selectedInclusionListChecked');
                                          }}
                                        >
                                          <SelectAllIcon />
                                        </IconButton>
                                        <Typography variant="caption">select all</Typography>
                                      </Stack>
                                      <Stack direction="row" justifyContent="center" alignItems="center">
                                        <IconButton
                                          onClick={() => {
                                            handleDeSelectAll('selectedInclusionListChecked');
                                          }}
                                        >
                                          <DeselectIcon />
                                        </IconButton>
                                        <Typography variant="caption">Deselect all</Typography>
                                      </Stack>
                                    </Box>
                                    <FormGroup>
                                      {contractDetails.selectedInclusionList.map((res) => {
                                        return (
                                          <Grid item xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={contractDetails.selectedInclusionListChecked?.includes(
                                                    `${res.id}`
                                                  )}
                                                  onChange={(e) =>
                                                    handleCheckBoxGroupChange(e, 'selectedInclusionListChecked')
                                                  }
                                                />
                                              }
                                              label={res.description}
                                              value={res.id}
                                            />
                                          </Grid>
                                        );
                                      })}
                                    </FormGroup>
                                  </Box>
                                  <Box
                                    sx={{
                                      border: '1px solid #ccc',
                                      padding: '10px 15px',
                                      height: '250px',
                                      overflowY: 'auto',
                                      // paddingBottom: contractDetails.selectedInclusionList.length === 0 ? '55%' : '0px',
                                      // height: contractDetails.selectedExclusionsList.length === 0 ? '50%' : 'auto',
                                    }}
                                  >
                                    {/* <Typography color={palette.secondary.darker} variant="subtitle2">
                                        Exclusions
                                      </Typography> */}
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Typography color={palette.secondary.darker} variant="subtitle2">
                                        Exclusions
                                      </Typography>
                                      <Stack direction="row" justifyContent="center" alignItems="center">
                                        <IconButton
                                          onClick={() => {
                                            handleSelectAll('selectedExclusionsList', 'selectedExclusionsListChecked');
                                          }}
                                        >
                                          <SelectAllIcon />
                                        </IconButton>
                                        <Typography variant="caption">select all</Typography>
                                      </Stack>
                                      <Stack direction="row" justifyContent="center" alignItems="center">
                                        <IconButton
                                          onClick={() => {
                                            handleDeSelectAll('selectedExclusionsListChecked');
                                          }}
                                        >
                                          <DeselectIcon />
                                        </IconButton>
                                        <Typography variant="caption">Deselect all</Typography>
                                      </Stack>
                                    </Box>
                                    <FormGroup>
                                      {contractDetails.selectedExclusionsList.map((res) => {
                                        return (
                                          <Grid item xs={12}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={contractDetails.selectedExclusionsListChecked?.includes(
                                                    `${res.id}`
                                                  )}
                                                  onChange={(e) =>
                                                    handleCheckBoxGroupChange(e, 'selectedExclusionsListChecked')
                                                  }
                                                />
                                              }
                                              label={res.description}
                                              value={res.id}
                                            />
                                          </Grid>
                                        );
                                      })}
                                    </FormGroup>
                                  </Box>
                                </Stack>
                              </Grid>
                            </Grid>
                          
                          </Box>
  
                           
  
                          {/* Refundable Security Amount */}
                          <Grid item xs={12} sm={12} md={6}>
                            <MoneyInputBRH
                              label="Refundable Security Amount"
                              value={contractDetails.refundableSecurityAmount}
                              name="refundableSecurityAmount"
                              handleChange={handleMoneyChange}
                              required
                              readOnly={isRenewal}
                            />
                          </Grid>
                          {/* Monthly Service Charges */}
                          <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={8}>
                                <MoneyInputBRH
                                  label="Monthly Rent"
                                  value={contractDetails.monthlyServiceCharges}
                                  name="monthlyServiceCharges"
                                  handleChange={handleMoneyChange}
                                  required
                                />
                                {/* <p style={{ display: isRenewal ? 'block' : 'none' }}>
                                  {Number(contractDetails.monthlyServiceCharges) +
                                    Number(contractDetails.monthlyServiceCharges) * (renewalYOY.current / 100)}
                                </p> */}
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <NumberInputBRH
                                  label="Tax"
                                  value={contractDetails.monthlyServiceChargesTax}
                                  name="monthlyServiceChargesTax"
                                  handleChange={handleChange}
                                  required
                                  InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
  
  
  
                          <Grid item xs={12} sm={12} md={12} sx={{ display: isRenewal ? 'block' : 'none' }}>
                            <MoneyInputBRH
                              label="Monthly Rent(with YOY)"
                              value={
                                Number(contractDetails.monthlyServiceCharges) +
                                Number(contractDetails.monthlyServiceCharges) * (renewalYOY.current / 100)
                              }
                              name="monthlyServiceCharges"
                              readOnly={isRenewal}
                            // handleChange={handleMoneyChange}
                            />
                          </Grid>
                          {/* Interest Rate if Failed to Pay MG */}
                          <Grid item xs={12} sm={12} md={6}>
                            <NumberInputBRH
                              label="Interest Rate if Failed to Pay MG "
                              value={contractDetails.intrestRate}
                              handleChange={handleChange}
                              name="intrestRate"
                              InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                              }}
                              required
                            />
                          </Grid>
                          {/* Any Other Services */}
                          <Grid item xs={12} sm={12} md={6}>
                            <TextInputBRH
                              label="Any Other Services"
                              value={contractDetails.anyServices}
                              name="anyServices"
                              handleChange={handleChange}
                            // required
                            />
                          </Grid>
                          {/* <Grid xs={12} > 
                          <p style={{ color:"#ffa531" ,marginBottom:'0rem' }}>Monthly Rent : <b> {contractDetails.monthlyServiceCharges} </b> </p> 
                          <br/>
                            </Grid> */}
  
                          {/* MG Representatives */}
                          <Grid item sx={12} md={6}>
                            <h4 style={{ color: "#ffa531" }} >Signatory</h4>
                            <TextField
                              fullWidth
                              select
                              label="MG Representatives"
                              name="company_address"
                              value={contractDetails.company_address}
                              onChange={handleChange}
                              required
                            >
                              {contractDetails.contactPersonsList?.map((ele) => {
                                let stateName;
  
                                return (
                                  <MenuItem key={ele.id} value={ele.id}>
                                    {contractDetails.stateList.forEach((st) => {
                                      if (Number(ele?.state_id) === st?.id) {
                                        stateName = st.name;
                                      }
                                    })}
                                    {`${stateName}-${ele?.contact_person_name}`}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                            
                          </Grid>
  
  
  
                          {/* Company Signatories */}
                          <Grid item sx={12} md={6}>
                            <h4 style={{ color: "#ffa531" }} > &nbsp;  </h4>
  
                            <TextInputBRH
                              label="Representative Signatory"
                              key="Representative Signatory"
                              value={contractDetails.representativeSignatoryId}
                              name="representativeSignatoryId"
                              handleChange={handleChange}
                              required
                            />
                          </Grid>
                          {/* cpname signatory */}
                          <Grid item sx={12} md={6}>
                            <TextInputBRH
                              label="Contact Person Name"
                              key="Contact Person Name"
                              value={contractDetails.clientCPName}
                              name="clientCPName"
                              handleChange={handleChange}
                              required
                              readOnly
                            />
                          </Grid>
                          {/* client signatory */}
                          <Grid item sx={12} md={6}>
                            <TextInputBRH
                              label="Client Signatory"
                              key="Client Signatory"
                              value={contractDetails.clientSignatory}
                              name="clientSignatory"
                              handleChange={handleChange}
                              required
                              readOnly
                            />
                          </Grid>
                          <Grid item sm={12}>
                            <ErrorMessageBRH
                              display={contractDetails.isOfficeDetailsSelected === false}
                              message="Please select OfficeSpace Details"
                            />
                          </Grid>
  
                          {/* Direct Contract */}
                          <Grid
                            item
                            sm={12}
                            md={12}
                            sx={{ display: isRenewal || contractDetails.kycStatus !== KYC_ACCEPTED ? 'none' : 'block' }}
                          >
                            <TextDivider>Verify Contract</TextDivider>
                          </Grid>
                          <Grid
                            item
                            sm={12}
                            md={12}
                            sx={{ display: isRenewal || contractDetails.kycStatus !== KYC_ACCEPTED ? 'none' : 'block' }}
                          >
                            <CheckBoxInputBRH
                              label="To Verify Click here"
                              value={contractDetails.isDirectContract}
                              handleChange={handleCheckboxChange}
                              name="isDirectContract"
                            />
                          </Grid>
  
                          {/* KYC */}
                          <Grid item sm={12} md={12} sx={{ display: isRenewal ? 'none' : 'block' }}>
                            <TextDivider>KYC</TextDivider>
                          </Grid>
                          <Grid item sm={12} md={12} sx={{ display: isRenewal ? 'none' : 'block' }}>
                            {(!contractId && contractDetails.kycStatus === 2) || contractDetails.kycStatus === 0 ? (
                              <>
                                <CheckBoxInputBRH
                                  label="Generate KYC"
                                  name="isKycChecked"
                                  value={contractDetails.isKycChecked}
                                  handleChange={handleKYCChange}
                                />
                                <Typography variant="caption">( required for MOU Generation )</Typography>
                              </>
                            ) : (
                              <>
                                <KycBox
                                  // key={kycKey}
                                  contractId={contractId}
                                  onUpdate={setIskycUpdated}
                                  kycStatus={contractDetails.kycStatus}
                                  KycReason={contractDetails.kycReason}
                                  kycFile={contractDetails.kycFile}
                                  userData={userData.current}
                                  kycRemarks={contractDetails.kycRemarks}
                                >
                                  'Upload KYC document'
                                </KycBox>
                              </>
                            )}
                          </Grid>

  

                          {  contractDetails.isDirectContract  &&   ReneweReive &&
                            <Grid item sm={12} md={8} sx={{ display: isRenewal ? 'none' : 'block' }}>
                        <LoadingButtonBRH 
                          ref={loadingButtonRef}
                             handleSubmit={(e) => handleSubmitMOU(e, true)}
                              type="submit"
                              style={{
                                height: '3.5rem',
                                width: 300,
                                mx: 1,
                              }}
                              variant="contained"
                              disabled={contractDetails.kycStatus === 3}
                              isLoading={isButtonLoading}
                              
                              label="Renew Contract"
                            />
  
                          </Grid>  }
                          {/* FOR RENEWAL */}
                          <Grid item sm={12} md={8} sx={{ display: isRenewal ? 'none' : 'none' ,visibility:'hidden'}}>
                            <LoadingButtonBRH
                              label="Generate Contract gg"
                              isLoading={contractDetails.isButtonLoading}
                              disabled={!contractDetails.agreeEndDate}
                              // handleSubmit={() => console.log('laksh-renewal', contractDetails.kycFile)}
                              type="submit"
                            />
                          </Grid>

                          {/* FOR CRS DRAFT */}
                          <Grid item sm={12} md={4} justifyContent="end" >
                            <Box sx={{ display: contractDetails.kycStatus === 0 ? 'none' : 'none' }}>
                              <Button
                                color="secondary"
                                onClick={() => {
                                  setCancelOpen(true);
                                }}
                                variant="outlined"
                                sx={{ marginRight: '24px' }}
                              >
                                Cancel
                              </Button>
  
                              <Button style={{ visibility:'hidden'}}
                                onClick={(e) => {
                                  handleSubmit(e);
                                }}
                                variant="contained"
                              >
                                Save As Draft
                              </Button>
                            </Box>
                          </Grid>
  
                        </Grid>
                      </FormControl>
                    </form>
                  </Box>
                </Scrollbar>
              </Card>
            </Container>
            {/* PREVIEW CRS */}
            <Dialog style={{ visibility:'hidden',display:'none' }} open={!contractDetails.isRadioDataLoaded}>
              <Box
                sx={{
                  width: '150px',
                  height: '100px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  rowGap: '16px',
                }}
              >
                {' '}
                <Typography variant="overline">Loading</Typography>
                <SyncLoader color="#fc9200" />
              </Box>
            </Dialog>
            
            <div
              className="crs_back"
              style={{ display: contractDetails.isOpenDialog ? 'none' : 'none', paddingBottom: '24px' ,visibility:'hidden'}}
            >
              <Box>
                <iframe src="" title="preview-contract" width="100%" height="500px" />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '16px' }}>
                  <Button
                    variant="contained"
                    color="info"
                    sx={{ marginRight: '24px' }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                 
                  <LoadingButtonBRH
                    variant="contained"
                    // onClick={(e) => {
                    //   handleSubmitMOU(e, true);
                    // }}
                    handleSubmit={(e) => handleSubmitMOU(e, true)}
                    isLoading={isButtonLoading}
                    label="Generate"
                  />
                </Box>
              </Box>
              <CommonAlert
                open={kycOpen}
                setKycOpen={setKycOpen}
                message="Do you want to request a KYC ?"
                proceedFunction={handleKYCProceed}
                goBackFunction={handleKYCBack}
              />
            </div>
          </>
        ) : (
          <>
            <FormSkeleton lines={5} />
          </>
        )}

<Modal size="lg"  show={show}  style={{ marginTop:'3rem' }} >
        <Modal.Header >
          <Modal.Title>Contract Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height:'55vh' , overflowY:'auto' }}> 
          <Row>
            <Col lg={5} md={5} sm={5}>Client Name</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p>{contractDetails?.company_name ? contractDetails?.company_name?.company_name : " - "}  </p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Agreement Execution Date</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p>{contractDetails?.dateOfAgreement.toDateString()} </p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Client Address</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>{contractDetails?.clientAddress} </div></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Agreement Start Date</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>{newSRDate} </div></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Agreement End Date</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>{newSREndDate} </div></Col>
          </Row>

          <Row>
            <Col lg={5} md={5} sm={5}>No. of Months</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>{contractDetails?.numberOfMonths} Months </div></Col>
        </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Lock in Period</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>{contractDetails?.lockedInPeriod} Months </div></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Notice Period</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>{contractDetails?.noticePeriod} Months </div></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Rent Starting Date</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>{newSRDate} </div></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>YOY Increment </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>{contractDetails?.yearOnYearIncrement} % </div></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Property Type </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>
                {contractDetails?.propertyType===9 && <div>Scouted Property </div>}  
                {contractDetails?.propertyType===4 && <div>BRH Property </div>}  
                {contractDetails?.propertyType===1 && <div>Co-Working Property </div>}  
          </div>
          </Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Property Name </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p>{contractDetails?.property_name ?contractDetails?.property_name?.property_name : "-"}  </p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Working Hours </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}>
            <p>{starttime} - {endttime}
               </p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Working Days </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><span>{contractDetails?.workingDayMon ===1 && <div>Monday-Working </div>} 
            {contractDetails?.workingDayTue ===1 && <div>Tuesday-Working  </div>} 
            {contractDetails?.workingDayWed ===1 && <div>Wednesday-Working   </div>} 
            {contractDetails?.workingDayThu ===1 && <div>Thursday-Working  </div>} 
            {contractDetails?.workingDayFri ===1 && <div>Friday-Working   </div>} 
            {contractDetails?.workingDaySat ===1 && <div>Saturday-Working   </div>} 
            {contractDetails?.workingDaySun ===1 && <div>Sunday-Working   </div>}  
            </span></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Office Details </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}>
            <span> {matchingNames.map((cd,i)=>(
              <div key={i}> {i+1} . {cd}  </div>
            ))} </span>
            </Col>
          </Row>
          {contractDetails?.flexiSeatsSelected !==0 && 
              <Row>
            <Col lg={5} md={5} sm={5}>Flexi Desk </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p> 
            {contractDetails?.flexiSeatPrice} INR/Quantity  - {contractDetails.flexiSeatsSelected} Quantity 
               </p>
              </Col>
          </Row> }
          {contractDetails?.dedicatedSeatsSelected !==0 && 
              <Row>
            <Col lg={5} md={5} sm={5}>Dedicated Desk </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p> 
            {contractDetails?.dedicatedSeatPrice} INR/Quantity  - {contractDetails.dedicatedSeatsSelected} Quantity 
               </p>
              </Col>
          </Row> }
        
          <Row>
            <Col lg={5} md={5} sm={5}>Free Facilities </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p> 
              {/* {contractDetails?.selectedFreeAmentities} */}
              {matchingFreeAm && matchingFreeAm.map((cs,i)=>(
                <div key={i}> {i+1}.{cs} <br/> </div>
              ))}
               </p>
              </Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Free Parking </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}> 
            <div>
            <>Car Parking- {contractDetails.numberOfCarParking?contractDetails.numberOfCarParking:"0"} Quantity <br/>
            Bike Parking- {contractDetails.numberOfBikeParking?contractDetails.numberOfBikeParking:"0"} Quantity</>
            </div>
            </Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Paid Facilities </Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}>
           
              {/* {contractDetails?.selectedPaidAmentities}  */}
              {MatchingPaid && MatchingPaid.map((css,i)=>(
                <div key={i}> {i+1}. {css.name} - {css.inputValue} </div>
              ))}
              {!MatchingPaid && <div>-</div>}
               </Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}> Extra Facilities</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><div>
            {contractDetails?.isElectricitySelected===1 && <div>Electricity   </div>} 
            {contractDetails?.isWaterSelected ===1 && <div>Water </div>} 
            {contractDetails?.isTeaCoffeSelected ===1 && <div>Tea-Coffee   </div>} 
            {contractDetails?.isInternetSelected ===1 && <div>Internet   </div>} 
            {contractDetails?.isDgSelected ===1 && <div>DG   </div>}  
           
                 </div></Col>
          </Row>

          <Row>
            <Col lg={5} md={5} sm={5}> Inclusions</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}>
            <div>
               {contractDetails?.selectedInclusionList?.map((cds,i)=>(
                <p key={i}> {i+1}. {cds.description} <br/></p>
               ))} 
               </div>
            </Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}> Exclusions</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}>
            <p>
               {contractDetails?.selectedExclusionsList?.map((cds,i)=>(
                <p key={i}> {i+1}. {cds.description} <br/></p>
               ))} 
               </p>
            </Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}> Refundable Security Amount</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p> {contractDetails?.refundableSecurityAmount} INR</p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}> Other Services</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p> {contractDetails?.anyServices} </p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}> Signatory</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p>{contractDetails?.representativeSignatoryId}</p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}> Contact Person Name</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p> {contractDetails?.clientCPName} </p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}> Client Signatory</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p> {contractDetails?.clientSignatory} </p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}>Monthly Rent</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p> {contractDetails?.monthlyServiceCharges} INR   + {contractDetails?.monthlyServiceChargesTax}% Tax </p></Col>
          </Row>
          <Row>
            <Col lg={5} md={5} sm={5}> Interest Rate</Col>
            <Col lg={1} md={1} sm={1}>:</Col>
            <Col lg={6} md={6} sm={6}><p> {contractDetails?.intrestRate} % </p></Col>
          </Row>
       
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleMMClose}>
            Close
          </Button> */}
          <Button variant="primary" onClick={handleActualReview}>
            Proceed to generate
          </Button>
        </Modal.Footer>
      </Modal>
      </>
    );
  }
  