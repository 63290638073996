import React from 'react';
import { Button, InputAdornment, TextField } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import styled from '@emotion/styled';
import palette from '../../theme/palette';

export const FileInputBRH = ({ handleFileUpload, buttonText, color = 'inherit' }) => {
  const VisuallyHiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;
  return (
    <Button component="label" variant="contained" startIcon={<UploadFileIcon />} color={color} fullWidth>
      {buttonText}
      <VisuallyHiddenInput type="file" onChange={(e) => handleFileUpload(e)} />
    </Button>
  );
};
