import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { postMethod } from '../../../API_SERVICE_LAYER/ApiConfigs';

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();

  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [error, setError] = useState(null);
  const [passError, setPassError] = useState(null);

  const handleClick = () => {
    const { token } = queryString.parse(location.search);
    const tokenArrary = token.split('/');
    if (newPassword === confirmPassword) {
      console.log('Correct');
      postMethod('user-management/api/v1/reset-password', {
        token: tokenArrary[0],
        password: confirmPassword,
        pwd_token: tokenArrary[2],
      })
        .then((res) => {
          if (res?.data?.status) {
            navigate('/login', { replace: true });
          }
        })
        .catch((err) => {
          setError(err?.response?.data?.message);
        });
    } else {
      setPassError('Confirm Password does not match with Password');
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="password"
          label="New Password"
          onFocus={() => setPassError(null)}
          onChange={(e) => setNewPassword(e.currentTarget.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          onFocus={() => setPassError(null)}
          name="password"
          label="Confirm Password"
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack sx={{ my: 1 }}>
        {passError && <Alert severity="warning">{passError}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
      </Stack>

      <LoadingButton sx={{ my: 3 }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Submit
      </LoadingButton>
    </>
  );
}
