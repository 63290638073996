import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import { GmailInputBRH } from '../../../components/forms/GmailInputBRH';
import { TextFieldSelectBRH } from '../../../components/forms/TextFieldSelectBRH';
// import TextFieldSelectBRH from '../../../components/forms/TextFieldSelectBRH';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import { SelectInputBRH } from '../../../components/forms/SelectInputBRH';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';
import { TimeInputBRH } from '../../../components/forms/TimeInputBRH';
import { CheckBoxInputBRH } from '../../../components/forms/CheckBoxInputBRH';
import { MobileInputBRH } from '../../../components/forms/MobileInputBRH';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { PROVIDERLIST, COUNTRYLIST, STATELIST, CITYLIST } from '../../../API_SERVICE_LAYER/ApiNames';
import { ToastBRH } from '../../../components/toasts/ToastBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import {
  setBascisDetailsRedux,
  setFormImcompleteRedux,
  setSelectedImagesRedux,
} from '../../../Redux/actions/BasicDetailsActions';

const EditBasicDetails = ({ onSubmit }) => {
  const basicDetailsdata = useSelector((state) => state.BasicDetails.basicDetails);
  const selectedImagesRedux = useSelector((state) => state.BasicDetails.selectedImages);
  const [selectedImages, setSelectedImages] = useState(selectedImagesRedux);
  const [sortOrder, setSortOrder] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [formData, setformData] = useState({});
  const [Pstatus, setPStatus] = useState();
  const [Istatus, setIStatus] = useState();
  // const [accordian1FormImcomplete, setFormIncomplete] = useState(true);
  const [basicDetails, setBascisDetails] = useState({
    address1: basicDetailsdata.address1,
    isSelectDataloadedCountry: basicDetailsdata.isSelectDataloadedCountry,
    address2: basicDetailsdata.address2,
    property_type_name: basicDetailsdata.property_type_name,
    name: basicDetailsdata.name,
    sortOptions: basicDetailsdata.sortOptions,
    imageFile: basicDetailsdata.imageFile,
    contact_alternate_email: basicDetailsdata.contact_alternate_email,
    email: basicDetailsdata.email,
    contact_person: basicDetailsdata.contact_person,
    countryList: basicDetailsdata.countryList,
    countryObject: basicDetailsdata.countryObject,
    country_id: basicDetailsdata.country_id,
    stateList: basicDetailsdata.stateList,
    stateObject: basicDetailsdata.stateObject,
    state_id: basicDetailsdata.state_id,
    cityList: basicDetailsdata.cityList,
    pseudo_name: basicDetailsdata.pseudo_name,
    cityObject: basicDetailsdata.cityObject,
    city_id: basicDetailsdata.city_id,
    workingDayMon: basicDetailsdata.workingDayMon,
    workingDayTue: basicDetailsdata.workingDayTue,
    workingDayWed: basicDetailsdata.workingDayWed,
    workingDayThu: basicDetailsdata.workingDayThu,
    workingDayFri: basicDetailsdata.workingDayFri,
    workingDaySat: basicDetailsdata.workingDaySat,
    workingDaySun: basicDetailsdata.workingDaySun,
    workingHoursStart: basicDetailsdata.workingHoursStart,
    workingHoursEnd: basicDetailsdata.workingHoursEnd,
    floor_no: basicDetailsdata.floor_no,

    property_status: basicDetailsdata.property_status,
    is_draft: basicDetailsdata.is_draft,
    landmark: basicDetailsdata.landmark,
    landline: basicDetailsdata.landline,
    latitude: basicDetailsdata.latitude,
    locality: basicDetailsdata.locality,
    long_desc: basicDetailsdata.long_desc,
    longitude: basicDetailsdata.longitude,
    contact_number: basicDetailsdata.contact_number,
    property_code: basicDetailsdata.property_code,
    property_name: basicDetailsdata.property_name,
    property_tabs: basicDetailsdata.property_tabs,
    property_type_id: basicDetailsdata.property_type_id,
    provider_id: basicDetailsdata.provider_id,
    pseudoname: basicDetailsdata.pseudoName,
    short_desc: basicDetailsdata.short_desc,
    show_actual_name: basicDetailsdata.show_actual_name,
    sort_id: basicDetailsdata.sort_id,
    sortOrderObject: basicDetailsdata.sortOrderObject,
    sort_order: basicDetailsdata.sort_order,
    start_at: basicDetailsdata.start_at,
    propertyDataList: basicDetailsdata.propertyDataList,
    propertyTypeObject: basicDetailsdata.propertyTypeObject,
    status: basicDetailsdata.status,
    total_sq_ft: basicDetailsdata.total_sq_ft,
    zipcode: basicDetailsdata.zipcode,
    // pincode: null,
    providerDataList: basicDetailsdata.providerDataList,
    isFetched: basicDetailsdata.isFetched,
    isSelectDataloaded: basicDetailsdata.isSelectDataLoaded,
  });
  // useEffect(() => {
  //   API.getData(PROVIDERLIST)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  // parellel

  const accordian1FormImcomplete = useSelector((state) => state.BasicDetails.accordian1FormImcomplete);

  console.log('basicdetailsSata', basicDetailsdata);

  useEffect(() => {
    // let apiProviderData;
    // let apiCountryData;
    // step-1
    API.getData('api/provider/')
      .then((resProv) => {
        if (resProv?.data?.status === 'success') {
          console.log(resProv.data.status);
          const apiProviderData = resProv?.data?.data;
          const providerId = apiProviderData.id;
          console.log(providerId);
          console.log(apiProviderData);
          setBascisDetails((prevDetails) => ({
            ...prevDetails,
            providerDataList: apiProviderData,
            provider_id: providerId,
            isSelectDataloaded: true,
            countryObject: {
              id: 101,
              name: 'India',
            },
          }));
          // setBascisDetails({
          //   ...basicDetails,
          //   providerDataList: apiProviderData,
          // });
          console.log(apiProviderData);
          console.log(providerId);
        }
      })
      .catch((errProv) => {
        console.log(errProv);
      });
    // step-2
    API.getData(`${COUNTRYLIST}`)
      .then((resCountry) => {
        if (resCountry?.data?.status === true) {
          const apiCountryData = resCountry?.data?.data;
          API.getData(`${STATELIST}/${basicDetails.country_id}`)
            .then((resultState) => {
              if (resultState?.data?.status === true) {
                const dataState = resultState?.data?.data;
                setBascisDetails((prev) => ({
                  ...prev,
                  countryList: apiCountryData,
                  countryObject: apiCountryData.find((country) => country.id === 101),
                  stateList: dataState,
                  state_id: dataState.length > 0 ? dataState[0].id : null,
                  isFetched: true,
                }));
                // setBascisDetails({
                //   ...basicDetails,
                //   countryList: apiCountryData,
                //   countryObject: apiCountryData.find((country) => country.id === 101),
                //   stateList: dataState,
                //   state_id: dataState.length > 0 ? dataState[0].id : null,
                //   isFetched: true,
                // });
              }
            })
            .catch((errState) => {
              console.log('STATELIST', errState);
              setBascisDetails({
                ...basicDetails,
                countryList: apiCountryData,
                countryObject: apiCountryData[0],
                isFetched: true,
              });
            });
        }
      })
      .catch((errCountry) => {
        console.log('COUNTRYLIST', errCountry);
        setBascisDetails({
          ...basicDetails,
          isFetched: true,
        });
      });

    API.getDataToken('api/propertytype/')
      .then((resProperty) => {
        console.log(resProperty.data.status);
        if (resProperty?.data?.status === 'success') {
          console.log(resProperty.data.status);
          const apiPropertyData = resProperty?.data?.data?.data;
          console.log(apiPropertyData);
          setBascisDetails((prevDetails) => ({
            ...prevDetails,
            propertyDataList: apiPropertyData,
            isSelectDataloaded: true,
          }));
        }
        // console.log('laksh', resProperty.data?.data?.data);
        console.log('mannsn', resProperty?.data?.data?.data);
      })
      .catch((errProp) => {
        console.log(errProp);
        setBascisDetails((prevDetails) => ({
          ...prevDetails,
          propertyDataList: [],
          isSelectDataLoaded: true,
        }));
      });

    API.getData('api/sortorder?limit=10')
      .then((result) => {
        console.log(result);
        if (result.data.data) {
          console.log(result.data.data.data);
          const sortOrderOptions = result.data.data.data.map((item) => ({
            id: item.id,
            sort_order: item.sort_order,
          }));
          setBascisDetails((prevDetails) => ({
            ...prevDetails,
            sortOptions: sortOrderOptions,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    console.log(basicDetails);
  }, [basicDetails]);

  useEffect(() => {
    if (basicDetails.property_type_name && basicDetails.stateObject) {
      const pseudoName = `BRH ${basicDetails.property_type_name} ${basicDetails.property_code}`;
      // alert(pseudoName);
      setBascisDetails({
        ...basicDetails,
        pseudoname: pseudoName,
      });
    }
  }, [basicDetails.property_type_name, basicDetails.stateObject]);

  // sequential
  // useEffect(() => {
  //   API.getData('api/provider/')
  //     .then((result) => {
  //       console.log(result);
  //       const dataProvider = result.data.data;

  //       setBascisDetails((prevDetails) => ({
  //         ...prevDetails,

  //         providerDataList: dataProvider,
  //         isSelectDataloaded: true,
  //       }));
  //       return API.getData(COUNTRYLIST);
  //     })
  //     .then((resCountry) => {
  //       if (resCountry?.data?.status === true) {
  //         const dataCountry = resCountry?.data?.data;
  //       }
  //       return API.getData(STATELIST);
  //     })
  //     .then((resSt) => {
  //       if (resSt?.data?.status === true) {
  //         const dataState = resSt?.data?.data;
  //       }
  //       return null;
  //     })
  //     .catch((error) => {
  //       console.log('error', error);
  //     });
  // }, []);

  const handleCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setBascisDetails({ ...basicDetails, isSelectDataloadedCountry: false });
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setBascisDetails({
              ...basicDetails,
              country_id: id,
              countryObject: newValue,
              stateList: res.data.data,
              stateObject: null,
              cityList: [],
              cityObject: null,
              isSelectDataloadedCountry: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setBascisDetails({
            ...basicDetails,
            country_id: id,
            stateList: [],
            isSelectDataloadedCountry: true,
          });
        });
    }
  };
  const handleStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    const data = { state_id: id };
    console.log('data', basicDetails);
    if (name === 'name') {
      // MAKE LOADER ONLY WHEN VALUE IS THERE
      setBascisDetails({ ...basicDetails, isSelectDataloadedState: false });
      // GET DETAILS HERE BY SEQUENTIALLY EVENTHOUGH IT IS INDEPENDEDNT
      // CALL CITY API ACCORDING TO STATE
      API.getData(`${CITYLIST}/${id}`)
        .then((resCity) => {
          if (resCity.data.status) {
            console.log('API-CITYLIST');

            // GET PROPERTY CODE FROM API FOR SPECIFIC STATE
            console.log('------', resCity.data.data);
            API.postMethod('/api/propertycode', data).then((res) => {
              if (res.data.status !== 'success') {
                const laksh = `${newValue.iso2}001`;
                setBascisDetails({
                  ...basicDetails,
                  isSelectDataloadedState: true,
                  stateObject: newValue,
                  state_id: id,
                  property_code: laksh,
                  cityList: resCity.data.data,
                  cityObject: null,
                });
              } else {
                const code = res.data.data.property_code;
                let propertycodes = parseInt(code.match(/\d+/g)[0], 10) + 1;
                propertycodes =
                  propertycodes > 99
                    ? `${propertycodes}`
                    : propertycodes > 9
                    ? `0${propertycodes}`
                    : `00${propertycodes}`;

                const laksh = `${code.match(/[a-zA-Z]+/g)[0]}${propertycodes}`;
                setBascisDetails({
                  ...basicDetails,
                  isSelectDataloadedState: true,
                  stateObject: newValue,
                  state_id: id,
                  property_code: laksh,
                  cityList: resCity.data.data,
                  cityObject: null,
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log('CITYLIST', err);
          ToastBRH('error', `${err?.response?.data?.message}`, '_', false);
          setBascisDetails({ ...basicDetails, isSelectDataloaded: true, cityList: [], state_id: id });
        });
    }

    // API.postMethod('/api/propertycode', data).then((res) => {
    //   console.log(res, 'sjjsjhhausuahjsuuaduajsb');
    //   console.log(res.data.status, 'ywyuwuiiiwuwiqiwiwuiqiuwu');
    //   if (res.data.status === 'success') {
    //     console.log(res.data.status);
    //     const propertyCodeFromAPI = res.data.data.property_code;
    //     mansoor = propertyCodeFromAPI;
    //     console.log(mansoor, 'code api');
    //     // const propertycode = res.data.data.property_code.match(/\d+/g);
    //     // console.log('before', propertycode);
    //     // const location = res.data.data.property_code.match(/[a-zA-Z]+/g);

    //     // const propertycodes = parseInt(propertycode[0], 10) + 1;
    //     // const newPropertyCode = `${location[0]}${
    //     //   propertycodes > 99 ? propertycodes : propertycodes > 9 ? `0${propertycodes}` : `00${propertycodes}`
    //     // }`;
    //     // console.log('after', propertycodes);

    //     // setBascisDetails((prevDetails) => ({
    //     //   ...prevDetails,
    //     //   property_code: propertyCodeFromAPI,
    //     // }));

    //     console.log(basicDetails.property_type_name);
    //     // if (basicDetails.property_type_name) {
    //     //   console.log(basicDetails.property_type_name);
    //     //   const pseudoName = `BRH ${basicDetails.property_type_name} ${propertyCodeFromAPI}`;
    //     //   console.log(pseudoName);
    //     //   setBascisDetails((prevDetails) => ({
    //     //     ...prevDetails,
    //     //     pseudoname: pseudoName,
    //     //     property_type_name: basicDetails.property_type_name,
    //     //   }));
    //     // }
    //   } else {
    //     const stateData = basicDetails.statesData.find((state) => state.id === id);
    //     if (stateData) {
    //       const propertyCode = `${stateData.iso2}001`;
    //       if (basicDetails.property_type_name) {
    //         const pseudoName = `BRH ${basicDetails.property_type_name} ${propertyCode}`;
    //         // mansoor = pseudoName;
    //         // setBascisDetails((prevDetails) => ({
    //         //   ...prevDetails,
    //         //   property_code: propertyCode,
    //         //   pseudoname: pseudoName,
    //         // }));
    //       }
    //     }
    //   }
    // });
    // setBascisDetails((prevDetails) => ({
    //   ...prevDetails,
    //   state_id: id,
    //   stateObject: newValue,
    //   property_code: mansoor,
    // }));
    // if (name === 'name') {
    //   setBascisDetails({ ...basicDetails, isSelectDataloadedState: false });
    //   API.getData(`${CITYLIST}/${id}`)
    //     .then((res) => {
    //       if (res.data.status) {
    //         console.log('API-CITYLIST');
    //         setBascisDetails({
    //           ...basicDetails,
    //           isSelectDataloadedState: true,
    //           stateObject: newValue,
    //           state_id: id,
    //           cityList: res.data.data,
    //           cityObject: null,
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       console.log('CITYLIST', err);
    //       ToastBRH('error', `${err?.response?.data?.message}`, '_', false);
    //       setBascisDetails({ ...basicDetails, isSelectDataloaded: true, cityList: [], state_id: id });
    //     });
    // }

    // .catch((err) => {
    //   console.log('CITYLIST', err);
    //   ToastBRH('error', `${err?.response?.data?.message}`, '_', false);
    //   setBascisDetails({ ...basicDetails, isSelectDataloaded: true, cityList: [], state_id: id });
    // });
  };
  const handleCityChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setBascisDetails({ ...basicDetails, city_id: id, cityObject: newValue });
    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (
      name === 'workingDaySun' ||
      name === 'workingDayMon' ||
      name === 'workingDayTue' ||
      name === 'workingDayWed' ||
      name === 'workingDayThu' ||
      name === 'workingDayFri' ||
      name === 'workingDaySat'
    ) {
      setBascisDetails((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (type === 'checkbox') {
      if (name === 'is_default_1' && checked) {
        setBascisDetails({
          ...basicDetails,
          is_default_1: 1,
          is_default_2: 0,
        });
      } else if (name === 'is_default_2' && checked) {
        setBascisDetails({
          ...basicDetails,
          is_default_1: 0,
          is_default_2: 1,
        });
      } else {
        setBascisDetails({
          ...basicDetails,
          is_default_1: 0,
          is_default_2: 0,
        });
      }
    } else {
      setBascisDetails({ ...basicDetails, [name]: value });
    }
  };
  const handleImageSelection = (event) => {
    const files = Array.from(event.target.files);
    setImageError(false);
    setSelectedImages((prevSelectedImages) => [...prevSelectedImages, ...files]);
  };
  const handleImageDelete = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  const handlePropertyTypeChange = (event, newValue) => {
    console.log(newValue);
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    console.log(id);
    setBascisDetails((prevDetails) => ({
      ...prevDetails,
      [name]: newValue.property_type_name,
      propertyTypeObject: newValue,
      property_type_id: id,
    }));
  };
  const handleSortOrderChange = (event, newValue) => {
    if (!newValue) return;
    console.log(newValue);
    setBascisDetails((prevDetails) => ({
      ...prevDetails,
      sort_id: newValue.id, // for backend
      sortOrderObject: newValue, // for frontend
    }));
  };
  const handlePropertyStatusChange = (event) => {
    const selectedValues = event.target.value; // An array of selected IDs
    console.log('selec', selectedValues, event);
    setBascisDetails((prevDetails) => ({
      ...prevDetails,
      property_status: selectedValues,
    }));
  };
  const handleAutoCompleteChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    if (name === 'name') {
      const selectedProvider = basicDetails.providerDataList.find((val) => val.id === id);
      console.log(selectedProvider);

      if (selectedProvider) {
        setBascisDetails({
          ...basicDetails,
          isSelectDataloaded: false,
          provider_id: selectedProvider.id,
        });
        const selectedCountry = basicDetails.countryList.find((country) => country.id === selectedProvider.country_id);

        console.log('Updating country:', selectedCountry);
        setBascisDetails((prevDetails) => ({
          ...prevDetails,
          [name]: newValue,
          countryObject: selectedCountry,
          state_id: selectedProvider.state_id,
          city_id: selectedProvider.city_id,
          address1: selectedProvider.address1,
          address2: selectedProvider.address2,
          zipcode: selectedProvider.zipcode,
          contact_person: selectedProvider.contact_person,
          email: selectedProvider.email,
          contact_number: selectedProvider.contact_number,
          // isSelectDataloaded: true,
        }));

        API.getData(`${STATELIST}/${selectedProvider.country_id}`)
          .then((resultState) => {
            if (resultState?.data?.status === true) {
              const dataState = resultState?.data?.data;
              const selectedState = dataState.find((state) => state.id === selectedProvider.state_id);
              setBascisDetails((prevDetails) => ({
                ...prevDetails,
                stateList: dataState,
                stateObject: selectedState,
                state_id: selectedProvider.state_id,
                isSelectDataloaded: true,
              }));
            }
          })
          .catch((errState) => {
            console.log('STATELIST', errState);
            setBascisDetails((prevDetails) => ({
              ...prevDetails,
              stateList: [],
              isSelectDataloaded: true,
            }));
          });
        API.getData(`${CITYLIST}/${selectedProvider.state_id}`)
          .then((resultCity) => {
            if (resultCity?.data?.status === true) {
              const dataCity = resultCity?.data?.data;
              const selectedCity = dataCity.find((city) => city.id === selectedProvider.city_id);
              setBascisDetails((prevDetails) => ({
                ...prevDetails,
                cityList: dataCity,
                cityObject: selectedCity,
                city_id: selectedProvider.city_id,
                isSelectDataloaded: true,
              }));
            }
          })
          .catch((errCity) => {
            console.log('CITYLIST', errCity);
            setBascisDetails((prevDetails) => ({
              ...prevDetails,
              cityList: [],
              isSelectDataloaded: true,
            }));
          });

        // const data = { state_id: id };
        // API.postMethod('/api/propertycode', data)

        //   .then((result) => {
        //     console.log(result);
        //     if (result.status === 'success') {
        //       const propertycode = result.data.property_code.match(/\d+/g);
        //       const location = result.data.property_code.match(/[a-zA-Z]+/g);
        //       const propertycodes = parseInt(propertycode[0], 10) + 1;
        //       const newPropertyCode = `${location[0]}${
        //         propertycodes > 99 ? propertycodes : propertycodes > 9 ? `0${propertycodes}` : `00${propertycodes}`
        //       }`;

        //       setBascisDetails((prevDetails) => ({
        //         ...prevDetails,
        //         property_code: newPropertyCode,
        //         property_type_name: selectedProvider.property_type_name,
        //         pseudo_name: `BRH ${selectedProvider.property_type_name} ${newPropertyCode}`,
        //         isSelectDataloaded: true,
        //       }));
        //       // console.log(pseudo_name);
        //     } else {
        //       // Handle error case
        //     }
        //   })
        //   .catch((error) => {
        //     // Handle error case
        //   });
      }
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setFormImcompleteRedux(false);

    setBascisDetailsRedux(basicDetails);
  };

  const [expanded, setExpanded] = React.useState('');

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleBasicDetails = async () => {
    const data = {
      provider_id: basicDetails.provider_id,

      property_name: basicDetails.property_name,

      property_type_id: basicDetails.property_type_id,
      contact_person: basicDetails.contact_person,

      contact_alternate_email: basicDetails.contact_alternate_email,
      mobile_no: basicDetails.mobile_no,

      email: basicDetails.email,
      landline: basicDetails.landline,
      address1: basicDetails.address1,
      address2: basicDetails.address2,
      latitude: basicDetails.latitude,
      longitude: basicDetails.longitude,
      country_id: basicDetails.country_id,
      state_id: basicDetails.state_id,
      city_id: basicDetails.city_id,
      landmark: basicDetails.landmark,
      locality: basicDetails.locality,
      zipcode: basicDetails.zipcode,
      property_code: basicDetails.property_code,

      total_sq_ft: basicDetails.total_sq_ft,
      floor_no: basicDetails.floor_no,
      start_at: basicDetails.workingHoursStart,
      end_at: basicDetails.workingHoursEnd,
      sort_id: basicDetails.sort_id,
      short_desc: basicDetails.short_desc,
      long_desc: basicDetails.long_desc,
      is_draft: 1,
      // is_primary: basicDetails.is_primary,
      // is_alternative_primary: basicDetails.is_alternative_primary,
      property_tabs: basicDetails.property_tabs,
      furnished_status: basicDetails.property_status,
      // show_actual_name: basicDetails.show_actual_name,
      pseudoname: basicDetails.pseudoname,
      status: 0,
      days: [
        {
          day: 'Monday',
          day_id: 'mon',
          open: basicDetails.workingDayMon ? 1 : 0,
          status: basicDetails.workingDayMon ? 1 : 0,
        },
        {
          day: 'Tuesday',
          day_id: 'tue',
          open: basicDetails.workingDayTue ? 1 : 0,
          status: basicDetails.workingDayTue ? 1 : 0,
        },
        {
          day: 'Wednesday',
          day_id: 'wed',
          open: basicDetails.workingDayWed ? 1 : 0,
          status: basicDetails.workingDayWed ? 1 : 0,
        },
        {
          day: 'Thursday',
          day_id: 'thu',
          open: basicDetails.workingDayThu ? 1 : 0,
          status: basicDetails.workingDayThu ? 1 : 0,
        },
        {
          day: 'Friday',
          day_id: 'fri',
          open: basicDetails.workingDayFri ? 1 : 0,
          status: basicDetails.workingDayFri ? 1 : 0,
        },
        {
          day: 'Saturday',
          day_id: 'sat',
          open: basicDetails.workingDaySat ? 1 : 0,
          status: basicDetails.workingDaySat ? 1 : 0,
        },
        {
          day: 'Sunday',
          day_id: 'sun',
          open: basicDetails.workingDaySun ? 1 : 0,
          status: basicDetails.workingDaySun ? 1 : 0,
        },
      ],
    };
    setSelectedImagesRedux(selectedImages);
    setFormImcompleteRedux(false);

    await API.postMethod('api/property/', data)

      .then((result) => {
        console.log(result);

        if (result.data.status === 'success') {
          setPStatus(result.data.status);
          localStorage.setItem('message', result.data.message);
          // this.handleImageUpload();
        } else if (result.data.status === 'error') {
          ToastBRH('error', 'mesage', '_', false);
        }
      })
      .catch((e) => {
        console.log('testing data', e);
      });

    const formData = new FormData();

    selectedImages?.map((i) => formData.append('image_path[]', i));
    selectedImages?.map((i, index) => formData.append('image_order', `new_${index}`));
    formData.append(
      'property_id',
      basicDetails.property_type_id === '' || basicDetails.property_type_id === null
        ? basicDetailsdata.property_type_id
        : basicDetails.property_type_id
    );
    //  formData.append('is_default', this.state.is_default);

    console.log(formData);

    await API.fileupload('api/propertyimages', formData).then((result) => {
      // console.log(result);
      if (result.status === 200) {
        if (result.data.status === 'success') {
          localStorage.setItem('message', result.data.message);
          setIStatus(result.data.status);

          // localStorage.setItem('property_tabs', 'amenities');
        }

        // window.location.reload();
      }
    });
    // console.log(this.state.imageFile);
    setBascisDetailsRedux(basicDetails);
    // console.log(this.state.imageFile);
  };
  useEffect(() => {
    if (Pstatus === 'success' && Istatus === 'success') {
      onSubmit();
    }
  }, [Pstatus, Istatus]);

  return (
    <>
      <Accordion
        sx={{ background: 'light', padding: '20px' }}
        expanded={expanded === 'panel1a-content'}
        onChange={handleChangeAccordian('panel1a-content')}
      >
        <AccordionSummary
          sx={{ backgroundColor: '#f9fafb' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ background: '#f9fafb', width: '100%' }}> Property Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
              <Typography variant="h4" gutterBottom>
                Add Basic Details
              </Typography>
            </Stack> */}
            <Card sx={{ p: 2 }}>
              <Box sx={{ flexGrow: 1, m: 4 }}>
                <form noValidate={false} onSubmit={(e) => handleFormSubmit(e)}>
                  <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                    <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="Provider Name"
                          name="name"
                          required="true"
                          defaultValue={basicDetailsdata.name}
                          value={
                            basicDetails.name === '' || basicDetails.name === null
                              ? basicDetailsdata.name
                              : basicDetails.name
                          }
                          handleChange={handleAutoCompleteChange}
                          options={basicDetails.providerDataList}
                          isSelectDataloaded={basicDetails.isSelectDataloaded}

                          // handleChange={}

                          // onChange={handleEventChange}
                          // options={}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Property Name"
                          name="property_name"
                          required="true"
                          value={basicDetails.property_name}
                          handleChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="Property Type"
                          name="property_type_name"
                          required="true"
                          value={basicDetails.propertyTypeObject}
                          handleChange={handlePropertyTypeChange}
                          options={basicDetails.propertyDataList}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Contact Person Name"
                          name="contact_person"
                          required="true"
                          value={basicDetails.contact_person}
                          handleChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <GmailInputBRH
                          label="Contact Person Email"
                          name="email"
                          required="true"
                          value={basicDetails.email}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <GmailInputBRH
                          label="Alternative Email"
                          name="contact_alternate_email"
                          value={basicDetails.contact_alternate_email}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} sx={{ marginBottom: '32px' }}>
                        <MobileInputBRH
                          label="Contact Person Number"
                          name="contact_number"
                          required="true"
                          value={basicDetails.contact_number}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} sx={{ marginBottom: '32px' }}>
                        <MobileInputBRH
                          label="Landline Number"
                          name="landline"
                          value={basicDetails.landline}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Address Line #1"
                          name="address1"
                          required="true"
                          value={basicDetails.address1}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Address Line #2"
                          name="address2"
                          value={basicDetails.address2}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Latitude"
                          name="latitude"
                          value={basicDetails.latitude}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Longitude"
                          name="longitude"
                          value={basicDetails.longitude}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="Country"
                          name="name"
                          required="true"
                          value={basicDetails.countryObject}
                          handleChange={handleCountryChange}
                          options={basicDetails.countryList}
                          isSelectDataloaded={basicDetails.isSelectDataLoaded}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="State"
                          required="true"
                          name="name"
                          value={basicDetails.stateObject}
                          handleChange={handleStateChange}
                          options={basicDetails.stateList}
                          isSelectDataloaded={basicDetails.isSelectDataloadedState}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="City"
                          name="name"
                          required="true"
                          value={basicDetails.cityObject}
                          handleChange={handleCityChange}
                          options={basicDetails.cityList}
                          // required
                        />{' '}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Land Mark"
                          name="landmark"
                          value={basicDetails.landmark}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Locality"
                          name="locality"
                          required="true"
                          value={basicDetails.locality}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <NumberInputBRH
                          label="Pincode"
                          name="zipcode"
                          required="true"
                          value={basicDetails.zipcode}
                          handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Display Name"
                          name="pseudoname"
                          required="true"
                          value={basicDetails.pseudoname}
                          handleChange={handleChange} // Assuming you have a handleChange function
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Property Code"
                          name="property_code"
                          required="true"
                          value={basicDetails.property_code}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Total Square Feet"
                          name="total_sq_ft"
                          value={basicDetails.total_sq_ft}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Floor Number"
                          name="floor_no"
                          required="true"
                          value={basicDetails.floor_no}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TimeInputBRH
                          label="Working Hours From"
                          name="workingHoursStart"
                          required="true"
                          value={basicDetails.workingHoursStart}
                          handleChange={(e) => setBascisDetails({ ...basicDetails, workingHoursStart: e })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TimeInputBRH
                          label="Working Hours To"
                          name="workingHoursEnd"
                          required="true"
                          value={basicDetails.workingHoursEnd}
                          handleChange={(e) => setBascisDetails({ ...basicDetails, workingHoursEnd: e })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid container sx={{ border: '1px solid #ccc', padding: '5px 10px' }}>
                          <Grid item xs={12}>
                            <Typography>Working Days</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Sun"
                              name="workingDaySun"
                              value={basicDetails.workingDaySun}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Mon"
                              name="workingDayMon"
                              value={basicDetails.workingDayMon}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Tue"
                              name="workingDayTue"
                              value={basicDetails.workingDayTue}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Wed"
                              name="workingDayWed"
                              value={basicDetails.workingDayWed}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Thu"
                              name="workingDayThu"
                              value={basicDetails.workingDayThu}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Fri"
                              name="workingDayFri"
                              value={basicDetails.workingDayFri}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Sat"
                              name="workingDaySat"
                              value={basicDetails.workingDaySat}
                              handleChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <AutoCompleteInputBRH
                          label="Sort Order"
                          name="sort_order"
                          value={basicDetails.sortOrderObject}
                          handleChange={handleSortOrderChange}
                          options={basicDetails.sortOptions}
                          // required
                        />{' '}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldSelectBRH
                          label="Property Furnished Status"
                          name="property_status"
                          required="true"
                          value={basicDetails.property_status}
                          handleChange={(e) => handlePropertyStatusChange(e)}
                          options={[
                            { id: 'Fully Furnished', name: 'Fully Furnished' },
                            { id: 'Bareshell', name: 'Bareshell' },
                          ]}
                          renderLabel="name"
                          renderValue="id"
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Short Description"
                          name="short_desc"
                          required="true"
                          value={basicDetails.short_desc}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH
                          label="Long Description"
                          name="long_desc"
                          multiline
                          rows={4}
                          value={basicDetails.long_desc}
                          handleChange={handleChange}
                          //   required
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={6}>
                        <label htmlFor="upload-button" aria-required>
                          <h5 className="text-center" style={{ color: '#fc9200', padding: 0 }}>
                            Upload your photo *
                          </h5>
                          <input
                            type="file"
                            id="upload-button"
                            required
                            multiple
                            style={{ color: 'orange' }}
                            onChange={handleImageSelection}
                          />
                        </label>
                        <Grid style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                          {imageError ? (
                            <p>upload image</p>
                          ) : (
                            selectedImages.map((image, index) => (
                              <div key={index}>
                                <img
                                  src={URL.createObjectURL(image)}
                                  alt={`Preview ${index}`}
                                  style={{
                                    width: '200px',
                                    height: '100px',
                                    marginRight: '10px',
                                    boxShadow: ' 0 3px 10px rgb(0 0 0 / 0.2)',
                                  }}
                                />
                                <button onClick={() => handleImageDelete(index)}>delete</button>
                              </div>
                            ))
                          )}
                        </Grid>
                      </Grid> */}
                      {/* <Grid item xs={12} sm={12} md={6}>
                        <Button sx={{ display: 'flex', gap: '10px', alignItems: 'center' }} component="label">
                          <CloudUploadIcon size={50} />
                          Upload Property Images
                          <input type="file" hidden multiple onChange={handleImageSelection} required />
                        </Button>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                          {selectedImages.map((image, index) => (
                            <div key={index}>
                              <IconButton onClick={() => handleImageDelete(index)}>
                                <ClearIcon />
                              </IconButton>
                              <img
                                src={URL.createObjectURL(image)}
                                alt={`Preview ${index}`}
                                style={{ width: 'auto', height: '100px', marginRight: '10px' }}
                              />
                            </div>
                          ))}
                        </div>
                      </Grid> */}
                      <Grid
                        sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}
                        item
                        xs={12}
                        sm={12}
                        md={6}
                      >
                        {/* <Button sx={{ height: '2.5rem', width: 150, mx: 1 }} variant="contained">
                        Cancel
                      </Button> */}
                        <LoadingButtonBRH type="submit" variant="containeds" label="Next" />
                      </Grid>
                    </Grid>
                  </FormControl>
                </form>
              </Box>
            </Card>

            {}
          </Container>
        </AccordionDetails>
      </Accordion>
      {accordian1FormImcomplete ? null : (
        <>
          <Accordion
            sx={{ background: 'light', padding: '20px' }}
            expanded={expanded === 'panel2a-content'}
            onChange={handleChangeAccordian('panel2a-content')}
          >
            <AccordionSummary
              sx={{ backgroundColor: '#f9fafb' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{ background: '#f9fafb', width: '100%' }}> Property Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
                {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
              <Typography variant="h4" gutterBottom>
                Add Basic Details
              </Typography>
            </Stack> */}
                <Card sx={{ p: 2 }}>
                  <Box sx={{ flexGrow: 1, m: 4 }}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Button sx={{ display: 'flex', gap: '10px', alignItems: 'center' }} component="label">
                        <CloudUploadIcon size={50} />
                        Upload Property Images
                        <input type="file" hidden multiple onChange={handleImageSelection} required />
                      </Button>
                      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                        {selectedImages?.map((image, index) => (
                          <div key={index}>
                            <IconButton onClick={() => handleImageDelete(index)}>
                              <ClearIcon />
                            </IconButton>
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Preview ${index}`}
                              style={{ width: 'auto', height: '100px', marginRight: '10px' }}
                            />
                          </div>
                        ))}
                      </div>
                    </Grid>
                  </Box>
                </Card>

                {}
              </Container>
            </AccordionDetails>
          </Accordion>
          {selectedImages.length <= 0 ? null : (
            <Grid sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }} item xs={12} sm={12} md={6}>
              {/* <Button sx={{ height: '2.5rem', width: 150, mx: 1 }} variant="contained">
                        Cancel
                      </Button> */}
              {/* <LoadingButtonBRH type="submit" variant="containeds" label="Save" color="grey" />
        <LoadingButtonBRH type="submit" variant="containeds" label="Save" /> */}
              <LoadingButtonBRH variant="containeds" label="Save & Next" handleSubmit={handleBasicDetails} />
            </Grid>
          )}
        </>
      )}{' '}
    </>
  );
};

export default EditBasicDetails;
