import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { getMethod } from '../../API_SERVICE_LAYER/ApiConfigs';

export default function TransferList({ checked, setChecked, masterIncExc,
  setMasterIncExc, inclusions, setInclusions,
  exclusions, setExclusions }) {
  // const [checked, setChecked] = useState([]);
  // const [masterIncExc, setMasterIncExc] = useState([]);
  // const [inclusions, setInclusions] = useState([]);
  // const [exclusions, setExclusions] = useState([]);

  useEffect(() => {
    getMethod('/api/inclusionexclusion?limit=10000').then((res) => {
      if (res?.data?.status === 'success') {
        const data = res?.data?.data?.data || [];
        setMasterIncExc(data);
      }
    });
  }, []);

  const handleToggle = (value) => () => {
    const newChecked = [...checked];
    const currentIndex = newChecked.indexOf(value);

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAddAllInclusions = () => {
    const remainingItems = masterIncExc.filter((item) => !inclusions.includes(item));
    const newInclusions = [...inclusions, ...remainingItems];
    const newExclusions = exclusions.filter((item) => !remainingItems.includes(item));
    const newMasterIncExc = masterIncExc.filter((item) => !newInclusions.includes(item));

    setInclusions(newInclusions);
    setExclusions(newExclusions);
    setChecked([]);
    setMasterIncExc(newMasterIncExc);
  };

  const handleRemoveAllInclusions = () => {
    setInclusions([]);
    setChecked([]);
  };

  const handleAddAllExclusions = () => {
    const remainingItems = masterIncExc.filter((item) => !exclusions.includes(item));
    const newExclusions = [...exclusions, ...remainingItems];
    const newInclusions = inclusions.filter((item) => !remainingItems.includes(item));
    const newMasterIncExc = masterIncExc.filter((item) => !newExclusions.includes(item));

    setExclusions(newExclusions);
    setInclusions(newInclusions);
    setChecked([]);
    setMasterIncExc(newMasterIncExc);
  };

  const handleRemoveAllExclusions = () => {
    const newMasterIncExc = [...masterIncExc, ...exclusions];
    setExclusions([]);
    setChecked([]);
    setMasterIncExc(newMasterIncExc);
  };

  const handleRemoveCheckedItemFromInclusion = () => {
    const remainingItems = inclusions.filter((item) => !checked.includes(item));
    const newMasterIncExc = [...masterIncExc, ...checked];

    setInclusions(remainingItems);
    setChecked([]);
    setMasterIncExc(newMasterIncExc);
  };

  const handleRemoveCheckedItemFromExclusion = () => {
    const remainingItems = exclusions.filter((item) => !checked.includes(item));
    const newMasterIncExc = [...masterIncExc, ...checked];

    setExclusions(remainingItems);
    setChecked([]);
    setMasterIncExc(newMasterIncExc);
  };

  const handleAddCheckedItemToInclusion = () => {
    const remainingItems = checked.filter((item) => !inclusions.includes(item));
    const newInclusions = [...inclusions, ...remainingItems];
    const newExclusions = exclusions.filter((item) => !remainingItems.includes(item));
    const newMasterIncExc = masterIncExc.filter((item) => !newInclusions.includes(item));

    setInclusions(newInclusions);
    setExclusions(newExclusions);
    setChecked([]);
    setMasterIncExc(newMasterIncExc);
  };

  const handleAddCheckedItemToExclusion = () => {
    const remainingItems = checked.filter((item) => !exclusions.includes(item));
    const newExclusions = [...exclusions, ...remainingItems];
    const newInclusions = inclusions.filter((item) => !remainingItems.includes(item));
    const newMasterIncExc = masterIncExc.filter((item) => !newExclusions.includes(item));

    setExclusions(newExclusions);
    setInclusions(newInclusions);
    setChecked([]);
    setMasterIncExc(newMasterIncExc);
  };

  const customList = (items) => (
    <Paper sx={{ width: '100%', height: 500, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items?.map((value) => {
          // console.log(value);
          const labelId = `transfer-list-item-${value?.description}-label`;

          return (
            <ListItem key={value?.description} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value?.description} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
  // console.log(inclusions);
  return (
    <Grid container spacing={1} justifyContent="center" alignItems="start">
      <Grid md={4} sx={{ height: 570, border: '1px solid #e8ebee', borderRadius: '5px', p: 1 }} item>
        <b>Inclusions & Exclusion Master</b>
        {customList(masterIncExc)}
      </Grid>

      <Grid md={3} item>
        <Grid container direction="column" justifyContent="center" alignItems="start">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAddAllInclusions}
            disabled={checked.length !== 0 || masterIncExc.length === 0 || inclusions.length === masterIncExc.length}
            aria-label="move all right"
          >
            Add All Inclusions
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleRemoveAllInclusions}
            disabled={inclusions.length === 0}
            aria-label="move all right"
          >
            Remove All Inclusions
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAddCheckedItemToInclusion}
            disabled={checked.length === 0}
            aria-label="move selected right"
          >
            Add To Inclusion
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleRemoveCheckedItemFromInclusion}
            disabled={checked.length === 0}
            aria-label="move selected left"
          >
            Remove From Inclusion
          </Button>

          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAddAllExclusions}
            disabled={masterIncExc.length === 0 || exclusions.length === masterIncExc.length}
            aria-label="move all right"
          >
            Add All Exclusions
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleRemoveAllExclusions}
            disabled={exclusions.length === 0}
            aria-label="move all right"
          >
            Remove All Exclusions
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAddCheckedItemToExclusion}
            disabled={checked.length === 0}
            aria-label="move selected right"
          >
            Add To Exclusion
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleRemoveCheckedItemFromExclusion}
            disabled={checked.length === 0}
            aria-label="move selected left"
          >
            Remove From Exclusion
          </Button>
        </Grid>
      </Grid>
      <Grid md={5} p={1} sx={{ overflow: 'unset' }}>
        <b>Inclusion's </b>
        <Grid sx={{ height: 265, overflow: 'auto', border: '1px solid #e8ebee', borderRadius: '5px', p: 1, m: 1 }} item>
          {customList(inclusions)}
        </Grid>
        <b>Exclusion's </b>
        <Grid sx={{ height: 265, overflow: 'auto', border: '1px solid #e8ebee', borderRadius: '5px', p: 1, m: 1 }} item>
          {customList(exclusions)}
        </Grid>
      </Grid>
    </Grid>
  );
}
