import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useNavigate } from 'react-router-dom';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';

function CancelButtonBRH({
  cancelOpen,
  setCancelOpen,
  message = 'All data populated in the field will be discarded, do you wish to continue?',
  title = 'Alert !',
  routeName = '',
  yesLabel = 'Proceed',
  noLabel = 'Go Back',
}) {
  // fix default value
  routeName = routeName ?? routeNames.INDEX;

  const navigate = useNavigate();
  const handleClose = () => {
    setCancelOpen(false);
  };
  const handleNavigate = () => {
    setCancelOpen(false);
    navigate(routeName);
  };

  return (
    <>
      <Dialog
        open={cancelOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="outlined">
            {noLabel}
          </Button>
          <Button onClick={handleNavigate} variant="contained">
            {yesLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CancelButtonBRH;
