import { Box, Container, Grid, Paper, Skeleton } from '@mui/material';
import React from 'react';

export const TableSkeleton = ({ lines = 7 }) => {
  return (
    <Paper>
      <Container>
        <Grid container rowSpacing={2}>
          <Grid item sm={12} sx={{ margin: '32px 0' }}>
            <Skeleton width={300} height={40} />
          </Grid>
        </Grid>
        <Box sx={{ border: '1px solid #f1f3f5', padding: '10px 10px' }}>
          {Array.from({ length: +lines }, (_, i) => {
            return (
              <>
                <Grid
                  container
                  sx={{
                    borderBottom: i === 0 ? '1px solid #f1f3f5' : '',
                  }}
                >
                  <Grid item sm={12} sx={{ marginBottom: i === lines - 1 ? '32px' : '10px' }}>
                    <Grid container spacing={3}>
                      <Grid item sm={3}>
                        <Skeleton height={50} animation="pulse" />
                      </Grid>
                      <Grid item sm={3}>
                        <Skeleton height={50} animation="pulse" />
                      </Grid>
                      <Grid item sm={3}>
                        <Skeleton height={50} animation="pulse" />
                      </Grid>
                      <Grid item sm={3}>
                        <Skeleton height={50} animation="pulse" />
                      </Grid>
                    </Grid>
                    {/* <Skeleton height={30} animation="pulse" /> */}
                  </Grid>
                </Grid>
              </>
            );
          })}
        </Box>
      </Container>
    </Paper>
  );
};
