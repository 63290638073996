import React, { useState, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
  RadioGroup,
  Radio,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import TextDivider from '../../../components/text-divider/TextDivider';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { TextFieldSelectBRH } from '../../../components/forms/TextFieldSelectBRH';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';

const DueDiligence = () => {
  const [expanded, setExpanded] = React.useState('dueDiligence');
  const [aadharSelectedFiles, setAadharSelectedFiles] = useState(null);
  const [panSelectedFiles, setPanSelectedFiles] = useState(null);
  const [saleSelectedFiles, setSaleSelectedFiles] = useState(null);
  const [occupSelectedFiles, setOccupSelectedFiles] = useState(null);
  const [propertySelectedFiles, setPropertySelectedFiles] = useState(null);
  const [electricitySelectedFiles, setElectricitySelectedFiles] = useState(null);
  const [authoritySelectedFiles, setAuthoritySelectedFiles] = useState(null);
  const [gstSelectedFiles, setGstSelectedFiles] = useState(null);
  const [bankSelectedFiles, setBankSelectedFiles] = useState(null);
  const [dueDiliData, setDueDiliData] = useState({
    aadharNumber: '',
    panNumber: '',
    saleDeed: '',
    occupcertifi: '',
    propertyTax: '',
    electricty: '',
    authorityApproval: '',
    gstCertificate: '',
    bankAccDetails: '',
  });
  // ###########
  const aadharFileName = aadharSelectedFiles?.name;
  const panFileName = panSelectedFiles?.name;
  const saleFileName = saleSelectedFiles?.name;
  const occupFileName = occupSelectedFiles?.name;
  const propertyTaxFileName = propertySelectedFiles?.name;
  const electrcityFileName = electricitySelectedFiles?.name;
  const authorityFileName = authoritySelectedFiles?.name;
  const gstFileName = gstSelectedFiles?.name;
  const bankAccFileName = bankSelectedFiles?.name;

  console.log(aadharFileName);
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleAadharChange = (event) => {
    // const files = Array.from(event.target.files[0]);
    setAadharSelectedFiles(event.target.files[0]);
  };
  const handlePanChange = (event) => {
    setPanSelectedFiles(event.target.files[0]);
  };
  const handleSaleChange = (event) => {
    setSaleSelectedFiles(event.target.files[0]);
  };
  const handleOccupationChange = (event) => {
    setOccupSelectedFiles(event.target.files[0]);
  };
  const handlepropertyTaxChange = (event) => {
    setPropertySelectedFiles(event.target.files[0]);
  };
  const handleElectricityChange = (event) => {
    setElectricitySelectedFiles(event.target.files[0]);
  };
  const handleAuthorityChange = (event) => {
    setAuthoritySelectedFiles(event.target.files[0]);
  };
  const handleGstChange = (event) => {
    setGstSelectedFiles(event.target.files[0]);
  };
  const handleBankChange = (event) => {
    setBankSelectedFiles(event.target.files[0]);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDueDiliData({ ...dueDiliData, [name]: value });
  };

  return (
    <>
      {' '}
      <Box sx={{ flexGrow: 1, m: 1 }}>
        <form noValidate={false}>
          <Grid item sm={12} md={12}>
            <TextDivider>Due Diligence Documents </TextDivider>
          </Grid>
          <FormGroup>
            <Grid container rowSpacing={2.5} columnSpacing={6}>
              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextInputBRH
                    label="Aadhar Card"
                    handleChange={handleChange}
                    value={dueDiliData.aadharNumber}
                    name="aadharNumber"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            cursor: 'pointer',
                            color: '#fc9200',
                            // border: '1px solid #d8dde1',
                            // backgroundColor: aadharSelectedFiles ? '#fff7cd' : '',
                            // padding: '15px',
                            // display: 'flex',
                            // alignItems: 'center',
                          }}
                          component="label"
                        >
                          <CloudUploadIcon />

                          <input type="file" hidden onChange={handleAadharChange} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>

                <p>{aadharFileName}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextInputBRH
                    label="PAN Card"
                    handleChange={handleChange}
                    value={dueDiliData.panNumber}
                    name="panNumber"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          component="label"
                          sx={{
                            cursor: 'pointer',
                            color: '#fc9200',
                            // border: '1px solid #d8dde1',
                            // backgroundColor: panSelectedFiles ? '#fff7cd' : '',
                            // padding: '15px',
                            // display: 'flex',
                            // alignItems: 'center',
                          }}
                        >
                          <CloudUploadIcon />

                          <input type="file" hidden onChange={handlePanChange} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <p> {panFileName}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextInputBRH
                    label="Sale Deed"
                    handleChange={handleChange}
                    value={dueDiliData.saleDeed}
                    name="saleDeed"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            cursor: 'pointer',
                            color: '#fc9200',
                            // border: '1px solid #d8dde1',
                            // backgroundColor: saleSelectedFiles ? '#fff7cd' : '',
                            // padding: '15px',
                            // display: 'flex',
                            // alignItems: 'center',
                          }}
                          component="label"
                        >
                          <CloudUploadIcon />

                          <input type="file" hidden onChange={handleSaleChange} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <p> {saleFileName}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextInputBRH
                    label="Occupation Certificate"
                    handleChange={handleChange}
                    value={dueDiliData.occupcertifi}
                    name="occupcertifi"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            cursor: 'pointer',
                            color: '#fc9200',
                          }}
                          component="label"
                        >
                          <CloudUploadIcon />

                          <input type="file" hidden onChange={handleOccupationChange} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <p>{occupFileName}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextInputBRH
                    label="Latest Property tax Paid Receipt"
                    handleChange={handleChange}
                    value={dueDiliData.propertyTax}
                    name="propertyTax"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            cursor: 'pointer',
                            color: '#fc9200',
                          }}
                          component="label"
                        >
                          <CloudUploadIcon />

                          <input type="file" hidden onChange={handlepropertyTaxChange} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <p>{propertyTaxFileName}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextInputBRH
                    label="Last Electricity Bill Paid Receipt"
                    handleChange={handleChange}
                    value={dueDiliData.electricty}
                    name="electricty"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            cursor: 'pointer',
                            color: '#fc9200',
                          }}
                          component="label"
                        >
                          <CloudUploadIcon />

                          <input type="file" hidden onChange={handleElectricityChange} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <p> {electrcityFileName}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextInputBRH
                    label="Local Authority approval and other relevant approvals"
                    handleChange={handleChange}
                    value={dueDiliData.authorityApproval}
                    name="authorityApproval"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            cursor: 'pointer',
                            color: '#fc9200',
                            // border: '1px solid #d8dde1',
                            // backgroundColor: authoritySelectedFiles ? '#fff7cd' : '',
                            // padding: '15px',
                            // display: 'flex',
                            // alignItems: 'center',
                          }}
                          component="label"
                        >
                          {authoritySelectedFiles ? <FileDownloadDoneIcon /> : <CloudUploadIcon />}

                          <input type="file" hidden onChange={handleAuthorityChange} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <p>{authorityFileName}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextInputBRH
                    label="GST Certificate, if available"
                    handleChange={handleChange}
                    value={dueDiliData.gstCertificate}
                    name="gstCertificate"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            cursor: 'pointer',
                            color: '#fc9200',
                          }}
                          component="label"
                        >
                          <CloudUploadIcon />

                          <input type="file" hidden onChange={handleGstChange} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <p> {gstFileName}</p>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextInputBRH
                    label="Bank Account Details"
                    handleChange={handleChange}
                    value={dueDiliData.bankAccDetails}
                    name="bankAccDetails"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          sx={{
                            cursor: 'pointer',
                            color: '#fc9200',
                            // backgroundColor: bankSelectedFiles ? '#fff7cd' : '',
                            // //  padding: '15px',
                            // display: 'flex',
                            // alignItems: 'center',
                          }}
                          component="label"
                        >
                          <CloudUploadIcon />

                          <input type="file" hidden onChange={handleBankChange} />
                        </IconButton>
                      ),
                    }}
                  />
                </div>
                <p> {bankAccFileName}</p>
              </Grid>
            </Grid>
          </FormGroup>
          <div style={{ display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end', marginTop: '30px' }}>
            <LoadingButtonBRH variant="containeds" label="Save & Next" type="submit" />
          </div>
        </form>
      </Box>
    </>
  );
};

export default DueDiligence;
