import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Modal,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { json, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import Swal from 'sweetalert2';

import Scrollbar from '../../components/scrollbar/Scrollbar';
import Iconify from '../../components/iconify/Iconify';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { CRSLIST, GENERATEMOU, PROPERTYLIST } from '../../API_SERVICE_LAYER/ApiNames';
import { TableSkeleton } from '../../components/skeleton/TableSkeleton';
import { NormalTable } from '../../components/tables/NormalTable';
import palette from '../../theme/palette';
import { contractContext } from '../../components/context/AppContext';
import { ToastBRH } from '../../components/toasts/ToastBRH';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius:'5px',
  p: 4,
};

export default function CrsList() {
  const navigate = useNavigate();
  const { propertyList } = useContext(contractContext);

  const [open, setOpen] = useState(false);
  const [detailset, setdetailset]=useState({id:null,cname:""})
  const handleOpen = (d) => {
    // console.log('ss',d,"ssd")
    setdetailset({id:d.id,cname:d.contract_client?.company_name});
    setOpen(true)
  };
  const handleClose = () =>{
    setdetailset({id:null,cname:""});
    setOpen(false);
  }
  // useMemo()
  const [crsData, setCrsData] = useState({
    crsListData: [],
    propertyList: [],
    isFetched: false,
  });




  const DeleteContractHandler =(d)=>{
    // console.log(d?.id)
           API.deleteMethod(`/contract-management/api/v1/delete-contract/${d?.id}`)
          .then((res) => {
            if (res?.data?.status) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Contract Delete Succesfully",
                showConfirmButton: false,
                timer: 1500,
              });
              handleClose();
              setTimeout(() => {
              window.location.reload();
              }, 2000);
            }
          })
          .catch((err)=>{
            console.log(err)
            Swal.fire({
              position: 'center',
              icon: 'warning',
              title: "Something went wrong",
              showConfirmButton: false,
              timer: 1500,
            });
        });

       

  }

  const generateMOU = (id) => {
    const formData = {
      contract_id: id,
    };
    // working https://dev.berighthere.com/contract-management/api/v1/generate-mou/${id}
    // axios(
    //   `https://dev.berighthere.com/contract-management/api/v1/generate-mou`,
    //   {
    //     method: 'POST',
    //     responseType: 'blob', // Force to receive data in a Blob Format,
    //   },
    // )
    // .then((response) => {
    //   // Create a Blob from the PDF Stream
    //   const file = new Blob([response.data], { type: 'application/pdf' });
    //   // Build a URL from the file
    //   const fileURL = URL.createObjectURL(file);
    //   // Open the URL on new Window
    //   window.open(fileURL);
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
    // axios
    //   .post(`https://dev.berighthere.com/contract-management/api/v1/generate-mou`, formData,{
    //     responseType: 'arraybuffer'})
    // API.postMethod(GENERATEMOU, formData)
    // .then((response) => {
    //   // Create a Blob from the PDF Stream
    //   const file = new Blob([response.data], { type: 'application/pdf' });
    //   // Build a URL from the file
    //   const fileURL = URL.createObjectURL(file);
    //   // Open the URL on new Window
    //   window.open(fileURL,'_blank');
    // })
    // .catch((error) => {
    //   console.log(error);
    // });

    API.pdfDownload(GENERATEMOU, formData)
      .then((response) => {
        // Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: 'application/pdf' });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL on new Window
        // window.open(fileURL, '_blank'); work
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;

        downloadLink.download = 'MOU.pdf';

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);
        // console.log(response);
        if (response?.status === 200) {
          ToastBRH('success', 'MOU Generated Successfully', `${routeNames.MOU}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(crsData?.crsListData,"mymy");


  const columns = useMemo(
    () => [
      {
        accessorKey: 'contract_client.company_name',
        header: 'Client Name',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          const status = rowData?.kyc_status;
          const remarks = rowData?.remark;
          return (
            <>
              {/* <span>{rowData?.contract_client?.company_name}</span> */}
              <Button
                variant="outlined"
                // color="secondary"
                sx={{ background: 'none', cursor: 'pointer', color: '#3d464f' }}
                fullWidth
                onClick={
                  () => {
                    // navigate(`${routeNames.CRS}/edit/${rowData?.id}`, {
                    //   state: { isUpdated: true, updatedId: rowData?.id },
                    // });
                    navigate(`${routeNames.CRS}edit/${rowData?.id}`);
                  }
                  // navigate(`/masters/client/edit/${rowData?.id}`, {
                  //   state: { isUpdated: true, updatedId: rowData?.id },
                  // })
                }
              >
                {/* {rowData?.contract_client?.company_name} */}
                <Stack>
                  <span>{rowData?.contract_client?.company_name}</span>
                  <span
                    style={{
                      color: status === 1 ? 'green' : status === 2 ? 'blue' : status === 3 ? 'red' : status === 5 ?'green' : 'grey',
                      fontSize: '12px',
                      // textAlign: 'start',
                    }}
                  >
                    (
                    {status === 1 && remarks
                      ? 'KYC Re-approved'
                      : status === 1
                      ? 'KYC Approved'
                      : status === 2
                      ? 'KYC Requested'
                      : status === 3
                      ? 'KYC Rejected'
                      : status === 5
                      ? 'KYC Skipped'
                      : 'draft'}
                    )
                  </span>
                </Stack>
              </Button>
            </>
          );
        },
        size: 150,
      },
      // {
      //   accessorKey: 'property_id',
      //   header: 'Address',
      //   size: 200,
      // },
      {
        accessorKey: 'propertyData.property_name',
        header: 'Property Name',

        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          // console.log(rowData,"mydata")
          return (
            <>
             <span>{rowData?.propertyData &&  rowData?.propertyData?.property_name }</span> 
             {/* {rowData?.propertyData?.property_name && <span>{rowData.property_name &&  rowData.property_name }</span> } */}
              
            </>
          );
        },
        size: 150,
      },

      {
        accessorKey: 'agreement_start_date',
        header: 'Start Date',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <span>{moment(rowData.agreement_start_date).format('DD - MMM - YYYY')}</span>
            </>
          );
        },
        size: 150,
      },
      {
        accessorKey: 'agreement_end_date',
        header: 'End Date',
        enableColumnFilter: false,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <span>{moment(rowData.agreement_end_date).format('DD - MMM - YYYY')}</span>
            </>
          );
        },
        size: 150,
      },
      {
        header: 'Action',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              {/* <span>{moment(rowData.agreement_end_date).format('DD - MMM - YYYY')}</span> */}
              <Button 
             onClick={()=>handleOpen(rowData)}
              // onClick={()=> DeleteContractHandler(rowData)}
               > Delete </Button>
            </>
          );
        },
        size: 150,
      }
      // {
      //   accessorKey: 'is_mou',
      //   header: '',
      //   size: 150,
      //   enableColumnFilter: false,
      //   Cell: (cell) => {
      //     const rowData = cell?.row?.original;
      //     return (
      //       <>
      //         <Button
      //           onClick={() => generateMOU(rowData.id)}
      //           style={{
      //             backgroundColor: `${palette.secondary.light}`,
      //           }}
      //           disabled={rowData?.kyc_status !== 1}
      //         >
      //           Generate MOU
      //         </Button>
      //       </>
      //     );
      //   },
      // },
    ],
    []
  );

  useEffect(() => {
    // AFTER CONTEXT
    API.getData(CRSLIST)
      .then((res) => {
        if (res?.data?.status === 'success') {
          let data = res?.data?.results;
          // console.log(data,"ressspond")
          data = data?.filter((crs) => crs.is_contract === 0 && crs.direct_contract === 0 );
          
          const sortedData = data.toReversed();

          const propertyData = propertyList;
          // const laksh = data.flatMap((elecrs, i) => {
          //   data[i].propertyData = {};
          //   let sri;
          //   propertyData?.forEach((elePro) => {
          //     if (elecrs.property_id === elePro.id) {
          //       sri = elePro;
          //       data[i].propertyData = elePro;
          //       return 1;
          //     }
          //     return [];
          //   });
          //   return 1;
          // });
          setCrsData({
            ...crsData,
            isFetched: true,
            crsListData: sortedData,
            propertyList: propertyData,
          });
        }
      })
      .catch((err) => {
        // console.log(' API-CRSLIST', err);
        setCrsData({ ...crsData, isFetched: true });
      });
  }, []);

  // useEffect(() => {
  //   // before Context
  //   API.getData(CRSLIST)
  //     .then((res) => {
  //       if (res?.data?.status === 'success') {
  //         let data = res?.data?.results;
  //         data = data?.filter((crs) => crs.is_contract === 0);
  //         console.log('laksh', data);
  //         API.getData(PROPERTYLIST)
  //           .then((resPro) => {
  //             if (resPro?.data?.status === 'success') {
  //               const propertyData = resPro.data.result;
  //               const laksh = data.flatMap((elecrs, i) => {
  //                 data[i].propertyData = {};
  //                 let sri;
  //                 propertyData.forEach((elePro) => {
  //                   if (elecrs.property_id === elePro.id) {
  //                     sri = elePro;
  //                     data[i].propertyData = elePro;
  //                     return 1;
  //                   }
  //                   return [];
  //                 });
  //                 return 1;
  //               });
  //               setCrsData({ ...crsData, isFetched: true, crsListData: data, propertyList: resPro.data.result });
  //             }
  //           })
  //           .catch((errPro) => {
  //             setCrsData({ ...crsData, isFetched: true, crsListData: data });
  //             console.log(errPro);
  //           });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(' API-CRSLIST', err);
  //       setCrsData({ ...crsData, isFetched: true });
  //     });
  // }, []);

  return (
    <>
      {crsData.isFetched ? (
        <>
          <Helmet>
            <title> Contracts | BRH </title>
          </Helmet>
          <Container>
            {/* <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Typography color="text.primary">CRS</Typography>
        </Breadcrumbs> */}
            {/* page titls */}
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                CRS
              </Typography>
              <Button
                onClick={() => navigate(`${routeNames.CRSADD}`)}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New CRS
              </Button>
            </Stack>
            {/* page body */}
            <Card sx={{ p: 2 }}>
              {/* <Scrollbar>
            <MaterialReactTable columns={columns} data={data} />
          </Scrollbar> */}
              {/* <NormalTable columns={columns} data={crsData.crsListData.filter((c)=>c.kyc_document!=="")} /> */}
              <NormalTable 
  columns={columns} 
  data={crsData?.crsListData?.filter((c) => c.contract_mou?.length <1) || []} 
/>
            </Card>
          </Container>
        </>
      ) : (
        <>
          <TableSkeleton />
        </>
      )}

<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <div>
            Are you sure want to delete <b style={{ color:"#fc9200" }}>{detailset?.cname} </b>  ? &nbsp;   &nbsp; 
         
          </div>

          <div style={{ marginTop:'2rem',float:'right' }}>
          <Button  onClick={()=>DeleteContractHandler(detailset)} style={{ background:"rgb(252, 146, 0)",color:"#fff" }} > Yes </Button>  &nbsp;   &nbsp; 
            <Button  onClick={handleClose} style={{ background:"gray",color:"#fff" }} > No </Button>

          </div>
        

        </Box>
      </Modal>

    </>
  );
}
