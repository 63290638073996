import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
// import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet-async';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Iconify from '../../../components/iconify/Iconify';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { GETCLIENTS, UPDATECLIENTS } from '../../../API_SERVICE_LAYER/ApiNames';
import { TableSkeleton } from '../../../components/skeleton/TableSkeleton';
import { NormalTable } from '../../../components/tables/NormalTable';
import { SwichInputBRH } from '../../../components/forms/SwichInputBRH';
import { ToastBRH } from '../../../components/toasts/ToastBRH';
import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';

export default function ClientsPage() {
  const [clientsData, setClientsData] = useState({
    clientsList: [],
    isFetched: false,
  });
  const navigate = useNavigate();

  const redirectTo = () => {
    navigate('/masters/client/add');
  };

  const data = [
    {
      name: {
        firstName: 'John',
        lastName: 'Doe',
      },
      address: '261 Erdman Ford',
      city: 'East Daphne',
      state: 'Kentucky',
    },
    {
      name: {
        firstName: 'Jane',
        lastName: 'Doe',
      },
      address: '769 Dominic Grove',
      city: 'Columbus',
      state: 'Ohio',
    },
    {
      name: {
        firstName: 'Joe',
        lastName: 'Doe',
      },
      address: '566 Brakus Inlet',
      city: 'South Linda',
      state: 'West Virginia',
    },
    {
      name: {
        firstName: 'Kevin',
        lastName: 'Vandy',
      },
      address: '722 Emie Stream',
      city: 'Lincoln',
      state: 'Nebraska',
    },
    {
      name: {
        firstName: 'Joshua',
        lastName: 'Rolluffs',
      },
      address: '32188 Larkin Turnpike',
      city: 'Charleston',
      state: 'South Carolina',
    },
    {
      name: {
        firstName: 'John',
        lastName: 'Doe',
      },
      address: '261 Erdman Ford',
      city: 'East Daphne',
      state: 'Kentucky',
    },
    {
      name: {
        firstName: 'Jane',
        lastName: 'Doe',
      },
      address: '769 Dominic Grove',
      city: 'Columbus',
      state: 'Ohio',
    },
    {
      name: {
        firstName: 'Joe',
        lastName: 'Doe',
      },
      address: '566 Brakus Inlet',
      city: 'South Linda',
      state: 'West Virginia',
    },
    {
      name: {
        firstName: 'Kevin',
        lastName: 'Vandy',
      },
      address: '722 Emie Stream',
      city: 'Lincoln',
      state: 'Nebraska',
    },
    {
      name: {
        firstName: 'Joshua',
        lastName: 'Rolluffs',
      },
      address: '32188 Larkin Turnpike',
      city: 'Charleston',
      state: 'South Carolina',
    },
    {
      name: {
        firstName: 'John',
        lastName: 'Doe',
      },
      address: '261 Erdman Ford',
      city: 'East Daphne',
      state: 'Kentucky',
    },
    {
      name: {
        firstName: 'Jane',
        lastName: 'Doe',
      },
      address: '769 Dominic Grove',
      city: 'Columbus',
      state: 'Ohio',
    },
    {
      name: {
        firstName: 'Joe',
        lastName: 'Doe',
      },
      address: '566 Brakus Inlet',
      city: 'South Linda',
      state: 'West Virginia',
    },
    {
      name: {
        firstName: 'Kevin',
        lastName: 'Vandy',
      },
      address: '722 Emie Stream',
      city: 'Lincoln',
      state: 'Nebraska',
    },
    {
      name: {
        firstName: 'Joshua',
        lastName: 'Rolluffs',
      },
      address: '32188 Larkin Turnpike',
      city: 'Charleston',
      state: 'South Carolina',
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'company_name',
        header: 'Client Name',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <Button
                variant="text"
                sx={{ background: 'none', cursor: 'pointer', border: 'none', color: '#3d464f' }}
                onClick={() => {
                  navigate(`${routeNames.CLIENTS}/edit/${rowData?.id}`, {
                    state: { isUpdated: true, updatedId: rowData?.id },
                  });
                }}
              >
                {rowData.company_name}
              </Button>
            </>
          );
        },
        size: 250,
      },
      {
        accessorKey: 'company_address',
        header: 'Client Address',
        size: 250,
      },
      {
        accessorKey: 'contact_person_name',
        header: 'Contact Person Name',
        size: 250,
      },
      {
        accessorKey: 'contact_person_number',
        header: 'Contact Person Number',
        size: 250,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <SwichInputBRH
                name="status"
                value={rowData?.status}
                handleChange={(e) => handleStatusChange(e, rowData)}
              />
            </>
          );
        },
        size: 150,
      },
    ],
    []
  );

  const handleStatusChange = (e, newValue) => {
    // console.log('clicked', newValue, e.target.checked);
    const changedStatus = newValue.status === 1 ? 0 : 1;
    const formData = {
      id: newValue.id,
      status: changedStatus,
    };
    API.updateMethod(UPDATECLIENTS, formData)
      .then((res) => {
        if (res.data.status === 'success') {
          console.log('API-UPDATECLIENTS');
          console.log(`${routeNames.CLIENTS}`);
          ToastBRH('success', 'Status updated Successfully', `${routeNames.CLIENTS}`);
        }
      })
      .catch((err) => {
        ToastBRH('error', 'Something went wrong', false);
        console.log('API-UPDATECLIENTS', err);
      });
  };
  useEffect(() => {
    API.getData(`${GETCLIENTS}`)
      .then((res) => {
        if (res?.data?.status === 'success') {
          const filteredClients = res?.data?.results
            ?.flatMap((cli) => {
              if (cli.is_prospect === 0) {
                return cli;
              }
              return [];
            })
            ?.toReversed();
          setClientsData({ ...clientsData, clientsList: filteredClients, isFetched: true });
        }
      })
      .catch((err) => {
        console.log('API-GETCLIENTS', err);
        setClientsData({ ...clientsData, isFetched: true });
      });
  }, []);

  return (
    <>
      {clientsData.isFetched ? (
        <>
          <Helmet>
            <title> Prospects | BRH </title>
          </Helmet>
          <Container>
            <Breadcrumbs aria-label="breadcrumb">
              <Button onClick={() => navigate(routeNames.INDEX)} color="secondary">
                Dashboard
              </Button>
              <Typography color="primary" variant="button">
                Clients
              </Typography>
              {/* <Link underline="hover" color="inherit" href="/dashboard">
                Dashboard
              </Link>
              <Link underline="hover" color="inherit" href="/masters/client">
                Masters
              </Link>
              <Typography color="text.primary">Clients</Typography> */}
            </Breadcrumbs>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Clients
              </Typography>
              <Button onClick={redirectTo} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                New Clients
              </Button>
            </Stack>
            <Card sx={{ p: 2 }}>
              <NormalTable columns={columns} data={clientsData.clientsList} />
              {/* <MaterialReactTable columns={columns} data={clientsData.clientsList} /> */}
              {/* <Scrollbar>
              </Scrollbar> */}
            </Card>
          </Container>
        </>
      ) : (
        <>
          <TableSkeleton />
        </>
      )}
    </>
  );
}
