import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckBoxInputBRH } from '../../../components/forms/CheckBoxInputBRH';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';

const EditAmenities = (props) => {
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [freeAmenities, setFreeAmenities] = useState([]);
  const [paidAmenities, setPaidAmenities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [propertyId, setPropertyId] = useState(null);
  const [propertyAmenities, setPropertyAmenities] = useState([]);
  const [propertyData, setPropertyData] = useState({});
  const [isDraft, setIsDraft] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const data = JSON.parse(localStorage.getItem('basicDetails'));
  const [expanded, setExpanded] = useState('panel1a-header');

  console.log('data', data.property_type_id);
  console.log('Amitiesdata', amenitiesData);
  console.log('pAmitiesdata', propertyAmenities);
  useEffect(() => {
    console.log(props.property_id, 'defghjkdfghj');
    if (data.property_type_id) {
      const id = data.property_type_id;

      setPropertyId(id);

      API.getData(`api/amenities?limit=100`).then((result) => {
        console.log(result.data.data.data, 'res');
        setAmenitiesData(result.data.data.data);
      });

      API.getData(`api/propertyamenities/${id}`).then((result) => {
        setPropertyAmenities(result.data.data);
      });

      API.getData(`api/property/${id}`).then((result) => {
        setIsLoading(false);
        setPropertyData(result.result);
        setIsDraft(result.data.is_draft);
      });
    }
  }, [data.property_type_id]);

  const defaultCheck =
    amenitiesData &&
    amenitiesData.map((value) => {
      if (value.is_paid === 0 && propertyAmenities) {
        const match = propertyAmenities.find((values) => {
          return values.amenity_id === value.id && values.property_id === data.property_type_id;
        });

        return match ? 1 : 0;
      }
      return 0; // Default value if the condition is not met.
    });
  console.log(defaultCheck, 'def');
  // Use defaultCheck value as needed

  console.log(` defaultCheck is ${defaultCheck}`);
  const IMAGE_API = 'https://brhfiles.s3.ap-south-1.amazonaws.com/';
  console.log(IMAGE_API, 'IMAGES');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <Accordion
        sx={{ background: 'light', padding: '20px' }}
        expanded={expanded === 'panel1a-header'}
        onChange={handleChange('panel1a-header')}
      >
        <AccordionSummary
          sx={{ backgroundColor: '#f9fafb' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ background: '#f9fafb', width: '100%' }}>Amenities</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <Card sx={{ flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1, m: 1 }}>
                <form noValidate={false}>
                  <FormControl sx={{ flexGrow: 1, width: '100%' }}>
                    <Grid container spacing={1}>
                      {amenitiesData &&
                        amenitiesData.map((value, index) => (
                          <Grid item xs={12} sm={12} md={6} key={index}>
                            <div className=" card col-lg-6 col-md-6 col-sm-12 bg-danger">
                              <div className="row align-items-center">
                                {defaultCheck[index] === 1 ? (
                                  <CheckBoxInputBRH
                                    label={value.amenity_name}
                                    imagePath={IMAGE_API + value.icon_path}
                                    id={value.amenity_name}
                                    name="free_amenities[]"
                                    value={defaultCheck[index] === 1}
                                  />
                                ) : (
                                  <CheckBoxInputBRH
                                    label={value.amenity_name}
                                    imagePath={IMAGE_API + value.icon_path}
                                    id={value.amenity_name}
                                    name="free_amenities[]"
                                    value={defaultCheck[index] === 1}
                                  />
                                )}
                              </div>
                            </div>
                          </Grid>
                        ))}
                    </Grid>
                  </FormControl>
                </form>
              </Box>
            </Card>
          </Container>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ background: 'light', padding: '20px' }}>
        <AccordionSummary
          sx={{ backgroundColor: '#f9fafb' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ background: '#f9fafb', width: '100%' }}>Value Added Services</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Accordion sx={{ background: 'light', padding: '20px' }}>
            <AccordionSummary
              sx={{ backgroundColor: '#f9fafb' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography sx={{ background: '#f9fafb', width: '100%' }}>Value Added Services</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
                <input />
              </Container>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ background: 'light', padding: '20px' }}>
            <AccordionSummary
              sx={{ backgroundColor: '#f9fafb' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <Typography sx={{ background: '#f9fafb', width: '100%' }}>Value Added Services</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Container>
                <input />
              </Container>
            </AccordionDetails>
          </Accordion>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default EditAmenities;
