import React, { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Button,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import { CheckBoxInputBRH } from '../../../components/forms/CheckBoxInputBRH';
import { TimeInputBRH } from '../../../components/forms/TimeInputBRH';
import { SwichInputBRH } from '../../../components/forms/SwichInputBRH';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';

import { FormSkeleton } from '../../../components/skeleton/FormSkeleton';
import {
  COUNTRYLIST,
  STATELIST,
  CITYLIST,
  GETCLIENTDATA,
  GETCLIENTS,
  UPDATECLIENTS,
} from '../../../API_SERVICE_LAYER/ApiNames';
import { ToastBRH } from '../../../components/toasts/ToastBRH';
import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';
import { MobileInputBRH } from '../../../components/forms/MobileInputBRH';
import { GmailInputBRH } from '../../../components/forms/GmailInputBRH';
import CancelAlertBRH from '../../../components/forms/CancelAlertBRH';
import { TOAST_SUCCESS, TOAST_ERROR } from '../../../utils/toastTimings';

function AddClient() {
  // for updation
  const { state } = useLocation();
  const clientId = state?.updatedId;
  const isUpdated = state?.isUpdated;
  const navigate = useNavigate();
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const [clientData, setClientData] = useState({
    companyNameList: [],
    country_id: 101,
    state_id: '',
    city_id: '',
    prospects: [],
    countryList: [],
    stateList: [],
    cityList: [],
    countryObject: null,
    stateObject: null,
    cityObject: null,
    pincode: null,
    prospectId: '',
    company_name: null,
    companyAddress: '',
    companySignatory: '',
    companyBusinessType: '',
    contactPersName: '',
    contactPersDesignation: '',
    contactPersMail: '',
    contactPersNumber: '',
    specialInstructions: '',
    workingDayMon: true,
    workingDayTue: true,
    workingDayWed: true,
    workingDayThu: true,
    workingDayFri: true,
    workingDaySat: false,
    workingDaySun: false,
    workingHoursStart: new Date(2023, 1, 1, 10, 0, 0),
    workingHoursEnd: new Date(2023, 1, 1, 18, 0, 0),
    status: 1,
    isLoading: false, // for loading button
    isFetched: false, // for useState
    isSelectDataLoaded: true, // for auto complete input
    ContractType: 1, // Company-1,indivitual-2
  });
  // handle autocomplete specifically
  const handleAutoCompleteChange = (event, newValue) => {
    console.log(newValue);
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    console.log(name, id);
    if (name === 'company_name') {
      setClientData({ ...clientData, isSelectDataLoaded: true });
      const {
        stateObject,
        cityObject,
        country_id: countryId,
        state_id: stateId,
        city_id: cityId,
        pincode,
        company_address: companyAddress,
        company_signatory: companySignatory,
        company_nature_of_business: companyBusinessType,
        contact_person_name: contactPersName,
        contact_person_email: contactPersMail,
        contact_person_number: contactPersNumber,
        contact_person_designation: contactPersDesignation,
        special_instrucions: specialInstructions,
      } = newValue;
      API.getData(COUNTRYLIST)
        .then((resCou) => {
          if (resCou?.data?.status === true) {
            const dataCountry = resCou?.data?.data;
            let apiCountryObject;
            dataCountry?.forEach((cou) => {
              if (cou.id === countryId) {
                apiCountryObject = cou;
              }
            });
            API.getData(`${STATELIST}/${countryId}`)
              .then((resSt) => {
                if (resSt?.data?.status === true) {
                  const dataState = resSt?.data?.data;
                  let apiStateObject;
                  dataState?.forEach((st) => {
                    if (st.id === stateId) {
                      apiStateObject = st;
                    }
                  });
                  API.getData(`${CITYLIST}/${stateId}`).then((resCT) => {
                    if (resCT?.data?.status === true) {
                      const dataCity = resCT?.data?.data;
                      let apiCityObject;
                      dataCity?.forEach((ct) => {
                        if (ct.id === cityId) {
                          apiCityObject = ct;
                        }
                      });
                      console.log(countryId, stateId, cityId);
                      setClientData({
                        ...clientData,
                        [name]: newValue,
                        prospectId: id,
                        countryObject: apiCountryObject,
                        stateObject: apiStateObject,
                        cityObject: apiCityObject,
                        country_id: countryId,
                        state_id: stateId,
                        city_id: cityId,
                        companyAddress,
                        pincode,
                        companySignatory,
                        companyBusinessType,
                        contactPersName,
                        contactPersDesignation,
                        contactPersMail,
                        contactPersNumber,
                        specialInstructions,
                        // isSelectDataLoaded: true,
                      });
                    }
                  });
                }
              })
              .catch((errSt) => {
                console.log(errSt);
              });
          }
        })
        .catch((errCou) => {
          console.log(errCou);
        });

      setClientData({
        ...clientData,
        [name]: newValue,
        prospectId: id,
        stateObject,
        cityObject,
        countryId,
        stateId,
        cityId,
        companyAddress,
        pincode,
        companySignatory,
        companyBusinessType,
        contactPersName,
        contactPersMail,
        contactPersDesignation,
        contactPersNumber,
        specialInstructions,
        isSelectDataLoaded: true,
      });
    }
  };
  // handle normal inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === 'workingDaySun' ||
      name === 'workingDayMon' ||
      name === 'workingDayTue' ||
      name === 'workingDayWed' ||
      name === 'workingDayThu' ||
      name === 'workingDayFri' ||
      name === 'workingDaySat'
    ) {
      const checkboxValue = !clientData[name];
      setClientData({ ...clientData, [name]: checkboxValue });
    } else if (name === 'status') {
      setClientData({ ...clientData, status: e.target.checked });
    } else {
      setClientData({ ...clientData, [name]: value });
    }
  };
  // handle time input

  const handleSubmit = (e) => {
    e.preventDefault();
    setClientData({ ...clientData, isLoading: true });
    if (!clientData.isLoading) {
      const fomData = {
        id: clientData.prospectId,
        company_name: clientData.company_name?.company_name,
        company_address: clientData.companyAddress,
        country_id: clientData.country_id,
        city_id: clientData.city_id,
        state_id: clientData.state_id,
        company_signatory: clientData.companySignatory,
        company_nature_of_business: clientData.companyBusinessType,
        contact_person_name: clientData.contactPersName,
        contact_person_designation: clientData.contactPersDesignation,
        contact_person_email: clientData.contactPersMail,
        contact_person_number: Number(clientData.contactPersNumber),
        pincode: Number(clientData.pincode),
        special_instrucions: clientData.specialInstructions,
        is_prospect: 0,
        from_time: clientData.workingHoursStart.toLocaleTimeString('en-IN', { hour12: false }),
        to_time: clientData.workingHoursEnd.toLocaleTimeString('en-IN', { hour12: false }),
        timings: [
          {
            day_code: 'Sun',
            day_id: 1,
            is_selected: clientData.workingDaySun ? 1 : 0,
          },
          {
            day_code: 'Mon',
            day_id: 2,
            is_selected: clientData.workingDayMon ? 1 : 0,
          },
          {
            day_code: 'Tue',
            day_id: 3,
            is_selected: clientData.workingDayTue ? 1 : 0,
          },
          {
            day_code: 'Wed',
            day_id: 4,
            is_selected: clientData.workingDayWed ? 1 : 0,
          },
          {
            day_code: 'Thu',
            day_id: 5,
            is_selected: clientData.workingDayThu ? 1 : 0,
          },
          {
            day_code: 'Fir',
            day_id: 6,
            is_selected: clientData.workingDayFri ? 1 : 0,
          },
          {
            day_code: 'Sat',
            day_id: 7,
            is_selected: clientData.workingDaySat ? 1 : 0,
          },
        ],
        status: clientData.status,
        contract_document_type_id: Number(clientData.ContractType),
      };
      console.log('clicked', fomData);
      API.updateMethod(`${UPDATECLIENTS}`, fomData)
        .then((res) => {
          if (res.data.status === 'success') {
            ToastBRH('success', 'Client Updated Successfully', TOAST_SUCCESS);
            setTimeout(() => navigate(routeNames.CLIENTS), 2000);
          }
        })
        .catch((err) => {
          console.log(err);
          ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
          setClientData({ ...clientData, isLoading: false });
        });
    }
    return -1;
  };
  useEffect(() => {
    if (isUpdated === true) {
      API.getData(`${GETCLIENTDATA}/${clientId}`)
        .then((res) => {
          if (res.data.status === 'success') {
            const apiData = res.data.results;
            const [startHours, startMinutes, startSeconds] = apiData?.from_time?.split(':');
            const [endHours, endminutes, endSeconds] = apiData?.to_time?.split(':');
            const startDate = new Date(2023, 1, 1, +startHours, +startMinutes, +startSeconds);
            const endDate = new Date(2023, 1, 1, +endHours, +endminutes, +endSeconds);
            API.getData(COUNTRYLIST)
              .then((resCountry) => {
                if (resCountry?.data?.status === true) {
                  const dataCountry = resCountry?.data?.data;
                  let apiCountryObject;
                  dataCountry.forEach((cou) => {
                    if (cou.id === apiData.country_id) {
                      apiCountryObject = cou;
                    }
                  });
                  API.getData(`${STATELIST}/${apiData.country_id}`)
                    .then((resSt) => {
                      if (resSt?.data?.status === true) {
                        const dataState = resSt?.data?.data;
                        let apiStateObject;
                        dataState?.forEach((st) => {
                          if (st.id === apiData?.state_id) {
                            apiStateObject = st;
                          }
                        });
                        API.getData(`${CITYLIST}/${apiData?.state_id}`)
                          .then((resCT) => {
                            if (resCT?.data?.status === true) {
                              const dataCity = resCT?.data?.data;
                              let apiCityObject;
                              dataCity?.forEach((ct) => {
                                if (ct.id === apiData?.city_id) {
                                  apiCityObject = ct;
                                }
                              });
                              console.log('ssss', apiData?.contract_document_type_id);
                              setClientData({
                                ...clientData,
                                isFetched: true,
                                prospects: res.data.results,
                                prospectId: clientId,
                                company_name: apiData?.company_name,
                                companyAddress: apiData?.company_address,
                                pincode: apiData?.pincode,
                                companyBusinessType: apiData?.company_nature_of_business,
                                companySignatory: apiData?.company_signatory,
                                contactPersName: apiData?.contact_person_name,
                                contactPersDesignation: apiData?.contact_person_designation,
                                contactPersMail: apiData?.contact_person_email,
                                contactPersNumber: apiData?.contact_person_number,
                                country_id: apiData?.country_id,
                                ContractType: apiData?.contract_document_type_id,

                                countryList: dataCountry,
                                city_id: apiData?.city_id,
                                cityList: dataCity,
                                countryObject: apiCountryObject,
                                stateObject: apiStateObject,
                                stateList: dataState,
                                state_id: apiData?.state_id,
                                cityObject: apiCityObject,
                                specialInstructions: apiData?.special_instrucions,
                                workingHoursStart: startDate ?? new Date(),
                                workingHoursEnd: endDate ?? new Date(),
                                workingDaySun: apiData.client_timings[0]?.is_selected,
                                workingDayMon: apiData.client_timings[1]?.is_selected,
                                workingDayTue: apiData.client_timings[2]?.is_selected,
                                workingDayWed: apiData.client_timings[3]?.is_selected,
                                workingDayThu: apiData.client_timings[4]?.is_selected,
                                workingDayFri: apiData.client_timings[5]?.is_selected,
                                workingDaySat: apiData.client_timings[6]?.is_selected,
                                status: apiData?.status,
                              });
                            }
                          })
                          .catch((errCT) => {
                            console.log('API-CITYLIST', errCT);
                            setClientData({
                              ...clientData,
                              isFetched: true,
                              prospects: res.data.results,
                              prospectId: clientId,
                              company_name: apiData?.company_name,
                              companyAddress: apiData?.company_address,
                              pincode: apiData?.pincode,
                              companyBusinessType: apiData?.company_nature_of_business,
                              companySignatory: apiData?.company_signatory,
                              contactPersName: apiData?.contact_person_name,
                              contactPersMail: apiData?.contact_person_email,
                              contactPersNumber: apiData?.contact_person_number,
                              contactPersDesignation: apiData?.contact_person_designation,
                              country_id: apiData?.country_id,
                              state_id: apiData?.state_id,
                              city_id: apiData?.city_id,
                              countryList: dataCountry,
                              stateList: dataState,

                              countryObject: apiCountryObject,
                              stateObject: apiStateObject,
                              specialInstructions: apiData?.special_instrucions,
                              workingHoursStart: startDate ?? new Date(),
                              workingHoursEnd: endDate ?? new Date(),
                              workingDaySun: apiData.client_timings[0]?.is_selected,
                              workingDayMon: apiData.client_timings[1]?.is_selected,
                              workingDayTue: apiData.client_timings[2]?.is_selected,
                              workingDayWed: apiData.client_timings[3]?.is_selected,
                              workingDayThu: apiData.client_timings[4]?.is_selected,
                              workingDayFri: apiData.client_timings[5]?.is_selected,
                              workingDaySat: apiData.client_timings[6]?.is_selected,
                              status: apiData?.status,
                            });
                          });
                      }
                    })
                    .catch((errSt) => {
                      console.log('API-STATELIST', errSt);
                      setClientData({
                        ...clientData,
                        isFetched: true,
                        prospects: res.data.results,
                        prospectId: clientId,
                        company_name: apiData?.company_name,
                        companyAddress: apiData?.company_address,
                        pincode: apiData?.pincode,
                        companyBusinessType: apiData?.company_nature_of_business,
                        companySignatory: apiData?.company_signatory,
                        contactPersName: apiData?.contact_person_name,
                        contactPersDesignation: apiData?.contact_person_designation,
                        contactPersMail: apiData?.contact_person_email,
                        contactPersNumber: apiData?.contact_person_number,
                        country_id: apiData?.country_id,
                        countryList: dataCountry,
                        state_id: apiData?.state_id,
                        city_id: apiData?.city_id,
                        countryObject: apiCountryObject,
                        specialInstructions: apiData?.special_instrucions,
                        workingHoursStart: startDate ?? new Date(),
                        workingHoursEnd: endDate ?? new Date(),
                        workingDaySun: apiData.client_timings[0]?.is_selected,
                        workingDayMon: apiData.client_timings[1]?.is_selected,
                        workingDayTue: apiData.client_timings[2]?.is_selected,
                        workingDayWed: apiData.client_timings[3]?.is_selected,
                        workingDayThu: apiData.client_timings[4]?.is_selected,
                        workingDayFri: apiData.client_timings[5]?.is_selected,
                        workingDaySat: apiData.client_timings[6]?.is_selected,
                        status: apiData?.status,
                      });
                    });
                }
              })
              .catch((errCountry) => {
                console.log('API-COUNTRYLIST', errCountry);
                setClientData({
                  ...clientData,
                  isFetched: true,
                  prospects: res.data.results,
                  prospectId: clientId,
                  company_name: apiData?.company_name,
                  companyAddress: apiData?.company_address,
                  pincode: apiData?.pincode,
                  companyBusinessType: apiData?.company_nature_of_business,
                  companySignatory: apiData?.company_signatory,
                  contactPersName: apiData?.contact_person_name,
                  contactPersDesignation: apiData?.contact_person_designation,
                  contactPersMail: apiData?.contact_person_email,
                  contactPersNumber: apiData?.contact_person_number,
                  country_id: apiData?.country_id,
                  state_id: apiData?.state_id,
                  city_id: apiData?.city_id,
                  specialInstructions: apiData?.special_instrucions,
                  workingHoursStart: startDate ?? new Date(),
                  workingHoursEnd: endDate ?? new Date(),
                  workingDaySun: apiData.client_timings[0]?.is_selected,
                  workingDayMon: apiData.client_timings[1]?.is_selected,
                  workingDayTue: apiData.client_timings[2]?.is_selected,
                  workingDayWed: apiData.client_timings[3]?.is_selected,
                  workingDayThu: apiData.client_timings[4]?.is_selected,
                  workingDayFri: apiData.client_timings[5]?.is_selected,
                  workingDaySat: apiData.client_timings[6]?.is_selected,
                  status: apiData?.status,
                });
              });
            // const isMonSelected = apiData.client_timings[0].is_selected === 1 ? true : false;
          }
        })
        .catch((err) => {
          console.log(err);
          setClientData({ ...clientData, isFetched: true });
        });
    } else {
      API.getMethod(`${GETCLIENTS}`)
        .then((resCli) => {
          if (resCli?.data?.status === 'success') {
            const dataclients = resCli?.data.results;
            const prospectsList = dataclients?.flatMap((cli) => (cli.is_prospect === 1 ? cli : []));
            API.getData(COUNTRYLIST)
              .then((resCountry) => {
                if (resCountry?.data?.status === true) {
                  const dataCountry = resCountry?.data?.data;
                  API.getData(`${STATELIST}/${clientData.country_id}`)
                    .then((resSt) => {
                      if (resSt?.data?.status === true) {
                        const dataState = resSt?.data?.data;
                        setClientData({
                          ...clientData,
                          isFetched: true,
                          prospects: prospectsList,
                          countryList: dataCountry,
                          countryObject: dataCountry[0],
                          stateList: dataState,
                        });
                      }
                    })
                    .catch((errSt) => {
                      console.log(errSt);
                      setClientData({
                        ...clientData,
                        isFetched: true,
                        prospects: prospectsList,
                        countryList: dataCountry,
                        countryObject: dataCountry[0],
                      });
                    });
                }
              })
              .catch((errCountry) => {
                console.log('API-COUNTRYLIST', errCountry);
                setClientData({ ...clientData, isFetched: true, prospects: prospectsList });
              });
          }
        })
        .catch((errCli) => {
          console.log('API-GETCLIENTS', errCli);
          setClientData({ ...clientData, isFetched: true });
        });
    }
  }, []);

  const handleCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setClientData({ ...clientData, isSelectDataLoaded: false });
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            setClientData({
              ...clientData,
              country_id: id,
              countryObject: newValue,
              stateList: res.data.data,
              cityList: [],
              stateObject: null,
              cityObject: null,
              isSelectDataLoaded: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setClientData({
            ...clientData,
            country_id: id,
            stateList: [],
            isSelectDataLoaded: true,
          });
        });
    }
  };
  const handleStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    if (name === 'name') {
      setClientData({ ...clientData, isSelectDataLoaded: false });
      API.getData(`${CITYLIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            console.log('API-CITYLIST');
            setClientData({
              ...clientData,
              isSelectDataLoaded: true,
              stateObject: newValue,
              state_id: id,
              cityList: res.data.data,
              cityObject: null,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setClientData({ ...clientData, isSelectDataLoaded: true, cityList: [], state_id: id });
        });
    }

    // setCompanyMasterData({ ...companyMasterData, state_id1: id });
  };
  const handleCityChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    setClientData({ ...clientData, city_id: id, cityObject: newValue });
  };
  return (
    <>
      {clientData.isFetched ? (
        <>
          <Helmet>
            <title> Clients | BRH </title>
          </Helmet>
          {/* page top nav */}
          <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
            <Button onClick={() => navigate(routeNames.INDEX)} color="secondary">
              Dashboard
            </Button>
            <Button onClick={() => navigate(routeNames.CLIENTS)} color="secondary">
              Master
            </Button>
            <Typography color="primary" variant="button">
              {clientId ? 'Edit' : 'Add'} Client
            </Typography>
          </Breadcrumbs>

          <>
            <Container>
              {/* page title */}
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h4" gutterBottom>
                  {clientId ? 'Edit' : 'Add'} Clients
                </Typography>
              </Stack>
              {/* page body */}
              <Card sx={{ p: 2 }}>
                <Box sx={{ flexGrow: 1, m: 4 }}>
                  <form noValidate={false} onSubmit={handleSubmit}>
                    <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                      <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                        {/* choose client or indivitual */}
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl>
                            <FormLabel id="ContractType-radio-buttons-group">Contract Type</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="ContractType-radio-buttons-group"
                              name="ContractType"
                              value={clientData.ContractType}
                              onChange={handleChange}
                            >
                              <FormControlLabel value={1} control={<Radio />} label="Company" />
                              <FormControlLabel value={2} control={<Radio />} label="Individual" />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        {/* Company Name */}
                        <Grid item xs={12} sm={12} md={6}>
                          {isUpdated ? (
                            <>
                              <TextInputBRH value={clientData.company_name} readOnly="true" />
                            </>
                          ) : (
                            <>
                              <AutoCompleteInputBRH
                                label="Prospect Name"
                                name="company_name"
                                value={clientData.company_name}
                                handleChange={handleAutoCompleteChange}
                                options={clientData.prospects}
                                isSelectDataloaded={clientData.isSelectDataLoaded}
                                required
                              />
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="Country"
                            name="name"
                            value={clientData.countryObject}
                            handleChange={handleCountryChange}
                            options={clientData.countryList}
                            isSelectDataloaded={clientData.isSelectDataLoaded}
                            required
                          />
                        </Grid>
                        {/* State */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="State"
                            name="name"
                            value={clientData.stateObject}
                            handleChange={handleStateChange}
                            options={clientData.stateList}
                            isSelectDataloaded={clientData.isSelectDataLoaded}
                            required
                          />
                        </Grid>
                        {/* City */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="City"
                            name="name"
                            value={clientData.cityObject}
                            handleChange={handleCityChange}
                            options={clientData.cityList}
                            required
                          />{' '}
                        </Grid>
                        {/* Company Address */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Client Address"
                            name="companyAddress"
                            value={clientData.companyAddress}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Pincode */}
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label="Pincode"
                            name="pincode"
                            value={clientData.pincode}
                            key="pincode"
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Company Signatory */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Client Signatory"
                            name="companySignatory"
                            value={clientData.companySignatory}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Company Nature of Business */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Client Nature of Business"
                            name="companyBusinessType"
                            value={clientData.companyBusinessType}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Contact Person Name */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Contact Person Name"
                            name="contactPersName"
                            value={clientData.contactPersName}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Contact Person Email */}
                        <Grid item xs={12} sm={12} md={6}>
                          <GmailInputBRH
                            label="Contact Person Email"
                            name="contactPersMail"
                            value={clientData.contactPersMail}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Contact Person Number */}
                        <Grid item xs={12} sm={12} md={6} sx={{ marginBottom: '32px' }}>
                          <MobileInputBRH
                            label="Contact Person Number"
                            name="contactPersNumber"
                            value={clientData.contactPersNumber}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Contact Person Designation"
                            name="contactPersDesignation"
                            value={clientData.contactPersDesignation}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* Special Instructions */}
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Special Instructions"
                            name="specialInstructions"
                            value={clientData.specialInstructions}
                            handleChange={handleChange}
                            multiline
                            rows={4}
                            required
                          />
                        </Grid>
                        {/* workingHoursStart */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TimeInputBRH
                            label="Working Hours From"
                            name="workingHoursStart"
                            value={clientData.workingHoursStart}
                            handleChange={(e) => setClientData({ ...clientData, workingHoursStart: e })}
                          />
                        </Grid>
                        {/* workingHoursEnd */}
                        <Grid item xs={12} sm={12} md={6}>
                          <TimeInputBRH
                            label="Working Hours To"
                            name="workingHoursEnd"
                            value={clientData.workingHoursEnd}
                            handleChange={(e) => setClientData({ ...clientData, workingHoursEnd: e })}
                          />
                        </Grid>
                        {/* Working Days */}
                        <Grid item xs={12} sm={12} md={6}>
                          <Grid container sx={{ border: '1px solid #ccc', padding: '5px 10px' }}>
                            <Grid item xs={12}>
                              <Typography>Working Days</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Sun"
                                name="workingDaySun"
                                value={clientData.workingDaySun}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Mon"
                                name="workingDayMon"
                                value={clientData.workingDayMon}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Tue"
                                name="workingDayTue"
                                value={clientData.workingDayTue}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Wed"
                                name="workingDayWed"
                                value={clientData.workingDayWed}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Thu"
                                name="workingDayThu"
                                value={clientData.workingDayThu}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Fri"
                                name="workingDayFri"
                                value={clientData.workingDayFri}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Sat"
                                name="workingDaySat"
                                value={clientData.workingDaySat}
                                handleChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* status */}
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          sx={{
                            display: 'flex',
                            justifySelf: 'start',
                            alignItems: 'center',
                          }}
                        >
                          <SwichInputBRH
                            label="Status"
                            name="status"
                            value={clientData.status}
                            handleChange={handleChange}
                          />
                          {/* <Grid container justifyContent='flex-start' alignItems='flex-end' sx={{ marginLeft: "16px" }} >
                    <Grid item>
                    </Grid>
                  </Grid> */}
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6}>
                                </Grid> */}
                      </Grid>
                      {/* sbmission */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <Button
                          color="secondary"
                          onClick={() => {
                            setCancelOpen(true);
                          }}
                          variant="outlined"
                          sx={{ marginRight: '24px' }}
                        >
                          Cancel
                        </Button>
                        <LoadingButtonBRH
                          isLoading={clientData.isLoading}
                          label={isUpdated ? 'Update' : 'Add'}
                          // handleSubmit={handleSubmit}
                          type="submit"
                        />

                        <CancelAlertBRH
                          cancelOpen={cancelOpen}
                          setCancelOpen={setCancelOpen}
                          routeName={routeNames.CLIENTS}
                        />
                      </Box>
                    </FormControl>
                  </form>
                </Box>
              </Card>
            </Container>
            {/* <SwichInputBRH label="Status" name="status" value={clientData.status} handleChange={handleChange} /> */}

            {/* <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Mon" />
                <FormControlLabel control={<Checkbox />} label="Tue" />
                <FormControlLabel control={<Checkbox />} label="Wed" />
                <FormControlLabel control={<Checkbox />} label="Thu" />
                <FormControlLabel control={<Checkbox />} label="Fri" />
                <FormControlLabel control={<Checkbox />} label="Sat" />
                <FormControlLabel control={<Checkbox />} label="Sun" />
            </FormGroup> */}
          </>
        </>
      ) : (
        <>
          <FormSkeleton />
        </>
      )}
    </>
  );
}

export default AddClient;
