import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import { MOUUPDATE } from '../../API_SERVICE_LAYER/ApiNames';
import { ToastBRH } from '../../components/toasts/ToastBRH';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
import { TextInputBRH } from '../../components/forms/TextInputBRH';
import { ErrorMessageBRH } from '../../components/forms/ErrorMessageBRH';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/toastTimings';

const MouAlert = ({ open, setOpen, isLead, isOk, id, contractId, isMou = 1, MOUACCEPT, onSetKey }) => {
  const navigate = useNavigate();
  const [rejectionReason, setRejectionReason] = useState();
  const [isReasonEntered, setIsReasonEntered] = useState(true);
  const handleIsVerified = (e, id, value, isLead = 1) => {
    // reset the reject status value 0 from 2
    if (value === 0) {
      value = 2;
    }
    const tempIsVerified = value;

    e.preventDefault();
    let formData = {
      id,
      contract_id: contractId,
      is_mou_accept: MOUACCEPT,
    };
    if (isMou === 1) {
      // LOGICS FOR MOU
      // ADD APPROVE OR REJECTION STATUS
      formData =
        isLead === 1 ? { ...formData, is_verified: value, is_approved: null } : { ...formData, is_approved: value };
      // ADD REJECTION REASON PAYLOAD
      // formData =
      //   value === 2 && isLead === 1
      //     ? { ...formData, reason_of_lead_rejection: rejectionReason }
      //     : value === 2 && isLead === 0
      //     ? { ...formData, reason_of_head_rejection: rejectionReason }
      //     : { ...formData };
    } else {
      // LOGICS FOR CONTRACT
      // ADD APPROVE OR REJECTION STATUS
      formData =
        isLead === 1
          ? { ...formData, is_contract_verified: value, is_contract_approved: null }
          : { ...formData, is_contract_approved: value };
      // ADD REJECTION REASON PAYLOAD
      // formData =
      //   value === 2 && isLead === 1
      //     ? { ...formData, reason_of_lead_rejection: rejectionReason }
      //     : value === 2 && isLead === 0
      //     ? { ...formData, reason_of_head_rejection: rejectionReason }
      //     : { ...formData };
    }

    API.updateMethod(MOUUPDATE, formData)
      .then((res) => {
        if (res.data?.status !== 'success') {
          ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
          // ToastBRH('warning', 'Something Went Wrong Please Try again', '_', false);
        } else {
          //   ToastBRH('success', `MOU ${isLead === 1 ? 'Verified' : 'Approved'} Successfully`, `${routeNames.MOU}`);
          // const redirectRoute = isLead === 1 && isMou === 1 ? routeNames.MOU : routeNames.CONTRACT;
          setOpen(false);
          // ToastBRH(
          //   'success',
          //   `${isMou === 1 ? 'MOU' : 'Contract'} ${
          //     isLead === 1 && isOk === 1 ? 'Verified' : isLead === 0 && isOk === 1 ? 'Approved' : 'Rejected'
          //   } Successfully`,
          //   TOAST_SUCCESS
          // );
          ToastBRH(
            'success',
            MOUACCEPT === 1
              ? 'Contract Generated Successfully'
              : `${isMou === 1 ? 'MOU' : 'Contract'} ${
                    isLead === 1 && isOk === 1 ? 'Verified' : isLead === 0 && isOk === 1 ? 'Approved' : 'Rejected'
                  } Successfully`,
            TOAST_SUCCESS
          );
          // setTimeout(() => navigate(redirectRoute), TOAST_SUCCESS);
          setTimeout(
            MOUACCEPT === 1 ? navigate(routeNames.CONTRACT) : onSetKey(new Date().toTimeString()),
            TOAST_SUCCESS
          );
        }
      })
      .catch((err) => console.error(err));
    // console.log(id, value, formData);
  };
  const handleClose = () => {
    setRejectionReason('');
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleSubmit = (e, id, isOk, isLead) => {
    e.preventDefault();
    if (isOk === 0 && rejectionReason === '') {
      setIsReasonEntered(false);
      return setTimeout(() => setIsReasonEntered(true), 3000);
    }
    return handleIsVerified(e, id, isOk, isLead);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Confirmation!'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* {`
              
            Are you sure the ${isMou === 1 ? 'MOU' : 'Contract'} can be ${
                isLead === 1 && isOk === 1 ? 'Verified' : isLead === 0 && isOk === 1 ? 'Approved' : 'Rejected'
              }?`} */}

              {`
  ${
    MOUACCEPT === 1
      ? 'Are you sure want to generate the contract ?' 
      : `Are you sure the ${isMou === 1 ? 'MOU' : 'Contract'} can be ${
          isLead === 1 && isOk === 1 ? 'Verified' : isLead === 0 && isOk === 1 ? 'Approved' : 'Rejected'
        }?`
  }
`}
              {/* {`It will make MOU as ${
              isLead === 1 && isOk === 1 ? 'Verify' : isLead === 0 && isOk === 1 ? 'Approve' : 'Reject'
            } , do you wish to continue?`} */}
            </DialogContentText>
            <Box sx={{ marginTop: '32px', display: isOk === 0 ? 'block' : 'none' }}>
              <TextInputBRH
                label="Reason for rejection"
                name="rejectionReason"
                value={rejectionReason}
                handleChange={(e) => setRejectionReason(e.target.value)}
                size="small"
                variant="standard"
                required
              />
              <ErrorMessageBRH display={!isReasonEntered} message="Please Enter rejection reason" />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              No
            </Button>
            <Button
              variant="contained"
              // type = 'submit'
              onClick={(e) => {
                // handleIsVerified(e, id, isOk, isLead);
                handleSubmit(e, id, isOk, isLead);
              }}
            >
              {isLead === 1 && isOk === 1 ? 'Verify' : isLead === 0 && isOk === 1 ? 'Yes' : 'Yes'}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default MouAlert;
