import { Box, Divider, Grid } from '@mui/material';
import React, { useContext } from 'react';
import moment from 'moment';
import styles from './Notification.module.css';
import { contractContext } from '../../components/context/AppContext';
// import { TextFieldSelectBRH } from '../../components/forms/TextFieldSelectBRH';
import Iconify from '../../components/iconify';
import palette from '../../theme/palette';

// const STATUS_CONTACTED_CLIENT = 1;
// const STATUS_AWAITING_CLIENT_STATUS = 2;
// const STATUS_RENEWAL_CONTRACT = 3;
// const STATUS_RENEWAL_CANCELLED = 4;

// const statusOptions = [
//   {
//     label: 'Contacted Client',
//     value: STATUS_CONTACTED_CLIENT,
//   },
//   {
//     label: 'Awaiting Client Status',
//     value: STATUS_AWAITING_CLIENT_STATUS,
//   },
//   {
//     label: 'Renewal Contract',
//     value: STATUS_RENEWAL_CONTRACT,
//   },
//   {
//     label: 'Renewal Cencelled',
//     value: STATUS_RENEWAL_CANCELLED,
//   },
// ];

export default function NotificationTwo({ contract }) {
  const { propertyList } = useContext(contractContext);
 
  console.log(contract,"mycontract");
  const startDate = new Date(contract?.agreement_start_date);
  const endDate = new Date(contract?.agreement_end_date);
  
  const today = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  const numOfDays = Math.round(Math.abs((endDate - today) / oneDay));
//   const propertyId = contract?.renewals[0]?.property_id;
//   const propertyName = propertyList?.find((property) => property.id === propertyId)?.property_name;
const propertyName =contract?.propertyData?.property_name;
const contractn=contract?.contract_client?.company_name;

console.log(contract,"CCDDDSSS")
  
return (
    <li>
      <Box sx={{ marginBottom: '10px' }}>
        <Grid container rowSpacing={1}>
        
        
          <Grid item md={8}>
            <span style={{ marginRight: '16px' }}>
              <Iconify icon="fluent:arrow-step-in-right-28-filled" color={palette.primary.dark} />
            </span>
            <span className={styles.notificationDetail}>
              {/* <span>contract - </span> */}
             {contractn} {contract?.contractMou?.is_mou_accept===0 && contract?.contractMou?.direct_contract===0 && <> ( MOU )</> }| {propertyName} | {moment(startDate).format('DD - MMM - YYYY')} |{' '}
              {moment(endDate).format('DD - MMM - YYYY')} 
            </span>
           
           
          </Grid>
        
        
          <Grid item md={12} marginTop={2}>
            <Divider color="primary" />
          </Grid>
        </Grid>
       
       
      </Box>
    </li>
  );
}
