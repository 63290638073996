import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PropertyDetails from './PropertyDetails';
import PropertyCommercials from './PropertyCommercials';
import Amenities from './Amenities';
import DueDiligence from './DueDiligence';
import ComplianceSafety from './ComplianceSafety';
import { setSelectedTabRedux } from '../../../Redux/actions/TabsActions';

const AddPropertyAgreements = () => {
  // const [value, setValue] = React.useState('propertyDetails');
  const handleChange = (event, newValue) => {
    setSelectedTabRedux(newValue);
  };
  const value = useSelector((state) => state.TabsSelection.selectedTab);
  console.log('tabsData', value);
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Property details" value="propertyDetails" />
              <Tab label="Property Commercials" value="propertyCommercials" />
              <Tab label="Amenities" value="amenities" />
              <Tab label="Compliance & Safety" value="compliance&safety" />
              <Tab label="Due Diligence" value="dueDiligence" />
            </TabList>
          </Box>
          <TabPanel value="propertyDetails">
            <PropertyDetails />
          </TabPanel>
          <TabPanel value="propertyCommercials">
            <PropertyCommercials />
          </TabPanel>
          <TabPanel value="amenities">
            <Amenities />
          </TabPanel>
          <TabPanel value="compliance&safety">
            <ComplianceSafety />
          </TabPanel>
          <TabPanel value="dueDiligence">
            <DueDiligence />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default AddPropertyAgreements;
