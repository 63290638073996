import React from 'react';
import { Stack, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import palette from '../../theme/palette';

export const ErrorMessageBRH = ({ message, display }) => (
  <Typography
    sx={{
      color: palette.error.dark,
      fontStyle: 'italic',
      fontWeight: '600',
      paddingLeft: '8px',
      display: display ? 'flex' : 'none',
      marginTop: '16px',
    }}
  >
    <ErrorIcon color="error" />
    {message}
  </Typography>
  // <Stack
  //   direction="row"
  //   alignItems="center"

  //   sx={{ display: display ? 'flex' : 'none', marginTop: '16px' }}
  // >

  // </Stack>
);
