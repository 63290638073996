import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Box, Button, Card, Container, IconButton, Snackbar, Stack, Tooltip, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RejectIcon from '@mui/icons-material/GppBad';
import VerifyIcon from '@mui/icons-material/Beenhere';
import Completed from '@mui/icons-material/ThumbUpOffAlt';
import WaitIcon from '@mui/icons-material/HourglassBottom';

import palette from '../../theme/palette';
import { TableSkeleton } from '../../components/skeleton/TableSkeleton';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { CONTRACTMOUFILEUPLOAD, MOULIST } from '../../API_SERVICE_LAYER/ApiNames';
import { NormalTable } from '../../components/tables/NormalTable';
import { CAT_HEAD_APPROVED, CAT_HEAD_REJECTED, CAT_LEAD_REJECTED, CAT_LEAD_VERFIED } from '../../config/BRHConfig';
import MouAlert from './MouAlert';
import { ToastBRH } from '../../components/toasts/ToastBRH';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/toastTimings';
import { FileInputBRH } from '../../components/forms/FileInputBRH';

export const MouList = () => {
  const [key, setKey] = useState(new Date().toTimeString());
  const [mouData, setMouData] = useState({
    isFetched: false,
    mouListData: [],
    userDetails: {},
  });
  const [snackbarDetails, setSnackbarDetails] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLead, setIsLead] = useState();
  const [isOkay, setIsOkay] = useState();
  const [open, setOpen] = useState(false);
  const [popupId, setPopupId] = useState();
  const [contractId, setContractId] = useState();
  const [conmouaccept, setConMouAccept] = useState();
  const [file, setFile] = useState();

  const [pdfViewedStatus, setPdfViewedStatus] = useState(() => {
    const storedStatus = localStorage.getItem('pdfViewedStatus');
    return storedStatus ? JSON.parse(storedStatus) : {};
  });
  const handleView = (rowData) => {
    window.open(`${process.env.REACT_APP_IMAGE_URL}/${rowData?.pdf}`);
    const updatedStatus = {
      ...pdfViewedStatus,
      [rowData?.id]: true,
    };
    setPdfViewedStatus(updatedStatus);

    // Update local storage with the new status
    localStorage.setItem('pdfViewedStatus', JSON.stringify(updatedStatus));
  };


  const handleFileUpload = (e, id) => {
    const uploadedFile = e.target.files[0];
  
    // Update contractsData based on the specific row's id
    setMouData((prevData) => ({
      ...prevData,
      mouListData: prevData.mouListData.map((item) =>
        item.id === id ? { ...item, file: uploadedFile } : item // Update only the matching item
      ),
    }));
  
    setFile(uploadedFile); // Set the file locally if needed
    console.log(uploadedFile, "mnk");
  };


  const handleSubmit = (e, id, file) => {
    e.preventDefault();
    const formData = new FormData();
    // formData.append('template_path', 'lak');
    formData.append('id', id);
    formData.append('moufile', file);
    // formData.append('is_mou_accept', 1);
    API.fileupload(CONTRACTMOUFILEUPLOAD, formData)
      .then((res) => {
        if (res?.data?.status === 'success') {
          // ToastBRH('success', `Contract Uploaded Successfully`, `${routeNames.CONTRACT}`);
          ToastBRH('success', `MOU Sign Uploaded Successfully`, TOAST_SUCCESS);
          setTimeout(() => setKey(new Date().toTimeString()), TOAST_SUCCESS);
        } else {
          // ToastBRH('warning', 'Something went wrong! Please try again later', '_', false, 4000);
          ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
        }
      })
      .catch((err) => console.log(err));
  };


  const handleSnackbar = () => {
    handleView(snackbarDetails);
    setSnackbarOpen(false);
  };

  const handleVerifyApply = (isLead, isOkay, id, contractId, MOUACCEPT, rowData) => {
    const pdfViewedLocal = localStorage.getItem('viewed');
    setIsLead(isLead);
    setIsOkay(isOkay);
    setPopupId(id);
    setContractId(contractId);
    setConMouAccept(MOUACCEPT);
    if (MOUACCEPT === 1) {
      setSnackbarDetails(rowData);
      // test
      setOpen(true);
    } else if (pdfViewedStatus[id]) {
      setOpen(true);
    } else {
      // ToastBRH('warning', 'You need to view the file before approve or to verify', '_', false, 4000);
      setSnackbarOpen(true);
      setSnackbarDetails(rowData);

      return setTimeout(() => setSnackbarOpen(false), 2000);
    }

    return 1;
  };

  const columns = useMemo(
    () => [
      // Client Name
      {
        accessorKey: 'contract.contract_client.company_name',
        header: 'Client Name',
        Cell: ({ cell }) => {
          return (
            <>
              <span>{cell.getValue()}</span>
            </>
          );
        },
        filterVariant: 'text',
        size: 100,
      },
      // MOU PDF
      {
        accessorKey: 'property_id',
        header: 'MOU PDF',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <Tooltip title={`view ${rowData?.contract?.contract_client?.company_name} MOU`}>
                <IconButton
                  target="_blank"
                  // href={`${process.env.REACT_APP_IMAGE_URL}/${rowData?.pdf}`}
                  onClick={() => handleView(rowData)}
                >
                  <VisibilityIcon color="primary" />
                </IconButton>
              </Tooltip>
              {/* <Button target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${rowData?.pdf}`}>
                <VisibilityIcon />
              </Button> */}
            </>
          );
        },
        enableColumnFilter: false,
        size: 50,
      },
      // Generated Date
      {
        accessorKey: 'created_at',
        header: 'Generated Date',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <span>{moment(rowData.created_at).format('DD - MMM - YYYY')}</span>
            </>
          );
        },
        enableColumnFilter: false,
        size: 100,
      },
      // CAT Lead Status
      {
        accessorKey: 'is_verified',
        header: 'CAT Lead Status',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <Typography
                variant="overline"
                sx={{
                  color:
                    rowData.is_verified === CAT_LEAD_VERFIED
                      ? palette.success.dark
                      : rowData.is_verified === CAT_LEAD_REJECTED
                      ? palette.error.dark
                      : palette.warning.dark,
                  backgroundColor:
                    rowData.is_verified === CAT_LEAD_VERFIED
                      ? palette.success.lighter
                      : rowData.is_verified === CAT_LEAD_REJECTED
                      ? palette.error.lighter
                      : palette.warning.lighter,
                  padding: '8px 16px',
                  borderRadius: '3px',
                  letterSpacing: '1px',
                }}
              >
                {rowData.is_verified === CAT_LEAD_VERFIED
                  ? 'Verified'
                  : rowData.is_verified === CAT_LEAD_REJECTED
                  ? 'Rejected'
                  : 'Not yet Verfied'}
              </Typography>
            </>
          );
        },
        filterVariant: 'select',
        filterFn: 'equals',
        filterSelectOptions: [
          {
            value: '0',
            text: 'Not Yet Verified',
          },
          {
            value: 1,
            text: 'Verified',
          },
          {
            value: 2,
            text: 'Rejected',
          },
        ],
        size: 200,
      },
      // CAT Head Status
      {
        accessorKey: 'is_approved',
        header: 'Unit Head Status',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              {/* <span>{rowData?.pdf}</span> */}

              <Typography
                variant="overline"
                sx={{
                  color:
                    rowData.is_approved === CAT_HEAD_APPROVED
                      ? palette.success.dark
                      : rowData.is_approved === CAT_HEAD_REJECTED
                      ? palette.error.dark
                      : palette.warning.dark,
                  backgroundColor:
                    rowData.is_approved === CAT_HEAD_APPROVED
                      ? palette.success.lighter
                      : rowData.is_approved === CAT_HEAD_REJECTED
                      ? palette.error.lighter
                      : palette.warning.lighter,
                  padding: '8px 16px',
                  borderRadius: '3px',
                  letterSpacing: '1px',
                }}
              >
                {rowData.is_approved === CAT_HEAD_APPROVED
                  ? 'Approved'
                  : rowData.is_approved === CAT_HEAD_REJECTED
                  ? 'Rejected'
                  : 'Not yet Approved'}
              </Typography>
            </>
          );
        },
        filterVariant: 'select',
        filterFn: 'equals',
        filterSelectOptions: [
          {
            value: '0',
            text: 'Not Yet Approved',
          },
          {
            value: 1,
            text: 'Approved',
          },
          {
            value: 2,
            text: 'Rejected',
          },
        ],
        size: 250,
      },
      // Agreement status
      {
        accessorKey: 'updated_at',
        header: 'Agreement Status',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          // console.log(rowData, 'mmmkkkllll');
          return (
            <>
              {Number(mouData.userDetails.cat_head) === 1 ? (

                <>

                  {rowData.is_verified !== 1 ? (
                    <>
                      {/* NOT VERIFIED */}
                      <Button
                        variant="outlined"
                        startIcon={<WaitIcon />}
                        color="info"
                        sx={{ color: '#ccc', cursor: 'default' }}
                      >
                        Awaiting for verification
                      </Button>
                    </>
                  ) : rowData.is_approved === 0 ? (
                    <>
                      {/* FOR APPROVING */}
                      <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                        <Button
                        variant="outlined"
                        startIcon={<WaitIcon />}
                        color="info"
                        sx={{ color: '#ccc', cursor: 'default' }}
                      >
                        Awaiting for Approval
                      </Button>
                    
                      </Box>
                    </>
                  ) : (
                    <>
                    
                      {rowData.is_mou_accept === 0 ? (

                        <>
                          <Button
                            variant="outlined"
                            startIcon={<Completed />}
                            color="info"
                            sx={{ color: '#339af0', cursor: 'default', marginRight: '10px' }}
                          >
                            MOU Generated
                          </Button>
                        </>
                      ) : (

                        <>
                           {rowData.mou_document ===null ? 
                               <> 
                               {/* <Box sx={{ textAlign: 'center' }}>
                                 <form>
                                   <Stack rowGap={1} columnGap={1}>
                                     <FileInputBRH
                                       buttonText={file ? file.name : 'Upload Signed Contract'}
                                       handleFileUpload={(e)=> handleFileUpload(e, rowData.id)}
                                       color="primary"
                                     />
                                     <Button
                                       // type="submit"
                                       variant="outlined"
                                       sx={{ marginLeft: '8px' }}
                                       onClick={(e) => handleSubmit(e, rowData.id, file)}
                                     >
                                       Upload ddd
                                     </Button>
                                   </Stack>
                                 </form>
                               </Box> */}

<Button
                            variant="outlined"
                            startIcon={<Completed />}
                            color="info"
                            sx={{ color: '#339af0', cursor: 'default', marginRight: '10px' }}
                          >
                            MOU Generated
                          </Button>
                               
                     </> 
                     :
                        <Button
                          variant="outlined"
                          startIcon={<Completed />}
                          color="info"
                          sx={{ color: '#339af0', cursor: 'default' }}
                        >
                          Moved to Contract
                        </Button>
        }
                        </>
                      )}
                      {/* AFTER APPROVED */}
                    </>
                  )}
                  {/* {rowData.is_approved === 0 ? (
                    <>
                      <Box>
                        <Button
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_REJECTED, 0)}
                          onClick={(e) => {
                            handleVerifyApply(mouData.userDetails.cat_lead, 0, rowData.id);
                          }}
                          variant="contained"
                          sx={{ marginRight: '5px', color: '#fff' }}
                          startIcon={<RejectIcon />}
                          color="warning"
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_APPROVED, 0)}
                          onClick={(e) => {
                            handleVerifyApply(mouData.userDetails.cat_lead, 1, rowData.id);
                          }}
                          startIcon={<VerifyIcon />}
                          color="success"
                          sx={{ color: '#fff' }}
                        >
                          Approve
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        startIcon={<Completed />}
                        color="info"
                        sx={{ color: '#339af0', cursor: 'default' }}
                      >
                        completed
                      </Button>
                    </>
                  )} */}
                </>
              ) : Number(mouData.userDetails.cat_lead) === 1 ? (
                <>
                  {/* LOGIC FOR CAT LEAD ACTION */}
                  {rowData.is_verified === 0 ? (
                    <>
                      <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                        {/* <Button
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_REJECTED)}
                          onClick={() => {
                            handleVerifyApply(
                              mouData.userDetails.cat_lead,
                              0,
                              rowData.id,
                              rowData.contract_id,
                              0,

                              rowData
                            );
                          }}
                          variant="contained"
                          sx={{ marginRight: '5px', color: '#fff' }}
                          startIcon={<RejectIcon />}
                          color="warning"
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_VERFIED)}
                          onClick={(e) => {
                            handleVerifyApply(
                              mouData.userDetails.cat_lead,
                              1,
                              rowData.id,
                              rowData.contract_id,
                              rowData.is_mou_accept,

                              rowData
                            );
                          }}
                          startIcon={<VerifyIcon />}
                          color="success"
                          sx={{ color: '#fff' }}
                        >
                          Verify
                        </Button> */}
                         <Button
                        variant="outlined"
                        startIcon={<WaitIcon />}
                        color="info"
                        sx={{ color: '#ccc', cursor: 'default' }}
                      >
                        Awaiting for verification
                      </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {rowData.is_mou_accept === 0 ? (
                        <>
                          <Button
                            variant="outlined"
                            startIcon={<Completed />}
                            color="info"
                            sx={{ color: '#339af0', cursor: 'default', marginRight: '10px' }}
                          >
                            Completed
                          </Button>
                        </>
                      ) : (
                        <>
                          {rowData.is_mou_accept === 1 && (
                            <Button
                              variant="outlined"
                              startIcon={<Completed />}
                              color="info"
                              sx={{ color: '#339af0', cursor: 'default' }}
                            >
                              Moved to Contract
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>{/* AFTER APPROVED AND VERFIED */}</>
              )}
            </>
          );
        },
        enableColumnFilter: false,
        size: 250,
      },

      // Action 
      {
        accessorKey: 'updated_at',
        header: 'Action',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          console.log(rowData, 'mmmkkkllll');
          return (
            <>
              {Number(mouData.userDetails.cat_head) === 1 ? (
                <>
            
                <>
                  {/* LOGIC FOR CAT HEAD ACTION */}
                  {rowData.is_verified !== 1 ? (
                    <>
                      {/* NOT VERIFIED */}
                      {/* <Button
                        variant="outlined"
                        startIcon={<WaitIcon />}
                        color="info"
                        sx={{ color: '#ccc', cursor: 'default' }}
                      >
                        Awaiting for verification
                      </Button> */}
                      <> </>
                    </>
                  ) : rowData.is_approved === 0 ? (
                    <>
                      {/* FOR APPROVING */}

                      <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                        <Button
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_REJECTED, 0)}
                          onClick={() => {
                            handleVerifyApply(
                              mouData.userDetails.cat_lead,
                              0,
                              rowData.id,
                              rowData.contract_id,
                              0,

                              rowData
                            );
                          }}
                          variant="contained"
                          sx={{ marginRight: '5px', color: '#fff' }}
                          startIcon={<RejectIcon />}
                          color="warning"
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_APPROVED, 0)}
                          onClick={(e) => {
                            handleVerifyApply(
                              mouData.userDetails.cat_lead,
                              1,
                              rowData.id,
                              rowData.contract_id,
                              0,
                              rowData
                            );
                          }}
                          startIcon={<VerifyIcon />}
                          color="success"
                          sx={{ color: '#fff' }}
                        >
                          Approve
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {rowData.is_mou_accept === 0 ? (
                        <>
                         {rowData?.mou_document ===null ? 
                               <> 
                               <Box sx={{ textAlign: 'center' }}>
                                 <form>
                                   <Stack rowGap={1} columnGap={1}>
                                     <FileInputBRH
                                       buttonText={file ? file.name : 'Upload Signed MOU'}
                                       handleFileUpload={(e)=> handleFileUpload(e, rowData.id)}
                                       color="primary"
                                     />
                                     <Button
                                       // type="submit"
                                       variant="outlined"
                                       sx={{ marginLeft: '8px' }}
                                       onClick={(e) => handleSubmit(e, rowData.id, file)}
                                     >
                                       Upload 
                                     </Button>
                                   </Stack>
                                 </form>
                               </Box>
                     </> 
                     :
                        
                          <Button
                            //
                            onClick={(e) => {
                              handleVerifyApply(
                                mouData.userDetails.cat_lead,
                                1,
                                rowData.id,
                                rowData.contract_id,
                                1,
                                rowData
                              );
                            }}
                            variant="contained"
                            color="success"
                            sx={{ color: '#fff', cursor: 'default' }}
                          >
                            Generate Contract
                          </Button>

                    }
                      
                   
                        </>
                      ) : (
                     
                        <> 
                              <> 
                     </> 
                        
                        </>
                      )}
                      {/* AFTER APPROVED */}
                    </>
                  )}
                  {/* {rowData.is_approved === 0 ? (
                    <>
                      <Box>
                        <Button
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_REJECTED, 0)}
                          onClick={(e) => {
                            handleVerifyApply(mouData.userDetails.cat_lead, 0, rowData.id);
                          }}
                          variant="contained"
                          sx={{ marginRight: '5px', color: '#fff' }}
                          startIcon={<RejectIcon />}
                          color="warning"
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_APPROVED, 0)}
                          onClick={(e) => {
                            handleVerifyApply(mouData.userDetails.cat_lead, 1, rowData.id);
                          }}
                          startIcon={<VerifyIcon />}
                          color="success"
                          sx={{ color: '#fff' }}
                        >
                          Approve
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        startIcon={<Completed />}
                        color="info"
                        sx={{ color: '#339af0', cursor: 'default' }}
                      >
                        completed
                      </Button>
                    </>
                  )} */}
                </>
                
                </>
              ) : Number(mouData.userDetails.cat_lead) === 1 ? (
                <>
                  {/* LOGIC FOR CAT LEAD ACTION */}
                  {rowData.is_verified === 0 ? (
                    <>
                      <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                        <Button
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_REJECTED)}
                          onClick={() => {
                            handleVerifyApply(
                              mouData.userDetails.cat_lead,
                              0,
                              rowData.id,
                              rowData.contract_id,
                              0,

                              rowData
                            );
                          }}
                          variant="contained"
                          sx={{ marginRight: '5px', color: '#fff' }}
                          startIcon={<RejectIcon />}
                          color="warning"
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_VERFIED)}
                          onClick={(e) => {
                            handleVerifyApply(
                              mouData.userDetails.cat_lead,
                              1,
                              rowData.id,
                              rowData.contract_id,
                              rowData.is_mou_accept,

                              rowData
                            );
                          }}
                          startIcon={<VerifyIcon />}
                          color="success"
                          sx={{ color: '#fff' }}
                        >
                          Verify
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {rowData.is_mou_accept === 0 ? (
                        <>
                          {/* <Button
                            variant="outlined"
                            startIcon={<Completed />}
                            color="info"
                            sx={{ color: '#339af0', cursor: 'default', marginRight: '10px' }}
                          >
                            Completed
                          </Button> */}

                        </>
                      ) : (
                        <>
                          {rowData.is_mou_accept === 1 && (
                            // <Button
                            //   variant="outlined"
                            //   startIcon={<Completed />}
                            //   color="info"
                            //   sx={{ color: '#339af0', cursor: 'default' }}
                            // >
                            //   Moved to Contract
                            // </Button>
                            <> </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>{/* AFTER APPROVED AND VERFIED */}</>
              )}
            </>
          );
        },
        enableColumnFilter: false,
        size: 250,
      },
    ],
    [mouData, pdfViewedStatus]
  );
  useEffect(() => {
    const user = localStorage.getItem('user');
    const userData = JSON.parse(user);
    setMouData({
      ...mouData,
      userDetails: userData,
      isFetched: false,
    });
    API.getData(MOULIST)
      .then((resMou) => {
        if (resMou?.data?.status === 'success') {
          const dataMou = resMou?.data?.results;
          // AVOID DIRECT CONTRACTS
          const filteredMOU = dataMou.filter((mou) => mou.direct_contract !== 1);
          const sortedMOU = filteredMOU.toReversed();
          setMouData({
            ...mouData,
            isFetched: true,
            mouListData: sortedMOU,
            userDetails: userData,
          });
        }
      })
      .catch((errMou) => {
        console.log('API-MOULIST', errMou);
        setMouData({
          ...mouData,
          isFetched: true,
          // userDetails: userData,
        });
      });
  }, [key]);

  return (
    <>
      {mouData.isFetched === true ? (
        <>
          <Helmet>
            <title> MOU | BRH </title>
          </Helmet>
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                MOU
              </Typography>
            </Stack>

            <Card sx={{ p: 2 }}>
              <NormalTable
                columns={columns}
                data={mouData.mouListData}
                initialState={{
                  columnVisibility: {
                    updated_at:
                      Number(mouData.userDetails.cat_head) === 1 || Number(mouData.userDetails.cat_lead) === 1,
                  },
                  showGlobalFilter: false,
                  density: 'compact',
                }}
              />
            </Card>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={snackbarOpen}
              autoHideDuration={3000}
            >
              <Alert sx={{ width: '100%' }} severity="warning">
                {/* onClose={handleSnackbar}  */}
                <Stack rowGap="10px" direction="row" columnGap="10px">
                  <Box>
                    <Typography variant="button"> You need to view the file before approve or to verify</Typography>
                  </Box>
                  <Box>
                    <Button onClick={handleSnackbar} variant="contained" size="small">
                      view mou
                    </Button>
                  </Box>
                </Stack>
              </Alert>
            </Snackbar>
            <MouAlert
              open={open}
              setOpen={setOpen}
              isLead={isLead}
              isOk={isOkay}
              id={popupId}
              MOUACCEPT={conmouaccept}
              contractId={contractId}
              onSetKey={setKey}
            />
          </Container>
        </>
      ) : (
        <>
          <TableSkeleton />
        </>
      )}
    </>
  );
};
