import { FormControl, TextField } from '@mui/material';
import React from 'react';
import palette from '../../theme/palette';

export const NumberInputBRH = ({
  name,
  id,
  label,
  value,
  handleChange,
  sx,
  required,
  InputProps,
  size,
  readOnly = false,
  key,
  isAutoPopulate = 0,
  minValue = 0,
}) => (
  <FormControl fullWidth sx={{ backgroundColor: isAutoPopulate ? palette.secondary.lighter : 'inherit' }}>
    {/*  sx={{ backgroundColor: isAutoPopulate ? palette.secondary.lighter : 'inherit' }}  */}
    <TextField
      id={id}
      key={key ?? label}
      label={label}
      name={name}
      value={value}
      onChange={handleChange}
      required={required}
      sx={{ ...sx, backgroundColor: readOnly ? '#f8f9fa' : 'inherit' }}
      InputProps={InputProps}
      // InputProps={{ inputProps: { min: minValue } }}
      variant="outlined"
      type="number"
      size={size}
      inputProps={{ readOnly, min: minValue }}
      InputLabelProps={{ shrink: value >= 0 }}
      //  InputLabelProps={{ shrink: !!value }}
    />
  </FormControl>
);
