import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import SignedContractIcon from '@mui/icons-material/AssignmentTurnedIn';
import EditIcon from '@mui/icons-material/Create';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CompletedIcon from '@mui/icons-material/ThumbUpAlt';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
import { NormalTable } from '../../components/tables/NormalTable';
import { TextFieldSelectBRH } from '../../components/forms/TextFieldSelectBRH';
import { LoadingButtonBRH } from '../../components/forms/LoadingButtonBRH';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { EDITCRS } from '../../API_SERVICE_LAYER/ApiNames';
import { ToastBRH } from '../../components/toasts/ToastBRH';
import { TOAST_SUCCESS } from '../../utils/toastTimings';
import { TextInputBRH } from '../../components/forms/TextInputBRH';

const STATUS_SELECT = 0;
const STATUS_CONTACTED_CLIENT = 1;
const STATUS_AWAITING_CLIENT_STATUS = 2;
const STATUS_RENEWAL_CONTRACT = 3;
const STATUS_RENEWAL_CANCELLED = 4;
const STATUS_RENEWAL_COMPLETED = 5;

const statusOptions = [
  {
    label: 'Select',
    value: STATUS_SELECT,
  },
  {
    label: 'Renew Contract',
    value: STATUS_RENEWAL_CONTRACT,
  },
  {
    label: 'Renewal Sent to Customer',
    value: STATUS_CONTACTED_CLIENT,
  },
  {
    label: 'Awaiting Customer Status',
    value: STATUS_AWAITING_CLIENT_STATUS,
  },
  {
    label: 'Renewal Cancelled',
    value: STATUS_RENEWAL_CANCELLED,
  },
];

export default function CancelledContracts() {
  const navigate = useNavigate();
  const User = JSON.parse(localStorage.getItem('user'));
  const contracts = useSelector((state) => state.contractsList.contracts);
  const CCexpiringContracts = useSelector((state) => state.contractsList);
  const expiringContracts = useSelector((state) => state.contractsList.contracts);
  const [renewalState, setRenewalState] = useState(() =>
    expiringContracts?.map((contract) => ({
      id: contract.id,
      status: contract.renewal_status,
      reason: null,
      isLoading: false,
    }))
  );
  const [renewalContractsState, setRenewalContractsState] = useState({
    openContract: false,
    contractId: null,
  });

  console.log(CCexpiringContracts,"CCexpiringContracts");
  function handleOpen(row) {
    return setRenewalContractsState((prevData) => ({ ...prevData, openContract: true, contractId: row.id }));
  }
  function handleReset() {
    setRenewalState(
      expiringContracts.map((contract) => ({
        id: contract.id,
        status: contract.renewal_status,
        reason: null,
        isLoading: false,
      }))
    );
    return setRenewalContractsState({ openContract: false, contractId: null });
  }
  const columns = useMemo(
    () => [
    //   {
    //     accessorKey: 'id',
    //     header: 'Contract Type',
    //     size: 50,
    //     Cell: ({ cell }) => {
    //       const rowData = cell?.row?.original;
    //       const tableRenewalStatus = renewalState.find((contract) => contract.id === rowData.id)?.status;
    //       const isDisabled =
    //         tableRenewalStatus === STATUS_RENEWAL_COMPLETED || tableRenewalStatus === STATUS_RENEWAL_CANCELLED;
    //       return (
    //         <>
    //         {
    //           rowData.renewal_status!==4 ?
    //           <Box sx={{ textAlign: 'center' }}>
              
    //           <Tooltip title="Update Renewal Status">
    //             <IconButton onClick={() => handleOpen(cell.row.original)} >
    //               <EditIcon color={isDisabled ? 'disabled' : 'info'} />
    //             </IconButton>
    //           </Tooltip>
    //         </Box>
               
    //            :
    //           " "
    //         }  
    //           </>
        
    //       );
    //     },
    //   },
      {
        // accessorKey: 'id',

        accessorKey: 'contract_client.company_name',
        header: 'Client Name',
        // size: 150,
        filterVariant: 'text',
        enableColumnFilter: true,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <Button
              onClick={() =>
                navigate(`${routeNames.CLIENTS}/edit/${rowData.contract_client_id}`, {
                  state: { isUpdated: true, updatedId: rowData?.contract_client_id },
                })
              }
            >
              {cell.getValue()}
            </Button>
          );
        },
      },
      {
        accessorKey: 'propertyName',
        header: 'Property Name',
        // size: 150,
        filterVariant: 'text',
        enableColumnFilter: true,
        Cell: ({ cell }) => {
            const rowData = cell?.row?.original;
            return (
                <span> {rowData?.propertyData?.property_name} </span>
            )
        },
      },
      {
        accessorKey: 'agreement_start_date',
        header: 'Start Date',
        // size: 150,
        enableColumnFilter: false,
        Cell: ({ cell }) => <span>{moment(cell.getValue()).format('DD - MMM - YYYY')}</span>,
      },
      {
        accessorKey: 'agreement_end_date',
        header: 'End Date',
        // size: 150,
        enableColumnFilter: false,
        Cell: ({ cell }) => <span>{moment(cell.getValue()).format('DD - MMM - YYYY')}</span>,
      },
      {
        accessorKey: 'renewal_status',
        header: 'Renewal Status',
        // size: 150,
        Cell: ({ cell }) => {
          const renewalStatus = cell.getValue();
          const renewalStatusLabel = statusOptions.find((opt) => opt.value === renewalStatus)?.label;
          const rowData = cell?.row?.original;
          console.log('laksh-status', rowData);
          const tableRenewalStatus = renewalState.find((contract) => contract.id === rowData.id)?.status;
          const isDisabled = tableRenewalStatus !== STATUS_RENEWAL_CONTRACT;
          if (tableRenewalStatus === STATUS_RENEWAL_COMPLETED)
            return (
              <Box sx={{ padding: '8px 16px', backgroundColor: '#e0f2f1', borderRadius: '3px' }}>
                <CompletedIcon color="success" sx={{ marginRight: '8px' }} />
                <Typography variant="overline" sx={{ color: '#004d40' }}>
                  Contract Renewed
                </Typography>
              </Box>
            );
          if (tableRenewalStatus === STATUS_RENEWAL_CANCELLED)
            return (
              <Tooltip title={rowData.renewal_reject_reasons}>
                <Typography>{renewalStatusLabel}</Typography>
              </Tooltip>
            );
          return (
            <Button
              color="secondary"
              variant={tableRenewalStatus !== STATUS_RENEWAL_CONTRACT ? 'text' : 'outlined'}
              disabled={isDisabled}
              onClick={() => navigate(`${routeNames.CRS}edit/${rowData.id}`, { state: { isRenewal: true } })}
            >
              {renewalStatus === 0 ? 'NA' : renewalStatusLabel}
            </Button>
          );
        },
      },
      {
        accessorKey: 'contract_mou',
        header: 'Contract File',
        // size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          const contractsList = rowData?.contract_mou;
          // getting last generated Contract
          console.log(rowData,"dddd");
          const updatedContract = contractsList[contractsList.length - 1]?.unsigned_document;
          return (
            <Box sx={{ textAlign: 'center' }}>
              <Tooltip title={`view Contract`}>
                <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${updatedContract}`}>
                  <SignedContractIcon color="success" />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    [renewalState]
  );

  const handleStatus = (e) => {
    e.preventDefault();
    const id = renewalContractsState.contractId;
    const isLoading = renewalState.find((contract) => contract.id === id)?.isLoading;
    const renewalStatus = renewalState.find((contract) => contract.id === id)?.status;
    const renewalReason = renewalState.find((contract) => contract.id === id)?.reason;
    const formData = {
      id: id,
      renewal_status: renewalStatus,
    };
    // console.log("myrene")
    if(renewalStatus===3) {
      // console.log("3yes")
      navigate(`${routeNames.TAKERENEWALCONTRACT}edit/${id}`);

    } else {
      // console.log('norenwal')
    if (renewalStatus === STATUS_RENEWAL_CANCELLED) formData.renewal_reject_reasons = renewalReason;
    // prevent selecting SELECT and avoid multiple API calls
    if (renewalStatus < 1 || isLoading) return;
    setRenewalState((prevData) => prevData.map((value) => (value.id === id ? { ...value, isLoading: true } : value)));
    API.updateMethod(EDITCRS, formData)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          ToastBRH('success', 'Renewal Status Updated Successfully', TOAST_SUCCESS);
          setTimeout(() => navigate(routeNames.INDEX), TOAST_SUCCESS);
        }
      })
      .catch((err) => console.error(err));
    console.log(formData);
    }
  };

  // console.log(expiringContracts.filter(contract => contract.is_renewal !== 1),"nmk")

  useEffect(() => {
    if (!contracts || contracts.length === 0) {
      navigate(routeNames.INDEX);
    }
  }, [contracts, routeNames.INDEX]);
  return (
    <>
      <Helmet>
        <title>Contracts | BRH</title>
      </Helmet>
      <Container>
        <Breadcrumbs aria-label="breadcrumb">
          <Button onClick={() => navigate(routeNames.INDEX)} color="secondary">
            Dashboard
          </Button>
          <Button onClick={() => navigate(routeNames.CONTRACT)} color="secondary">
            Contracts
          </Button>
          <Typography color="primary" variant="button">
            Cancelled Contracts
          </Typography>
        </Breadcrumbs>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Box>
            <Typography variant="h4" gutterBottom>
              Cancelled Contracts
            </Typography>
          </Box>
        </Stack>
        <Card sx={{ p: 2 }}>
          <NormalTable
            data={expiringContracts.filter(contract => (contract.renewal_status === 4   ) )}
            columns={columns}
            key="renewalContract"
            initialState={{
              columnVisibility: {
                id: Number(User.cat_head) === 1 || Number(User.cat_lead) === 1,
              },
              showGlobalFilter: false,
              density: 'compact',
            }}
          />
        </Card>
        <Dialog open={renewalContractsState.openContract}>
          <form onSubmit={handleStatus}>
            <DialogTitle>Renewal Status</DialogTitle>
            <DialogContent sx={{ minWidth: '500px', margin: '32px 0px' }}>
              <Box sx={{ paddingTop: '8px' }}>
                <TextFieldSelectBRH
                  label="Approved Status"
                  name="approvedStatus"
                  options={statusOptions}
                  value={renewalState?.find((contract) => contract.id === renewalContractsState.contractId)?.status}
                  handleChange={(e) => {
                    console.log('laks-select', e);
                    setRenewalState((prevData) =>
                      prevData.map((value) =>
                        value.id === renewalContractsState.contractId ? { ...value, status: e.target.value } : value
                      )
                    );
                  }}
                  renderLabel="label"
                  renderValue="value"
                  required
                  size="small"
                />
                <TextInputBRH
                  label="Rejection Reason"
                  required={
                    renewalState?.find((contract) => contract.id === renewalContractsState.contractId)?.status ===
                    STATUS_RENEWAL_CANCELLED
                  }
                  value={renewalState?.find((contract) => contract.id === renewalContractsState.contractId)?.reason}
                  handleChange={(e) => {
                    setRenewalState((prevData) =>
                      prevData.map((value) =>
                        value.id === renewalContractsState.contractId ? { ...value, reason: e.target.value } : value
                      )
                    );
                  }}
                  sx={{
                    marginTop: '16px',
                    display:
                      renewalState?.find((contract) => contract.id === renewalContractsState.contractId)?.status ===
                      STATUS_RENEWAL_CANCELLED
                        ? 'block'
                        : 'none',
                  }}
                  size="small"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleReset()} variant="outlined" color="secondary">
                Cancel
              </Button>
              <LoadingButtonBRH
                type="submit"
                isLoading={
                  renewalState?.find((contract) => contract.id === renewalContractsState.contractId)?.isLoading
                }
                label="Update Status"
                handleSubmit={() => handleStatus(renewalContractsState.contractId)}
                size="small"
              />
            </DialogActions>
          </form>
        </Dialog>
      </Container>
    </>
  );
}
