import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Dialog,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { Navigate, useNavigate } from 'react-router-dom';
import { NormalTable } from '../../../components/tables/NormalTable';
import { TableSkeleton } from '../../../components/skeleton/TableSkeleton';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Iconify from '../../../components/iconify/Iconify';
import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';
import { contractContext } from '../../../components/context/AppContext';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';

const PropertyAgreementsList = () => {
  const navigate = useNavigate();
  const redirectTo = () => {
    navigate('/masters/property-agreements/add');
  };

  const data = [
    {
      name: {
        firstName: 'John',
        lastName: 'Doe',
      },
      address: '261 Erdman Ford',
      city: 'East Daphne',
      state: 'Kentucky',
    },
    {
      name: {
        firstName: 'Jane',
        lastName: 'Doe',
      },
      address: '769 Dominic Grove',
      city: 'Columbus',
      state: 'Ohio',
    },
    {
      name: {
        firstName: 'Joe',
        lastName: 'Doe',
      },
      address: '566 Brakus Inlet',
      city: 'South Linda',
      state: 'West Virginia',
    },
    {
      name: {
        firstName: 'Kevin',
        lastName: 'Vandy',
      },
      address: '722 Emie Stream',
      city: 'Lincoln',
      state: 'Nebraska',
    },
  ];
  const columns = useMemo(() => [
    {
      accessorKey: 'company_name',
      header: 'Company Name',
      size: 250,
    },
    {
      accessorKey: 'company_address',
      header: 'Name of Landlord',
      size: 250,
    },
    {
      accessorKey: 'date_agreement',
      header: 'Date of Agreement',
      size: 250,
    },
    {
      accessorKey: 'land_lord',
      header: 'Landlord Address',
      size: 250,
    },
    {
      accessorKey: 'company_address',
      header: 'Company Address',
      size: 250,
    },
  ]);
  return (
    <>
      <Helmet>
        <title> Property Agreement | BRH</title>
      </Helmet>
      <Container>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/dashboard">
            Dashboard
          </Link>
          <Link underline="hover" color="inherit" href="/masters/property-agreements">
            Masters
          </Link>
          <Typography color="text.primary">Property Agreement</Typography>
        </Breadcrumbs>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Property Agreements
          </Typography>
          <Button onClick={redirectTo} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Property Agreements
          </Button>
        </Stack>
        <Card sx={{ p: 2 }}>
          <NormalTable columns={columns} data={data} />
        </Card>
      </Container>
    </>
  );
};

export default PropertyAgreementsList;
