import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import './App.css';
import AppContext from './components/context/AppContext';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ThemeProvider>
          <ScrollToTop />
          <AppContext>
            <Router />
            {/* <StyledChart /> */}
          </AppContext>
        </ThemeProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}
