import React, { useEffect, useState } from 'react';
import PhoneInput from 'material-ui-phone-number';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
} from '@mui/material';
import { setSelectedTabRedux } from '../../../Redux/actions/TabsActions';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import TextDivider from '../../../components/text-divider/TextDivider';
import { CITYLIST, COUNTRYLIST, STATELIST } from '../../../API_SERVICE_LAYER/ApiNames';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { ToastBRH } from '../../../components/toasts/ToastBRH';
import { GmailInputBRH } from '../../../components/forms/GmailInputBRH';
import { MoneyInputBRH } from '../../../components/forms/MoneyInputBRH';
import { TextFieldSelectBRH } from '../../../components/forms/TextFieldSelectBRH';

const modelOfAssociation = [
  { id: 1, name: 'Have a furnished place', value: 'Have a furnished place' },
  { id: 2, name: 'Ready to build', value: 'Ready to build' },
  { id: 3, name: 'Rent the property', value: 'Rent the property' },
];
const propertyTypes = [
  { id: 1, name: 'Bareshell/Warmshell', value: 'Bareshell/Warmshell' },
  { id: 2, name: 'Furnished', value: 'Furnished' },
  { id: 3, name: 'Coworking Centre', value: 'Coworking Centre' },
  { id: 4, name: 'Cafe', value: 'Cafe' },
  { id: 5, name: 'Training Centre', value: 'Training Centre' },
  { id: 6, name: 'Other business', value: 'Other business' },
];
const Locality = [
  { id: 1, Locality: 'Residential', value: 'Residential' },
  { id: 2, Locality: 'Commercial', value: 'Commercial' },
];
const BuildingStatus = [
  { id: 1, Building_Status: 'Operational', value: 'Operational' },
  { id: 2, Building_Status: 'WIP', value: 'WIP' },
];
const BuildingClassification = [
  { id: 1, Building_Classification: 'A Grade', value: 'A Grade' },
  { id: 2, Building_Classification: 'B Grade', value: 'B Grade' },
  { id: 3, Building_Classification: 'C Grade', value: 'C Grade' },
];
const FlooringType = [
  { id: 1, Type_of_flooring: 'Operational', value: 'Operational' },
  { id: 2, Type_of_flooring: 'WIP', value: 'WIP' },
];
const WashArea = [
  { id: 1, washArea: 'washroom', value: 'Washrooms' },
  { id: 2, washArea: 'toilets', value: 'Toilets' },
  { id: 3, washArea: 'urinals', value: 'Urinals' },
];

const PropertyDetails = () => {
  const [expanded, setExpanded] = React.useState('proposedCenter');
  const [formData, setformData] = useState({
    modelOfAssociationObj: null,
    propertyTypeObject: null,
    LocalityObj: null,
    BuildingStatusObj: null,
    BuildingGradeObj: null,
    FloorTypeObj: null,
    WashArea: WashArea.map((Area) => ({
      washArea: Area.value,
      count: 0,
      status: 0, // Initialize all statuses as 0 (unchecked)
    })),
    countryList: [],
    countryObject: null,
    country_id: 101,

    stateList: [],
    stateObject: null,
    state_id: '',
    isSelectDataloadedState: true,

    cityList: [],
    cityObject: null,
    city_id: '',

    propertyName: '',
    property_code: '',
    address1: '',
    address2: '',
    contact_person: '',
    email: '',
    phonenumber: '',
    Landmarks: '',
    builtUpArea: '',
    carpetArea: '',
    floorNum: '',
    heightOfCeiling: '',
    AMOUNTforPaidparking: '',
  });
  const [parkingDetails, setParkingDetails] = useState({
    typeOfParkingObj: null,
    BikeParkingDetails: { Free_parking: '', Paid_parking: '', Amount: '' },
    CarParkingDetails: { Free_parking: '', Paid_parking: '', Amount: '' },
  });
  const handleChangeParking = (type, e) => {
    const { name, value } = e.target;
    const parkingType = type.includes('Bike') ? 'BikeParkingDetails' : 'CarParkingDetails';

    setParkingDetails((prevState) => ({
      ...prevState,
      [parkingType]: {
        ...prevState[parkingType],
        [name]: value,
      },
    }));
  };
  const handleParkingAreaChange = (event) => {
    const selectedValues = event.target.value;
    console.log('selec', selectedValues, event);
    setParkingDetails((prevDetails) => ({
      ...prevDetails,
      typeOfParkingObj: selectedValues,
    }));
  };

  const [state, setState] = React.useState({
    bikeParking: false,
    CarParking: false,
  });

  const handleChangeCheckbox = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });

    const updatedFormData = { ...formData };

    if (event.target.name === 'bikeParking' && event.target.checked) {
      updatedFormData.two_Wheeler_parking = '';
    } else if (event.target.name === 'bikeParking' && !event.target.checked) {
      delete updatedFormData.two_Wheeler_parking;
    }

    if (event.target.name === 'CarParking' && event.target.checked) {
      updatedFormData.Car_Parking = '';
    } else if (event.target.name === 'CarParking' && !event.target.checked) {
      delete updatedFormData.Car_Parking;
    }
    setformData(updatedFormData);
  };

  const { bikeParking, CarParking } = state;

  const handleModelOfAssociation = (e, newValue) => {
    setformData((prev) => ({ ...prev, modelOfAssociationObj: newValue }));
  };
  const handlePropertyTypeChange = (e, newValue) => {
    setformData((prev) => ({ ...prev, propertyTypeObject: newValue }));
  };
  const handleLocality = (e, newValue) => {
    setformData((prev) => ({ ...prev, LocalityObj: newValue }));
  };
  const handleBuildingStatus = (e, newValue) => {
    setformData((prev) => ({ ...prev, BuildingStatusObj: newValue }));
  };
  const handlebuildingGradeChange = (e, newValue) => {
    setformData((prev) => ({ ...prev, BuildingGradeObj: newValue }));
  };
  const handleFloorTypeChange = (e, newValue) => {
    setformData((prev) => ({ ...prev, FloorTypeObj: newValue }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setformData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleChangeWashArea = (value, e) => {
    setformData((prevData) => ({
      ...prevData,
      WashArea: prevData.WashArea.map((item) =>
        item.washArea === value ? { ...item, status: e.target.checked ? 1 : 0 } : item
      ),
    }));
  };
  const handleWashAreaCountChange = (washArea, e) => {
    console.log(e.target.value);
    const updatedFormData = { ...formData };
    const washAreaItem = updatedFormData.WashArea.find((item) => item.washArea === washArea);

    if (washAreaItem) {
      washAreaItem.count = parseInt(e.target.value, 10);
      setformData(updatedFormData);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { BikeParkingDetails, CarParkingDetails } = parkingDetails;
    if (BikeParkingDetails.Paid_parking !== '' && BikeParkingDetails.Amount === '') {
      ToastBRH('warning', 'Incomplete fields ', '___', false);
      return;
    }
    if (CarParkingDetails.Paid_parking !== '' && CarParkingDetails.Amount === '') {
      ToastBRH('warning', 'Incomplete fields ', '___', false);
      return;
    }
    console.log('submitted', formData, 'Parking', parkingDetails);
    // setSelectedTabRedux('propertyCommercials');
    // localStorage.setItem('formData', JSON.stringify(formData));
  };
  useEffect(() => {
    API.getData(`${COUNTRYLIST}`)
      .then((resCountry) => {
        if (resCountry?.data?.status === true) {
          const apiCountryData = resCountry?.data?.data;
          API.getData(`${STATELIST}/${formData.country_id}`)
            .then((resultState) => {
              if (resultState?.data?.status === true) {
                const dataState = resultState?.data?.data;
                setformData((prev) => ({
                  ...prev,
                  countryList: apiCountryData,
                  countryObject: apiCountryData.find((country) => country.id === 101),
                  stateList: dataState,
                  state_id: dataState.length > 0 ? dataState[0].id : null,
                }));
              }
            })
            .catch((errState) => {
              console.log('STATELIST', errState);
              setformData({
                ...formData,
                countryList: apiCountryData,
                countryObject: apiCountryData[0],
              });
            });
        }
      })
      .catch((errCountry) => {
        console.log('COUNTRYLIST', errCountry);
      });
  }, []);
  const handleCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setformData({
              ...formData,
              country_id: id,
              countryObject: newValue,
              stateList: res.data.data,
              stateObject: null,
              cityList: [],
              cityObject: null,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setformData({
            ...formData,
            country_id: id,
            stateList: [],
          });
        });
    }
  };
  const handleStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    const data = { state_id: id };

    if (name === 'name') {
      // MAKE LOADER ONLY WHEN VALUE IS THERE
      setformData({ ...formData, isSelectDataloadedState: false });
      // GET DETAILS HERE BY SEQUENTIALLY EVENTHOUGH IT IS INDEPENDEDNT
      // CALL CITY API ACCORDING TO STATE
      API.getData(`${CITYLIST}/${id}`)
        .then((resCity) => {
          if (resCity.data.status) {
            console.log('API-CITYLIST');

            // GET PROPERTY CODE FROM API FOR SPECIFIC STATE
            console.log('------', resCity.data.data);
            API.postMethod('/api/propertycode', data).then((res) => {
              if (res.data.status !== 'success') {
                const laksh = `${newValue.iso2}001`;
                setformData({
                  ...formData,
                  isSelectDataloadedState: true,
                  stateObject: newValue,
                  state_id: id,
                  property_code: laksh,
                  cityList: resCity.data.data,
                  cityObject: null,
                });
              } else {
                const code = res.data.data.property_code;
                let propertycodes = parseInt(code.match(/\d+/g)[0], 10) + 1;
                propertycodes =
                  propertycodes > 99
                    ? `${propertycodes}`
                    : propertycodes > 9
                    ? `0${propertycodes}`
                    : `00${propertycodes}`;

                const laksh = `${code.match(/[a-zA-Z]+/g)[0]}${propertycodes}`;
                setformData({
                  ...formData,
                  isSelectDataloadedState: true,
                  stateObject: newValue,
                  state_id: id,
                  property_code: laksh,
                  cityList: resCity.data.data,
                  cityObject: null,
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log('CITYLIST', err);
          ToastBRH('error', `${err?.response?.data?.message}`, '_', false);
          setformData({ ...formData, isSelectDataloaded: true, cityList: [], state_id: id });
        });
    }
  };
  console.log(formData.isSelectDataloadedState);
  const handleCityChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setformData({ ...formData, city_id: id, cityObject: newValue });
    }
  };

  return (
    <>
      <form noValidate={false} onSubmit={handleSubmit}>
        <FormControl sx={{ flexGrow: 1, width: '100%' }} sub="true">
          <Grid item sm={12} md={12}>
            <TextDivider>Proposed Centre Basic Data</TextDivider>
          </Grid>
          <Grid container rowSpacing={2.5} columnSpacing={2}>
            <Grid item sm={12} md={6}>
              <AutoCompleteInputBRH
                label="Model of Association"
                name="name"
                required
                value={formData.modelOfAssociationObj}
                handleChange={handleModelOfAssociation}
                options={modelOfAssociation}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AutoCompleteInputBRH
                label="Property Type"
                name="name"
                required
                value={formData.propertyTypeObject}
                handleChange={handlePropertyTypeChange}
                options={propertyTypes}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Building/Property Name"
                name="propertyName"
                value={formData.propertyName}
                handleChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Nearby Commercial Landmarks"
                name="Landmarks"
                required
                value={formData.Landmarks}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Address Line #1"
                name="address1"
                required
                value={formData.address1}
                handleChange={handleChange}
                //   required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Address Line #2"
                name="address2"
                value={formData.address2}
                handleChange={handleChange}
                //   required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Contact Person Name"
                name="contact_person"
                required
                value={formData.contact_person}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <GmailInputBRH
                label="Contact Person Email"
                name="email"
                required
                value={formData.email}
                handleChange={handleChange}
                // required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <PhoneInput
                defaultCountry={'in'}
                value={formData.phonenumber}
                onChange={(e) => setformData((prev) => ({ ...prev, phonenumber: e }))}
                name="phonenumber"
                label="Phone Number"
                variant="outlined"
                countryCodeEditable={false}
                required
                style={{
                  width: '100%',
                }}
                sx={{
                  '& label.Mui-focused': {
                    color: '#fc9200',
                  },
                  '& .MuiFormLabel-root': {
                    color: '#B9B9B9',
                  },
                  '& .MuiInput-input': {
                    color: 'black',
                    borderBottomColor: '#fc9200',
                  },
                  '& .MuiInput-underline:before': {
                    borderBottomColor: '#fc9200',
                  },
                  '& .MuiInput-underline:after': {
                    borderBottomColor: '#fc9200',
                  },
                  '& .MuiSelect-icon': {
                    color: '#fc9200',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AutoCompleteInputBRH
                label="Locality"
                name="Locality"
                value={formData.LocalityObj}
                handleChange={handleLocality}
                options={Locality}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <AutoCompleteInputBRH
                label="Country"
                name="name"
                required
                value={formData.countryObject}
                handleChange={handleCountryChange}
                options={formData.countryList}
                // isSelectDataloaded={formData.isSelectDataLoaded}
                // required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AutoCompleteInputBRH
                label="State"
                //  required
                name="name"
                value={formData.stateObject}
                handleChange={handleStateChange}
                options={formData.stateList}
                isSelectDataloaded={formData.isSelectDataloadedState}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AutoCompleteInputBRH
                label="City"
                name="name"
                //  required
                value={formData.cityObject}
                handleChange={handleCityChange}
                options={formData.cityList}
                required
              />{' '}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Property Code"
                name="property_code"
                required
                value={formData.property_code}
                handleChange={handleChange}
                //   required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Latitude"
                name="latitude"
                value={formData.latitude}
                handleChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Longitude"
                name="longitude"
                value={formData.longitude}
                handleChange={handleChange}
                required
              />
            </Grid>
          </Grid>
          {/* <Accordion
            expanded={expanded === 'proposedCenter'}
            onChange={handleChangeAccordian('proposedCenter')}
            sx={{ background: 'light', padding: '20px', marginTop: '20px' }}
          >
            <AccordionSummary
              sx={{ backgroundColor: '#f9fafb' }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="proposedCenterd-content"
              id="proposedCenterd-header"
            >
              <Typography sx={{ background: '#f9fafb', width: '100%' }}> Proposed Centre Premises Data</Typography>
            </AccordionSummary>
            <AccordionDetails> */}
          <Grid item sm={12} md={12}>
            <TextDivider>Proposed Centre Premises Data</TextDivider>
          </Grid>
          <Grid container rowSpacing={2.5} columnSpacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <AutoCompleteInputBRH
                label="Building Status"
                name="Building_Status"
                value={formData.BuildingStatusObj}
                handleChange={handleBuildingStatus}
                options={BuildingStatus}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <AutoCompleteInputBRH
                label="Building Classification"
                name="Building_Classification"
                value={formData.BuildingGradeObj}
                handleChange={handlebuildingGradeChange}
                options={BuildingClassification}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Built up Area"
                name="builtUpArea"
                required
                value={formData.builtUpArea}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextInputBRH
                label="Carpet Area"
                name="carpetArea"
                value={formData.carpetArea}
                handleChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <NumberInputBRH
                label="Floor Number"
                name="floorNum"
                required
                value={formData.floorNum}
                handleChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <AutoCompleteInputBRH
                label="Type of flooring"
                name="Type_of_flooring"
                value={formData.FloorTypeObj}
                handleChange={handleFloorTypeChange}
                options={FlooringType}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <NumberInputBRH
                label="Height of the Ceiling"
                name="heightOfCeiling"
                required
                value={formData.heightOfCeiling}
                handleChange={handleChange}
              />
            </Grid>

            <Grid
              container
              className="d-flex flex-row align-items-center  mt-3"
              xs={12}
              sm={12}
              md={5}
              style={{ marginLeft: '20px' }}
            >
              {/* <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Wash Area</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="WashArea"
                  value={formData.WashArea}
                  onChange={handleChange}
                  aria-required
                >
                  <FormControlLabel value="washrooms" control={<Radio />} label="Washrooms" />
                  <FormControlLabel value="toilets" control={<Radio />} label="Toilets" />
                  <FormControlLabel value="urinals" control={<Radio />} label="Urinals" />
                </RadioGroup>
              </FormControl> */}
              <FormControl>
                <FormLabel component="legend">Wash Area</FormLabel>
                <FormGroup style={{ marginTop: '-10px', width: '100%' }}>
                  {formData.WashArea?.map((Area) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '5px',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Area.status === 1}
                            onChange={(e) => handleChangeWashArea(Area.washArea, e)}
                            name={Area.washArea}
                            // aria-label={Area.washArea}
                          />
                        }
                        style={{ width: '250px' }}
                        label={Area.washArea}
                      />
                      {Area.status === 1 && (
                        <NumberInputBRH
                          sx={{ width: '200px' }}
                          label={`No.of ${Area.washArea} `}
                          name="two_Wheeler_parking"
                          value={Area.count === 0 ? null : Area.count}
                          handleChange={(value) => handleWashAreaCountChange(Area.washArea, value)}
                        />
                      )}
                    </div>
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12}>
            <TextDivider>Parking</TextDivider>
          </Grid>
          {/* <Grid container rowSpacing={2.5} columnSpacing={2}>
            {' '}
            <Grid item xs={12} sm={12} md={4}>
              <TextFieldSelectBRH
                label="Type of Parking area"
                name="parkingArea"
                value={parkingDetails.typeOfParkingObj}
                handleChange={(e) => handleParkingAreaChange(e)}
                options={[
                  { id: 'Basement', name: 'Basement' },
                  { id: 'Stilt', name: 'Stilt' },
                  { id: 'Open', name: 'Open' },
                ]}
                renderLabel="name"
                renderValue="id"
              />
            </Grid>
          </Grid> */}
          <Grid container rowSpacing={2.5} columnSpacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <TextFieldSelectBRH
                label="Type of Parking area"
                name="parkingArea"
                value={parkingDetails.typeOfParkingObj}
                handleChange={(e) => handleParkingAreaChange(e)}
                options={[
                  { id: 'Basement', name: 'Basement' },
                  { id: 'Stilt', name: 'Stilt' },
                  { id: 'Open', name: 'Open' },
                ]}
                renderLabel="name"
                renderValue="id"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="d-flex justify-content-center flex-column">
              <span style={{ fontSize: '20px', fontWeight: 500, letterSpacing: 1, marginBottom: '5px' }}>
                Bike Parking
              </span>

              <Grid container rowSpacing={2.5} columnSpacing={2}>
                <Grid item sm={12} md={5}>
                  <NumberInputBRH
                    label="Free Parking"
                    name="Free_parking"
                    value={parkingDetails.BikeParkingDetails.Free_parking}
                    handleChange={(e) => handleChangeParking('Bike', e)}
                  />
                </Grid>

                <Grid item sm={12} md={5}>
                  <NumberInputBRH
                    label="Paid Parking"
                    name="Paid_parking"
                    value={parkingDetails.BikeParkingDetails.Paid_parking}
                    handleChange={(e) => handleChangeParking('Bike', e)}
                  />
                </Grid>

                <Grid item sm={12} md={2}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                      label="Amount"
                      type="number"
                      disabled={parkingDetails.BikeParkingDetails.Paid_parking === ''}
                      name="Amount"
                      value={parkingDetails.BikeParkingDetails.Amount}
                      onChange={(e) => handleChangeParking('Bike', e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="d-flex justify-content-center flex-column">
              <span style={{ fontSize: '20px', fontWeight: 500, letterSpacing: 1, marginBottom: '5px' }}>
                Car Parking
              </span>

              <Grid container rowSpacing={2.5} columnSpacing={2}>
                <Grid item sm={12} md={5}>
                  <NumberInputBRH
                    label="Free Parking"
                    name="Free_parking"
                    value={parkingDetails.CarParkingDetails.Free_parking}
                    handleChange={(e) => handleChangeParking('Car', e)}
                  />
                </Grid>
                <Grid item sm={12} md={5}>
                  <NumberInputBRH
                    label="Paid Parking"
                    name="Paid_parking"
                    value={parkingDetails.CarParkingDetails.Paid_parking}
                    handleChange={(e) => handleChangeParking('Car', e)}
                  />
                </Grid>

                <Grid item sm={12} md={2}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                      label="Amount"
                      type="number"
                      name="Amount"
                      disabled={parkingDetails.CarParkingDetails.Paid_parking === ''}
                      value={parkingDetails.CarParkingDetails.Amount}
                      onChange={(e) => handleChangeParking('Car', e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <div style={{ display: 'flex', alignSelf: 'flex-end', marginTop: '30px' }}>
            <LoadingButtonBRH variant="containeds" label="Save & Next" type="submit" />
          </div>
        </FormControl>
      </form>
    </>
  );
};

export default PropertyDetails;
