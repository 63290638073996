import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CheckBoxInputBRH } from '../../../components/forms/CheckBoxInputBRH';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';

import { ToastBRH } from '../../../components/toasts/ToastBRH';
import { CommonAlert } from '../../../components/toasts/CommonAlert';

const Amenities = ({ propertyId, onChangeNext, onChange }) => {
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [freeAmenities, setFreeAmenities] = useState([]);
  const [paidAmenities, setPaidAmenities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [propertyId, setPropertyId] = useState(null);
  const [propertyAmenities, setPropertyAmenities] = useState([]);
  const [propertyData, setPropertyData] = useState({});
  const [isDraft, setIsDraft] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const data = useSelector((state) => state.BasicDetails.basicDetails);
  const [expanded, setExpanded] = useState('panel1a-header');
  const [SelectAll, setSelectAll] = useState(false);
  const [errStatus, setErrStatus] = useState(0);
  const [errMessage, setErrMessage] = useState('');
  const [errPlace, setErrPlace] = useState('');
  const [draftopen, setDraftOpen] = useState(false);
  const handleDraftBack = () => {
    setDraftOpen(false);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (propertyId) {
      const id = propertyId;

      API.getData(`api/amenities?limit=100`).then((result) => {
        setAmenitiesData(result.data.data.data);
      });

      API.getData(`api/propertyamenities/${id}`).then((result) => {
        setPropertyAmenities(result.data.data);
      });

      API.getData(`api/property/${id}`).then((result) => {
        setIsLoading(false);
        setPropertyData(result.data.data);
        setIsDraft(result.data.data.is_draft);
      });
    }
  }, [propertyId]);

  const FreeAmenities = amenitiesData && amenitiesData.filter((value) => value.is_paid === 0); // Default value if the condition is not met.
  const PaidAmenities = amenitiesData && amenitiesData.filter((value) => value.is_paid === 1); // Default value if the condition is not met.

  const IMAGE_API = 'https://brhfiles.s3.ap-south-1.amazonaws.com/';

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCheckBoxGroupChange = (id, name, isChecked) => {
    if (isChecked) {
      // Add the item to the selectedAmenities array
      setSelectedItems([...selectedAmenities, { amentity_id: id, status: 1 }]);
    } else {
      // Remove the item from the selectedAmenities array

      setSelectedItems(selectedAmenities.filter((item) => item.amentity_id !== id));
    }
    // if (isChecked) {
    //   // Add the item to the selectedAmenities array
    //   setFreeAmenities([...freeAmenities, { amentity_id: id, status: 1 }]);
    // } else {
    //   // Remove the item from the selectedAmenities array
    //   const updatedFreeAmenities = freeAmenities.map((freeAmenity) => {
    //     if (freeAmenity.amentity_id === id) {
    //       return { ...freeAmenity, status: isChecked ? 1 : 0 };
    //     }
    //     return freeAmenity;
    //   });
    //   setFreeAmenities(updatedFreeAmenities);
    // }
    if (isChecked) {
      // Check if the item with the same amentity_id already exists
      const existingItemIndex = freeAmenities.findIndex((freeAmenity) => freeAmenity.amentity_id === id);

      if (existingItemIndex === -1) {
        // Item does not exist, add it to the selectedAmenities array
        setFreeAmenities([...freeAmenities, { amentity_id: id, status: 1 }]);
      } else {
        // Item already exists, update its status to 1
        const updatedFreeAmenities = [...freeAmenities];
        updatedFreeAmenities[existingItemIndex].status = 1;
        setFreeAmenities(updatedFreeAmenities);
      }
    } else {
      // Update the status of the item with the specified amentity_id to 0
      const updatedFreeAmenities = freeAmenities.map((freeAmenity) => {
        if (freeAmenity.amentity_id === id) {
          return { ...freeAmenity, status: 0 };
        }
        return freeAmenity;
      });
      setFreeAmenities(updatedFreeAmenities);
    }
  };

  const [selectedAmenities, setSelectedItems] = useState([]);
  const [formData, setFormData] = useState([
    {
      paid: '',
      amenities_id: '',
      check: false,
      fees: '',
    },
    {
      paid: '',
      amenities_id: '',
      check: false,
      fees: '',
    },
    {
      paid: '',
      amenities_id: '',
      check: false,
      fees: '',
    },
  ]);

  const handleSelectAll = () => {
    setSelectedItems(
      FreeAmenities.map((i) => {
        return { amentity_id: i.id, status: 1 };
      })
    );
    setFreeAmenities(
      FreeAmenities.map((i) => {
        return { amentity_id: i.id, status: 1 };
      })
    );
    setSelectAll(true);
  };
  const handleDeSelectAll = () => {
    setSelectedItems([]);
    setFreeAmenities([]);
    setSelectAll(false);
  };
  const handleChangePaidAmenities = (index, name, value, id, amenityId, check) => {
    // Clone the formData array to avoid mutating state directly
    const updatedFormData = [...formData];

    // If the object at the specified index does not exist, create it
    if (!updatedFormData[index]) {
      updatedFormData[index] = {};
    }

    // Set the value for the input field with the given name

    updatedFormData[index][name] = value;
    updatedFormData[index][amenityId] = id;
    updatedFormData[index][check] = false;
    // Update the state with the new formData
    setFormData(updatedFormData);
  };
  const handleAmenities = () => {
    const data = {
      property_id: propertyId,
      free_amenities: freeAmenities,
      paid_data: formData,
      is_draft: 0,
    };
    const checkData = [];
    // let errMessage = "";
    // let errStatus = 1;
    // if (formData.length === 0) {
    //   errStatus = 0;
    //   errMessage = "please fill the Value Added services";
    //   //  ToastBRH('error', 'please fill the Value Added services', '_', false);
    // }

    // formData?.forEach((value) => {
    //   if (value.paid) {
    //     if (value.fees && value.fees !== "") {
    //       checkData.push(value);
    //     } else {
    //       errStatus = 0;
    //       const filteredData = amenitiesData.filter(
    //         (item) => item.id === value.amenities_id
    //       );

    //       filteredData.forEach((item1) => {
    //         errMessage = `Please add fee unit ${item1.amenity_name}`;
    //         //  ToastBRH('error', `Please add fee unit ${item1.amenity_name}`, '_', false);
    //       });
    //     }
    //   }
    //   if (value.fees) {
    //     if (value.paid && value.paid !== "") {
    //       checkData.push(value);
    //     } else {
    //       errStatus = 0;
    //       const filteredData = amenitiesData.filter(
    //         (item) => item.id === value.amenities_id
    //       );
    //       filteredData.forEach((item1) => {
    //         errMessage = `Pls add paid unit ${item1.amenity_name}`;
    //         //  ToastBRH('error', `Please add paid unit ${item1.amenity_name}`, '_', false);
    //       });
    //     }
    //   }
    // });
    // if (errStatus === 0) {
    //   ToastBRH("error", errMessage, "_", false);
    //   return;
    // }
    if (propertyAmenities.length === 0) {
      // if (formData.length === 0 && selectedAmenities.length === 0) {
      //   ToastBRH("error", "Please select atleast one service", "_", false);

      //   return;
      // }
      if (selectedAmenities.length === 0) {
        ToastBRH('error', 'Please select atleast one service', '_', false);

        return;
      }
    }

    API.postMethod('api/propertyamenity', data).then((result) => {
      if (result.data.status === 'success') {
        if (result.data.data.amenity_count === 0) {
          handlePropertyTabs('amenities');
        } else {
          onChangeNext();
        }
      }

      if (result.data.status === 'error') {
        localStorage.setItem('property_tabs', 'workspace');

        onChangeNext();
      }
    });
  };

  const handleDraftAmenities = () => {
    const dataforDraft = {
      property_id: propertyId,
      free_amenities: freeAmenities,
      paid_data: formData,
      is_draft: 1,
    };

    API.postMethod('api/propertyamenity', dataforDraft).then((result) => {
      if (result.data.status === 'success') {
        localStorage.setItem('message', result.data.message);

        const tabsdata = {
          property_id: propertyId,
          tabs: 'amenities',
        };
        API.postMethod('api/propertytabs', tabsdata).then((result) => {
          if (result.data.status === 'success') {
            navigate(routeNames.PROSPECTPROPERTYLIST);
          }
        });
      }
      if (result.data.status === 201) {
        const tabsdata = {
          property_id: propertyId,
          tabs: 'amenities',
        };

        API.postMethod('api/propertytabs', tabsdata).then((result) => {
          if (result.data.status === 'success') {
            navigate(routeNames.PROSPECTPROPERTYLIST);
          }
        });
      }
      if (result.status === 'error') {
        ToastBRH('error', result.message, '_', false);
      }
    });
  };
  useEffect(() => {
    // Initialize selected amenities and paid amenities data based on propertyAmenitiesData
    const selected = [];
    const paidData = [];

    amenitiesData.forEach((amenity) => {
      const isPaidAmenity = amenity.is_paid === 1;
      const propertyAmenity = propertyAmenities.find((pa) => pa.amenity_id === amenity.id);

      if (propertyAmenity) {
        if (isPaidAmenity) {
          paidData.push({
            amenities_id: amenity.id,
            paid: propertyAmenity.paid_units,
            fees: propertyAmenity.fee_per_unit,
            check: false,
          });
        } else {
          selected.push({ amentity_id: amenity.id, status: 1 });
        }
      }
    });

    setFreeAmenities(selected);
    setPaidAmenities(paidData);
    setSelectedItems(selected);

    setFormData(paidData);
    // paidData.map((i, index) => handleChangePaidAmenities(index, ''));
  }, [amenitiesData, propertyAmenities]);

  const handlePropertyTabs = (tab) => {
    const tabsdata = {
      property_id: propertyId,
      tabs: tab,
    };

    API.postMethod('api/propertytabs', tabsdata).then((result) => {
      if (result.data.status === 'success') {
        window.location.reload();
      }
    });
  };
  const handlePrevious = () => {
    localStorage.setItem('property_tabs', 'basicdetails');
    // handlePropertyTabs('basicdetails');
    onChange();
    // window.location.reload();
  };
  return (
    <>
      <Accordion
        sx={{ background: 'light', padding: '20px' }}
        expanded={expanded === 'panel1a-header'}
        onChange={handleChange('panel1a-header')}
      >
        <AccordionSummary
          sx={{ backgroundColor: '#f9fafb' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ background: '#f9fafb', width: '100%' }}>Amenities</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <Stack direction="row" justifyContent="start" alignItems="center">
              {!SelectAll ? (
                <>
                  {' '}
                  <IconButton
                    onClick={() => {
                      handleSelectAll();
                    }}
                  >
                    <SelectAllIcon />
                  </IconButton>{' '}
                  <Typography variant="caption">select all</Typography>
                </>
              ) : (
                <>
                  <IconButton
                    onClick={() => {
                      handleDeSelectAll();
                    }}
                  >
                    <DeselectIcon />
                  </IconButton>
                  <Typography variant="caption">Deselect all</Typography>
                </>
              )}
            </Stack>
            <Card sx={{ flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1, m: 1 }}>
                <form noValidate={false}>
                  <FormControl sx={{ flexGrow: 1, width: '100%' }}>
                    <FormGroup>
                      <Grid container>
                        {FreeAmenities &&
                          FreeAmenities.map((item) => {
                            return (
                              <>
                                <Grid item xs={6}>
                                  <FormControlLabel
                                    control={
                                      <div
                                        style={{
                                          display: 'flex',

                                          alignItems: 'center',

                                          height: '10vh',
                                          marginRight: '1vw',
                                        }}
                                      >
                                        <Checkbox
                                          // checked={freeAmenities.selectedFreeAmentities?.includes(`${ame.id}`)}
                                          // defaultChecked={selectedAmenities.some(
                                          //   (selected) => selected.amentity_id === item.id
                                          // )}
                                          checked={selectedAmenities.some(
                                            (selected) => selected.amentity_id === item.id
                                          )}
                                          onChange={(e) =>
                                            handleCheckBoxGroupChange(item.id, item.amenity_name, e.target.checked)
                                          }
                                        />
                                        <img
                                          src={IMAGE_API + item.icon_path}
                                          alt="vijay"
                                          style={{
                                            width: '25px',
                                            height: '20px',
                                          }}
                                        />
                                      </div>
                                    }
                                    label={item.amenity_name}
                                    value={item.id}
                                  />
                                </Grid>
                              </>
                            );
                          })}
                      </Grid>
                    </FormGroup>
                  </FormControl>
                </form>
              </Box>
            </Card>
          </Container>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ background: 'light', padding: '20px' }}>
        <AccordionSummary
          sx={{ backgroundColor: '#f9fafb' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography sx={{ background: '#f9fafb', width: '100%' }}>Value Added Services</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {PaidAmenities &&
            PaidAmenities.map((value, index) => {
              const amenityvalue =
                formData.length > 0 ? formData?.find((item) => item?.amenities_id === value.id) || {} : {};

              return (
                <Accordion sx={{ background: 'light', padding: '10px' }}>
                  <AccordionSummary
                    sx={{ backgroundColor: '#f9fafb', margin: 0 }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography sx={{ background: '#f9fafb', width: '100%' }}>{value.amenity_name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Container
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',

                        alignSelf: 'center',
                        marginTop: '4vh',
                        //   backgroundColor: 'green',
                      }}
                    >
                      <NumberInputBRH
                        label={
                          value.paid_unit === '1'
                            ? 'Print & Photocopy'
                            : value.paid_unit === '0'
                            ? 'IT Support'
                            : value.paid_unit
                        }
                        handleChange={(e) =>
                          handleChangePaidAmenities(
                            index,
                            // value.paid_unit === '1'
                            //   ? 'Print & Photocopy'
                            //   : value.paid_unit === '0'
                            //   ? 'IT Support'
                            //   : value.paid_unit,
                            'paid',
                            e.target.value,
                            value.id,
                            'amenities_id',
                            'check'
                          )
                        }
                        name={value.paid_unit}
                        sx={{ marginRight: '20px' }}
                        value={amenityvalue ? amenityvalue.paid : ''}
                        required
                      />
                      <NumberInputBRH
                        label="Fee Per Unit"
                        handleChange={(e) =>
                          handleChangePaidAmenities(index, 'fees', e.target.value, value.id, 'amenities_id', 'check')
                        }
                        name="lockedInPeriod"
                        value={amenityvalue ? amenityvalue.fees : ''}
                        required
                      />
                    </Container>
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </AccordionDetails>
      </Accordion>
      <Grid sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }} item xs={12} sm={12} md={6}>
        {/* <Button sx={{ height: '2.5rem', width: 150, mx: 1 }} variant="contained">
                          Cancel
                        </Button> */}
        {/* <LoadingButtonBRH type="submit" variant="containeds" label="Save" color="grey" />
          <LoadingButtonBRH type="submit" variant="containeds" label="Save" /> */}
        <Button
          type="button"
          sx={{
            height: '2.5rem',
            width: 150,
            mx: 1,
            backgroundColor: 'grey',
            color: 'white',
            ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
          }}
          onClick={() => navigate(routeNames.PROSPECTPROPERTYLIST)}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          type="button"
          sx={{
            height: '2.5rem',
            width: 150,
            mx: 1,
            backgroundColor: 'grey',
            color: 'white',
            ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
          }}
          onClick={() => handlePrevious()}
          variant="contained"
        >
          Previous
        </Button>
        {isDraft === 1 ? (
          <Button
            sx={{
              height: '2.5rem',
              width: 150,
              mx: 1,
              backgroundColor: 'grey',
              color: 'white',
              ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
            }}
            //  color="grey"
            variant="contained"
            onClick={() => setDraftOpen(true)}
          >
            Save as Draft
          </Button>
        ) : (
          ''
        )}
        {localStorage.getItem('verified') === '1' || localStorage.getItem('verified') === '2' ? (
          <LoadingButtonBRH variant="containeds" label="Update" handleSubmit={handleAmenities} />
        ) : (
          <LoadingButtonBRH variant="containeds" label="Save & Next" handleSubmit={handleAmenities} />
        )}
      </Grid>
      <CommonAlert
        open={draftopen}
        setKycOpen={setDraftOpen}
        message="All Amenities Data will be save as draft, do you wish to Proceed ?"
        proceedFunction={handleDraftAmenities}
        goBackFunction={handleDraftBack}
      />
    </>
  );
};

export default Amenities;
