import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import { getMethod } from '../../API_SERVICE_LAYER/ApiConfigs';
import TransferList from '../../components/TransferList/TransferList';
import { AutoCompleteInputBRH } from '../../components/forms/AutoCompleteInputBRH';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { FormSkeleton } from '../../components/skeleton/FormSkeleton';
import { SelectInputBRH } from '../../components/forms/SelectInputBRH';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddContracts() {
  // for apis
  const PROPERTYLIST = 'api/property';
  const AMENTITIESLIST = 'api/amenities';
  const PROPERTYAMENTITY = 'api/propertyamenities';
  const PROPERTYRESOURCE = 'api/propertyresources';
  const [personName, setPersonName] = React.useState([]);
  const [workingDays, setWorkingDays] = React.useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [contractDetails, setContractDetails] = useState({
    client_id: null,
    date_of_agreement: null,
    agreement_start_date: null,
    agreement_end_date: null,
    no_of_years: null,
    lock_in_period_months: null,
    notice_in_period_months: null,
    rent_start_date: null,
    year_on_year_increment: null,
    no_of_car_parking: null,
    no_of_bike_parking: null,
    refunable_security_amount: null,
    monthly_service_charges: null,
    interest_rate_if_failed_to_pay_mg: null,
    any_other_services: null,
    dg: null,
    water: null,
    internet: null,
    electricity: null,
    tea_coffee: null,
    is_mou: null,
    is_contract: null,
    status: null,
    // for laksh
    propertiesList: [],
    amentitiesList: [],
    resourceList: [],
    propertyResourcesList: [],
    propertyFreeAmentitiesList: [],
    propertyPaidAmentitiesList: [],
    property_name: '',
    propertyId: '',
    isFetched: false,
    isSelectDataloaded: true,
    selectedAmentities: [],
    selectedResources: [],
  });

  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];
  const handleSelectChange = (e) => {
    console.log('clicked');
    const { name, value } = e.target;
    setContractDetails({ ...contractDetails, [name]: typeof value === 'string' ? value.split(',') : value });
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === 'string' ? value.split(',') : value);
  };

  const handleEventChange = (event, newValue) => {
    const { value, name } = event.target;
    console.log(name, newValue.id, value);
  };

  const workingDayHandleChnage = (event) => {
    setWorkingDays({
      ...workingDays,
      [event.target.name]: event.target.checked,
    });
  };

  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = workingDays;

  const handleAutoCompleteChange = (event, newValue) => {
    setContractDetails({ ...contractDetails, isSelectDataloaded: false });
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    // setContractDetails({ ...contractDetails, [name]: value, propertyId: id });

    // for addiional data
    API.getData(`${PROPERTYAMENTITY}/${id}`)
      .then((resAme) => {
        if (resAme?.data?.status) {
          console.log('API-Prop-ame', resAme?.data?.data);
          const paidAmentities = [];
          const freeAmentities = [];
          contractDetails.amentitiesList.forEach((ameList) => {
            resAme.data.data.forEach((ame) => {
              if (ameList.id === ame.amenity_id && ame.is_paid === 0) {
                freeAmentities.push({
                  id: ameList.id,
                  name: ameList.amenity_name,
                });
              } else if (ameList.id === ame.amenity_id && ame.is_paid === 1) {
                paidAmentities.push({
                  id: ameList.id,
                  name: ameList.amenity_name,
                });
              }
            });
          });
          API.getData(`${PROPERTYRESOURCE}/${id}`)
            .then((resPro) => {
              if (resPro?.data?.success) {
                console.log('API-Prop-res', resPro?.data?.data);
                const resources = resPro?.data?.data.map((res) => {
                  return { id: res.resource_group_id, name: res.resource_name };
                });
                console.log('laksh', freeAmentities, paidAmentities, resources);
                setContractDetails({
                  ...contractDetails,
                  [name]: value,
                  propertyId: id,
                  propertyFreeAmentitiesList: freeAmentities,
                  propertyPaidAmentitiesList: paidAmentities,
                  propertyResourcesList: resources,
                  isSelectDataloaded: true,
                });
              }
            })
            .catch((errPro) => {
              console.log(errPro);
            });
        }
      })
      .catch((errAme) => {
        console.log(errAme);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('workingDays', workingDays);
  };

  useEffect(() => {
    // step-1
    API.getData(PROPERTYLIST)
      .then((resPro) => {
        if (resPro?.data?.status) {
          console.log('API-ProprtyList', resPro?.data?.result);
          // step-2
          API.getData(AMENTITIESLIST, {
            parameter: {
              limit: 1000,
            },
          })
            .then((resAme) => {
              if (resAme?.data?.data) {
                setContractDetails({
                  ...contractDetails,
                  propertiesList: resPro.data.result,
                  amentitiesList: resAme?.data?.data?.data,
                  isFetched: true,
                });
                console.log('API-amentitiesList', resAme?.data?.data?.data);
              }
            })
            .catch((errAme) => {
              setContractDetails({ ...contractDetails, isFetched: true });
              console.log('API-amentitiesList', errAme);
            });
        }
      })
      .catch((errPro) => {
        setContractDetails({ ...contractDetails, isFetched: true });
        console.log('API-ProprtyList', errPro);
      });
  }, []);
  // const getClientsList = () => {
  //   return new Promise((resolve, reject) => {
  //     getMethod('contract-management/api/v1/get-all-client')
  //       .then((res) => {
  //         if (res?.data?.results) {
  //           resolve(res.data.results);
  //         } else {
  //           reject('Error: No clients found');
  //         }
  //       })
  //       .catch((error) => {
  //         reject(`Error: ${error}`);
  //       });
  //   });
  // };

  return (
    <>
      {contractDetails.isFetched ? (
        <>
          <>
            <Helmet>
              <title> Add Contract | BRH </title>
            </Helmet>
            <Container>
              {/* <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Link underline="hover" color="inherit" href="/contract/client">
                  Masters
                </Link>
                <Typography color="text.primary">Add Contract</Typography>
              </Breadcrumbs> */}

              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography variant="h4" gutterBottom>
                  Add CRS
                </Typography>
              </Stack>

              <Card sx={{ p: 2 }}>
                <Scrollbar>
                  <Box sx={{ flexGrow: 1, m: 4 }}>
                    <form onSubmit={handleSubmit}>
                      <FormControl sx={{ flexGrow: 1, width: '100%' }}>
                        <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                          <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} item xs={12} sm={12} md={6}>
                            <Autocomplete
                              labelId="clientName"
                              disablePortal
                              id="clientName"
                              name="client_id"
                              onChange={handleEventChange}
                              options={[
                                { id: 1, label: 'Sheshank' },
                                { id: 2, label: 'Nirmal' },
                                { id: 3, label: 'Mansoor' },
                                { id: 4, label: 'Lakshmanan' },
                                { id: 5, label: 'Hisham' },
                              ]}
                              sx={{ width: '85%' }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name="client_id"
                                  labelId="clientName"
                                  id="clientName"
                                  label="Client Name"
                                />
                              )}
                            />
                            <Button sx={{ fontSize: '1.5rem' }} variant="outlined">
                              +
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker onCha label="Date of Agreement[Execution Date]" sx={{ width: '100%' }} />
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="Client Address "
                              variant="outlined"
                              multiline
                              rows={3}
                            />{' '}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker label="Agreement Start Date" sx={{ width: '100%' }} />
                            </LocalizationProvider>
                          </Grid>
                          <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} item xs={12} sm={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker label="Agreement End Date" sx={{ width: '70%' }} />
                            </LocalizationProvider>
                            <TextField sx={{ width: '25%' }} variant="outlined" label="No Of Years" />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="Locked-in Period in Months"
                              variant="outlined"
                            />{' '}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="Notice Period in Months"
                              variant="outlined"
                            />{' '}
                          </Grid>
                          <Grid sx={{ display: 'flex', justifyContent: 'space-between' }} item xs={12} sm={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker label="Rent Starting Date" sx={{ width: '70%' }} />
                            </LocalizationProvider>
                            <TextField sx={{ width: '25%' }} variant="outlined" label="Rent Free" />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="Year on Year Increment (%)"
                              variant="outlined"
                            />{' '}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl sx={{ width: '100%' }}>
                              <AutoCompleteInputBRH
                                label="Select Property"
                                name="property_name"
                                value={contractDetails.property_name}
                                handleChange={handleAutoCompleteChange}
                                options={contractDetails.propertiesList}
                                isSelectDataloaded={contractDetails.isSelectDataloaded}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <SelectInputBRH
                              label="Shared Facilities Included in Rent"
                              name="selectedAmentities"
                              value={contractDetails.selectedAmentities}
                              handleChange={handleSelectChange}
                              options={contractDetails.propertyFreeAmentitiesList}
                              keyValue="id"
                              keyName="name"
                            />
                            {/* <FormControl sx={{ width: '100%' }}>
                              <InputLabel id="sharedFaclities">Shared Facilities Included in Rent</InputLabel>
                              <Select
                                labelId="sharedFaclities"
                                id="sharedFaclities"
                                multiple
                                value={personName}
                                onChange={handleChange}
                                input={<OutlinedInput label="Shared Facilities Included in Rent" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                              >
                                {contractDetails.freeAmentities?.map((ame) => (
                                  <MenuItem key={ame.id} value={ame.id}>
                                    <Checkbox checked={personName.indexOf(name) > -1} />
                                    <ListItemText primary={ame.name} />
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <SelectInputBRH
                              label="Office Space Details"
                              name="selectedResources"
                              value={contractDetails.selectedResources}
                              handleChange={handleSelectChange}
                              options={contractDetails.propertyResourcesList}
                              keyValue="id"
                              keyName="name"
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="No of Car Parking"
                              variant="outlined"
                            />{' '}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="No of Bike Parking"
                              variant="outlined"
                            />{' '}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              sx={{
                                mt: -1,
                                border: '1px solid #E8EBEE',
                                px: 2.5,
                                py: 1,
                                borderRadius: '5px',
                                width: '100%',
                              }}
                              component="fieldset"
                              variant="standard"
                            >
                              <FormLabel component="legend">Working Days</FormLabel>

                              <FormGroup
                                sx={{
                                  display: 'grid',
                                  columnGap: 2,
                                  rowGap: 1,
                                  gridTemplateColumns: 'repeat(4, 1fr)',
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={monday} onChange={workingDayHandleChnage} name="monday" />
                                  }
                                  label="Mon"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={tuesday} onChange={workingDayHandleChnage} name="tuesday" />
                                  }
                                  label="Tue"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={wednesday} onChange={workingDayHandleChnage} name="wednesday" />
                                  }
                                  label="Wed"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={thursday} onChange={workingDayHandleChnage} name="thursday" />
                                  }
                                  label="Thu"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={friday} onChange={workingDayHandleChnage} name="friday" />
                                  }
                                  label="Fri"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={saturday} onChange={workingDayHandleChnage} name="saturday" />
                                  }
                                  label="Sat"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox checked={sunday} onChange={workingDayHandleChnage} name="sunday" />
                                  }
                                  label="Sun"
                                />
                              </FormGroup>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <TimePicker
                                sx={{ width: '100%', mb: 1.5 }}
                                label="Working Hours From"
                                // value={value}
                                ampm={false}
                                minutesStep={30}
                                // onChange={(newValue) => setValue(newValue)}
                              />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <TimePicker
                                sx={{ width: '100%' }}
                                label="Working Hours To"
                                // value={value}
                                ampm={false}
                                minutesStep={30}
                                // onChange={(newValue) => setValue(newValue)}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="Refundable Security Amount"
                              variant="outlined"
                            />{' '}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="Monthly Service Charges"
                              variant="outlined"
                            />{' '}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="Interest Rate if Failed to Pay MG (%)"
                              variant="outlined"
                            />{' '}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <TextField
                              sx={{ width: '100%' }}
                              id="filled-basic"
                              label="Any Other Services"
                              variant="outlined"
                            />{' '}
                          </Grid>

                          <Grid
                            sx={{
                              display: 'grid',
                              columnGap: 3,
                              rowGap: 2,
                              gridTemplateColumns: 'repeat(5, 1fr)',
                              border: '1px solid #e8ebee',
                              px: 2,
                              py: 2,
                              my: 2,
                              ml: 2,
                              width: '100%',
                              borderRadius: '5px',
                            }}
                            item
                            xs={12}
                            sm={12}
                            md={12}
                          >
                            <FormControl>
                              <FormLabel id="demo-controlled-radio-buttons-group">DG</FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //  value={value}
                                onChange={handleChange}
                              >
                                <FormControlLabel value="included" control={<Radio />} label="Included" />
                                <FormControlLabel value="reimbursing" control={<Radio />} label="Reimbursing" />
                                <FormControlLabel value="na" control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <FormLabel id="demo-controlled-radio-buttons-group">Water</FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //  value={value}
                                onChange={handleChange}
                              >
                                <FormControlLabel value="included" control={<Radio />} label="Included" />
                                <FormControlLabel value="reimbursing" control={<Radio />} label="Reimbursing" />
                                <FormControlLabel value="na" control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl>
                            {/* dynamic resources */}
                            {contractDetails.propertyPaidAmentitiesList?.map((ame) => {
                              return (
                                <>
                                  <FormControl>
                                    <FormLabel id={`paidAmentitiesLabel-${ame.name}`}> {ame.name}</FormLabel>
                                    <RadioGroup
                                      aria-labelledby="demo-controlled-radio-buttons-group"
                                      name={ame.name}
                                      value={contractDetails[ame]}
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel value="included" control={<Radio />} label="Included" />
                                      <FormControlLabel value="reimbursing" control={<Radio />} label="Reimbursing" />
                                      <FormControlLabel value="na" control={<Radio />} label="NA" />
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            })}
                            {/* <FormControl>
                              <FormLabel id="demo-controlled-radio-buttons-group">Internet</FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //  value={value}
                                onChange={handleChange}
                              >
                                <FormControlLabel value="included" control={<Radio />} label="Included" />
                                <FormControlLabel value="reimbursing" control={<Radio />} label="Reimbursing" />
                                <FormControlLabel value="na" control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <FormLabel id="demo-controlled-radio-buttons-group">Electricity</FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //  value={value}
                                onChange={handleChange}
                              >
                                <FormControlLabel value="included" control={<Radio />} label="Included" />
                                <FormControlLabel value="reimbursing" control={<Radio />} label="Reimbursing" />
                                <FormControlLabel value="na" control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl>
                            <FormControl>
                              <FormLabel id="demo-controlled-radio-buttons-group">Tea/Coffee</FormLabel>
                              <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                //  value={value}
                                onChange={handleChange}
                              >
                                <FormControlLabel value="included" control={<Radio />} label="Included" />
                                <FormControlLabel value="reimbursing" control={<Radio />} label="Reimbursing" />
                                <FormControlLabel value="na" control={<Radio />} label="NA" />
                              </RadioGroup>
                            </FormControl> */}
                          </Grid>

                          <Box md={12} sx={{ m: 2, p: 1, width: '100%' }}>
                            <TransferList />
                          </Box>

                          <Stack
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              mx: 1.5,
                            }}
                            direction="row"
                          >
                            <div>
                              <TextField label="Version" />
                            </div>
                            <Button type="button" sx={{ height: '3.5rem', width: 300, mx: 1 }} variant="contained">
                              Save as Draft
                            </Button>
                            <Button type="submit" sx={{ height: '3.5rem', width: 300, mx: 1 }} variant="contained">
                              Submit
                            </Button>
                          </Stack>
                        </Grid>
                      </FormControl>
                    </form>
                  </Box>
                </Scrollbar>
              </Card>
            </Container>
          </>
        </>
      ) : (
        <>
          <FormSkeleton lines={5} />
        </>
      )}
    </>
  );
}
