import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';

import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import palette from '../../theme/palette';
import { EDITCRS } from '../../API_SERVICE_LAYER/ApiNames';
import { ToastBRH } from '../../components/toasts/ToastBRH';
import { KycAlert } from './KycAlert';
import { KycReApproveAlert } from './KycReApproveAlert';
import Iconify from '../../components/iconify';

export const KYC_ACCEPTED = 1;
export const KYC_REQUESTED = 2;
export const KYC_REJECTED = 3;
export const NO_KYC = 5;

export const KycBox = ({ contractId, onUpdate, kycStatus, KycReason, kycRemarks, kycFile, userData }) => {
  const [file, setFile] = useState(null);
  const [rejectedReason, setRejectedReason] = useState(null);
  const [isReasonFilled, setIsReasonFilled] = useState(true);
  const [isFileSelected, setIsFileSelected] = useState(true);
  const [isOpenKycAlert, setIsOpenKycAlert] = useState(false);
  const [isOpenReApproveKycAlert, setIsOpenReApproveKycAlert] = useState(false);
  const [isKycApproved, setIsKycApproved] = useState(null);

  const fileName = file?.name;

  const handleKycOpen = () => {
    setIsOpenKycAlert(false);
    // const laksh = '';
  };
  const handleReApproveKycOpen = () => {
    setIsOpenReApproveKycAlert(false);
  };
  const handleValidation = () => {
    if (!file) {
      setIsFileSelected(false);
      setTimeout(() => setIsFileSelected(true), 3000);
      return -2;
    }
    if (!rejectedReason) {
      setIsReasonFilled(false);
      setTimeout(() => setIsReasonFilled(true), 3000);
      return -1;
    }
    setIsReasonFilled(true);
    return 1;
    // else {
    // }
  };

  const handleNoKYC =()=>{
    const formData = new FormData();

    formData.append('id', contractId);
    formData.append('kyc_status', 5);

    console.log(formData,"nmjkkkk")

    // if (isValid === 1) {
      API.updateMethod(EDITCRS, formData)
        .then((res) => {
          if (res?.data?.status === 'success') {
            ToastBRH('success', ' Submitted Successfully', '_', false);
            onUpdate(true);
          }
        })
        .catch((err) => {
          ToastBRH('warning', 'Something Went Wrong! Please Try Again', '_', false);
          console.log(err);
        });
    // }
  
  }
  const handleReject = (status) => {
    const isValid = handleValidation();
    const formData = new FormData();
    formData.append('id', contractId);
    formData.append('kyc_document', file);
    formData.append('kyc_status', status);
    formData.append('kyc_reason', rejectedReason);
    if (isValid === 1) {
      API.updateMethod(EDITCRS, formData)
        .then((res) => {
          if (res?.data?.status === 'success') {
            ToastBRH('success', 'KYC Rejected Successfully', '_', false);
            onUpdate(true);
          }
        })
        .catch((err) => {
          ToastBRH('warning', 'Something Went Wrong! Please Try Again', '_', false);
          console.log(err);
        });
    }
  };
  const handleAccept = (status) => {
    if (!file) {
      setIsFileSelected(false);
      setTimeout(() => setIsFileSelected(true), 3000);
      return;
    }
    const formData = new FormData();
    formData.append('id', contractId);
    formData.append('kyc_document', file);
    formData.append('kyc_status', status);

    API.updateMethod(EDITCRS, formData)
      .then((res) => {
        if (res?.data?.status === 'success') {
          onUpdate(true);
          ToastBRH('success', 'KYC Accepted Successfully', '_', false);
        }
      })
      .catch((err) => {
        ToastBRH('warning', 'Something Went Wrong! Please Try Again', '_', false);
        console.log(err);
      });
  };

  return (
    <>
      {kycStatus === KYC_ACCEPTED ? (
        <>
          <Box>
            <Typography variant="button" color={palette.success.dark} fontSize={18}>
              KYC Approved{' '}
              {/* {kycRemarks && (
                <Typography color={palette.warning[700]} variant="caption">
                  ({kycRemarks}){' '}
                </Typography>
              )} */}
            </Typography>
            <Button
              href={`${process.env.REACT_APP_IMAGE_URL}/${kycFile}`}
              target="_blank"
              variant="outlined"
              color="secondary"
            >
              View KYC
            </Button>
            {kycRemarks && (
              <Typography alignContent="center" color={palette.common.black} sx={{ marginTop: '16px' }}>
                <Iconify icon="typcn:warning" color="error" /> KYC rejected by CST team & Re-approved by Unit Head
              </Typography>
            )}
            {/* <Box sx={{ marginTop: '16px' }}>
            </Box> */}
          </Box>
          {/* <Box>view file</Box> */}
        </>
      ) : kycStatus === KYC_REQUESTED ? (
        <>
          <Box
            sx={{
              border: '1px solid #ccc',
              padding: '24px 8px',
              borderRadius: '3px',
              width: '600px',
              margin: '0px auto',
            }}
          >
            <form>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                {/* <Grid item sm={12} md={12}>
                  <FileInputBRH
                    buttonText={fileName ?? 'Upload KYC document'}
                    handleFileUpload={(e) => setFile(e.target.files[0])}
                    color="secondary"
                  />
                </Grid> */}
                {/* <Grid item sm={12} md={8}>
                  <TextInputBRH
                    label="KYC Rejection Reason"
                    value={rejectedReason}
                    handleChange={(e) => setRejectedReason(e.target.value)}
                    size="small"
                  />
                </Grid> */}
                <Grid item sm={12} md={12}>
                  <Stack direction="row" sx={{ justifyContent: 'space-evenly' }}>
                    <Box>
                      {/* onClick={() => handleReject(KYC_REJECTED)} */}
                      <Button
                        onClick={() => {
                          setIsOpenKycAlert(true);
                          setIsKycApproved(0);
                        }}
                        variant="outlined"
                        color="secondary"
                      >
                        KYC Reject
                      </Button>
                    </Box>
                    {/* onClick={() => handleAccept(KYC_ACCEPTED)} */}
                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setIsOpenKycAlert(true);
                          setIsKycApproved(1);
                        }}
                      >
                        KYC Approve
                      </Button>
                    </Box>


                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => {
                          // setIsOpenKycAlert(true);
                          // setIsKycApproved(5);
                          handleNoKYC()
                        }}
                      >
                         KYC Not Required  
                      </Button>
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
              <KycAlert
                open={isOpenKycAlert}
                onSetOpen={handleKycOpen}
                isApprove={isKycApproved}
                contractId={contractId}
                onUpdate={onUpdate}
                NO_KYC={NO_KYC}
                KYC_ACCEPTED={KYC_ACCEPTED}
                KYC_REJECTED={KYC_REJECTED}
              />
            </form>
            {/* <Box sx={{ display: isFileSelected === true && isReasonFilled === true ? 'none' : 'block' }}>
              <Typography
                sx={{
                  color: palette.error.dark,
                  fontStyle: 'italic',
                  fontWeight: '600',
                  marginTop: '16px',
                  paddingLeft: '8px',
                }}
              >
                {!isFileSelected
                  ? 'Please Upload KYC File'
                  : !isReasonFilled
                  ? 'Please fill Rejection Reason before Rejecting'
                  : 'KK'}
               
              </Typography>
            </Box> */}
          </Box>
        </>
      ) : kycStatus === KYC_REJECTED ? (
        <>
          <Box>
            {/* <Typography variant="button" color={palette.error.dark} fontSize={18}>
              KYC Rejected{' '}
              <Typography color={palette.grey[700]} variant="caption">
                ({KycReason})
              </Typography>
            </Typography> */}
            <Box sx={{ marginTop: '16px' }}>
              <Stack spacing={2} direction="row">
                <Typography variant="button" color={palette.error.dark} fontSize={18}>
                  KYC Rejected{' '}
                  <Typography color={palette.grey[700]} variant="caption">
                    ({KycReason})
                  </Typography>
                </Typography>
                <Box>
                  <Button
                    href={`${process.env.REACT_APP_IMAGE_URL}/${kycFile}`}
                    target="_blank"
                    variant="outlined"
                    color="secondary"
                  >
                    View KYC
                  </Button>
                </Box>
                <Box sx={{ display: userData?.cat_head === 1 ? 'block' : 'none' }}>
                  <Button
                    onClick={() => {
                      setIsOpenReApproveKycAlert(true);
                      setIsKycApproved(1);
                    }}
                    variant="contained"
                  >
                    Re-Approve
                  </Button>
                  <KycReApproveAlert
                    open={isOpenReApproveKycAlert}
                    onSetOpen={handleReApproveKycOpen}
                    contractId={contractId}
                    onUpdate={onUpdate}
                  />
                </Box>
              </Stack>
            </Box>
          </Box>
        </>
      ) : (
        ''
      )}
    </>
  );
};
