import Swal from 'sweetalert2';
import './toast.css';

// export const ToastBRH = (icon, title, navigateTo, isReload = true, timer = 2000, position = 'top-end') => {
export const ToastBRH = (icon, title, timer = 2000, position = 'top-end') => {
  const Toast = Swal.mixin({
    toast: true,
    position,
    showConfirmButton: false,
    timer,
    timerProgressBar: false,
  });
  return Toast.fire({
    icon,
    title,
  });
};
