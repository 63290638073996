import { FormControl, TextField, capitalize } from '@mui/material';
import React from 'react';
import palette from '../../theme/palette';

export const TextInputBRH = ({
  name,
  label,
  value,
  handleChange,
  endAdornment,
  sx,
  multiline,
  rows,
  key,
  type,
  onKeyPress,
  defaultValue,
  required,
  disable,
  InputProps,
  readOnly = false,
  isTypeNumber = false,
  maxRows,
  isGmail,
  size,
  variant = 'outlined',
  isAutoPopulate = 0,
}) => {
  const capitalizefirstLetter = {
    style: {
      textTransform: 'Capitalize',
      backgroundColor: readOnly ? '#f8f9fa' : 'inherit',
    },
    readOnly,
  };
  // const NUMERICS = /^[0-9._\b]+$/;
  const NUMERICS = /^[0-9._\b]+$/;
  const ALPHABETS = /^[a-zA-Z._\b]+$/;
  // const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;
  // onKeyDown={(event) => {^[a-zA-Z0-9[\b]]
  const handleKeyDown = (event) => {
    if (isTypeNumber === 'true') {
      if (!(NUMERICS.test(event.key) || event.key === 'Backspace')) {
        event.preventDefault();
      }
    }
  };
  return (
    <FormControl
      fullWidth
      sx={{
        backgroundColor: isAutoPopulate ? palette.secondary.lighter : 'inherit',
      }}
    >
      <TextField
        defaultValue={defaultValue}
        fullWidth
        id={label}
        InputLabelProps={{ shrink: value }}
        // InputLabelProps={{ shrink: !!value }}
        inputProps={isGmail ?? capitalizefirstLetter}
        InputProps={{ endAdornment: InputProps }}
        key={key ?? label}
        label={label}
        multiline={multiline}
        maxRows={maxRows}
        name={name}
        onChange={handleChange}
        required={required}
        rows={rows}
        sx={sx}
        variant={variant}
        value={value}
        endAdornment={endAdornment}
        // onKeyPress={onKeyPress}
        type={type}
        onKeyDown={(event) => {
          if (isTypeNumber === 'true') {
            if (!(NUMERICS.test(event.key) || event.key === 'Backspace')) {
              event.preventDefault();
            }
          }
        }}
        disabled={disable}
        size={size}
      />
    </FormControl>
  );
};
