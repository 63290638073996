import { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
// @mui
import { Grid, Container, Typography, Box, Card, Stack, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
// components
// sections
import { AppOrderTimeline, AppWidgetSummary } from '../sections/@dashboard/app';
import * as API from '../API_SERVICE_LAYER/ApiConfigs';
import { CRSALLLIST, CRSLIST, MOULIST,  PROPERTYLISTCOUNTS, REMINDERDAYS } from '../API_SERVICE_LAYER/ApiNames';
import DashboardLoader from './DashboardLoader';
import styles from './notification/Notification.module.css';
import NotificationItem from './notification/NotificationItem';
import palette from '../theme/palette';
import { contractContext } from '../components/context/AppContext';
import { routeNames } from '../layouts/dashBoardBRH/nav/routeNames';
import {
  addContractsList,
  addExpiredContracts,
  addExpiringContracts,
  addLockInContracts,
  addYoyContracts,
  adddActiveContracts,
} from '../Redux/reducers/ContractListSlice';
import { addReminderDays } from '../Redux/reducers/ReminderSlice';
import NotificationTwo from './notification/NotificationTwo';

// ----------------------------------------------------------------------
const MOUICON = 'mdi:file';
const ACTIVEICON = 'mdi:file-certificate';
const EXPRINGICON = 'mdi:file-alert';
const EXPIREDICON = 'mdi:file-cancel';
const LOCKINICON = 'mdi:file-lock';
const YOYICON = 'mdi:file-chart';

const BOXMOU = 1;
const BOXACTIVE = 2;
const BOXEXPIRING = 3;
const BOXEXPIRED = 4;
const BOXYOY = 5;
const BOXLOCKIN = 6;

// const REMINDERDAYSCOUNT = 60;
const ONEDAY = 24 * 60 * 60 * 1000;
const TODAY = new Date();
const YOY_MONTHS = 12;
const MILLISECONDSPERDAY = 1000 * 60 * 60 * 24;
const MILLISECONDSPERYEAR = MILLISECONDSPERDAY * 365.25;

export default function DashboardAppPage() {
  // const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { propertyList } = useContext(contractContext);
  const [dashboardState, setDashboardState] = useState({
    isLoading: false,
    contractsData: [],
    mouData: [],
    reminderDays: 30,
    propertyList: [],
  });


  const[AllContractList,setAllContractList]=useState([])
  const [selectedBox, setSelectBox] = useState(BOXEXPIRING);
  // DERIVED STATE
  const activeContracts = [];
  const expiredContracts = [];
  const expiringContracts = [];
  const yoyContracts = [];
  const lockInContracts = [];
  const contracts= [];


  console.log(expiringContracts,"exp");
  console.log(AllContractList,"alllc");
  dashboardState.contractsData.forEach((contract) => {
    const propertyName = dashboardState.propertyList?.find(
      (property) => property.id === contract.property_id
    )?.property_name;
    contract = { ...contract, propertyName: propertyName };
    const startDate = new Date(contract.agreement_start_date);
    // ACTIVE
    const endDate = new Date(contract.agreement_end_date);
    const finalDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() - dashboardState.reminderDays
    );
    // LOCK-IN
    const endDateLockIn = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate() - contract.lock_in_period_months * 30
    );
    const finalDateLockIn = new Date(
      endDateLockIn.getFullYear(),
      endDateLockIn.getMonth(),
      endDateLockIn.getDate() - dashboardState.reminderDays
    );
    // YOY
    const endDateOneYR = new Date(startDate.getFullYear(), startDate.getMonth() + YOY_MONTHS, startDate.getDate());
    const finalDateYOY = new Date(
      endDateOneYR.getFullYear(),
      endDateOneYR.getMonth(),
      endDateOneYR.getDate() - dashboardState.reminderDays
    );
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    const years = Math.floor(timeDifference / MILLISECONDSPERYEAR);
    // const finalDateYOY = new Date(
    //   endDateOneYR.getFullYear(),
    //   endDateOneYR.getMonth(),
    //   endDateOneYR.getDate() - REMINDERDAYSCOUNT
    // );
    if (TODAY <= endDate) {
      activeContracts.push(contract);
      // YOY
      // 1.GET CONTRACT ABOVE THE 1 YEAR &&  MENTION YOY INCREAMENT IN CRS
      // 2.CREATE FINAL DATES ARRAY && FIX FINALDATEYOY AS CURRENT YEAR
      if (years && contract.year_on_year_increment && TODAY >= finalDateYOY && TODAY <= endDate) {
        let updatedEndDateYOY = endDateOneYR;
        // let updatedFinalDateYOY = finalDateYOY;

        if (years > 1) {
          // Adjust endDateOneYR and finalDateYOY based on the number of years
          for (let i = 1; i < years; i += 1) {
            if (updatedEndDateYOY > TODAY) {
              break;
            }
            updatedEndDateYOY = new Date(
              updatedEndDateYOY.getFullYear() + 1,
              endDateOneYR.getMonth(),
              endDateOneYR.getDate()
            );

            // updatedFinalDateYOY = new Date(
            //   updatedEndDateYOY.getFullYear(),
            //   endDateOneYR.getMonth(),
            //   endDateOneYR.getDate() - REMINDERDAYSCOUNT
            // );
          }
        }
        contract = { ...contract, endDateYOY: updatedEndDateYOY };
        yoyContracts.push(contract);
      }
      // LOCK IN CONTRACTS
      if (TODAY >= finalDateLockIn && TODAY <= endDateLockIn) {
        contract = { ...contract, endDateLockIn };
        lockInContracts.push(contract);
      }
      // EXPIRING CONTRACTS
      if (TODAY >= finalDate) {
        contract = { ...contract, finalDate };
        expiringContracts.push(contract);
      }
    } else {
      expiredContracts.push(contract);
    }
  });

  useEffect(()=>{
    API.getData(CRSALLLIST)
    .then((res)=>{
        console.log(res?.data.results,"myserrr")
        setAllContractList(res.data.results);
    })
    .catch((err)=>console.log(err))
  },[])


  useEffect(() => {
    let apiContractsData = [];
    let apiMouData = [];
    let apiReminderDays = 30;
    let apiPropertyList = [];


    // setDashboardState({ ...dashboardState, isLoading: true });
    setDashboardState((state) => ({ ...state, isLoading: true }));
    console.log("loader start");
    Promise.allSettled([
      API.getData(PROPERTYLISTCOUNTS),
      API.getData(REMINDERDAYS),
      API.getData(CRSALLLIST),
      API.getData(MOULIST),
    ])
      .then((res) => {
    console.log("my datas");

        res.forEach((ele, i) => {
          const { status, value } = ele;
          if (status === 'fulfilled') {
            if (i === 0) {
              apiPropertyList = value.data.result;
            } else if (i === 1) {
              apiReminderDays = value.data.data[0].reminder_period;
            } else if (i === 2) {
              // contracts

              apiContractsData = value.data.results.filter(
                (contract) =>
                  contract.is_contract === 1 && contract.contract_mou[contract.contract_mou.length - 1]?.document
              );

              console.log("sfdfs")
              // (contract) => contract.is_contract === 1 && contract.contract_mou.pop()?.document
              apiContractsData.forEach((contract) => {
                const propertyName = apiPropertyList?.find(
                  (property) => property.id === contract.property_id
                )?.property_name;
                contract = { ...contract, propertyName: propertyName };
                const startDate = new Date(contract.agreement_start_date);
                // ACTIVE
                const endDate = new Date(contract.agreement_end_date);
                const finalDate = new Date(
                  endDate.getFullYear(),
                  endDate.getMonth(),
                  endDate.getDate() - apiReminderDays
                );
                // LOCK-IN
                const endDateLockIn = new Date(
                  endDate.getFullYear(),
                  endDate.getMonth(),
                  endDate.getDate() - contract.lock_in_period_months * 30
                );
                const finalDateLockIn = new Date(
                  endDateLockIn.getFullYear(),
                  endDateLockIn.getMonth(),
                  endDateLockIn.getDate() - apiReminderDays
                );
                // YOY
                const endDateOneYR = new Date(
                  startDate.getFullYear(),
                  startDate.getMonth() + YOY_MONTHS,
                  startDate.getDate()
                );
                const finalDateYOY = new Date(
                  endDateOneYR.getFullYear(),
                  endDateOneYR.getMonth(),
                  endDateOneYR.getDate() - apiReminderDays
                );
                const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
                const years = Math.floor(timeDifference / MILLISECONDSPERYEAR);
                // const finalDateYOY = new Date(
                //   endDateOneYR.getFullYear(),
                //   endDateOneYR.getMonth(),
                //   endDateOneYR.getDate() - REMINDERDAYSCOUNT
                // );
                if (TODAY <= endDate) {
                  activeContracts.push(contract);
                  // YOY
                  // 1.GET CONTRACT ABOVE THE 1 YEAR &&  MENTION YOY INCREAMENT IN CRS
                  // 2.CREATE FINAL DATES ARRAY && FIX FINALDATEYOY AS CURRENT YEAR
                  if (years && contract.year_on_year_increment && TODAY >= finalDateYOY && TODAY <= endDate) {
                    let updatedEndDateYOY = endDateOneYR;
                    // let updatedFinalDateYOY = finalDateYOY;

                    if (years > 1) {
                      // Adjust endDateOneYR and finalDateYOY based on the number of years
                      for (let i = 1; i < years; i += 1) {
                        if (updatedEndDateYOY > TODAY) {
                          break;
                        }
                        updatedEndDateYOY = new Date(
                          updatedEndDateYOY.getFullYear() + 1,
                          endDateOneYR.getMonth(),
                          endDateOneYR.getDate()
                        );

                        // updatedFinalDateYOY = new Date(
                        //   updatedEndDateYOY.getFullYear(),
                        //   endDateOneYR.getMonth(),
                        //   endDateOneYR.getDate() - REMINDERDAYSCOUNT
                        // );
                      }
                    }
                    contract = { ...contract, endDateYOY: updatedEndDateYOY };
                    yoyContracts.push(contract);
                  }
                  // LOCK IN CONTRACTS
                  if (TODAY >= finalDateLockIn && TODAY <= endDateLockIn) {
                    contract = { ...contract, endDateLockIn };
                    lockInContracts.push(contract);
                  }
                  // EXPIRING CONTRACTS
                  if (TODAY >= finalDate) {
                    contract = { ...contract, finalDate };
                    expiringContracts.push(contract);
                  }
                } else {
                  expiredContracts.push(contract);
                }
              });
            } else if (i === 3) {
              apiMouData = value.data.results;
            }
          }
        });

        console.log('yes test')

        console.log(activeContracts,expiredContracts, expiredContracts, lockInContracts , yoyContracts ,apiReminderDays , "mydatas")
        // setDashboardState({ ...dashboardState, contractsData: apiContractsData, mouData: apiMouData });
        // SETTING STATE IN REDUX
        dispatch(addContractsList(apiContractsData));
        dispatch(adddActiveContracts(activeContracts));
        dispatch(addExpiringContracts(expiringContracts));
        dispatch(addExpiredContracts(expiredContracts));
        dispatch(addLockInContracts(lockInContracts));
        dispatch(addYoyContracts(yoyContracts));
        dispatch(addReminderDays(apiReminderDays));
        setDashboardState((state) => ({
          state,
          isLoading: false,
          contractsData: apiContractsData,
          mouData: apiMouData,
          reminderDays: apiReminderDays,
          propertyList: apiPropertyList,
        }));
      })
      .catch((err) => {
        console.error(err);
        setDashboardState((state) => ({ ...state, isLoading: false }));
      });
  }, []);
  if (dashboardState.isLoading) return <DashboardLoader />;

  const today = new Date();
  console.log(expiringContracts,"express")
  return (
    <>
      <Helmet>
        <title> Contract | BRH </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>
          {/* <Grid item xs={12} md={6} lg={12}>
            <AppNewsUpdate
              title="Reminders"
              list={[...Array(4)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                // image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="MOU"
              total={dashboardState.mouData.length}
              color="info"
              icon={MOUICON}
              boxNumber={BOXMOU}
              selectedBox={selectedBox}
              onSelectedBox={setSelectBox}
            />
          </Grid> */}

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Active contracts"
              total={activeContracts.length}
              color="success"
              // icon={'ant-design:apple-filled'}
              icon={ACTIVEICON}
              boxNumber={BOXACTIVE}
              selectedBox={selectedBox}
              onSelectedBox={setSelectBox}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Expiring contracts"
              total={expiringContracts?.filter((cc)=>cc
                .renewal_status!==3 && cc.renewal_status!==4
              )?.length}
              color="warning"
              // icon={'ant-design:windows-filled'}
              icon={EXPRINGICON}
              boxNumber={BOXEXPIRING}
              selectedBox={selectedBox}
              onSelectedBox={setSelectBox}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Expired contracts"
              total={expiredContracts.length}
              color="error"
              // icon={'ant-design:bug-filled'}
              icon={EXPIREDICON}
              boxNumber={BOXEXPIRED}
              selectedBox={selectedBox}
              onSelectedBox={setSelectBox}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="YOY increment"
              total={yoyContracts.length}
              color="quaternary"
              icon={YOYICON}
              boxNumber={BOXYOY}
              selectedBox={selectedBox}
              onSelectedBox={setSelectBox}
              // icon={'ant-design:android-filled'}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="Lock-in expiring"
              total={lockInContracts.length}
              color="ternary"
              icon={LOCKINICON}
              boxNumber={BOXLOCKIN}
              selectedBox={selectedBox}
              onSelectedBox={setSelectBox}
              // icon={'ant-design:android-filled'}
            />
          </Grid> */}
          {selectedBox === BOXACTIVE && (
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ padding: '8px 16px', textAlign: 'center' }}>
                {activeContracts.length < 1 ? (
                  <>
                    {' '}
                    <Typography style={{ color: 'red' }}>No Active Contracts</Typography>
                  </>
                ) : (
                  <>
                    <AppOrderTimeline
                      title="Active Contracts"
                      // const oneDay = 24 * 60 * 60 * 1000;
                      // const numOfDays = Math.round(Math.abs((endDate - today) / oneDay));
                      list={activeContracts.map((contract) => {
                        const numOfDays = Math.round(
                          Math.abs((new Date(contract?.agreement_end_date) - TODAY) / ONEDAY)
                        ); //
                        return {
                          id: contract.id,
                          title: `${contract?.contract_client.company_name} | ${moment(contract.agreement_start_date).format(
                            'DD - MMM - YYYY'
                          )} |
                 ${moment(contract.agreement_end_date).format('DD - MMM - YYYY')}`,
                          date: `Ends in ${numOfDays} days`,
                        };
                      })}
                    />
                  </>
                )}
              </Card>
            </Grid>
          )}
          {selectedBox === BOXEXPIRING && (
            <Grid item md={12}>
              <Card sx={{ padding: '16px 8px' }}>
                {expiringContracts.length === 0 ? (
                  <Typography style={{ color: 'red' }}>No Data Found</Typography>
                ) : (
                  <>
                    <Box>
                      <Stack spacing={2} direction="row" justifyContent="space-between">
                        <Typography variant="h4">
                          Notification <span style={{ color: palette.primary.dark }}>|</span>{' '}
                          <span className={styles.notificationTitle}>expiring soon</span>
                        </Typography>
                        <Box>
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => navigate(routeNames.RENEWALCONTRACT)}
                          >
                            View All
                          </Button>
                        </Box>
                      </Stack>
                    </Box>

                    <p className={styles.notificationSummary}>contract is about to expire and take necessary action.</p>
                    <Box className={styles.notificationContainer}>
                      <ul>
                        {expiringContracts.filter((cc)=>cc
                        .renewal_status!==3 && cc.renewal_status!==4
                      ).map((contract) => (
                          <NotificationItem key={contract.id} contract={contract} />
                        ))}
                      </ul>
                    </Box>

                    {expiringContracts.length >0  && (
                      <>
                     <Box>
                     <Stack spacing={2} direction="row" justifyContent="space-between">
                       <Typography variant="h4">
                         Renewed Contracts 
                         {/* <span className={styles.notificationTitle}>expiring soon</span> */}
                       </Typography>
                       <Box>
                          <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => navigate(routeNames.CONTRACT)}
                          >
                            View All
                          </Button>
                        </Box>
                      

                     </Stack>
                   </Box>
                   <Box className={styles.notificationContainer}>
                    
                   <ul>
                    
             
                   {AllContractList
  .filter((contract) => 
    expiringContracts.some((cc) => cc.id === contract.renewal_contract_id && cc.renewal_status !== 4 )
  )
  .map((contract) => (
    <NotificationTwo key={contract.contract_id} contract={contract} />
  ))}
                   </ul>
                 </Box>
                 </>
                   ) }
                  </>
                )}
              </Card>
            </Grid>
          )}
          {selectedBox === BOXEXPIRED && (
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ padding: '8px 16px', textAlign: 'center' }}>
                {expiredContracts.length < 1 ? (
                  <>
                    {' '}
                    <Typography style={{ color: 'red' }}>No Expired Contracts</Typography>
                  </>
                ) : (
                  <>
                    <AppOrderTimeline
                      title="Expired Contracts"
                      // const oneDay = 24 * 60 * 60 * 1000;
                      // const numOfDays = Math.round(Math.abs((endDate - today) / oneDay));
                      list={expiredContracts.map((contract) => {
                        const numOfDays = Math.round(Math.abs((new Date(contract?.endDate) - TODAY) / ONEDAY)); //
                        return {
                          id: contract.id,
                          title: `${contract?.contract_client.company_name} | ${moment(contract.agreement_start_date).format(
                            'DD - MMM - YYYY'
                          )} |
                ${moment(contract.agreement_end_date).format('DD - MMM - YYYY')}`,
                          date: `Ends in ${numOfDays} days`,
                        };
                      })}
                    />
                  </>
                )}
              </Card>
            </Grid>
          )}
          {selectedBox === BOXYOY && (
            <Grid item xs={12} md={12} lg={12}>
              <Card sx={{ padding: '8px 16px', textAlign: 'center' }}>
                {yoyContracts.length < 1 ? (
                  <>
                    {' '}
                    <Typography style={{ color: 'red' }}>No YOY Expiring Contracts</Typography>
                  </>
                ) : (
                  <>
                    <AppOrderTimeline
                      title="YOY Expiring Contracts"
                      // const oneDay = 24 * 60 * 60 * 1000;
                      // const numOfDays = Math.round(Math.abs((endDate - today) / oneDay));
                      list={yoyContracts.map((contract) => {
                        const numOfDays = Math.round(Math.abs((new Date(contract?.endDateYOY) - TODAY) / ONEDAY)); //
                        return {
                          id: contract.id,
                          title: `${contract?.contract_client.company_name} | ${moment(contract.agreement_start_date).format(
                            'DD - MMM - YYYY'
                          )} |
                ${moment(contract.agreement_end_date).format('DD - MMM - YYYY')}`,
                          date: `Ends in ${numOfDays} days`,
                        };
                      })}
                    />
             
                  </>
                )}
              </Card>
            </Grid>
          )}
          {selectedBox === BOXLOCKIN && (
            <Grid item xs={12} md={12} lg={12}>
              <AppOrderTimeline
                title="Lock-in Expiring Contracts"
                // const oneDay = 24 * 60 * 60 * 1000;
                // const numOfDays = Math.round(Math.abs((endDate - today) / oneDay));
                list={lockInContracts.map((contract) => {
                  const numOfDays = Math.round(Math.abs((new Date(contract?.endDateLockIn) - TODAY) / ONEDAY)); //
                  return {
                    id: contract.id,
                    title: `${contract.propertyName} | ${moment(contract.agreement_start_date).format(
                      'DD - MMM - YYYY'
                    )} |
                ${moment(contract.agreement_end_date).format('DD - MMM - YYYY')}`,
                    date: `Ends in ${numOfDays} days`,
                  };
                })}
              />
            </Grid>
          )}

          {/* ------------> DON'T DELETE, IN FUTURE IT WILL HELP TO MAKE CHARTS <------------ */}
          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
