import React, { useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, Stack, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import GrassIcon from '@mui/icons-material/Grass';

import brhLogo from '../../../assets/BRH_logo.png';
import './nav.css';
import palette from '../../../theme/palette';
import { routeNames } from './routeNames';

export const NavBRH = ({ open }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleChange = (isExpanded, panel) => {
    setExpanded(isExpanded ? panel : false);
  };

  const drawerWidth = 275;
  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    // overflowY: 'hidden',
  });
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  // set sticky brh logo

  const AccordionSummaryBRH = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // backgroundColor: 'lightgreen',
    // padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  }));
  const AccordionSummaryTypographyBRH = styled('div')(({ theme }) => ({
    paddingLeft: '16px',
    textTransform: 'capitalize',
    color: theme.palette.common.black,
  }));

  const accodionStyles = {
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '16px',
    boxShadow: ' 0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(5px)',
    border: ' 1px solid rgba(255, 255, 255, 0.3)',
  };
  const accodionSummaryStyles = {
    backgroundColor: 'rgba(255,255,255,0.2)',
    borderRadius: '10px',
  };
  const accordionSummaryIconStyles = {
    // color: `${palette.secondary.darker}`,
    color: `${palette.common.black}`,
  };
  const menuIconStyles = {
    fontSize: '10px',
    marginRight: '16px',
    color: `${palette.primary.main}`,
  };
  const menuButtonStyles = {
    justifyContent: 'start',
    color: `${palette.common.white}`,
  };

  const isDashBoardActive =
    pathname === routeNames.INDEX || pathname === routeNames.LOGIN || pathname === routeNames.FORGOTPASSWORD;
  const isMasterActive =
    pathname === routeNames.PROSPECTS ||
    pathname === routeNames.PROSPECTSADD ||
    pathname === routeNames.PROSPECTSEDIT ||
    pathname === routeNames.CLIENTS ||
    pathname === routeNames.CLIENTSADD ||
    pathname === routeNames.CLIENTSEDIT;
  const isCrsActive = pathname === routeNames.CRS || pathname === routeNames.CRSADD || pathname === routeNames.CRSEDIT;
  const isPrsActive = pathname === routeNames.PRS || pathname === routeNames.PRSADD || pathname === routeNames.PRSEDIT;

  const isMOUActive = pathname === routeNames.MOU || pathname === routeNames.MOUADD || pathname === routeNames.MOUEDIT;
  const isContractActive =
    pathname === routeNames.CONTRACT ||
    pathname === routeNames.CONTRACTADD ||
    pathname === routeNames.CONTRACTEDIT ||
    pathname === routeNames.RENEWALCONTRACT;
  const isSettingsActive =
    pathname === routeNames.COMPANYMASTER ||
    pathname === routeNames.COMPANYMASTERADD ||
    pathname === routeNames.COMPANYMASTEREDIT;
  const isReportsActive = pathname === `${routeNames.CONTRACTTEMPLATES} `;

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <header className="header-brh">
            <Button
              onClick={() => {
                navigate('/');
              }}
            >
              <img src={brhLogo} style={{ width: '100%' }} alt="BRH logo" />
            </Button>
          </header>
        </DrawerHeader>
        {/* <Box sx={{ px: 2.5 }}>
          <DashboardIcon color="inherit" />
        </Box> */}
        <div className="nav_back">
          <Box sx={{ padding: open ? '16px 10px 0px 10px' : '10px 0px 0px 0px' }}>
            <Stack direction="column" spacing={4}>
              {/* DashBoard */}
              {/* dashboard with notification and reminder options */}
              {/* <Box>
                {open ? (
                  <>
                    <Accordion
                      expanded={expanded === 'panel1'}
                      onChange={(event, isExpanded) => handleChange(isExpanded, 'panel1')}
                      sx={{ ...accodionStyles }}
                    >
                      <AccordionSummary
                        id="panel1-header"
                        aria-controls="panel1-content"
                        expandIcon={<ExpandMoreRoundedIcon />}
                        sx={{
                          ...accodionSummaryStyles,
                          backgroundColor: isDashBoardActive ? `${palette.secondary.lighter}` : 'inherit',
                        }}
                      >
                        <AccordionSummaryBRH>
                          <DashboardIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isDashBoardActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isDashBoardActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography variant="button">dashboard</Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="column" spacing={1} sx={{ justifyContent: 'start' }}>
                          <Button
                            sx={{ ...menuButtonStyles }}
                            // style={{ ...menuButtonStyles, color: 'red' }}
                            onClick={() => navigate('/contracts/contracts')}
                          >
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button" sx={{ color: '#fff' }}>
                              Contracts
                            </Typography>
                          </Button>
                          <Button sx={{ ...menuButtonStyles }}>
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">Notifications</Typography>
                          </Button>
                          <Button sx={{ ...menuButtonStyles }}>
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">Reminders</Typography>
                          </Button>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <DashboardIcon sx={{ color: isDashBoardActive ? `${palette.primary.dark}` : 'inherit' }} />
                    </Box>
                  </>
                )}
              </Box> */}
              {/* dashboard without notification and reminder options */}
              <Box>
                {open ? (
                  <>
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      onClick={() => navigate(`${routeNames.INDEX}`)}
                    >
                      <Box
                        sx={{
                          ...accodionSummaryStyles,
                          padding: '12px 10px',
                          backgroundColor: isDashBoardActive ? `${palette.secondary.lighter}` : 'rgba(255,255,255,0.2)',
                        }}
                      >
                        <AccordionSummaryBRH>
                          <DashboardIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isDashBoardActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isDashBoardActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography color={(theme) => theme.palette.secondary} variant="button">
                              Dashboard
                            </Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </Box>
                    </button>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <DashboardIcon sx={{ color: isDashBoardActive ? `${palette.primary.dark}` : 'inherit' }} />
                    </Box>
                  </>
                )}
              </Box>
              {/* Master */}
              <Box>
                {open ? (
                  <>
                    <Accordion
                      expanded={expanded === 'panel2'}
                      onChange={(event, isExpanded) => handleChange(isExpanded, 'panel2')}
                      sx={{
                        ...accodionStyles,
                      }}
                    >
                      <AccordionSummary
                        id="panel2-header"
                        aria-controls="panel2-content"
                        expandIcon={<ExpandMoreRoundedIcon />}
                        sx={{
                          ...accodionSummaryStyles,
                          backgroundColor: isMasterActive ? `${palette.secondary.lighter}` : 'inherit',
                        }}
                      >
                        <AccordionSummaryBRH>
                          <ListAltOutlinedIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isMasterActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isMasterActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography color={(theme) => theme.palette.secondary} variant="button">
                              masters
                            </Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="column" spacing={1} sx={{ justifyContent: 'start' }}>
                          <Button
                            sx={{
                              ...menuButtonStyles,
                              color:
                                pathname === routeNames.PROSPECTS || pathname === routeNames.PROSPECTSADD
                                  ? `${palette.common.black}`
                                  : `${menuButtonStyles.color}`,
                            }}
                            onClick={() => navigate(`${routeNames.PROSPECTS}`)}
                          >
                            <CircleIcon
                              sx={{
                                ...menuIconStyles,
                                color:
                                  pathname === routeNames.PROSPECTS || pathname === routeNames.PROSPECTSADD
                                    ? ''
                                    : `${palette.primary.main}`,
                              }}
                            />
                            <Typography variant="button">prospects</Typography>
                          </Button>
                          <Button
                            sx={{
                              ...menuButtonStyles,
                              color:
                                pathname === routeNames.CLIENTS || pathname === routeNames.CLIENTSADD
                                  ? `${palette.common.black}`
                                  : `${menuButtonStyles.color}`,
                            }}
                            onClick={() => navigate(`${routeNames.CLIENTS}`)}
                          >
                            <CircleIcon
                              sx={{
                                ...menuIconStyles,
                                color:
                                  pathname === routeNames.CLIENTS || pathname === routeNames.CLIENTSADD
                                    ? ''
                                    : `${palette.primary.main}`,
                              }}
                            />
                            <Typography variant="button">clients</Typography>
                          </Button>
                          <Button
                            sx={{
                              ...menuButtonStyles,
                              color:
                                pathname === routeNames.PROSPECTPROPERTYLIST ||
                                pathname === routeNames.PROSPECTPROPERTYADD
                                  ? `${palette.common.black}`
                                  : `${menuButtonStyles.color}`,
                            }}
                            onClick={() => navigate(`${routeNames.PROSPECTPROPERTYLIST}`)}
                          >
                            <CircleIcon
                              sx={{
                                ...menuIconStyles,
                                color:
                                  pathname === routeNames.PROSPECTPROPERTYLIST ||
                                  pathname === routeNames.PROSPECTPROPERTYADD
                                    ? ''
                                    : `${palette.primary.main}`,
                              }}
                            />
                            <Typography variant="button">Scouted Properties</Typography>
                          </Button>
                          <Button
                            sx={{
                              ...menuButtonStyles,
                              color:
                                pathname === routeNames.PROPERTYAGREEMENTSLIST ||
                                pathname === routeNames.PROPERTYAGREEMENTSADD
                                  ? `${palette.common.black}`
                                  : `${menuButtonStyles.color}`,
                            }}
                            onClick={() => navigate(`${routeNames.PROPERTYAGREEMENTSLIST}`)}
                          >
                            <CircleIcon
                              sx={{
                                ...menuIconStyles,
                                color:
                                  pathname === routeNames.PROPERTYAGREEMENTSLIST ||
                                  pathname === routeNames.PROPERTYAGREEMENTSADD
                                    ? ''
                                    : `${palette.primary.main}`,
                              }}
                            />
                            <Typography variant="button">Property</Typography>
                          </Button>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <ListAltOutlinedIcon sx={{ color: isMasterActive ? `${palette.primary.dark}` : 'inherit' }} />
                    </Box>
                  </>
                )}
              </Box>
              {/* PRS */}
              <Box>
                {open ? (
                  <>
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      onClick={() => navigate(`${routeNames.PRS}`)}
                    >
                      <Box
                        sx={{
                          ...accodionSummaryStyles,
                          padding: '12px 10px',
                          backgroundColor: isPrsActive ? `${palette.secondary.lighter}` : 'rgba(255,255,255,0.2)',
                        }}
                      >
                        <AccordionSummaryBRH>
                          <GrassIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isPrsActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isPrsActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography color={(theme) => theme.palette.secondary} variant="button">
                              PRS
                            </Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </Box>
                    </button>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <GrassIcon sx={{ color: isPrsActive ? `${palette.primary.dark}` : 'inherit' }} />
                    </Box>
                  </>
                )}
              </Box>
              {/* CRS */}
              <Box>
                {open ? (
                  <>
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        width: '100%',
                      }}
                      onClick={() => navigate(`${routeNames.CRS}`)}
                    >
                      <Box
                        sx={{
                          ...accodionSummaryStyles,
                          padding: '12px 10px',
                          backgroundColor: isCrsActive ? `${palette.secondary.lighter}` : 'rgba(255,255,255,0.2)',
                        }}
                      >
                        <AccordionSummaryBRH>
                          <CorporateFareOutlinedIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isCrsActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isCrsActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography color={(theme) => theme.palette.secondary} variant="button">
                              CRS
                            </Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </Box>
                    </button>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <CorporateFareOutlinedIcon sx={{ color: isCrsActive ? `${palette.primary.dark}` : 'inherit' }} />
                    </Box>
                  </>
                )}
              </Box>
              {/* Mou */}
              <Box>
                {open ? (
                  <>
                    <button
                      style={{ background: 'none', border: 'none', cursor: 'pointer', width: '100%' }}
                      onClick={() => navigate(`${routeNames.MOU}`)}
                    >
                      <Box
                        sx={{
                          ...accodionSummaryStyles,
                          padding: '12px 10px',
                          backgroundColor: isMOUActive ? `${palette.secondary.lighter}` : 'rgba(255,255,255,0.2)',
                        }}
                      >
                        <AccordionSummaryBRH>
                          <AutoStoriesOutlinedIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isMOUActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isMOUActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography color={(theme) => theme.palette.secondary} variant="button">
                              MOU
                            </Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </Box>
                    </button>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <AutoStoriesOutlinedIcon sx={{ color: isMOUActive ? `${palette.primary.dark}` : 'inherit' }} />
                    </Box>
                  </>
                )}
              </Box>
              {/* Contracts */}
              <Box>
                {open ? (
                  <>
                    <Accordion
                      expanded={expanded === 'panel5'}
                      onChange={(event, isExpanded) => handleChange(isExpanded, 'panel5')}
                      sx={{ ...accodionStyles }}
                    >
                      <AccordionSummary
                        id="panel5-header"
                        aria-controls="panel5-content"
                        expandIcon={<ExpandMoreRoundedIcon />}
                        sx={{
                          ...accodionSummaryStyles,
                          backgroundColor: isContractActive ? `${palette.secondary.lighter}` : 'inherit',
                        }}
                      >
                        <AccordionSummaryBRH>
                          <ReceiptLongOutlinedIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isContractActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isContractActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography variant="button">Contracts</Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="column" spacing={1} sx={{ justifyContent: 'start' }}>
                          <Button sx={{ ...menuButtonStyles }} onClick={() => navigate(`${routeNames.CONTRACT}`)}>
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">Contracts</Typography>
                          </Button>
                          <Button
                            sx={{ ...menuButtonStyles }}
                            onClick={() => navigate(`${routeNames.RENEWALCONTRACT}`)}
                          >
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">Renewal Due Contracts</Typography>
                          </Button>
                          <Button
                            sx={{ ...menuButtonStyles }}
                            onClick={() => navigate(`${routeNames.CANCELCONTRACT}`)}
                          >
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">Cancelled Contracts</Typography>
                          </Button>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <ReceiptLongOutlinedIcon
                        sx={{ color: isReportsActive ? `${palette.primary.dark}` : 'inherit' }}
                      />
                    </Box>
                  </>
                )}
              </Box>
              {/* <Box>
                {open ? (
                  <>
                    <button
                      style={{ background: 'none', border: 'none', cursor: 'pointer', width: '100%' }}
                      onClick={() => navigate(`${routeNames.CONTRACT}`)}
                    >
                      <Box
                        sx={{
                          ...accodionSummaryStyles,
                          padding: '12px 10px',
                          backgroundColor: isContractActive ? `${palette.secondary.lighter}` : 'rgba(255,255,255,0.2)',
                        }}
                      >
                        <AccordionSummaryBRH>
                          <ReceiptLongOutlinedIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isContractActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isContractActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography color={(theme) => theme.palette.secondary} variant="button">
                              Contracts
                            </Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </Box>
                    </button>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <ReceiptLongOutlinedIcon
                        sx={{ color: isContractActive ? `${palette.primary.dark}` : 'inherit' }}
                      />
                    </Box>
                  </>
                )}
              </Box> */}
              {/* reports */}
              <Box>
                {open ? (
                  <>
                    <Accordion
                      expanded={expanded === 'panel4'}
                      onChange={(event, isExpanded) => handleChange(isExpanded, 'panel4')}
                      sx={{ ...accodionStyles }}
                    >
                      <AccordionSummary
                        id="panel4-header"
                        aria-controls="panel4-content"
                        expandIcon={<ExpandMoreRoundedIcon />}
                        sx={{ ...accodionSummaryStyles }}
                      >
                        <AccordionSummaryBRH>
                          <AssessmentOutlinedIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isReportsActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isReportsActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography variant="button">reports</Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="column" spacing={1} sx={{ justifyContent: 'start' }}>
                          <Button sx={{ ...menuButtonStyles }}>
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">contract templates</Typography>
                          </Button>
                          <Button sx={{ ...menuButtonStyles }}>
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">transaction templates</Typography>
                          </Button>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <AssessmentOutlinedIcon sx={{ color: isReportsActive ? `${palette.primary.dark}` : 'inherit' }} />
                    </Box>
                  </>
                )}
              </Box>
              {/* Settings */}
              <Box>
                {open ? (
                  <>
                    <Accordion
                      expanded={expanded === 'panel3'}
                      onChange={(event, isExpanded) => handleChange(isExpanded, 'panel3')}
                      sx={{ ...accodionStyles }}
                    >
                      <AccordionSummary
                        id="panel3-header"
                        aria-controls="panel3-content"
                        expandIcon={<ExpandMoreRoundedIcon />}
                        sx={{
                          ...accodionSummaryStyles,
                          backgroundColor: isSettingsActive ? `${palette.secondary.lighter}` : 'inherit',
                        }}
                      >
                        <AccordionSummaryBRH>
                          <SettingsOutlinedIcon
                            sx={{
                              ...accordionSummaryIconStyles,
                              color: isSettingsActive ? `${palette.secondary.darker}` : 'inherit',
                            }}
                          />
                          <AccordionSummaryTypographyBRH
                            style={{
                              color: isSettingsActive ? `${palette.secondary.dark}` : 'inherit',
                            }}
                          >
                            <Typography variant="button">settings</Typography>
                          </AccordionSummaryTypographyBRH>
                        </AccordionSummaryBRH>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="column" spacing={1} sx={{ justifyContent: 'start' }}>
                          <Button
                            sx={{
                              ...menuButtonStyles,
                              color:
                                pathname === routeNames.COMPANYMASTER
                                  ? `${palette.common.black}`
                                  : `${menuButtonStyles.color}`,
                            }}
                            onClick={() => navigate(`${routeNames.COMPANYMASTER}`)}
                          >
                            <CircleIcon
                              sx={{
                                ...menuIconStyles,
                                color: pathname === routeNames.COMPANYMASTER ? '' : `${palette.primary.main}`,
                              }}
                            />
                            <Typography variant="button">MG master</Typography>
                          </Button>

                          {/* <Button sx={{ ...menuButtonStyles }}>
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">contract templates</Typography>
                          </Button> */}
                          {/* <Button sx={{ ...menuButtonStyles }}>
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">signatories</Typography>
                          </Button> */}
                          {/* onClick={() => navigate(routeNames.NOTIFICATION)} */}
                          {/* <Button sx={{ ...menuButtonStyles }}>
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">notification</Typography>
                          </Button> */}
                          <Button sx={{ ...menuButtonStyles }} onClick={() => navigate(routeNames.REMINDERSETTINGS)}>
                            <CircleIcon sx={{ ...menuIconStyles }} />
                            <Typography variant="button">Reminders</Typography>
                          </Button>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : (
                  <>
                    <Box sx={{ px: 2.5 }}>
                      <SettingsOutlinedIcon sx={{ color: isSettingsActive ? `${palette.primary.dark}` : 'inherit' }} />
                    </Box>
                  </>
                )}
              </Box>
            </Stack>
          </Box>
        </div>
      </Drawer>
      <Divider />
    </>
  );
};
