/* eslint-disable camelcase */
/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Box, Button, Card, Container, IconButton, Snackbar, Stack, Tooltip, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Navigate, useNavigate } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import {
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
  MRT_GlobalFiltersssTextField,
  MRT_ToggleDensePaddingButton,
  MRT_GlobalFilterTextField,
} from 'material-react-table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RejectIcon from '@mui/icons-material/GppBad';
import VerifyIcon from '@mui/icons-material/Beenhere';
import Completed from '@mui/icons-material/ThumbUpOffAlt';
import WaitIcon from '@mui/icons-material/HourglassBottom';
// import ContractIcon from '@mui/icons-material/Description';
import SignedContractIcon from '@mui/icons-material/AssignmentTurnedIn';
import UNSignedContractIcon from '@mui/icons-material/AssignmentReturned';

import { TableSkeleton } from '../../components/skeleton/TableSkeleton';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { CONTRACTFILEUPLOAD, CRSALLLIST, CRSLIST } from '../../API_SERVICE_LAYER/ApiNames';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
import { NormalTable } from '../../components/tables/NormalTable';
import palette from '../../theme/palette';
import { CAT_HEAD_APPROVED, CAT_HEAD_REJECTED, CAT_LEAD_REJECTED, CAT_LEAD_VERFIED } from '../../config/BRHConfig';
import { FileInputBRH } from '../../components/forms/FileInputBRH';
import { contractContext } from '../../components/context/AppContext';
import { ToastBRH } from '../../components/toasts/ToastBRH';
import MouAlert from '../mous/MouAlert';
import { TextFieldSelectBRH } from '../../components/forms/TextFieldSelectBRH';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/toastTimings';

// const MRTFullScreenToggleButton = <MRT_FullScreenToggleButton />;
// const MRTToggleDensePaddingButton = <MRT_ToggleDensePaddingButton />;

// FOR APPROVED STATUS
const STATUS_CAT_LEAD_PENDING = 1;
const STATUS_CAT_LEAD_REJECTED = 2;
const STATUS_CAT_HEAD_PENDING = 3;
const STATUS_CAT_HEAD_REJECTED = 4;
const STATUS_CONTRACT_FILE_PENDING = 5;
const STATUS_CONTRACT_FILE_UPLOADED = 6;
const NO_STATUS = 7;

const approvedStatusOptions = [
  {
    label: 'Cat Lead  Pending',
    value: STATUS_CAT_LEAD_PENDING,
  },
  {
    label: 'Cat Lead  Rejected',
    value: STATUS_CAT_LEAD_REJECTED,
  },
  {
    label: 'Unit Head  Pending',
    value: STATUS_CAT_HEAD_PENDING,
  },
  {
    label: 'Unit Head  Rejected',
    value: STATUS_CAT_HEAD_REJECTED,
  },
  {
    label: 'Upload Pending',
    value: STATUS_CONTRACT_FILE_PENDING,
  },
  {
    label: ' Completed',
    value: STATUS_CONTRACT_FILE_UPLOADED,
  },
  {
    label: 'Select',
    value: NO_STATUS,
  },
];

export const ContractsListPage = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState(new Date().toTimeString());
  const [isFilterationCompleted, setIsFilterationCompleted] = useState(true);
  const [contractsData, setContractsData] = useState({
    isFetched: false,
    contractsListData: [],
    isActive: false,
    isnewRenwal: false,
    approvedStatus: NO_STATUS,
  });
  const [newHistory, setNewHistory] = useState();

  const [renData, setRenData] = useState([]);
  const [snackbarDetails, setSnackbarDetails] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isLead, setIsLead] = useState();
  const [isOkay, setIsOkay] = useState();
  const [open, setOpen] = useState(false);
  const [popupId, setPopupId] = useState();
  const [contractId, setContractId] = useState();
  const [pdfViewedStatus, setPdfViewedStatus] = useState(() => {
    const storedStatus = localStorage.getItem('conractPdfViewedStatus');
    return storedStatus ? JSON.parse(storedStatus) : {};
  });
  const [userDetails, setUserDetails] = useState(() => {
    const user = localStorage.getItem('user');
    return JSON.parse(user);
  });
  const [file, setFile] = useState();
  const { propertyList } = useContext(contractContext);

  // console.log(contractsData,"vvdd")

  // filtering for active and history
  // const filteredContractListData = contractsData.isActive
  //   ? contractsData.contractsListData.reduce((acc, contract) => {
  //       const today = new Date();
  //       const agreeEndDate = new Date(contract.agreement_end_date);
  //       return agreeEndDate > today &&
  //         contract.is_contract === 1 &&
  //         contract.contract_mou[contract.contract_mou.length - 1]?.document
  //         ? [...acc, contract]
  //         : acc;
  //     }, [])
  //   : contractsData.contractsListData;

  const filteredContractListData = contractsData.isActive
    ? contractsData.contractsListData.reduce((acc, contract) => {
        const today = new Date();
        const agreeEndDate = new Date(contract?.agreement_end_date);
        return agreeEndDate > today &&
          contract.is_contract === 1 &&
          contract.contract_mou[contract.contract_mou.length - 1]?.document
          ? [...acc, contract]
          : acc;
      }, [])
    : contractsData.contractsListData;

  // const RenewecontractListData=   contractsData.isnewRenwal.reduce((acc, contract) => {
  //   const today = new Date();
  //   const agreeStartDate = new Date(contract.agreement_start_date);
  //   return today > agreeStartDate &&
  //     contract.is_contract === 1 &&
  //     contract.contract_mou[contract.contract_mou.length - 1]?.document
  //     ? [...acc, contract]
  //     : acc;
  // }, [])

  const handleFileUpload = (e) => {
    // setContractsData({ ...contractsData, file: e.target.files[0] });
    setFile(e.target.files[0]);
  };
  const handleView = (rowData) => {
    // console.log('laksh-handleView', rowData);
    window.open(
      `${process.env.REACT_APP_IMAGE_URL}/${rowData.direct_contract === 1 ? rowData?.unsigned_document : rowData?.unsigned_document}`
    );
    const updatedStatus = {
      ...pdfViewedStatus,
      [rowData?.id]: true,
    };
    setPdfViewedStatus(updatedStatus);

    // Update local storage with the new status
    localStorage.setItem('conractPdfViewedStatus', JSON.stringify(updatedStatus));
    // setFile();
  };


  const handleNextView = (rowData) => {
    console.log('laksh-handleView', rowData);
    window.open(
      `${process.env.REACT_APP_IMAGE_URL}/${rowData.direct_contract === 1 ? rowData?.unsigned_document : rowData?.unsigned_document}`
    );
    const updatedStatus = {
      ...pdfViewedStatus,
      [rowData?.id]: true,
    };
    setPdfViewedStatus(updatedStatus);

    // Update local storage with the new status
    localStorage.setItem('conractPdfViewedStatus', JSON.stringify(updatedStatus));
    // setFile();
  };
  const handleSnackbar = () => {
    handleView(snackbarDetails);
    setSnackbarOpen(false);
  };

  const handleAnotherVerify = (isLead, isOkay, id, contractId, rowData) => {
    // console.log(isLead, isOkay, id, contractId, rowData,"myverification")
    // const pdfViewedLocal = localStorage.getItem('conractPdfViewedStatus');
    setIsLead(isLead);
    setIsOkay(isOkay);
    setPopupId(id);
    setContractId(contractId);
    if (pdfViewedStatus[id]) {
      setOpen(true);
    } 
  }

  const handleVerifyApply = (isLead, isOkay, id, contractId, rowData) => {
    // console.log(isLead, isOkay, id, contractId, rowData,"myverification")
    // const pdfViewedLocal = localStorage.getItem('conractPdfViewedStatus');
    setIsLead(isLead);
    setIsOkay(isOkay);
    setPopupId(id);
    setContractId(contractId);
    if (pdfViewedStatus[id]) {
      setOpen(true);
    } else {
      // ToastBRH('warning', 'You need to view the file before approve or to verify', '_', false, 4000);
      setSnackbarOpen(true);
      setSnackbarDetails(rowData);

      return setTimeout(() => setSnackbarOpen(false), 6000);
    }
    return 1;
  };

  const handleSubmit = (e, id, file) => {
    e.preventDefault();
    const formData = new FormData();
    // formData.append('template_path', 'lak');
    formData.append('id', id);
    formData.append('file', file);
    API.fileupload(CONTRACTFILEUPLOAD, formData)
      .then((res) => {
        if (res?.data?.status === 'success') {
          // ToastBRH('success', `Contract Uploaded Successfully`, `${routeNames.CONTRACT}`);
          ToastBRH('success', `Contract Uploaded Successfully`, TOAST_SUCCESS);
          setTimeout(() => setKey(new Date().toTimeString()), TOAST_SUCCESS);
        } else {
          // ToastBRH('warning', 'Something went wrong! Please try again later', '_', false, 4000);
          ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
        }
      })
      .catch((err) => console.log(err));
  };

  function handleActiveContract(isActive, isnewRenwal) {
    return setContractsData({
      ...contractsData,
      isActive: isActive,
      isnewRenwal: isnewRenwal,
    });
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'contract_client.company_name',
        header: 'Client Name',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <Button
                variant="text"
                sx={{ background: 'none', cursor: 'pointer', border: 'none', color: '#3d464f' }}
                // onClick={
                //   () => {
                //     navigate(`${routeNames.CRS}/edit/${rowData?.id}`, {
                //       state: { isUpdated: true, updatedId: rowData?.id },
                //     });
                //   }
                // }
              >
                {/* {rowData?.contract_client?.company_name} */}
                <span>{cell.getValue()}</span>
              </Button>
            </>
          );
        },
        filterVariant: 'text',
        enableColumnFilter: true,
        size: 150,
      },

      {
        accessorKey: 'propertyData.property_name',
        header: 'Property Name ',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          // console.log(rowData,"ssssdddsss")
          return (
            <>
              <span>
                {rowData?.propertyData ? rowData?.propertyData.property_name : rowData?.property_name}
              </span>
            </>
          );
        },
        filterVariant: 'text',
        enableColumnFilter: true,
        size: 150,
      },

      {
        accessorKey: 'agreement_start_date',
        header: 'Start Date',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              {/* <span>{moment(rowData.agreement_start_date).format('DD - MMM - YYYY')}</span> */}
              <span>{moment(cell.getValue()).format('DD - MMM - YYYY')}</span>

              {/* <span>{cell.getValue()}</span> */}
            </>
          );
        },
        size: 150,
        enableColumnFilter: false,
      },
      {
        accessorKey: 'agreement_end_date',
        header: 'End Date',
        // accessorFn: (originalRow) => new Date(originalRow.agreement_end_date), // convert to date for sorting and filtering
        accessorFn: (row) => {
          new Date(row.agreement_end_date);
        }, // convert to Date for sorting and filtering
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <span>{moment(rowData.agreement_end_date).format('DD - MMM - YYYY')}</span>

              {/* <span>{moment(cell.getValue()).format('DD - MMM - YYYY')}</span> */}
            </>
          );
        },
        size: 150,
        enableColumnFilter: false,
      },
    ],
    []
  );

  const RenewedmouColumns = useMemo(
    () => [
      // mou
      // {
      //   accessorKey: 'id',
      //   header: 'Contract Type',
      //   Cell: ({ cell }) => {
      //     const rowData =cell?.row?.original;
      //    if(rowData?.is_renewal_contract===1) {
      //     if ((rowData.direct_contract === 1 )  && (rowData?.contractMou? rowData?.contractMou?.is_renewal_contract===0 : rowData.is_renewal_contract===0 )  ) {
      //       return (
      //         <Typography variant="overline" color="seagreen">
      //           Direct Contract
      //         </Typography>
      //       );
      //     }

      //     // Condition for renewal contract
      //     if ((rowData.direct_contract === 1 )  && (rowData?.contractMou? rowData?.contractMou?.is_renewal_contract===1 : rowData.is_renewal_contract===1 )  ) {

      //       return (
      //         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      //           <Typography variant="overline" color="orange">Renewed Contract</Typography>

      //         </Box>
      //       );
      //     }

      //     // Default case for MOU
      //     return (
      //       <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      //         <Typography variant="overline" color="seagreen">MOU</Typography>
      //         <Tooltip title={`View MOU`}>
      //           <IconButton target="_blank" onClick={() => handleView(rowData)}>
      //             <VisibilityIcon color="primary" />
      //           </IconButton>
      //         </Tooltip>
      //       </Box>
      //     );
      //   }
      //   },
      //   size: 150,
      // },

      // new
      {
        accessorKey: 'id',
        header: 'Contract Type',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;

          if (rowData?.is_renewal_contract === 1) {
            if (
              rowData.direct_contract === 1 &&
              (rowData?.contractMou
                ? rowData?.contractMou?.is_renewal_contract === 0
                : rowData.is_renewal_contract === 0)
            ) {
              return (
                <Typography variant="overline" color="seagreen">
                  Direct Contract
                </Typography>
              );
            }

            if (
              rowData.direct_contract === 1 &&
              (rowData?.contractMou
                ? rowData?.contractMou?.is_renewal_contract === 1
                : rowData.is_renewal_contract === 1)
            ) {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="overline" color="orange">
                    Renewed Contract
                  </Typography>
                </Box>
              );
            }

            return (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="overline" color="seagreen">
                  {/* MOU */}
                  Contract
                </Typography>
                {/* <Tooltip title={`View MOU`}>
                  <IconButton target="_blank" onClick={() => handleView(rowData)}>
                    <VisibilityIcon color="primary" />
                  </IconButton>
                </Tooltip> */}
              </Box>
            );
          }

          // Default return statement
          return (
            <Typography variant="overline" color="gray">
              Unknown Contract Type
            </Typography>
          );
        },
        size: 150,
      },

      // pdf
      {
        accessorKey: 'pdf',
        header: `Contract`,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          // Condition for renewal contract
          if (
            rowData.direct_contract === 1 &&
            (rowData?.contractMou ? rowData?.contractMou?.is_renewal_contract === 1 : rowData.is_renewal_contract === 1)
          ) {
            // console.log("aaaaabbbbb")
            return (
              // <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              //   <Typography variant="overline" color="orange">Renewal Contract</Typography>

              // </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Tooltip
                  title={`view UN-Signed Contract`}
                  style={{ display: rowData?.unsigned_document ? 'inline-block' : 'none' }}
                >
                  {/* <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${cell.getValue()}`}> */}
                  <IconButton  onClick={()=>handleNextView(rowData)} >
                    <UNSignedContractIcon color="info" /> 
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={`view Signed Contract`}
                  style={{ display: rowData?.contractMou?.document ? 'inline-block' : 'none' }}
                >
                  {/* <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${cell.getValue()}`}> */}
                  <IconButton
                    target="_blank"
                    href={`${process.env.REACT_APP_IMAGE_URL}/${rowData.contractMou?.document}`}
                  >
                    <SignedContractIcon color="success" />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          }
          //  console.log("ccccdddd");

          return (
            <>
              <Box sx={{ textAlign: 'center' }}>
                <Tooltip
                  title={`view UN-Signed Contract`}
                  style={{ display: rowData?.unsigned_document ? 'inline-block' : 'none' }}
                >
                  {/* <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${cell.getValue()}`}> */}
                  <IconButton  onClick={()=>handleNextView(rowData)}  >
                    <UNSignedContractIcon color="info" /> 
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={`view Signed Contract`}
                  style={{ display: rowData?.document ? 'inline-block' : 'none' }}
                >
                  {/* <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${cell.getValue()}`}> */}
                  <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${rowData.document}`}>
                    <SignedContractIcon color="success" />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          );
        },
        size: 150,
      },
      // Generated Date
      {
        accessorKey: 'created_at',
        header: 'Generated Date',
        Cell: ({ cell }) => {
          return (
            <>
              <span>{moment(cell.getValue()).format('DD - MMM - YYYY')}</span>{' '}
            </>
          );
        },
        size: 150,
      },

      // CAT Lead Status
      {
        accessorKey: 'is_verified',
        header: 'CAT Lead Status',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          // console.log(rowData.is_contract_approved, rowData.is_contract_verified,"mmmkkmkkm")
          return (
            <>
              <Typography
                variant="overline"
                sx={{
                  color:
                    rowData.is_contract_verified === CAT_LEAD_VERFIED
                      ? palette.success.dark
                      : rowData.is_contract_verified === CAT_LEAD_REJECTED
                      ? palette.error.dark
                      : palette.warning.dark,
                  backgroundColor:
                    rowData.is_contract_verified === CAT_LEAD_VERFIED
                      ? palette.success.lighter
                      : rowData.is_contract_verified === CAT_LEAD_REJECTED
                      ? palette.error.lighter
                      : palette.warning.lighter,
                  padding: '8px 16px',
                  borderRadius: '3px',
                  letterSpacing: '1px',
                }}
              >
                {rowData.is_contract_verified === CAT_LEAD_VERFIED
                  ? 'Verified'
                  : rowData.is_contract_verified === CAT_LEAD_REJECTED
                  ? 'Rejected'
                  : 'Not yet Verfied'}
              </Typography>
            </>
          );
        },
        filterVariant: 'select',
        filterFn: 'equals',
        filterSelectOptions: [
          {
            value: '0',
            text: 'Not Yet Verified',
          },
          {
            value: 1,
            text: 'Verified',
          },
          {
            value: 2,
            text: 'Rejected',
          },
        ],
        size: 200,
      },
      // CAT Head Status
      {
        accessorKey: 'is_approved',
        header: 'Unit Head Status',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              {/* <span>{rowData?.pdf}</span> */}

              <Typography
                variant="overline"
                sx={{
                  color:
                    rowData.is_contract_approved === CAT_HEAD_APPROVED
                      ? palette.success.dark
                      : rowData.is_contract_approved === CAT_HEAD_REJECTED
                      ? palette.error.dark
                      : palette.warning.dark,
                  backgroundColor:
                    rowData.is_contract_approved === CAT_HEAD_APPROVED
                      ? palette.success.lighter
                      : rowData.is_contract_approved === CAT_HEAD_REJECTED
                      ? palette.error.lighter
                      : palette.warning.lighter,
                  padding: '8px 16px',
                  borderRadius: '3px',
                  letterSpacing: '1px',
                }}
              >
                {rowData.is_contract_approved === CAT_HEAD_APPROVED
                  ? 'Approved'
                  : rowData.is_contract_approved === CAT_HEAD_REJECTED
                  ? 'Rejected'
                  : 'Not yet Approved'}
              </Typography>
            </>
          );
        },
        filterVariant: 'select',
        filterFn: 'equals',
        filterSelectOptions: [
          {
            value: '0',
            text: 'Not Yet Approved',
          },
          {
            value: 1,
            text: 'Approved',
          },
          {
            value: 2,
            text: 'Rejected',
          },
        ],
        size: 250,
      },
      {
        accessorKey: 'updated_at',
        header: 'Action',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              {Number(userDetails.cat_head) === 1 ? (
                <>
                  {/* LOGIC FOR CAT HEAD ACTION */}
                  {rowData.is_contract_verified !== 1 ? (
                    <>
                      {/* NOT VERIFIED */}
                      <Button
                        variant="outlined"
                        startIcon={<WaitIcon />}
                        color="info"
                        sx={{ color: '#ccc', cursor: 'default' }}
                      >
                        Awaiting for verification
                      </Button>
                    </>
                  ) : rowData.is_contract_approved === 0 ? (
                    <>
                      {/* FOR APPROVING */}
                      <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                        <Button
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_REJECTED, 0)}
                          onClick={(e) => {
                            handleVerifyApply(userDetails.cat_lead, 0, rowData.id, rowData.contract_id, rowData);
                          }}
                          variant="contained"
                          sx={{ marginRight: '5px', color: '#fff' }}
                          startIcon={<RejectIcon />}
                          color="warning"
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_APPROVED, 0)}
                          onClick={(e) => {
                            handleVerifyApply(userDetails.cat_lead, 1, rowData.id, rowData.contract_id, rowData);
                          }}
                          startIcon={<VerifyIcon />}
                          color="success"
                          sx={{ color: '#fff' }}
                        >
                          Approve
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* AFTER APPROVED */}
                      {rowData?.document ? (
                        <>
                          <Button
                            variant="outlined"
                            startIcon={<Completed />}
                            color="info"
                            sx={{ color: '#339af0', cursor: 'pointer' }}
                          >
                            Completed
                          </Button>
                        </>
                      ) : (
                        <>
                          <Box sx={{ textAlign: 'center' }}>
                            <form>
                              <Stack rowGap={1} columnGap={1}>
                                <FileInputBRH
                                  buttonText={file ? file.name : 'Upload Signed Contract'}
                                  handleFileUpload={handleFileUpload}
                                  color="primary"
                                />
                                <Button
                                  // type="submit"
                                  variant="outlined"
                                  sx={{ marginLeft: '8px' }}
                                  onClick={(e) => handleSubmit(e, rowData.id, file)}
                                >
                                  Upload
                                </Button>
                              </Stack>
                            </form>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : Number(userDetails.cat_lead) === 1 ? (
                <>
                  {/* LOGIC FOR CAT LEAD ACTION */}
                  {rowData.is_contract_verified === 0 ? (
                    <>
                      <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                        <Button
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_REJECTED)}
                          onClick={(e) => {
                            handleVerifyApply(userDetails.cat_lead, 0, rowData.id, rowData.contract_id, rowData);
                          }}
                          variant="contained"
                          sx={{ marginRight: '5px', color: '#fff' }}
                          startIcon={<RejectIcon />}
                          color="warning"
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_VERFIED)}
                          onClick={(e) => {
                            handleVerifyApply(userDetails.cat_lead, 1, rowData.id, rowData.contract_id, rowData);
                          }}
                          startIcon={<VerifyIcon />}
                          color="success"
                          sx={{ color: '#fff' }}
                        >
                          Verify
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        startIcon={<Completed />}
                        color="info"
                        sx={{ color: '#339af0', cursor: 'default' }}
                      >
                        completed
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>{/* AFTER APPROVED AND VERFIED */}NULL</>
              )}
            </>
          );
        },
        enableColumnFilter: false,
        size: 350,
      },
    ],
    [file, pdfViewedStatus]
  );

  // if ((rowData.direct_contract === 1) && (rowData.is_renewal_contract !== 0)  ) {
  //   if ((rowData.direct_contract === 1) && (rowData.is_renewal_contract === 0)  ) {

  const mouColumns = useMemo(
    () => [
      // mou
      {
        accessorKey: 'id',
        header: 'Contract Type',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;

          // Condition for direct contract
          // console.log(rowData,"mydddd")
          if (
            rowData.direct_contract === 1 &&
            (rowData?.contractMou ? rowData?.contractMou?.is_renewal_contract === 0 : rowData.is_renewal_contract === 0)
          ) {
            return (
              <Typography variant="overline" color="seagreen">
                Direct Contract
              </Typography>
            );
          }

          // Condition for renewal contract
          if (
            rowData.direct_contract === 1 &&
            (rowData?.contractMou ? rowData?.contractMou?.is_renewal_contract === 1 : rowData.is_renewal_contract === 1)
          ) {
            return (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="overline" color="orange">
                  Renewed Contract
                </Typography>
              </Box>
            );
          }

          // Default case for MOU
          return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="overline" color="seagreen">
                {/* MOU */}
                Contract
              </Typography>
              {/* <Tooltip title={`View MOU`}>
                <IconButton target="_blank" onClick={() => handleView(rowData)}>
                  <VisibilityIcon color="primary" />
                </IconButton>
              </Tooltip> */}
            </Box>
          );
        },
        size: 150,
      },

      // pdf
      {
        accessorKey: 'pdf',
        header: `Contract`,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          // Condition for renewal contract
          if (
            rowData.direct_contract === 1 &&
            (rowData?.contractMou ? rowData?.contractMou?.is_renewal_contract === 1 : rowData.is_renewal_contract === 1)
          ) {
            // console.log(rowData.contractMou.unsigned_document,"mmmmm")
            return (
              // </Box>
              <Box sx={{ textAlign: 'center' }}>
                <Tooltip
                  title={`view UN-Signed Contract`}
                  style={{ display: rowData?.contractMou?.unsigned_document ? 'inline-block' : 'none' }}
                >
                  {/* <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${cell.getValue()}`}> */}
                  <IconButton
          onClick={()=>handleNextView(rowData)} 
                  >
                    <UNSignedContractIcon color="info" /> 
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={`view Signed Contract`}
                  style={{ display: rowData?.contractMou?.document ? 'inline-block' : 'none' }}
                >
                  {/* <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${cell.getValue()}`}> */}
                  <IconButton
                    target="_blank"
                    href={`${process.env.REACT_APP_IMAGE_URL}/${rowData.contractMou?.document}`}
                  >
                    <SignedContractIcon color="success" />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          }
          return (
            <>
              <Box sx={{ textAlign: 'center' }}>
                <Tooltip
                  title={`view UN-Signed Contract`}
                  style={{ display: rowData?.unsigned_document ? 'inline-block' : 'none' }}
                >
                  {/* <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${cell.getValue()}`}> */}
                  <IconButton  onClick={()=>handleNextView(rowData)}  >
                    <UNSignedContractIcon color="info" /> 
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={`view Signed Contract`}
                  style={{ display: rowData?.document ? 'inline-block' : 'none' }}
                >
                  {/* <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${cell.getValue()}`}> */}
                  <IconButton target="_blank" href={`${process.env.REACT_APP_IMAGE_URL}/${rowData.document}`}>
                    <SignedContractIcon color="success" />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          );
        },
        size: 150,
      },
      // Generated Date
      {
        accessorKey: 'created_at',
        header: 'Generated Date',
        Cell: ({ cell }) => {
          return (
            <>
              <span>{moment(cell.getValue()).format('DD - MMM - YYYY')}</span>{' '}
            </>
          );
        },
        size: 150,
      },

      // CAT Lead Status
      {
        accessorKey: 'is_verified',
        header: 'CAT Lead Status',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;

          if (
            rowData.direct_contract === 1 &&
            (rowData?.contractMou ? rowData?.contractMou?.is_renewal_contract === 1 : rowData.is_renewal_contract === 1)
          ) {
            return (
              <Typography
                variant="overline"
                sx={{
                  color:
                    rowData?.contractMou?.is_contract_verified === CAT_LEAD_VERFIED
                      ? palette.success.dark
                      : rowData?.contractMou?.is_contract_verified === CAT_LEAD_REJECTED
                      ? palette.error.dark
                      : palette.warning.dark,
                  backgroundColor:
                    rowData?.contractMou?.is_contract_verified === CAT_LEAD_VERFIED
                      ? palette.success.lighter
                      : rowData?.contractMou?.is_contract_verified === CAT_LEAD_REJECTED
                      ? palette.error.lighter
                      : palette.warning.lighter,
                  padding: '8px 16px',
                  borderRadius: '3px',
                  letterSpacing: '1px',
                }}
              >
                {rowData?.contractMou?.is_contract_verified === CAT_LEAD_VERFIED
                  ? 'Verified'
                  : rowData?.contractMou?.is_contract_verified === CAT_LEAD_REJECTED
                  ? 'Rejected'
                  : 'Not yet Verfied'}
              </Typography>
            );
          }
          return (
            <>
              <Typography
                variant="overline"
                sx={{
                  color:
                    rowData.is_contract_verified === CAT_LEAD_VERFIED
                      ? palette.success.dark
                      : rowData.is_contract_verified === CAT_LEAD_REJECTED
                      ? palette.error.dark
                      : palette.warning.dark,
                  backgroundColor:
                    rowData.is_contract_verified === CAT_LEAD_VERFIED
                      ? palette.success.lighter
                      : rowData.is_contract_verified === CAT_LEAD_REJECTED
                      ? palette.error.lighter
                      : palette.warning.lighter,
                  padding: '8px 16px',
                  borderRadius: '3px',
                  letterSpacing: '1px',
                }}
              >
                {rowData.is_contract_verified === CAT_LEAD_VERFIED
                  ? 'Verified'
                  : rowData.is_contract_verified === CAT_LEAD_REJECTED
                  ? 'Rejected'
                  : 'Not yet Verfied'}
              </Typography>
            </>
          );
        },
        filterVariant: 'select',
        filterFn: 'equals',
        filterSelectOptions: [
          {
            value: '0',
            text: 'Not Yet Verified',
          },
          {
            value: 1,
            text: 'Verified',
          },
          {
            value: 2,
            text: 'Rejected',
          },
        ],
        size: 200,
      },
      // CAT Head Status
      {
        accessorKey: 'is_approved',
        header: 'Unit Head Status',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;

          if (
            rowData.direct_contract === 1 &&
            (rowData?.contractMou ? rowData?.contractMou?.is_renewal_contract === 1 : rowData.is_renewal_contract === 1)
          ) {
            return (
              <>
                {/* <span>{rowData?.pdf}</span> */}

                <Typography
                  variant="overline"
                  sx={{
                    color:
                      rowData?.contractMou?.is_contract_approved === CAT_HEAD_APPROVED
                        ? palette.success.dark
                        : rowData?.contractMou?.is_contract_approved === CAT_HEAD_REJECTED
                        ? palette.error.dark
                        : palette.warning.dark,
                    backgroundColor:
                      rowData?.contractMou?.is_contract_approved === CAT_HEAD_APPROVED
                        ? palette.success.lighter
                        : rowData?.contractMou?.is_contract_approved === CAT_HEAD_REJECTED
                        ? palette.error.lighter
                        : palette.warning.lighter,
                    padding: '8px 16px',
                    borderRadius: '3px',
                    letterSpacing: '1px',
                  }}
                >
                  {rowData?.contractMou?.is_contract_approved === CAT_HEAD_APPROVED
                    ? 'Approved'
                    : rowData?.contractMou?.is_contract_approved === CAT_HEAD_REJECTED
                    ? 'Rejected'
                    : 'Not yet Approved'}
                </Typography>
              </>
            );
          }
          return (
            <>
              {/* <span>{rowData?.pdf}</span> */}

              <Typography
                variant="overline"
                sx={{
                  color:
                    rowData.is_contract_approved === CAT_HEAD_APPROVED
                      ? palette.success.dark
                      : rowData.is_contract_approved === CAT_HEAD_REJECTED
                      ? palette.error.dark
                      : palette.warning.dark,
                  backgroundColor:
                    rowData.is_contract_approved === CAT_HEAD_APPROVED
                      ? palette.success.lighter
                      : rowData.is_contract_approved === CAT_HEAD_REJECTED
                      ? palette.error.lighter
                      : palette.warning.lighter,
                  padding: '8px 16px',
                  borderRadius: '3px',
                  letterSpacing: '1px',
                }}
              >
                {rowData.is_contract_approved === CAT_HEAD_APPROVED
                  ? 'Approved'
                  : rowData.is_contract_approved === CAT_HEAD_REJECTED
                  ? 'Rejected'
                  : 'Not yet Approved'}
              </Typography>
            </>
          );
        },
        filterVariant: 'select',
        filterFn: 'equals',
        filterSelectOptions: [
          {
            value: '0',
            text: 'Not Yet Approved',
          },
          {
            value: 1,
            text: 'Approved',
          },
          {
            value: 2,
            text: 'Rejected',
          },
        ],
        size: 250,
      },
      {
        accessorKey: 'updated_at',
        header: 'Action',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          // console.log(rowData,"myrowdata");
          if (
            rowData.direct_contract === 1 &&
            (rowData?.contractMou ? rowData?.contractMou?.is_renewal_contract === 1 : rowData.is_renewal_contract === 1)
          ) {
            return (
              <>
                {Number(userDetails.cat_head) === 1 ? (
                  <>
                    {/* LOGIC FOR CAT HEAD ACTION */}
                    {rowData?.contractMou?.is_contract_verified !== 1 ? (
                      <>
                        {/* NOT VERIFIED */}
                        <Button
                          variant="outlined"
                          startIcon={<WaitIcon />}
                          color="info"
                          sx={{ color: '#ccc', cursor: 'default' }}
                        >
                          Awaiting for verification
                        </Button>
                      </>
                    ) : rowData?.contractMou?.is_contract_approved === 0 ? (
                      <>
                        {/* FOR APPROVING */}
                        <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                          <Button
                            // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_REJECTED, 0)}
                            onClick={(e) => {
                              handleVerifyApply(
                                userDetails.cat_lead,
                                0,
                                rowData?.contractMou?.id,
                                rowData?.contractMou?.contract_id,
                                rowData?.contract_mou[0]
                              );
                            }}
                            variant="contained"
                            sx={{ marginRight: '5px', color: '#fff' }}
                            startIcon={<RejectIcon />}
                            color="warning"
                          >
                            Reject
                          </Button>
                          <Button
                            variant="contained"
                            // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_APPROVED, 0)}
                            onClick={(e) => {
                              handleVerifyApply(
                                userDetails.cat_lead,
                                1,
                                rowData?.contractMou?.id,
                                rowData?.contractMou?.contract_id,
                                rowData?.contract_mou[0]
                              );
                            }}
                            startIcon={<VerifyIcon />}
                            color="success"
                            sx={{ color: '#fff' }}
                          >
                            Approve
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        {/* AFTER APPROVED */}
                        {rowData?.contractMou?.document ? (
                          <>
                            <Button
                              variant="outlined"
                              startIcon={<Completed />}
                              color="info"
                              sx={{ color: '#339af0', cursor: 'pointer' }}
                            >
                              Completed
                            </Button>
                          </>
                        ) : (
                          <>
                            <Box sx={{ textAlign: 'center' }}>
                              <form>
                                <Stack rowGap={1} columnGap={1}>
                                  <FileInputBRH
                                    buttonText={file ? file.name : 'Upload Signed Contract'}
                                    handleFileUpload={handleFileUpload}
                                    color="primary"
                                  />
                                  <Button
                                    // type="submit"
                                    variant="outlined"
                                    sx={{ marginLeft: '8px' }}
                                    onClick={(e) => handleSubmit(e, rowData?.contractMou?.id, file)}
                                  >
                                    Upload
                                  </Button>
                                </Stack>
                              </form>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </>
                ) : Number(userDetails.cat_lead) === 1 ? (
                  <>
                    {/* LOGIC FOR CAT LEAD ACTION */}
                    {rowData?.contractMou?.is_contract_verified === 0 ? (
                      <>
                        <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                          <Button
                            // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_REJECTED)}
                            onClick={(e) => {
                              handleVerifyApply(
                                userDetails.cat_lead,
                                0,
                                rowData?.contractMou?.id,
                                rowData?.contractMou?.contract_id,
                                rowData?.contract_mou[0]
                              );
                            }}
                            variant="contained"
                            sx={{ marginRight: '5px', color: '#fff' }}
                            startIcon={<RejectIcon />}
                            color="warning"
                          >
                            Reject
                          </Button>
                          <Button
                            variant="contained"
                            // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_VERFIED)}
                            onClick={(e) => {
                              handleVerifyApply(
                                userDetails.cat_lead,
                                1,
                                rowData?.contractMou?.id,
                                rowData?.contractMou?.contract_id,
                                rowData?.contract_mou[0]
                              );
                            }}
                            startIcon={<VerifyIcon />}
                            color="success"
                            sx={{ color: '#fff' }}
                          >
                            Verify
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="outlined"
                          startIcon={<Completed />}
                          color="info"
                          sx={{ color: '#339af0', cursor: 'default' }}
                        >
                          completed
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>{/* AFTER APPROVED AND VERFIED */}NULL</>
                )}
              </>
            );
          }

          return (
            <>
              {Number(userDetails.cat_head) === 1 ? (
                <>
                  {/* LOGIC FOR CAT HEAD ACTION */}
                  {rowData.is_contract_verified !== 1 ? (
                    <>
                      {/* NOT VERIFIED */}
                      <Button
                        variant="outlined"
                        startIcon={<WaitIcon />}
                        color="info"
                        sx={{ color: '#ccc', cursor: 'default' }}
                      >
                        Awaiting for verification
                      </Button>
                    </>
                  ) : rowData.is_contract_approved === 0 ? (
                    <>
                      {/* FOR APPROVING */}
                      <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                        <Button
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_REJECTED, 0)}
                          onClick={(e) => {
                            handleVerifyApply(userDetails.cat_lead, 0, rowData.id, rowData.contract_id, rowData);
                          }}
                          variant="contained"
                          sx={{ marginRight: '5px', color: '#fff' }}
                          startIcon={<RejectIcon />}
                          color="warning"
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_HEAD_APPROVED, 0)}
                          onClick={(e) => {
                            handleVerifyApply(userDetails.cat_lead, 1, rowData.id, rowData.contract_id, rowData);
                          }}
                          startIcon={<VerifyIcon />}
                          color="success"
                          sx={{ color: '#fff' }}
                        >
                          Approve
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {/* AFTER APPROVED */}
                      {rowData?.document ? (
                        <>
                          <Button
                            variant="outlined"
                            startIcon={<Completed />}
                            color="info"
                            sx={{ color: '#339af0', cursor: 'pointer' }}
                          >
                            Completed
                          </Button>
                        </>
                      ) : (
                        <>
                          <Box sx={{ textAlign: 'center' }}>
                            <form>
                              <Stack rowGap={1} columnGap={1}>
                                <FileInputBRH
                                  buttonText={file ? file.name : 'Upload Signed Contract'}
                                  handleFileUpload={handleFileUpload}
                                  color="primary"
                                />
                                <Button
                                  // type="submit"
                                  variant="outlined"
                                  sx={{ marginLeft: '8px' }}
                                  onClick={(e) => handleSubmit(e, rowData.id, file)}
                                >
                                  Upload
                                </Button>
                              </Stack>
                            </form>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : Number(userDetails.cat_lead) === 1 ? (
                <>
                  {/* LOGIC FOR CAT LEAD ACTION */}
                  {rowData.is_contract_verified === 0 ? (
                    <>
                      <Box sx={{ display: 'flex', rowGap: '16px', columnGap: '16px' }}>
                        <Button
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_REJECTED)}
                          onClick={(e) => {
                            handleVerifyApply(userDetails.cat_lead, 0, rowData.id, rowData.contract_id, rowData);
                          }}
                          variant="contained"
                          sx={{ marginRight: '5px', color: '#fff' }}
                          startIcon={<RejectIcon />}
                          color="warning"
                        >
                          Reject
                        </Button>
                        <Button
                          variant="contained"
                          // onClick={(e) => handleIsVerified(e, rowData.id, CAT_LEAD_VERFIED)}
                          onClick={(e) => {
                            handleVerifyApply(userDetails.cat_lead, 1, rowData.id, rowData.contract_id, rowData);
                          }}
                          startIcon={<VerifyIcon />}
                          color="success"
                          sx={{ color: '#fff' }}
                        >
                          Verify
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        startIcon={<Completed />}
                        color="info"
                        sx={{ color: '#339af0', cursor: 'default' }}
                      >
                        completed
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>{/* AFTER APPROVED AND VERFIED */}NULL</>
              )}
            </>
          );
        },
        enableColumnFilter: false,
        size: 350,
      },
    ],
    [file, pdfViewedStatus]
  );
  useEffect(() => {
    // AFTER CONTEXT
    setContractsData((prevData) => ({ ...prevData, isFetched: false }));
    API.getData(CRSALLLIST)
      .then((res) => {
        if (res?.data?.status === 'success') {
          let data = res?.data?.results;
          // console.log(
          //   data.map((d) => d.contractMou.filter((c)=>c.is_mou_accept===0 )),
          //   'nmjk'
          // );

          // console.log(
          //   data.filter((d) => d.contractMou.direct_contract === 1),
          //   'nmjk'
          // );
          // console.log(
          //   data.filter(
          //     (d) =>
          //       (d.contractMou.is_mou_accept === 1 &&
          //         d.contractMou.is_verified === 1 &&
          //         d.contractMou.is_approved === 1) ||
          //       d.contractMou.direct_contract === 1
          //   ),
          //   'nmjk'
          // );

          // console.log(
          //   data.filter((d) =>
          //     d.contractMou.some(
          //       (c) => (c.is_mou_accept === 1 && c.is_verified === 1 && c.is_approved === 1) || c.direct_contract === 1
          //     )
          //   ),
          //   'nmjk'
          // );

          const today = new Date(); // Get the current date
          //   const  data.filter(dateStr => {
          //     const dbDate = new Date(dateStr); // Convert each date string to a Date object
          //      dbDate > today; // Keep only dates that are in the future
          //   });
          // }

          // console.log(today,"todaydate")

          const NewRdata = data.map((d) => d?.renewals);
          const Rdata = data.filter((cp) => new Date(cp.agreement_start_date) > today);

          // console.log(data?.filter((css)=>css.is_renewal_contract===0),"cccc")
          // const Rdata=data?.filter((css)=>css.is_renewal_contract===0);

          setRenData(Rdata.toReversed());
          setNewHistory(NewRdata);
          // console.log(newHistory,"nmjk")

          data = data?.filter((crs) => crs.is_contract === 1);

          const sortedData = data.toReversed();
          const propertyData = propertyList;
          const laksh = data.flatMap((elecrs, i) => {
            data[i].propertyData = {};
            let sri;
            propertyData?.forEach((elePro) => {
              if (elecrs.property_id === elePro.id) {
                sri = elePro;
                data[i].propertyData = elePro;
                return 1;
              }
              return [];
            });
            return 1;
          });

          const filteredContracts = sortedData.filter(
            (contract) =>
              (
                contract.contractMou.is_mou_accept === 1 &&
                contract.contractMou.mou_document !== null &&
                contract.contractMou.is_verified === 1 &&
                contract.contractMou.is_approved === 1) ||
              contract.contractMou.direct_contract === 1
          );

          setContractsData({
            ...contractsData,
            isFetched: true,
            contractsListData: filteredContracts,
            propertyList: propertyData,
          });
          console.log(contractsData?.contractsListData, 'nnnnnn');
        }
      })
      .catch((err) => {
        // console.log(' API-CRSLIST', err);
        setContractsData({ ...contractsData, isFetched: true });
      });
  }, [key]);

  function handleFilterClientsDataHistory(status) {
    let filteredList = [];
    switch (status) {
      case STATUS_CAT_LEAD_PENDING: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === 0
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CAT_LEAD_REJECTED: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_REJECTED
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CAT_HEAD_PENDING: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_VERFIED &&
              con_mou.is_contract_approved === 0
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CAT_HEAD_REJECTED: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_VERFIED &&
              con_mou.is_contract_approved === CAT_HEAD_REJECTED
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CONTRACT_FILE_PENDING: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_VERFIED &&
              con_mou.is_contract_approved === CAT_HEAD_APPROVED &&
              !con_mou.document
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CONTRACT_FILE_UPLOADED: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_VERFIED &&
              con_mou.is_contract_approved === CAT_HEAD_APPROVED &&
              con_mou.document
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case NO_STATUS: {
        return filteredContractListData;
      }
      default:
        return console.log(`Sorry, we are out of ${status}.`);
    }
    // ;
    return filteredList;
  }

  // console.log(renData,"rrrnnnn")

  function handleFilterClientsData(status) {
    let filteredList = [];
    switch (status) {
      case STATUS_CAT_LEAD_PENDING: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === 0
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CAT_LEAD_REJECTED: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_REJECTED
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CAT_HEAD_PENDING: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_VERFIED &&
              con_mou.is_contract_approved === 0
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CAT_HEAD_REJECTED: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_VERFIED &&
              con_mou.is_contract_approved === CAT_HEAD_REJECTED
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CONTRACT_FILE_PENDING: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_VERFIED &&
              con_mou.is_contract_approved === CAT_HEAD_APPROVED &&
              !con_mou.document
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case STATUS_CONTRACT_FILE_UPLOADED: {
        filteredList = filteredContractListData.reduce((acc, cur) => {
          const isFound = cur.contract_mou.filter(
            (con_mou) =>
              con_mou.is_verified === CAT_LEAD_VERFIED &&
              con_mou.is_approved === CAT_HEAD_APPROVED &&
              con_mou.is_contract_verified === CAT_LEAD_VERFIED &&
              con_mou.is_contract_approved === CAT_HEAD_APPROVED &&
              con_mou.document
          );
          return isFound.length > 0 ? [...acc, cur] : acc;
        }, []);
        // return filteredList;

        break;
      }
      case NO_STATUS: {
        return filteredContractListData;
      }
      default:
        return console.log(`Sorry, we are out of ${status}.`);
    }
    // ;
    return filteredList;
  }

  return (
    <>
      {contractsData.isFetched === true ? (
        <>
          <Helmet>
            <title> Contracts | BRH </title>
          </Helmet>
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Box>
                <Typography variant="h4" gutterBottom>
                  Contracts
                  <span style={{ fontSize: '16px' }}>
                    ( {contractsData.isActive ? 'Active' : contractsData.isnewRenwal ? 'Renewed' : 'History'} )
                  </span>
                </Typography>
              </Box>

              <Box>
                <Button
                  variant={contractsData.isActive ? 'contained' : 'outlined'}
                  sx={{ padding: '8px 32px', marginRight: '10px' }}
                  onClick={() => handleActiveContract(true, false)} // Active Contracts
                >
                  Active Contracts
                </Button>
                <Button
                  variant={contractsData.isnewRenwal ? 'contained' : 'outlined'}
                  sx={{ padding: '8px 32px', marginRight: '10px' }}
                  onClick={() => handleActiveContract(false, true)} // Renewed Contracts
                >
                  Renewed Contracts
                </Button>
                <Button
                  variant={!contractsData.isActive && !contractsData.isnewRenwal ? 'contained' : 'outlined'}
                  sx={{ padding: '8px 32px' }}
                  onClick={() => handleActiveContract(false, false)} // History of Contracts
                >
                  History of Contracts
                </Button>
              </Box>
            </Stack>
            <Card sx={{ p: 2 }}>
              {contractsData.isnewRenwal ? (
                <>
                  {/* <p> Renewed  contract</p> */}

                  <NormalTable
                    columns={columns}
                    data={renData.filter((c) => c.renewal_contract_id !== null)}
                    renderDetailPanel={({ row }) => {
                      // const allMOU = row.original.contract_mou;
                      // console.log(renData,"renData2")

                      const MousData = row.original.contract_mou;
                      // console.log(MousData,"mousss")

                      return (
                        <Box
                          sx={{
                            backgroundColor: '#fff9db',
                            padding: '10px',
                            border: '1px solid #e67700',
                            overflowY: 'auto',
                          }}
                        >
                          <NormalTable
                            columns={RenewedmouColumns}
                            data={MousData.filter((c) => c.is_renewal_contract === 1)}
                            enableRowNumbers
                            enableTopToolbar={false}
                            enableBottomToolbar={false}
                            muiTableHeadCellProps={{
                              sx: (theme) => ({
                                background: '#74c0fc',
                                borderRight: '1px solid #fff', // #006afc #ffcb99
                                color: '#0b7285',
                              }),
                            }}
                          />
                        </Box>
                      );
                      //  return (<Box>Laksh</Box>);
                    }}
                    enableTopToolbar="true"
                    positionToolbarAlertBanner="bottom" // show selected rows count on bottom toolbar
                    // add custom action buttons to top-left of top toolbar
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box sx={{ display: 'flex', gap: '1rem', p: '4px', minWidth: '300px' }}>
                        <TextFieldSelectBRH
                          label="Approved Status"
                          name="approvedStatus"
                          value={contractsData.approvedStatus}
                          options={approvedStatusOptions}
                          handleChange={(e) => setContractsData({ ...contractsData, approvedStatus: e.target.value })}
                          renderLabel="label"
                          renderValue="value"
                          required
                          size="small"
                        />
                      </Box>
                    )}
                    // customize built-in buttons in the top-right of top toolbar
                    renderToolbarInternalActions={({ table }) => (
                      <Box>
                        <MRT_ToggleDensePaddingButton table={table} />
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_ToggleFiltersButton table={table} />
                      </Box>
                    )}
                  />
                </>
              ) : (
                <>
                  {contractsData.isActive ? (
                    <>
                      {/* <p> Active contract</p> */}
                      <NormalTable
                        columns={columns}
                        data={handleFilterClientsData(contractsData.approvedStatus)}
                        renderDetailPanel={({ row }) => {
                          const allMOU = row.original.contract_mou;

                          const filteredMOUWithDirectContracts = allMOU.map((mou) =>
                            mou.direct_contract === 1 ? { ...mou, is_verified: 1, is_approved: 1 } : mou
                          );
                          const filteredMOU = filteredMOUWithDirectContracts?.filter(
                            (mou) => mou.is_verified === 1 && mou.is_approved === 1
                          );

                          const additionalMOU = newHistory;
                          // console.log(row.original.renewals.map((c=>c.id)))
                          // console.log(row.original.renewals, row.original.contract_mou.filter((cs)=>cs.contract_id),"lkj")

                          const FirstArr = row.original.renewals;
                          const SecondArr = row.original.contract_mou.map((cs) => cs.contract_id);

                          // const filteredAdditionalMOU = additionalMOU?.filter(
                          //   (mou) => mou.renewal_contract_id === 206
                          // );

                          // console.log(row,"cdffdf")

                          // console.log(FirstArr,SecondArr,"tttyuuu")

                          const FinalArr = FirstArr.filter((contract) => contract.renewal_contract_id === SecondArr[0]);

                          // console.log(FinalArr,"mouusus")

                          return (
                            <Box
                              sx={{
                                backgroundColor: '#fff9db',
                                padding: '10px',
                                border: '1px solid #e67700',
                                overflowY: 'auto',
                              }}
                            >
                              {/* First Table */}
                              <NormalTable
                                columns={mouColumns}
                                // data={filteredMOU}
                                data={[...filteredMOU, ...FinalArr]}
                                enableRowNumbers
                                enableTopToolbar={false}
                                enableBottomToolbar={false}
                                muiTableHeadCellProps={{
                                  sx: (theme) => ({
                                    background: '#74c0fc',
                                    borderRight: '1px solid #fff',
                                    color: '#0b7285',
                                  }),
                                }}
                              />

                              {/*             
                    <NormalTable
                      columns={mouColumns} 
                      data={FinalArr}
                      enableRowNumbers
                      enableTopToolbar={false}
                      enableBottomToolbar={false}
                      enableColumnHeader={false}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          background: '#f8c291',
                          borderRight: '1px solid #fff',
                          color: '#6a4f4b',
                        }),
                      }}
                    /> */}
                            </Box>
                          );
                        }}
                        enableTopToolbar="true"
                        positionToolbarAlertBanner="bottom"
                        renderTopToolbarCustomActions={({ table }) => (
                          <Box sx={{ display: 'flex', gap: '1rem', p: '4px', minWidth: '300px' }}>
                            <TextFieldSelectBRH
                              label="Approved Status"
                              name="approvedStatus"
                              value={contractsData.approvedStatus}
                              options={approvedStatusOptions}
                              handleChange={(e) =>
                                setContractsData({ ...contractsData, approvedStatus: e.target.value })
                              }
                              renderLabel="label"
                              renderValue="value"
                              required
                              size="small"
                            />
                          </Box>
                        )}
                        renderToolbarInternalActions={({ table }) => (
                          <Box>
                            <MRT_ToggleDensePaddingButton table={table} />
                            <MRT_ShowHideColumnsButton table={table} />
                            <MRT_ToggleFiltersButton table={table} />
                          </Box>
                        )}
                      />
                    </>
                  ) : (
                    //  test
                    //  history
                    <NormalTable
                      columns={columns}
                      data={handleFilterClientsDataHistory(contractsData.approvedStatus)}
                      renderDetailPanel={({ row }) => {
                        const allMOU = row.original.contract_mou;

                        const filteredMOUWithDirectContracts = allMOU.map((mou) =>
                          mou.direct_contract === 1 ? { ...mou, is_verified: 1, is_approved: 1 } : mou
                        );
                        const filteredMOU = filteredMOUWithDirectContracts?.filter(
                          (mou) => mou.is_verified === 1 && mou.is_approved === 1 
                        );

                        const additionalMOU = newHistory;
                        // console.log(row.original.renewals.map((c=>c.id)))
                        // console.log(row.original.renewals, row.original.contract_mou.filter((cs)=>cs.contract_id),"lkj")

                        const FirstArr = row.original.renewals;
                        const SecondArr = row.original.contract_mou.map((cs) => cs.contract_id);

                        const filteredAdditionalMOU = additionalMOU?.filter((mou) => mou.renewal_contract_id === 206);

                        // console.log(FirstArr,SecondArr,"tttyuuu")

                        const FinalArr = FirstArr.filter((contract) => contract.renewal_contract_id === SecondArr[0]);

                        // console.log(FinalArr,"mouusus")

                        return (
                          <Box
                            sx={{
                              backgroundColor: '#fff9db',
                              padding: '10px',
                              border: '1px solid #e67700',
                              overflowY: 'auto',
                            }}
                          >
                            {/* First Table */}
                            <NormalTable
                              columns={mouColumns}
                              // data={filteredMOU}
                              data={[...filteredMOU, ...FinalArr]}
                              enableRowNumbers
                              enableTopToolbar={false}
                              enableBottomToolbar={false}
                              muiTableHeadCellProps={{
                                sx: (theme) => ({
                                  background: '#74c0fc',
                                  borderRight: '1px solid #fff',
                                  color: '#0b7285',
                                }),
                              }}
                            />

                            {/*             
                    <NormalTable
                      columns={mouColumns} 
                      data={FinalArr}
                      enableRowNumbers
                      enableTopToolbar={false}
                      enableBottomToolbar={false}
                      enableColumnHeader={false}
                      muiTableHeadCellProps={{
                        sx: (theme) => ({
                          background: '#f8c291',
                          borderRight: '1px solid #fff',
                          color: '#6a4f4b',
                        }),
                      }}
                    /> */}
                          </Box>
                        );
                      }}
                      enableTopToolbar="true"
                      positionToolbarAlertBanner="bottom"
                      renderTopToolbarCustomActions={({ table }) => (
                        <Box sx={{ display: 'flex', gap: '1rem', p: '4px', minWidth: '300px' }}>
                          <TextFieldSelectBRH
                            label="Approved Status"
                            name="approvedStatus"
                            value={contractsData.approvedStatus}
                            options={approvedStatusOptions}
                            handleChange={(e) => setContractsData({ ...contractsData, approvedStatus: e.target.value })}
                            renderLabel="label"
                            renderValue="value"
                            required
                            size="small"
                          />
                        </Box>
                      )}
                      renderToolbarInternalActions={({ table }) => (
                        <Box>
                          <MRT_ToggleDensePaddingButton table={table} />
                          <MRT_ShowHideColumnsButton table={table} />
                          <MRT_ToggleFiltersButton table={table} />
                        </Box>
                      )}
                    />
                  )}
                </>
              )}
            </Card>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={snackbarOpen}
              autoHideDuration={3000}
            >
              <Alert sx={{ width: '100%' }} severity="warning">
                {/* onClose={handleSnackbar}  */}
                <Stack rowGap="10px" direction="row" columnGap="10px">
                  <Box>
                    <Typography variant="button"> You need to view the file before approve or to verify</Typography>
                  </Box>
                  <Box>
                    <Button onClick={handleSnackbar} variant="contained" size="small">
                      {/* {console.log('laksh-view mou', snackbarDetails)} */}
                      {snackbarDetails?.direct_contract === 1 ? 'view Contract' : 'view contract'}
                    </Button>
                  </Box>
                </Stack>
              </Alert>
            </Snackbar>
            <MouAlert
              open={open}
              setOpen={setOpen}
              isLead={isLead}
              isOk={isOkay}
              id={popupId}
              contractId={contractId}
              isMou={0}
              onSetKey={setKey}
            />
          </Container>
        </>
      ) : (
        <>
          <TableSkeleton />
        </>
      )}
    </>
  );
};
