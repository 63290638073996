import { createSlice } from '@reduxjs/toolkit';
import React from 'react';

const initialState = {
  reminderDays: 30,
};

const reminderSlice = createSlice({
  name: 'reminderSettings',
  initialState,
  reducers: {
    addReminderDays(state, action) {
      state.reminderDays = action.payload;
    },
  },
});

export const { addReminderDays } = reminderSlice.actions;

export default reminderSlice.reducer;
