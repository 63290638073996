import { configureStore } from '@reduxjs/toolkit';
import BasicDetailsSlicereducer from '../reducers/BasicDetailsSlice';
import TabsSelectionSliceReducer from '../reducers/TabsSlice';
import ContractListSlice from '../reducers/ContractListSlice';
import ReminderSlice from '../reducers/ReminderSlice';

export const store = configureStore({
  reducer: {
    BasicDetails: BasicDetailsSlicereducer,
    TabsSelection: TabsSelectionSliceReducer,
    contractsList: ContractListSlice,
    reminderSettings: ReminderSlice,
  },
});
