import React from 'react';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import { CircularProgress, Button, ThemeProvider, Box, Stack, Typography } from '@mui/material';
import { CustomizedTemplate } from '../custome_themes/CustomizedTemplate';

export const LoadingButtonBRH = ({
  isLoading,
  label,
  handleSubmit,
  type = 'button',
  color = 'primary',
  style,
  size = 'medium',
  disabled,
}) => (
  <Button
    variant="contained"
    onClick={handleSubmit}
    type={type}
    color={color}
    sx={style}
    size={size}
    disabled={disabled}
  >
    {/* {isLoading ? (
    <LoadingButton
      variant="outlined"
      loading={isLoading}
      sx={{
        color: '#fc9200',
        backgroundColor: 'inherit',
      }}
    >
      s
    </LoadingButton>
  ) : (
    label
  )} */}
    <Stack spacing={2} direction="row" alignContent="center">
      <Box sx={{ lineHeight: 0 }}>
        {isLoading ? (
          <ThemeProvider theme={CustomizedTemplate}>
            <CircularProgress color="laksh" size={24} />
          </ThemeProvider>
        ) : (
          <SendIcon />
        )}
      </Box>
      <Box>
        <Typography variant="h6">{label}</Typography>
      </Box>
    </Stack>
  </Button>
);
