import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import ContractsPage from './pages/ContractsPage';
import NotificationsPage from './pages/NotificationsPage';
// import RemindersPage from './pages/RemindersPage';
import ProspectPage from './pages/masters/prospects/ProspectPage';
import AddProspect from './pages/masters/prospects/AddProspects';
import ClientsPage from './pages/masters/clients/ClientsPage';
import AddClient from './pages/masters/clients/AddClient';
import EditClient from './pages/masters/clients/EditClient';
import AddContracts from './pages/contracts/AddContracts';
import CompanyMasterListPage from './pages/contracts/companyMaster/CompanyMasterListPage';
import AddCompanyMaster from './pages/contracts/companyMaster/AddCompanyMaster';
import { routeNames } from './layouts/dashBoardBRH/nav/routeNames';
import CrsList from './pages/contracts/CrsList';
import AddCrs from './pages/contracts/AddCrs';
import AddPerson from './pages/contracts/companyMaster/AddPerson';
import { MouList } from './pages/mous/MouList';
import ProspectPropertyList from './pages/masters/prospectsAdd/PropectPropertyList';
import AddProspectListPage from './pages/masters/prospectsAdd/AddProspectListPage';
import { ContractsListPage } from './pages/contracts/ContractsListPage';
import RemindersPage from './pages/Reminders/Index';
import EditProspectListPage from './pages/masters/EditProspectesAdd/EditProspectListPage';
import { PrsList } from './pages/prs/PrsList';
import { PrsAdd } from './pages/prs/PrsAdd';
import PropertyAgreementsList from './pages/masters/propertyagreements/PropertyAgreementsList';
import AddPropertyAgreements from './pages/masters/propertyagreements/AddPropertyAgreements';
import ViewProfile from './pages/viewProfile/ViewProfile';
import Notification from './pages/notification/Notification';
import RenewalContractsList from './pages/contracts/RenewalContractsList';
import AddRenewalContract from './pages/contracts/AddRenewalContract';
import CancelledContracts from './pages/contracts/CancelledContracts';
// laksh

// checking
// import TempClient from './pages/masters/clients/AddClient';

// ----------------------------------------------------------------------

export default function Router() {
  const user = JSON.parse(localStorage.getItem('user'));

  const getElement = (element) => (user ? element : <Navigate to="/login" />);

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: user ? <DashboardLayout /> : <Navigate to="/login  " />,
      children: [
        { element: getElement(<Navigate to={user ? `${routeNames.INDEX}` : `${routeNames.LOGIN}`} />), index: true },
        { path: 'app', element: getElement(<DashboardAppPage />) },
        // { path: 'contracts', element: getElement(<ContractsPage />) },
        // { path: 'notifications', element: getElement(<NotificationsPage />) },
        // { path: 'reminders', element: getElement(<RemindersPage />) },
      ],
    },

    {
      path: '/editprospects/:id',
      element: <EditProspectListPage />,
    },
    // {
    //   path: '/dashboard',
    //   element: user ? <DashboardLayout /> : <Navigate to="/login  " />,
    //   children: [
    //     { element: getElement(<Navigate to={user ? `${routeNames.INDEX}` : `${routeNames.LOGIN}`} />), index: true },
    //   ],
    // },
    {
      path: '/masters',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/masters/app" />, index: true },
        { path: 'app', element: getElement(<DashboardAppPage />) },
        { path: `${routeNames.PROSPECTS}`, element: getElement(<ProspectPage />) },

        { path: `${routeNames.PROSPECTSADD}`, element: getElement(<AddProspect />) },
        { path: `${routeNames.PROSPECTSEDIT}`, element: getElement(<AddProspect />) },
        {
          path: `${routeNames.PROSPECTPROPERTYLIST}`,
          element: <ProspectPropertyList />,
        },
        { path: `${routeNames.PROSPECTPROPERTYADD}`, element: <AddProspectListPage /> },
        { path: `${routeNames.PROSPECTPROPERTYEDIT}`, element: <AddProspectListPage /> },
        { path: `${routeNames.PROPERTYAGREEMENTSLIST}`, element: getElement(<PropertyAgreementsList />) },
        { path: `${routeNames.PROPERTYAGREEMENTSADD}`, element: getElement(<AddPropertyAgreements />) },

        { path: `${routeNames.CLIENTS}`, element: getElement(<ClientsPage />) },
        { path: `${routeNames.CLIENTSADD}`, element: getElement(<AddClient />) },
        // { path: 'client/add', element: getElement(<TempClient />) },
        { path: `${routeNames.CLIENTSEDIT}`, element: getElement(<AddClient />) },
      ],
    },

    {
      path: '/crs',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/crs/list" />, index: true },
        { path: `${routeNames.CRS}`, element: getElement(<CrsList />) },
        { path: `${routeNames.CRSADD}`, element: getElement(<AddCrs />) },
        { path: `${routeNames.CRSEDIT}`, element: getElement(<AddCrs />) },
      ],
    },
    {
      path: '/contractrenewal',
      element: <DashboardLayout />,
      children: [
        { path: `${routeNames.EDITRENEWALCONTRACT}`, element: getElement(<AddRenewalContract />) },
      ],
    },

    {
      path: '/profile',
      element: <DashboardLayout />,
      // children: [{ element: <Navigate to=''/> }],
      children: [{ path: `${routeNames.VIEWPROFILE}`, element: getElement(<ViewProfile />) }],
    },
    {
      path: '/prs',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/crs/list" />, index: true },
        { path: `${routeNames.PRS}`, element: getElement(<PrsList />) },
        { path: `${routeNames.PRSADD}`, element: getElement(<PrsAdd />) },
        { path: `${routeNames.PRSEDIT}`, element: getElement(<PrsAdd />) },
      ],
    },
    {
      path: '/mou',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/crs/list" />, index: true },
        { path: `${routeNames.MOU}`, element: getElement(<MouList />) },
        { path: `${routeNames.MOUADD}`, element: getElement(<AddCrs />) },
        { path: `${routeNames.MOUEDIT}`, element: getElement(<AddCrs />) },
      ],
    },
    {
      path: '/contract',
      element: <DashboardLayout />,
      children: [
        // { element: <Navigate to="/crs/list" />, index: true },
        { path: `${routeNames.CONTRACT}`, element: getElement(<ContractsListPage />) },
        { path: `${routeNames.CONTRACTADD}`, element: getElement(<AddContracts />) },
        { path: `${routeNames.RENEWALCONTRACT}`, element: getElement(<RenewalContractsList />) },
        { path: `${routeNames.CANCELCONTRACT}`, element: getElement(<CancelledContracts />) },
        // { path: `${routeNames.CONTRACTEDIT}`, element: getElement(<AddCrs />) },
      ],
    },

    {
      path: '/settings',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/settings/app" />, index: true },
        { path: 'app', element: getElement(<DashboardAppPage />) },
        { path: `${routeNames.COMPANYMASTER}`, element: getElement(<AddCompanyMaster />) },
        { path: `${routeNames.COMPANYMASTERADD}`, element: getElement(<AddCompanyMaster />) },
        { path: `${routeNames.COMPANYMASTEREDIT}`, element: getElement(<AddCompanyMaster />) },
        { path: `${routeNames.REMINDERSETTINGS}`, element: getElement(<RemindersPage />) },
        { path: `${routeNames.NOTIFICATION}`, element: getElement(<Notification />) },
        { path: 'addperson', element: getElement(<AddPerson />) },
      ],
    },

    // {
    //   path: '/contracts',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/contracts/app" />, index: true },
    //     { path: 'app', element: getElement(<DashboardAppPage />) },
    //     { path: 'contracts', element: getElement(<ContractsListPage />) },
    //     { path: 'contracts/add', element: getElement(<AddContracts />) },
    //     { path: 'contracts/edit/:id', element: getElement(<AddContracts />) },
    //     { path: 'signatory', element: getElement(<ProspectPage />) },
    //     { path: 'contracts-templates', element: getElement(<ProspectPage />) },
    //     { path: 'company-masters', element: getElement(<CompanyMasterListPage />) },
    //     { path: 'company-masters/add', element: getElement(<AddCompanyMaster />) },
    //     { path: 'notification-rem-settings', element: getElement(<ProspectPage />) },
    //   ],
    // },
    {
      path: '/login',
      element: !user ? <LoginPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      path: '/forgot-password',
      element: !user ? <LoginPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      path: '/reset-password',
      element: !user ? <LoginPage /> : <Navigate to="/dashboard/app" />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
