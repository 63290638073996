import { Container, Grid, Skeleton } from '@mui/material';
import React from 'react';

export default function DashboardLoader() {
  return (
    <>
      <Container>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item md={12}>
            <Skeleton variant="rounded" width={200} height={25} />
          </Grid>
          <Grid item md={3}>
            <Skeleton variant="rounded" height={200} />
          </Grid>
          <Grid item md={3}>
            <Skeleton variant="rounded" height={200} />
          </Grid>
          <Grid item md={3}>
            <Skeleton variant="rounded" height={200} />
          </Grid>
          <Grid item md={3}>
            <Skeleton variant="rounded" height={200} />
          </Grid>
          <Grid item md={8}>
            <Skeleton variant="rounded" height={300} />
          </Grid>
          <Grid item md={4}>
            <Skeleton variant="rounded" height={300} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
