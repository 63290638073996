import React from 'react';
import { Container, Grid, Paper, Skeleton } from '@mui/material';

export const FormSkeleton = ({ lines = 3 }) => {
  return (
    <Paper>
      <Container>
        <Grid container spacing={4}>
          <Grid item sm={12} sx={{ margin: '32px 0' }}>
            <Skeleton animation="wave" width={300} height={40} />
          </Grid>

          {Array.from({ length: lines }, (_, i) => {
            return (
              <>
                <Grid item sm={6}>
                  <Skeleton width="50%" />
                  <Skeleton animation="wave" height={40} />
                </Grid>
                <Grid item sm={6}>
                  <Skeleton width="50%" />
                  <Skeleton animation="wave" height={40} />
                </Grid>
              </>
            );
          })}
          <Grid item sm={12} sx={{ display: 'flex', justifyContent: 'end' }}>
            <Skeleton animation="wave" width={100} height={60} />
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};
