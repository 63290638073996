import React, { createContext, useEffect, useState, useRef, useMemo } from 'react';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { PROPERTYLIST } from '../../API_SERVICE_LAYER/ApiNames';

export const contractContext = createContext();

const AppContext = (props) => {
  const [brhData, setBrhData] = useState({
    propertyList: [],
  });
  const propertyList = useMemo(() => brhData, [brhData]);

  useEffect(() => {
    API.getData(PROPERTYLIST)
      .then((res) => {
        if (res?.data?.status === 'success') {
          setBrhData({ ...brhData, propertyList: res.data.result });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <contractContext.Provider value={propertyList}>{props.children}</contractContext.Provider>
    </>
  );
};
export default AppContext;
