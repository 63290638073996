export const routeNames = {
  LOGIN: '/login ',
  FORGOTPASSWORD: '',
  INDEX: '/dashboard/app',

  PROSPECTS: '/masters/prospects',
  PROSPECTSADD: '/masters/prospects/add',
  PROSPECTSEDIT: '/masters/prospects/edit/:id',

  PROSPECTPROPERTYLIST: '/masters/scouted-properties/',
  PROSPECTPROPERTYADD: '/masters/scouted-properties/add',
  PROSPECTPROPERTYEDIT: '/masters/scouted-properties/edit/:id',

  PROPERTYAGREEMENTSLIST: '/masters/property-agreements',
  PROPERTYAGREEMENTSADD: '/masters/property-agreements/add',

  CLIENTS: '/masters/client',
  CLIENTSADD: '/masters/client/add',
  CLIENTSEDIT: '/masters/client/edit/:id',

  COMPANYMASTER: '/settings/MG-master',
  COMPANYMASTERADD: '/settings/MG-master/add',
  COMPANYMASTEREDIT: '/settings/MG-master/edit/:id',

  CRS: '/crs/',
  CRSADD: '/crs/add',
  CRSEDIT: '/crs/edit/:id',

  EDITRENEWALCONTRACT: '/contractrenewal/edit/:id',
  TAKERENEWALCONTRACT: '/contractrenewal/',


  PRS: '/prs/',
  PRSADD: '/prs/add',
  PRSEDIT: '/prs/edit:id',

  MOU: '/mou/',
  MOUADD: '/mou/add',
  MOUEDIT: '/mou/edit/:id',

  CONTRACT: '/contract',
  CONTRACTADD: '/contract/add',
  CONTRACTEDIT: '/contract/edit:id',
  RENEWALCONTRACT: '/contract/renewal',
  CANCELCONTRACT: '/contract/cancelled-contract',

  CONTRACTTEMPLATES: '/reports/contract-templates',

  NOTIFICATION: '/settings/notification',

  REMINDERSETTINGS: '/settings/reminder-settings',
  VIEWPROFILE: '/profile',
};
