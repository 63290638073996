import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import * as APIS from '../../../API_SERVICE_LAYER/ApiConfigs';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({ role: ['Super Admin', 'Sub Admin', 'Admin', 'Sales Executive', 'Staff'] });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    APIS.postMethod('user-management/api/v1/sign-in', formData)
      .then((res) => {
        console.log(res.data);
        if (res?.data?.status === true) {
          const rolesOrdered = [];
          res?.data?.data?.roles?.forEach((role, index) => {
            role.sort_id =
              role.role_name === 'Sales Executive'
                ? 1
                : role.role_name === 'Super Admin'
                ? 2
                : role.role_name === 'Admin'
                ? 3
                : role.role_name === 'Sub Admin'
                ? 4
                : role.role_name === 'Corporate'
                ? 5
                : role.role_name === 'Employee'
                ? 6
                : role.role_name === 'Staff'
                ? 7
                : role.role_name === 'Member'
                ? 8
                : 9;
            rolesOrdered.push(role);
          });

          const sortedRoleOrder = rolesOrdered.sort((a, b) => a.sort_id - b.sort_id);

          localStorage.setItem(
            'user',
            JSON.stringify({
              ...res?.data?.data,
              roles: rolesOrdered,
              role_id: rolesOrdered[0]?.id,
            })
          );

          const token = res.data.data.token;

          // const str = token;
          // str.replace(/,/g, '');
          // console.log('str', str);
          localStorage.setItem('token', token);
          localStorage.setItem('token_type', 'Bearer');
          navigate('/dashboard', { replace: true });
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField onFocus={() => setError(null)} name="email" label="Email address" onChange={handleChange} />

        <TextField
          name="password"
          label="Password"
          onChange={handleChange}
          onFocus={() => setError(null)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link href="/forgot-password" variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Login
      </LoadingButton>
    </>
  );
}
