import { FormControl, InputAdornment, TextField, Typography } from '@mui/material';
import React from 'react';
import { ToWords } from 'to-words';

export const MoneyInputBRH = ({
  name,
  label,
  value,
  handleChange,
  sx,
  required,
  size,
  endAdornmentText,
  readOnly = false,
}) => {
  const NUMERICS = /^[0-9._\b]+$/;
  const toWords = new ToWords();

  return (
    <>
      <FormControl fullWidth>
        <TextField
          label={label}
          name={name}
          // value={replace(Number(value).toLocaleString('en-IN'),',','')}
          value={value ? Number(value).toLocaleString('en-IN') : ''}
          // value={value}
          onChange={handleChange}
          required={required}
          sx={sx}
          disabled={readOnly}
          onKeyDown={(event) => {
            if (
              !(
                NUMERICS.test(event.key) ||
                event.key === 'Backspace' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight'
              )
            ) {
              event.preventDefault();
            }
          }}
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
            endAdornment: <InputAdornment position="end">{endAdornmentText && endAdornmentText}</InputAdornment>,
          }}
          size={size}
          // helperText={value &&  toWords.convert(value, { currency: true })}
          helperText={
            <Typography variant="caption" sx={{ marginTop: '3px', color: '#339af0' }}>
              {value && toWords.convert(value, { currency: true })}
            </Typography>
          }
        />
      </FormControl>
    </>
  );
};
