import React, { useEffect, useState } from 'react';
import PhoneInput from 'material-ui-phone-number';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Card,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Box,
  Button,
  Stack,
  Drawer,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';

import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import Swal from 'sweetalert2';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import MuiAppBar from '@mui/material/AppBar';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import Scrollbar from '../../../components/scrollbar/Scrollbar';

import { routeNames } from '../nav/routeNames';
import palette from '../../../theme/palette';
import { ToastModalBRH } from '../../../components/toasts/ToastModalBRH';
import './index.css';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { GmailInputBRH } from '../../../components/forms/GmailInputBRH';
import { MobileInputBRH } from '../../../components/forms/MobileInputBRH';
import { DatePickerInputBRH } from '../../../components/forms/DatePickerInputBRH';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';

export const HeaderBRH = ({ open, setOpen, anchor, props, id }) => {
  const navigate = useNavigate();

  const [profile, setProfile] = useState({});
  const [user, setUser] = useState();
  const [on, setOn] = useState(false);
  const { pathname } = useLocation();

  const drawerWidth = 275;
  const drawerBackBlockStyles = {
    color: '#111',
  };
  const redirectTo = () => {
    navigate('/profile');
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  const handleLogout = () => {
    console.log('logout');
    localStorage.clear();
    setTimeout(() => {
      window.location.href = '/login';
    }, 1500);
  };
  useEffect(() => {
    const localUser = localStorage.getItem('user');
    const userData = JSON.parse(localUser);
    setUser(userData);
  }, []);
  // useEffect(() => {
  //   console.log(user.id);
  // });
  useEffect(() => {});
  const toggleDrawer = (newOpen) => () => {
    setOn(newOpen);
  };
  return (
    <>
      {/* <Drawer
        anchor="top"
        open={on}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        sx={{ width: 'auto', height: '50vh' }}
      >
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2}>
            <Typography variant="h4">Edit Profile</Typography>
          </Stack>
          <Card sx={{ p: 2 }}>
            <Scrollbar>
              <Box sx={{ flexGrow: 1 }}>
                <form>
                  <FormControl sx={{ flexGrow: 1, width: '100%' }} sub="true">
                    <Grid container rowSpacing={2.5} columnSpacing={2} sx={{ marginTop: '10px' }}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextInputBRH label="User Name" />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <DatePickerInputBRH
                          label="Date Of Birth *"
                          // value={contractDetails.dateOfAgreement}
                          // handleChange={(value) => {
                          //   handleDateChange('dateOfAgreement', value);
                          // }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <MobileInputBRH
                          label="Contact Number"
                          name="contact_number"
                          // required="true"
                          // value={basicDetails.contact_number}
                          // handleChange={handleChange}
                          // required
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <GmailInputBRH
                          label="Contact Person Email"
                          name="email"
                          // required="true"
                          // value={basicDetails.email}
                          // handleChange={handleChange}
                          // required
                        />
                      </Grid>
                    </Grid>
                    <div style={{ display: 'flex', alignSelf: 'flex-end', marginTop: '30px' }}>
                      <LoadingButtonBRH variant="containeds" label="Save & Next" type="submit" />
                    </div>
                  </FormControl>
                </form>
              </Box>
            </Scrollbar>
          </Card>
        </Container>
      </Drawer> */}
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ backgroundColor: '#fafbfb', display: on && 'none', borderBottom: '1px solid orange' }}>
          <Box sx={{ display: open ? 'none' : 'block' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '16px',

                // display: 'flex', justifyContent: 'center',backgroundColor:"red",width:'64px',
                // ...(!open && { display: 'none' }),
              }}
            >
              <HomeIcon
                sx={{
                  ...drawerBackBlockStyles,
                  color: pathname === `${routeNames.INDEX}` ? `${palette.primary.dark}` : `${palette.common.black}`,
                }}
              />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 'auto',
                  //   display: on && 'none',
                  // ...(open && { display: 'none' }),
                }}
              >
                <MenuIcon sx={{ ...drawerBackBlockStyles }} />
              </IconButton>
            </Box>
            {/* <Header /> */}
            <Box sx={{ display: 'flex', columnGap: '16px' }}>
              <Box sx={{ alignSelf: 'center', letterSpacing: '1px' }}>
                <Typography color="primary" variant="overline">
                  {user?.name}
                </Typography>
              </Box>
              <div className="profile">
                <img src="/assets/images/avatars/avatar_default.jpg" alt="avatar" className="avatar" />
                <div className="drop-down">
                  <ul className="user_profile">
                    <Button
                      fullWidth
                      onClick={(e) => navigate(routeNames.VIEWPROFILE, { state: { id: user.id, token: user.token } })}
                    >
                      view profile
                    </Button>

                    <Button
                      fullWidth
                      onClick={() => {
                        console.log('log out');
                        // ToastModalBRH('question', 'Are You Sure Want To LogOut', false, handleLogout());
                        Swal.fire({
                          title: 'Are you sure want to logout?',
                          icon: 'info',
                          showCancelButton: true,
                          confirmButtonColor: '#fab005',
                          cancelButtonColor: '#ced4da',
                          confirmButtonText: 'Yes',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
                            handleLogout();
                          }
                        });
                      }}
                    >
                      logout
                    </Button>
                  </ul>
                </div>
              </div>
              {/* <IconButton
                onClick={() => {
                  console.log('log out');
                  // ToastModalBRH('question', 'Are You Sure Want To LogOut', false, handleLogout());
                  Swal.fire({
                    title: 'Are you sure want to logout?',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
                      handleLogout();
                    }
                  });
                }}
              >
                <ExitToAppIcon />
              </IconButton> */}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};
