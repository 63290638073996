// component
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: <DashboardIcon />,
    submenus: [
      {
        title: 'Contracts',
        path: '/dashboard/contracts',
        icon: icon('ic_list'),
      },
      {
        title: 'Notifications',
        path: '/dashboard/notifications',
        icon: icon('ic_add'),
      },
      {
        title: 'Reminders',
        path: '/dashboard/reminders',
        icon: icon('ic_add'),
      },
    ],
  },
  {
    title: 'Masters',
    path: '/masters/app',
    icon: <SettingsSuggestIcon />,
    submenus: [
      {
        title: 'Prospects',
        path: '/masters/prospects',
        icon: icon('ic_list'),
      },
      {
        title: 'Prospect Property',
        path: '/masters/prospects-property',
        icon: icon('ic_add'),
      },
      {
        title: 'Client',
        path: '/masters/client',
        icon: icon('ic_add'),
      },
    ],
  },

  {
    title: 'Contracts',
    path: '/contracts/contracts',
    icon: <HandshakeIcon />,
    submenus: [
      {
        title: 'Contracts',
        path: '/contracts/contracts',
        icon: icon('ic_list'),
      },
    ],
  },

  {
    title: 'Reports',
    path: '/reports',
    icon: <AssessmentIcon />,
    submenus: [
      {
        title: 'Contract Reports',
        path: '/reports/contract-reports',
        icon: icon('ic_list'),
      },
      {
        title: 'Transaction Reports',
        path: '/reports/transaction-reports',
        icon: icon('ic_add'),
      },
    ],
  },
  {
    title: 'Settings',
    path: '/settings/app',
    icon: <SettingsIcon />,
    submenus: [
      {
        title: 'Company Masters',
        path: '/settings/company-masters',
        icon: icon('ic_add'),
      },
      {
        title: 'Contract Templates',
        path: '/settings/contracts-templates',
        icon: icon('ic_add'),
      },

      {
        title: 'Signatory',
        path: '/settings/signatory',
        icon: icon('ic_list'),
      },

      {
        title: 'Notification Reminder Settings',
        path: '/settings/notification-rem-settings',
        icon: icon('ic_add'),
      },
    ],
  },
];

export default navConfig;
