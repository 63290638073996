import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Breadcrumbs, Button, Card, Container, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Scrollbar from '../../components/scrollbar/Scrollbar';
import { TextFieldSelectBRH } from '../../components/forms/TextFieldSelectBRH';
import { NumberInputBRH } from '../../components/forms/NumberInputBRH';
import { ToastBRH } from '../../components/toasts/ToastBRH';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
import CancelAlertBRH from '../../components/forms/CancelAlertBRH';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/toastTimings';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { REMINDERDAYS } from '../../API_SERVICE_LAYER/ApiNames';
import { FormSkeleton } from '../../components/skeleton/FormSkeleton';

function Index() {
  const [cancelOpen, setCancelOpen] = React.useState(false);
  const [reminderData, setReminderData] = useState({
    reminderMonths: [],
    financialMonths: [
      { label: 'Monthly', value: 1 },
      { label: 'Quarterly', value: 3 },
      { label: 'Half- Yearly', value: 6 },
      // { label: 'Annually', value: 12 },
    ],
    months: [
      { label: 1, value: 1 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
      { label: 4, value: 4 },
      { label: 5, value: 5 },
      { label: 6, value: 6 },
      { label: 7, value: 7 },
      { label: 8, value: 8 },
      { label: 9, value: 9 },
      { label: 10, value: 10 },
      { label: 11, value: 11 },
      { label: 12, value: 12 },
    ],
    reminderDays: 30,
    isFetched: true,
    // FOR VALIDATIONS
    // reminderError: false,
  });
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (reminderData.reminderDays < 31 || reminderData.reminderDays > 90) {
      // return ToastBRH('warning', 'Enter valid Day', '_', false);
      ToastBRH('warning', 'Enter valid Day', TOAST_SUCCESS);
      // setTimeout(() => navigate(routeNames.CLIENTS), 2000);
      // ToastBRH('warning', 'Enter valid Day', `${routeNames.INDEX}`);
    }
    const formData = {
      reminder_period: Number(reminderData.reminderDays),
    };
    console.log('submit');
    API.postMethod(`${REMINDERDAYS}`, formData)
      .then((res) => {
        console.log('laksh', res);
        if (res.data.status) {
          ToastBRH('success', 'Reminders Updated Successfully', TOAST_SUCCESS);
          setTimeout(() => navigate(routeNames.INDEX), TOAST_SUCCESS);
        }
      })
      .catch((err) => {
        console.log(err);
        ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
      });
  };
  const handleSelectChange = () => {
    console.log('selectd');
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReminderData({
      ...reminderData,
      [name]: value,
    });
  };
  useEffect(() => {
    setReminderData((prevData) => ({ ...prevData, isFetched: false }));
    API.getMethod(REMINDERDAYS)
      .then((res) => {
        const apiData = res.data.data[0];
        if (res.data.status) {
          setReminderData((prevData) => ({ ...prevData, isFetched: true, reminderDays: apiData.reminder_period }));
        }
      })
      .catch((err) => {
        console.error(err);
        setReminderData((prevData) => ({ ...prevData, isFetched: true }));
      });
  }, []);

  if (!reminderData.isFetched) return <FormSkeleton />;
  return (
    <>
      <Helmet>
        <title> Contract | BRH </title>
      </Helmet>
      <Container>
        <Breadcrumbs aria-label="breadcrumb">
          <Button onClick={() => navigate(routeNames.INDEX)} color="secondary">
            Dashboard
          </Button>
          <Typography color="primary" variant="button">
            Reminder Settings
          </Typography>
        </Breadcrumbs>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Reminders
          </Typography>
        </Stack>
        <Card sx={{ p: 2 }}>
          <Scrollbar>
            <Box sx={{ flexGrow: 1, m: 4 }}>
              <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                  <Grid item md={12}>
                    <NumberInputBRH
                      label="Reminder Period"
                      name="reminderDays"
                      value={reminderData.reminderDays}
                      handleChange={handleChange}
                      minValue={30}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">days</InputAdornment>,
                      }}
                    />
                    <Typography variant="caption"> Min-30 days Max-90 days</Typography>
                    {/* <TextFieldSelectBRH
                      label="Reminder Period (months)"
                      value={reminderData.reminderMonths}
                      handleChange={handleSelectChange}
                      options={reminderData.financialMonths}
                      renderLabel="label"
                      renderValue="value"
                    /> */}
                  </Grid>
                </Grid>
                <Box sx={{ marginTop: '64px' }}>
                  <Stack direction="row" justifyContent="end">
                    <Button
                      color="secondary"
                      onClick={() => {
                        setCancelOpen(true);
                      }}
                      variant="outlined"
                      sx={{ marginRight: '24px' }}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" sx={{ marginLeft: '16px' }} type="submit">
                      Save
                    </Button>
                    <CancelAlertBRH
                      cancelOpen={cancelOpen}
                      setCancelOpen={setCancelOpen}
                      routeName={routeNames.INDEX}
                    />
                  </Stack>
                </Box>
              </form>
            </Box>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}

export default Index;
