// const { createSlice } = require('@reduxjs/toolkit');

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contracts: [],
  activeContracts: [],
  expiredContracts: [],
  expiringContracts: [],
  LockInContracts: [],
  YoyContracts: [],
};

const contractsSlice = createSlice({
  name: 'contractsList',
  initialState,
  reducers: {
    addContractsList(state, action) {
      state.contracts = action.payload;
    },
    adddActiveContracts(state, action) {
      state.activeContracts = action.payload;
    },
    addExpiredContracts(state, action) {
      state.expiredContracts = action.payload;
    },
    addExpiringContracts(state, action) {
      state.expiringContracts = action.payload;
    },
    addYoyContracts(state, action) {
      state.YoyContracts = action.payload;
    },
    addLockInContracts(state, action) {
      state.LockInContracts = action.payload;
    },
  },
});

export const {
  addContractsList,
  adddActiveContracts,
  addExpiringContracts,
  addExpiredContracts,
  addYoyContracts,
  addLockInContracts,
} = contractsSlice.actions;

export default contractsSlice.reducer;
