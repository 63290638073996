import { FormControl, Grid, IconButton, InputAdornment } from '@mui/material';
import React, { useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AutoCompleteInputBRH } from '../../../../components/forms/AutoCompleteInputBRH';
import { TextInputBRH } from '../../../../components/forms/TextInputBRH';
import { GmailInputBRH } from '../../../../components/forms/GmailInputBRH';
import { MobileInputBRH } from '../../../../components/forms/MobileInputBRH';
import { NumberInputBRH } from '../../../../components/forms/NumberInputBRH';
import { TextFieldSelectBRH } from '../../../../components/forms/TextFieldSelectBRH';
import { LoadingButtonBRH } from '../../../../components/forms/LoadingButtonBRH';
import { DatePickerInputBRH } from '../../../../components/forms/DatePickerInputBRH';

import { CITYLIST, STATELIST } from '../../../../API_SERVICE_LAYER/ApiNames';
import * as API from '../../../../API_SERVICE_LAYER/ApiConfigs';
import { ToastBRH } from '../../../../components/toasts/ToastBRH';

function AddProvider({ onClose, countryList, handleProviderList }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [providerDetails, setProviderDetails] = React.useState({
    providerName: '',
    email: '',
    password: '',
    confirmPassword: '',
    dateOfBirth: null,
    contactPersonName: '',
    contactPersonNumber: '',
    address1: '',
    address2: '',
    pincode: '',
    country_id: '',
    state_id: '',
    city_id: '',
    countryObject: null,
    stateObject: null,
    cityObject: null,
    countryList: countryList,
    stateList: [],
    cityList: [],
    isSelectDataloadedCountry: true,
    isSelectDataloadedState: true,
    confirmPasswordErr: '',
    PasswordErr: '',
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (e, newValue) => {
    const name = e.target.name;
    setProviderDetails((prev) => ({
      ...prev,
      [name]: e.target.value,
    }));
    const [addressname] = e.target.id.split('-');
  };
  const handleCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    console.log(event);
    const id = newValue.id;
    if (name === 'name') {
      setProviderDetails((prev) => ({
        ...prev,
        isSelectDataloadedCountry: false,
      }));
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setProviderDetails((prev) => ({
              ...prev,
              country_id: id,
              countryObject: newValue,
              stateList: res.data.data,
              stateObject: null,
              cityList: [],
              cityObject: null,
              isSelectDataloadedCountry: true,
            }));
          }
        })
        .catch((err) => {
          setProviderDetails((prev) => ({
            ...prev,
            country_id: id,
            countryObject: newValue,
            stateList: [],
            isSelectDataloadedCountry: true,
          }));
        });
    }
  };
  const handleStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setProviderDetails((prev) => ({
        ...prev,
        isSelectDataloadedState: false,
      }));
      API.getData(`${CITYLIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setProviderDetails((prev) => ({
              ...prev,

              stateObject: newValue,
              state_id: id,
              cityList: res.data.data,
              cityObject: null,
              isSelectDataloadedState: true,
            }));
          }
        })
        .catch((err) => {
          setProviderDetails((prev) => ({
            ...prev,
            state_id: id,
            stateObject: newValue,
            cityList: [],
            isSelectDataloadedState: true,
          }));
        });
    }
  };
  const handleCityChange = (event, newValue) => {
    const id = newValue.id;

    setProviderDetails((prev) => ({
      ...prev,

      cityObject: newValue,
      city_id: id,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (providerDetails.password.length < 8) {
      setProviderDetails((Prev) => ({
        ...Prev,
        PasswordErr: 'Password should have atleast 8 characters',
      }));
      return;
    }
    if (providerDetails.password.length >= 8) {
      setProviderDetails((Prev) => ({
        ...Prev,
        PasswordErr: '',
      }));
    }
    if (providerDetails.password !== providerDetails.confirmPassword) {
      setProviderDetails((Prev) => ({
        ...Prev,
        confirmPasswordErr: 'Password and Confirm Password does not match',
      }));
      return;
    }
    if (providerDetails.password === providerDetails.confirmPassword) {
      setProviderDetails((Prev) => ({
        ...Prev,
        confirmPasswordErr: '',
      }));
    }

    const user = JSON.parse(localStorage.getItem('user'));
    console.log(providerDetails, 'provider');
    const emailVerifiedAt = new Date().toISOString();
    const data = {
      name: providerDetails.providerName,
      password: providerDetails.password,
      password_confirmation: providerDetails.confirmPassword,
      dob: providerDetails.dateOfBirth,

      email: providerDetails.email,
      // provider_email: providerDetails.email,
      // provider_mobile: providerDetails.mobile_no,
      mobile_no: providerDetails.contactPersonNumber,
      email_verified_at: emailVerifiedAt,
      contact_number: providerDetails.contactPersonNumber,
      //  profile_img_path: providerDetails.profile_img_path,
      address1: providerDetails.address1,
      address2: providerDetails.address2,
      city_id: providerDetails.city_id,
      state_id: providerDetails.state_id,
      country_id: providerDetails.country_id,
      // zipcode: providerDetails.zipcode,
      postal_code: providerDetails.pincode,
      contact_person: providerDetails.contactPersonName,

      created_by: user.id,
      is_verified: 0,
      role: 'Provider',
      register_from: 'Admin',
      role_id: 3,
      status: 1,
    };

    API.postMethod('api/provider', data)
      .then((res) => {
        if (res.status === 200) {
          handleProviderList();
          ToastBRH('success', res.data.message, '___', false);
        }
      })
      .catch((err) => ToastBRH('warning', err.response.data.message, '___', false));
  };
  const handleDateChange = (e) => {
    console.log(e);
    setProviderDetails((prev) => ({
      ...prev,
      dateOfBirth: e,
    }));
  };

  return (
    <form noValidate={false} onSubmit={handleFormSubmit}>
      <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
        <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Provider Name"
              name="providerName"
              required="true"
              value={providerDetails.providerName}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <GmailInputBRH
              label="Email"
              name="email"
              value={providerDetails.email}
              handleChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Password"
              name="password"
              value={providerDetails.password}
              handleChange={handleChange}
              required
              type={showPassword ? 'text' : 'password'}
              InputProps={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <span style={{ color: 'red', fontSize: '10px', fontWeight: 500 }}>{providerDetails.PasswordErr}</span>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            //  sx={{ marginBottom: "32px" }}
          >
            <TextInputBRH
              label="Confirm Password"
              name="confirmPassword"
              value={providerDetails.confirmPassword}
              handleChange={handleChange}
              required
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <span style={{ color: 'red', fontSize: '10px', fontWeight: 500 }}>
              {providerDetails.confirmPasswordErr}
            </span>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            //  sx={{ marginBottom: "32px" }}
          >
            <DatePickerInputBRH
              label="Date of Birth"
              name="dateOfBirth"
              value={providerDetails.dateOfBirth}
              handleChange={handleDateChange}
              maxDate={new Date()}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Contact Person Name"
              name="contactPersonName"
              required
              value={providerDetails.contactPersonName}
              handleChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MobileInputBRH
              label="Contact Person Number"
              name="contactPersonNumber"
              value={providerDetails.contactPersonNumber}
              handleChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} />
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Address Line #1"
              name="address1"
              value={providerDetails.address1}
              handleChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextInputBRH
              label="Address Line #2"
              name="address2"
              value={providerDetails.address2}
              handleChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <AutoCompleteInputBRH
              label="Country"
              name="name"
              value={providerDetails.countryObject}
              handleChange={handleCountryChange}
              options={countryList}
              isSelectDataloaded={providerDetails.isSelectDataloadedCountry}
              required
              handleClear={() =>
                setProviderDetails((prev) => ({
                  ...prev,
                  stateObject: null,
                  countryObject: null,
                  cityObject: null,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <AutoCompleteInputBRH
              label="State"
              // required="true"
              name="name"
              // disable={providerDetails.countryObject === null}
              value={providerDetails.stateObject}
              handleChange={handleStateChange}
              options={providerDetails.stateList}
              isSelectDataloaded={providerDetails.isSelectDataloadedState}
              required
              handleClear={() =>
                setProviderDetails((prev) => ({
                  ...prev,
                  stateObject: null,

                  cityObject: null,
                }))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <AutoCompleteInputBRH
              label="City"
              name="name"
              value={providerDetails.cityObject}
              handleChange={handleCityChange}
              options={providerDetails.cityList}
              required
              handleClear={() =>
                setProviderDetails((prev) => ({
                  ...prev,

                  cityObject: null,
                }))
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <NumberInputBRH
              label="Pincode"
              name="pincode"
              value={providerDetails.pincode}
              handleChange={handleChange}
              required
            />
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            marginTop: '30px',
          }}
          item
        >
          <LoadingButtonBRH type="submit" variant="containeds" label="Add" />
        </Grid>
      </FormControl>
    </form>
  );
}

export default AddProvider;
