import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useEffect, useMemo, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { Helmet } from 'react-helmet-async';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import { SwichInputBRH } from '../../../components/forms/SwichInputBRH';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Iconify from '../../../components/iconify/Iconify';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';

const WorkSpaceList = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [openDialog, setOpendialog] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const navigate = useNavigate();

  // useEffect(() => {
  //   API.getDataToken('api/resourcegroup?limit=500')
  //   .then((res) => {
  //   if (res.status === 'success') {

  //     }
  //   });
  // }, []);

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };
  const redirectTo = () => {
    navigate('/masters/prospect-property/add');
  };

  const data = [
    {
      name: {
        firstName: 'John',
        lastName: 'Doe',
      },
      address: '261 Erdman Ford',
      city: 'East Daphne',
      state: 'Kentucky',
    },
    {
      name: {
        firstName: 'Jane',
        lastName: 'Doe',
      },
      address: '769 Dominic Grove',
      city: 'Columbus',
      state: 'Ohio',
    },
    {
      name: {
        firstName: 'Joe',
        lastName: 'Doe',
      },
      address: '566 Brakus Inlet',
      city: 'South Linda',
      state: 'West Virginia',
    },
    {
      name: {
        firstName: 'Kevin',
        lastName: 'Vandy',
      },
      address: '722 Emie Stream',
      city: 'Lincoln',
      state: 'Nebraska',
    },
  ];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'resource_group',
        header: 'Resource Group',
        size: 150,
      },
      {
        accessorKey: 'resource_name',
        header: 'Resource Name',
        size: 150,
      },
      {
        accessorKey: 'code',
        header: 'Code',
        size: 200,
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        size: 150,
      },
      {
        accessorKey: 'capacity',
        header: 'Capacity',
        size: 150,
      },
      {
        accessorKey: 'admin_only',
        header: 'Admin only',
        size: 150,
      },
      {
        accessorKey: 'Request Only',
        header: 'request_only',
        size: 150,
      },
      {
        accessorKey: ' enable/disable',
        header: 'Enable/Disable',
        size: 150,
      },
      {
        accessorKey: 'action',
        header: 'Action',
      },
    ],
    []
  );
  const handleOpen = () => {
    setOpendialog(true);
  };
  const handleClose = () => {
    setOpendialog(false);
  };
  const handleImageSelection = (event) => {
    const files = Array.from(event.target.files);
    setSelectedImages((prevSelectedImages) => [...prevSelectedImages, ...files]);
  };
  const handleImageDelete = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };
  return (
    <>
      <Helmet>
        <title> Contracts | BRH </title>
      </Helmet>
      <Container>
        {/* <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/dashboard">
          Dashboard
        </Link>
        <Typography color="text.primary">CRS</Typography>
      </Breadcrumbs> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Work Space List
          </Typography>
          <Button onClick={handleOpen} variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Resource
          </Button>
          <Dialog open={openDialog} onClose={handleClose} fullWidth={fullWidth} maxWidth="lg">
            <form>
              <DialogContentText style={{ padding: '20px' }}>
                <FormControl sx={{ display: 'flex' }}>
                  <DialogActions style={{ padding: '0px 0px 8px 0px' }}>
                    <Box>
                      <Button variant="outlined" onClick={() => handleClose()} size="medium">
                        X
                      </Button>
                    </Box>
                  </DialogActions>
                  <Grid sx={{ display: ' flex' }} container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <AutoCompleteInputBRH label="Resource Group" name="resource_group" size="medium" />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextInputBRH
                        label="Capacity"
                        name="capacity"
                        //   value={companyMasterData.cpName}
                        //   handleChange={handleChange}
                        required
                        size="medium"
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextInputBRH label="Quantity" name="quantity" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextInputBRH label="Resource Code" name="resource_code" />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextInputBRH label="Resource Name" name="resource_name" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <AutoCompleteInputBRH label="Sort Order" name="sort_order" size="medium" />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextInputBRH label="Description" name="description" multiline rows={3} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Button sx={{ display: 'flex', gap: '10px', alignItems: 'center', padding: 0 }} component="label">
                        <CloudUploadIcon size={50} />
                        Upload Property Images
                        <input type="file" hidden multiple onChange={handleImageSelection} />
                      </Button>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          //   border: '1px solid #ccc',
                          //   padding: '5px',
                          //   height: '10rem',
                        }}
                      >
                        {selectedImages.map((image, index) => (
                          <div key={index} style={{ marginRight: '10px' }}>
                            <IconButton onClick={() => handleImageDelete(index)}>
                              <ClearIcon />
                            </IconButton>
                            <img
                              src={URL.createObjectURL(image)}
                              alt={`Preview ${index}`}
                              style={{ width: 'auto', height: '100px', marginRight: '10px' }}
                            />
                          </div>
                        ))}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <SwichInputBRH
                        name="admin_booking"
                        label="Admin Only Booking"
                        // value={prospectData.status}
                        // handleChange={onToggleButton}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <SwichInputBRH
                        name="meeting_conference"
                        label="Meeting or Conference"
                        // value={prospectData.status}
                        // handleChange={onToggleButton}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <SwichInputBRH
                        name="request_booking"
                        label="Request Only Booking"
                        // value={prospectData.status}
                        // handleChange={onToggleButton}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </DialogContentText>
              <DialogActions sx={{ marginBottom: '16px' }}>
                <Box sx={{ marginLeft: '8px' }}>
                  <Button variant="outlined" onClick={() => handleClose()} size="medium" sx={{ padding: '8px 16px' }}>
                    Cancel
                  </Button>
                </Box>
                <Box sx={{ marginLeft: '16px' }}>
                  <LoadingButtonBRH type="submit" variant="contained" label=" Add" />
                </Box>
              </DialogActions>
            </form>
          </Dialog>
        </Stack>
        <Card sx={{ p: 2 }}>
          <Scrollbar>
            <MaterialReactTable columns={columns} data={data} />
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
};

export default WorkSpaceList;
