import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Link,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  FormLabel,
} from '@mui/material';
import { Form } from 'react-bootstrap';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useMemo, useState, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import { Helmet } from 'react-helmet-async';
import { experimentalStyled as styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers';
import DatePicker from 'react-datepicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ToastBRH } from '../../../components/toasts/ToastBRH';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';
import { SwichInputBRH } from '../../../components/forms/SwichInputBRH';
import { TimeInputBRH } from '../../../components/forms/TimeInputBRH';
import { CheckBoxInputBRH } from '../../../components/forms/CheckBoxInputBRH';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Iconify from '../../../components/iconify/Iconify';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { MoneyInputBRH } from '../../../components/forms/MoneyInputBRH';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { CommonAlert } from '../../../components/toasts/CommonAlert';
import { TableSkeleton } from '../../../components/skeleton/TableSkeleton';
import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';

const PlansAdd = ({ propertyId, onChange, onChangeNext }) => {
  const starttime = new Date();
  starttime.setHours(10);
  starttime.setMinutes(0);
  const endtime = new Date();
  endtime.setHours(19);
  endtime.setMinutes(0);
  const [edit, setEdit] = useState('');
  const [draftopen, setDraftOpen] = useState(false);

  const [deleteOpen, setdeleteOpen] = useState(false);
  const [plansData, setPlansData] = useState({
    resouceDataList: [],
    propertyData: [],
    resourceTypeObject: null,
    resource_id: null,
    plansDataList: [],
    planTypeObject: null,
    plan_for_id: null,
    periodDataList: [],
    periodTypeObject: null,
    access_period: null,
    access_period_unit_id: null,
    invoiceTempDataList: [],
    invoiceTemObject: null,
    invoice_template_id: null,
    admin_only: 0,
    advance: null,
    sortOptions: [],
    sort_order: null,
    sortOrderObject: null,
    description: null,
    extra_information: null,
    hsn_code: '997212',
    is_location_time: null,
    plan_id: null,
    is_overnight: null,
    plan_name: null,
    price: null,
    property_id: null,
    request_only: 0,
    security_amt: null,
    sort_id: null,
    status: 1,
    workingDayMon: false,
    workingDayTue: false,
    workingDayWed: false,
    workingDayThu: false,
    workingDayFri: false,
    workingDaySat: false,
    workingDaySun: false,
    workingHoursStart: starttime,
    workingHoursEnd: endtime,
    plansresList: [],
    timingDataList: [],
    start_time: '',
    end_time: '',
    deleteData: null,
    Days: [
      {
        day: 'Monday',
        enum: 'mon',
        status: 0,
        start_time: '',
        end_time: '',
        open: 0,
      },
      {
        day: 'Tuesday',
        enum: 'tue',
        status: 0,
        start_time: '',
        end_time: '',
        open: 0,
      },
      {
        day: 'Wednesday',
        enum: 'wed',
        status: 0,
        start_time: '',
        end_time: '',
        open: 0,
      },
      {
        day: 'Thursday',
        enum: 'thu',
        status: 0,
        start_time: '',
        end_time: '',
        open: 0,
      },
      {
        day: 'Friday',
        enum: 'fri',
        status: 0,
        start_time: '',
        end_time: '',
        open: 0,
      },
      {
        day: 'Saturday',
        status: 0,
        enum: 'sat',
        start_time: '',
        end_time: '',
        open: 0,
      },
      {
        day: 'Sunday',
        enum: 'sun',
        status: 0,
        start_time: '',
        end_time: '',
        open: 0,
      },
    ],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const id = propertyId;
    API.getData(`api/propertyresources/${id}`)
      .then((resProres) => {
        console.log(resProres);
        if (resProres?.data.success === 'success') {
          const apiresourceData = resProres?.data?.data;
          console.log(apiresourceData, 'ajksjkak');
          setPlansData((prevDetails) => ({
            ...prevDetails,
            resouceDataList: apiresourceData,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    API.getData(`api/property/${id}`).then((result) => {
      console.log(result.data.data);
      // this.setState({ isLoading: false });
      // this.setState({ property_code: result.data.property_code });
      // this.setState({ is_draft: result.data.is_draft });
      setPlansData((prev) => ({
        ...prev,
        propertyData: result.data.data,
      }));
    });
    API.getData('api/plantype')
      .then((resPlan) => {
        console.log(resPlan);
        if (resPlan?.data.status === 'success') {
          const apiPlanData = resPlan?.data?.data.data;
          console.log(apiPlanData);
          setPlansData((prevDetails) => ({
            ...prevDetails,
            plansDataList: apiPlanData,
          }));
        }
      })
      .catch((errPlan) => {
        console.log(errPlan);
      });

    API.getData('api/periodunit')
      .then((resPer) => {
        console.log(resPer);
        if (resPer?.data?.status === 'success') {
          const apiPeriod = resPer?.data.data?.data;
          console.log(apiPeriod);
          setPlansData((prevDetails) => ({
            ...prevDetails,
            periodDataList: apiPeriod,
          }));
        }
      })
      .catch((errPeriod) => {
        console.log(errPeriod);
      });

    API.getData('api/invoicetemplate')
      .then((resInvoice) => {
        console.log(resInvoice);
        if (resInvoice?.data?.status === 'success') {
          const apiInvoiceData = resInvoice?.data?.data?.data;
          console.log(apiInvoiceData);
          setPlansData((prevDetails) => ({
            ...prevDetails,
            invoiceTempDataList: apiInvoiceData,
          }));
        }
      })
      .catch((errInv) => {
        console.log(errInv);
      });

    API.getData('api/sortorder?limit=10').then((resSort) => {
      console.log(resSort);
      if (resSort.data.data) {
        const sortOrderOptions = resSort.data.data.data.map((item) => ({
          id: item.id,
          sort_order: item.sort_order,
        }));
        setPlansData((prevDetails) => ({
          ...prevDetails,
          sortOptions: sortOrderOptions,
        }));
      }
    });
  }, []);

  useEffect(() => {
    const id = propertyId;
    API.getData(`api/resourceplans/${id}`).then((resData) => {
      console.log(resData);
      if (resData?.data?.data) {
        const apiPlansData = resData.data.data;
        console.log(apiPlansData);
        setPlansData((prevDetails) => ({
          ...prevDetails,
          plansresList: apiPlansData?.filter((i) => i.status !== 2),
        }));
      }
    });
  }, []);

  useEffect(() => {
    console.log(plansData, 'plans');
  }, [plansData]);

  useEffect(() => {
    if (plansData.resource_name && plansData.periodTypeObject) {
      const planName = `${plansData.resource_name} ${plansData.periodTypeObject.unit} Plan`;
      setPlansData({
        ...plansData,
        plan_name: planName,
      });
    }
  }, [plansData.resource_name, plansData.periodTypeObject]);

  const handleresourceChange = (event, newValue) => {
    console.log(newValue);
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    console.log(id);
    setPlansData((prevDetails) => ({
      ...prevDetails,
      [name]: newValue.resource_name,
      resourceTypeObject: newValue,
      resource_id: id,
    }));
  };

  const handlePlanChange = (event, newValue) => {
    console.log(newValue);
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    console.log(id);
    setPlansData((prevDetails) => ({
      ...prevDetails,
      [name]: newValue.plan_type_for,
      planTypeObject: newValue,
      plan_for_id: id,
    }));
  };

  const handlePeriodChange = (event, newValue) => {
    console.log(newValue);
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    console.log(id);
    setPlansData((prevDetails) => ({
      ...prevDetails,
      [name]: newValue.unit,
      periodTypeObject: newValue,
      access_period_unit_id: id,
    }));
  };

  const invoiceTemChange = (event, newValue) => {
    console.log(newValue);
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    console.log(id);
    setPlansData((prevDetails) => ({
      ...prevDetails,
      [name]: newValue.invoice_name,
      invoiceTemObject: newValue,
      invoice_template_id: id,
    }));
  };
  const handleSortOrderChange = (event, newValue) => {
    if (!newValue) return;
    setPlansData((prevDetails) => ({
      ...prevDetails,
      sort_id: newValue.id,
      sortOrderObject: newValue,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (
      name === 'workingDaySun' ||
      name === 'workingDayMon' ||
      name === 'workingDayTue' ||
      name === 'workingDayWed' ||
      name === 'workingDayThu' ||
      name === 'workingDayFri' ||
      name === 'workingDaySat'
    ) {
      setPlansData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (type === 'checkbox') {
      if (name === 'is_default_1' && checked) {
        setPlansData({
          ...plansData,
          is_default_1: 1,
          is_default_2: 0,
        });
      } else if (name === 'is_default_2' && checked) {
        setPlansData({
          ...plansData,
          is_default_1: 0,
          is_default_2: 1,
        });
      } else {
        setPlansData({
          ...plansData,
          is_default_1: 0,
          is_default_2: 0,
        });
      }
    } else {
      setPlansData({ ...plansData, [name]: value });
    }
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'status') {
      setPlansData({ ...plansData, [name]: checked ? 1 : 0 });
    }
  };

  const handleresourceSwitchChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'request_only') {
      setPlansData({ ...plansData, [name]: checked ? 1 : 0 });
    }
  };

  const handleAdminSwitchChange = (e) => {
    const { name, checked } = e.target;
    if (name === 'admin_only') {
      setPlansData({ ...plansData, [name]: checked ? 1 : 0 });
    }
  };

  const handleMoneyChange = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    value = String(value).replace(/,/g, '');
    setPlansData({
      ...plansData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const data = {
      property_id: propertyId,
      hsn_code: plansData.hsn_code,
      price: plansData.price,
      access_period: plansData.access_period,
      access_period_unit_id: plansData.access_period_unit_id,
      description: plansData.description,
      request_only: plansData.request_only,
      status: plansData.status,
      advance: plansData.advance,
      admin_only: plansData.admin_only,
      resource_id: plansData.resource_id,
      plan_for_id: plansData.plan_for_id,
      plan_name: plansData.plan_name,
      security_amt: plansData.security_amt,
      extra_information: plansData.extra_information,
      sort_id: plansData.sort_id,
      day_id: plansData.Days,
      // start_at: plansData.workingHoursStart.toLocaleTimeString('en-IN', { hour12: false }),
      // end_at: plansData.workingHoursEnd.toLocaleTimeString('en-IN', { hour12: false }),
      invoice_template_id: plansData.invoice_template_id,
    };
    console.log(data);
    const Url = edit === 'Edit' ? `api/resourceplan/${plansData.plan_id}` : 'api/resourceplan/';

    API.postMethod(Url, data)
      .then((result) => {
        if (result.data.status === 'success') {
          console.log(result.data.data);
          localStorage.setItem('message', result.data.message);
          ToastBRH('success', result.data.message, '_', false);
          handleCancelsubmit();
          const id = propertyId;
          API.getData(`api/resourceplans/${id}`).then((resData) => {
            console.log(resData);
            if (resData?.data?.data) {
              const apiPlansData = resData.data.data;
              console.log(apiPlansData);
              setPlansData((prevDetails) => ({
                ...prevDetails,
                plansresList: apiPlansData?.filter((i) => i.status !== 2),
              }));
            }
          });
          // API.getData(`api/resourceplans/${propertyId}`).then((resRes) => {
          //   console.log(resRes);
          // });
          // handlePropertyTabs('plans');
        }
        if (result.data.status === 'error') {
          ToastBRH('error', result.data.status, '_', false);
        }
      })
      .catch((e) => {
        console.log(e);
        ToastBRH('error', 'There is an error in property insertion', '_', false);
      });
  };
  console.log(plansData.propertyData?.is_draft, 'draft');
  const handlePropertyTabs = (tabs, skip) => {
    console.log(skip, 'skip');
    let tabsdata;
    // console.log(skip);
    // return;
    if (skip === '1') {
      tabsdata = {
        property_id: propertyId,
        tabs: tabs,
        skip_plans: 1,
      };
    } else {
      tabsdata = {
        property_id: propertyId,
        tabs: tabs,
      };
    }

    API.postMethod('api/propertytabs', tabsdata).then((result) => {
      console.log(result);
      if (result.data.status === 'success') {
        //  window.location.reload();
        onChangeNext();
        // window.location.href = "/property-list";
      }
    });
  };

  const handleChangeTIME = (e, name, dayId) => {
    const date = new Date(e);
    console.log(date, 'mansooedate');
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : `${date.getHours()}`;
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : `${date.getMinutes()}`;

    const timeHours = `${hours}:${minutes}`;

    if (name === 'start_time') {
      // this.state.Days.filter((daysVal) => daysVal.enum == dayId).map(function (
      //   days
      // ) {
      //   //map the new array to list items

      //   days.start_time = timeHours;
      //   console.log("final days-->", tempthis.state.Days);
      //   tempthis.setState({ Days: tempthis.state.Days });
      // });
      const filterDays = plansData.Days.filter((dayval) => dayval.enum === dayId);
      filterDays.map((days) => {
        days.start_time = timeHours;
        console.log('plan', plansData.Days);
        setPlansData((prev) => ({ ...prev, Days: plansData.Days }));
        return days;
      });
    } else {
      const filterDays = plansData.Days.filter((dayval) => dayval.enum === dayId);
      filterDays.map((days) => {
        days.end_time = timeHours;
        console.log('plan', plansData.Days);
        setPlansData((prev) => ({ ...prev, Days: plansData.Days }));
        return days;
      });
    }
    // Rest of your code for handleChangeTIME
  };

  const hanldeChangeDays = (e, index) => {
    const name = e.target.name;
    const workingDays = [];

    if (name === 'working_id') {
      const days = [...plansData.Days];

      if (e.target.checked === true) {
        days[index] = {
          ...days[index],
          status: 1,
          open: 1,
        };
      } else {
        days[index] = {
          ...days[index],
          status: 0,
          open: 0,
        };
      }

      setPlansData((prev) => ({
        ...prev,
        Days: days,
      }));
    }

    // Rest of your code for hanldeChangeDays
  };

  const data = [
    {
      name: {
        firstName: 'John',
        lastName: 'Doe',
      },
      address: '261 Erdman Ford',
      city: 'East Daphne',
      state: 'Kentucky',
    },
    {
      name: {
        firstName: 'Jane',
        lastName: 'Doe',
      },
      address: '769 Dominic Grove',
      city: 'Columbus',
      state: 'Ohio',
    },
    {
      name: {
        firstName: 'Joe',
        lastName: 'Doe',
      },
      address: '566 Brakus Inlet',
      city: 'South Linda',
      state: 'West Virginia',
    },
    {
      name: {
        firstName: 'Kevin',
        lastName: 'Vandy',
      },
      address: '722 Emie Stream',
      city: 'Lincoln',
      state: 'Nebraska',
    },
  ];
  const getPlanTypeFor = (planForId) => {
    const plan = plansData.plansDataList.find((item) => item.id === planForId);
    console.log('plansForId', planForId);
    console.log('found plan', plan);
    return plan ? plan.plan_type_for : '';
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: 'plan_name',
        header: 'Plan Name',
        size: 150,
      },
      {
        accessorKey: 'plan_for_id',
        header: 'Plan Type',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          const plansForId = parseInt(rowData.plan_for_id, 10); // Specify radix as 10

          // Find the plan type object in plansData.plansDataList
          const planType = plansData.plansDataList?.find((plan) => plan.id === plansForId);

          // Check if a plan type is found
          if (planType && planType.plan_type_for) {
            return <div>{planType.plan_type_for}</div>;
          }
          return null; // Return null when plan type is not found or doesn't have plan_type_for
        },
        size: 150,
      },
      {
        accessorKey: 'price',
        header: 'Price',
        size: 150,
      },
      {
        accessorKey: 'security_amt',
        header: 'Security Amount',

        size: 150,
      },
      {
        accessorKey: 'admin_only',
        header: 'Admin Only',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div>
                {cell.getValue() === 1 ? (
                  <span style={{ textAlign: 'center', color: 'green' }}>&#10004;</span>
                ) : (
                  <span style={{ textAlign: 'center', color: 'red' }}>&#10008;</span>
                )}
              </div>
            </>
          );
        },
        size: 150,
      },
      {
        accessorKey: 'request_only',
        header: 'Request Only',
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div>
                {cell.getValue() === 1 ? (
                  <span style={{ textAlign: 'center', color: 'green' }}>&#10004;</span>
                ) : (
                  <span style={{ textAlign: 'center', color: 'red' }}>&#10008;</span>
                )}
              </div>
            </>
          );
        },
        size: 150,
      },
      {
        accessorKey: 'status',
        header: 'Enable/Disable',
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div>
                <SwichInputBRH
                  name="Enable/diable"
                  // label="E/D"
                  value={cell.getValue() === 1}
                  handleChange={(e) => handleSwitchChange(e, rowData)}
                />
              </div>
            </>
          );
        },
      },
    ],
    [plansData]
  );
  const [expanded, setExpanded] = React.useState('');

  const handleChangeAccordian = (panel, row, edit) => (event, newExpanded) => {
    console.log('row', row, newExpanded, panel);

    if (edit) {
      setEdit(edit);
    }
    setExpanded(newExpanded || row ? panel : false);
    // if (row) {
    // }
    // `api/resourceplans/${id}`;
    API.getData(`api/resource/plantimings/${row.id}?plan_id=${row.id}`).then((resPalnTimi) => {
      console.log(resPalnTimi);
      if (resPalnTimi.data.status === 'success') {
        const apiTimingData = resPalnTimi?.data.data;
        console.log(apiTimingData, 'timiongs');
        console.log(plansData.Days, 'Days');
        apiTimingData.forEach((daytiming) => {
          const matchingDay = plansData.Days.find((day) => day.enum === daytiming.day_id);
          if (matchingDay) {
            matchingDay.start_time = daytiming.start_at;
            matchingDay.end_time = daytiming.end_at;
            matchingDay.status = daytiming.status;
            matchingDay.open = daytiming.status === 1 ? 1 : 0;
          }
        });
        setPlansData((prevDetails) => ({
          ...prevDetails,
          Days: plansData.Days,
          timingDataList: apiTimingData,
        }));
      } else if (resPalnTimi.data.message === 'There is no plan timing informations') {
        setPlansData((prevDetails) => ({
          ...prevDetails,
          Days: [
            {
              day: 'Monday',
              enum: 'mon',
              status: 0,
              start_time: '',
              end_time: '',
              open: 0,
            },
            {
              day: 'Tuesday',
              enum: 'tue',
              status: 0,
              start_time: '',
              end_time: '',
              open: 0,
            },
            {
              day: 'Wednesday',
              enum: 'wed',
              status: 0,
              start_time: '',
              end_time: '',
              open: 0,
            },
            {
              day: 'Thursday',
              enum: 'thu',
              status: 0,
              start_time: '',
              end_time: '',
              open: 0,
            },
            {
              day: 'Friday',
              enum: 'fri',
              status: 0,
              start_time: '',
              end_time: '',
              open: 0,
            },
            {
              day: 'Saturday',
              status: 0,
              enum: 'sat',
              start_time: '',
              end_time: '',
              open: 0,
            },
            {
              day: 'Sunday',
              enum: 'sun',
              status: 0,
              start_time: '',
              end_time: '',
              open: 0,
            },
          ],
          //  timingDataList: apiTimingData,
        }));
      }
    });
    if (row) {
      console.log(row.sort_order_id);
      plansData.resouceDataList.map((i) => console.log(i.id, row.resource_id));
      setPlansData((prev) => ({
        ...prev,
        sortOrderObject: prev.sortOptions.find((i) => i.id === row.sort_order_id),
        resourceTypeObject: prev.resouceDataList.find((i) => i.id === row.resource_id),
        planTypeObject: prev.plansDataList.find((i) => i.id === row.plan_for_id),
        periodTypeObject: prev.periodDataList.find((i) => i.id === row.access_period_unit_id),
        invoiceTemObject: prev.invoiceTempDataList.find((i) => i.id === row.invoice_template_id),
        hsn_code: row.hsn_code,
        sort_id: row.sort_order_id,
        price: row.price,
        access_period: row.access_period,
        access_period_unit_id: row.access_period_unit_id,
        description: row.description,
        request_only: row.request_only,
        status: row.status,
        admin_only: row.admin_only,
        resource_id: row.resource_id,
        plan_for_id: row.plan_for_id,
        plan_name: row.plan_name,
        security_amt: row.security_amt,
        plan_id: row.id,
        extra_information: row.extra_information,
        // day_id: day_id,
        //  Days: prev.Days,
        workingHoursStart: row.start_at,
        workingHoursEnd: row.end_at,
        invoice_template_id: row.invoice_template_id,
        advance: row.advance,
        show: false,
      }));
    }
  };
  const handleDeleteBack = () => {
    setdeleteOpen(false);
  };
  const handleDraftBack = () => {
    setDraftOpen(false);
  };
  const handleDraft = () => {
    const tabsdata = {
      property_id: propertyId,
      tabs: 'plans',
    };

    API.postMethod('api/propertytabs', tabsdata).then((result) => {
      console.log(result);
      if (result.data.status === 'success') {
        console.log(result);
        setDraftOpen(false);
        navigate(routeNames.PROSPECTPROPERTYLIST);
      }
    });
  };
  const handleDelete = () => {
    console.log(plansData.deleteData, 'deleteData');
    API.deleteMethod(`api/resourceplan/${plansData.deleteData.id}`).then((result) => {
      if (result.status === 200) {
        //     this.setState({ alert: true, message: result.data.message });
        ToastBRH('success', result.data.message, '_', false);

        setPlansData((prev) => ({
          ...prev,
          plansresList: prev.plansresList.filter((el) => el.id !== prev.deleteData.id),
        }));
        setdeleteOpen(false);
        localStorage.setItem('message', result.message);
        // window.location.reload();
      } else {
        localStorage.setItem('message', result.message);
        // window.location.reload();
      }
    });
  };
  const handleCancelsubmit = () => {
    //   edit !== '' && setEdit('');
    if (edit !== '') {
      setEdit('');
    }
    setPlansData((prev) => ({
      ...prev,
      sortOrderObject: null,
      resourceTypeObject: null,
      planTypeObject: null,
      periodTypeObject: null,
      invoiceTemObject: null,
      hsn_code: '997212',
      price: '',
      access_period: '',
      //   access_period_unit_id: null,
      description: '',
      request_only: 0,
      status: 1,
      admin_only: 0,
      resource_id: null,
      plan_for_id: null,
      plan_name: '',
      security_amt: '',
      extra_information: '',

      Days: [
        {
          day: 'Monday',
          enum: 'mon',
          status: 0,
          start_time: '',
          end_time: '',
          open: 0,
        },
        {
          day: 'Tuesday',
          enum: 'tue',
          status: 0,
          start_time: '',
          end_time: '',
          open: 0,
        },
        {
          day: 'Wednesday',
          enum: 'wed',
          status: 0,
          start_time: '',
          end_time: '',
          open: 0,
        },
        {
          day: 'Thursday',
          enum: 'thu',
          status: 0,
          start_time: '',
          end_time: '',
          open: 0,
        },
        {
          day: 'Friday',
          enum: 'fri',
          status: 0,
          start_time: '',
          end_time: '',
          open: 0,
        },
        {
          day: 'Saturday',
          status: 0,
          enum: 'sat',
          start_time: '',
          end_time: '',
          open: 0,
        },
        {
          day: 'Sunday',
          enum: 'sun',
          status: 0,
          start_time: '',
          end_time: '',
          open: 0,
        },
      ],

      advance: '',
      //    show: false,
    }));
  };
  const handlePrevious = () => {
    localStorage.setItem('property_tabs', 'workspace');
    // handlePropertyTabs('workspace');
    onChange();
    // window.location.reload();
  };
  const handlePlans = () => {
    localStorage.setItem('property_tabs', 'terms&conditions');

    //  handlePropertyTabs('terms&conditions');
    onChangeNext();
  };
  const handleSkip = () => {
    localStorage.setItem('plan_enable', true);
    handlePropertyTabs('terms&conditions', '1');
  };
  return (
    <>
      <Accordion
        sx={{ background: 'light', padding: '20px' }}
        expanded={expanded === 'plans-form'}
        onChange={handleChangeAccordian('plans-form')}
      >
        <AccordionSummary
          sx={{ backgroundColor: '#f9fafb' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {' '}
          <Typography sx={{ background: '#f9fafb', width: '100%' }}>
            {edit === '' ? 'Add Resources Plan' : 'Edit Resources Plan'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>

              </Typography>
            </Stack> */}
            <Card sx={{ flexGrow: 1 }}>
              <Box sx={{ flexGrow: 1, m: 1 }}>
                <form noValidate={false} onSubmit={(e) => handleFormSubmit(e)}>
                  <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                    <Grid
                      container
                      sx={{ marginTop: '10px', justifyContent: 'center' }}
                      columnSpacing={{ xs: 1, sm: 1, md: 1 }}
                    >
                      <Grid container rowSpacing={2.5} xs={12} sm={12} md={6}>
                        <Grid item xs={12} sm={12} md={11}>
                          <AutoCompleteInputBRH
                            label="Create Plan For"
                            name="plan_type_for"
                            value={plansData.planTypeObject}
                            handleChange={handlePlanChange}
                            options={plansData.plansDataList}
                            handleClear={() =>
                              setPlansData((prev) => ({
                                ...prev,
                                planTypeObject: null,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <AutoCompleteInputBRH
                            label="Resource Type"
                            name="resource_name"
                            value={plansData.resourceTypeObject}
                            handleChange={handleresourceChange}
                            options={plansData.resouceDataList}
                            handleClear={() =>
                              setPlansData((prev) => ({
                                ...prev,
                                resourceTypeObject: null,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label="Access Period"
                            name="access_period"
                            value={plansData.access_period}
                            handleChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                          <AutoCompleteInputBRH
                            label="Select Period"
                            name="unit"
                            value={plansData.periodTypeObject}
                            options={plansData.periodDataList}
                            handleChange={handlePeriodChange}
                            handleClear={() =>
                              setPlansData((prev) => ({
                                ...prev,
                                periodTypeObject: null,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <MoneyInputBRH
                            label="Price"
                            name="price"
                            value={plansData.price}
                            handleChange={handleMoneyChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <AutoCompleteInputBRH
                            label="Invoice Template"
                            name="invoice_name"
                            value={plansData.invoiceTemObject}
                            options={plansData.invoiceTempDataList}
                            handleChange={invoiceTemChange}
                            handleClear={() =>
                              setPlansData((prev) => ({
                                ...prev,
                                invoiceTemObject: null,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <NumberInputBRH
                            label="Advance %"
                            name="advance"
                            value={plansData.advance}
                            handleChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <AutoCompleteInputBRH
                            label="Sort Order"
                            name="sort_order"
                            value={plansData.sortOrderObject}
                            handleChange={handleSortOrderChange}
                            options={plansData.sortOptions}
                            handleClear={() =>
                              setPlansData((prev) => ({
                                ...prev,
                                sortOrderObject: null,
                              }))
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={11}>
                          <SwichInputBRH
                            label="Enable Plan"
                            name="status"
                            value={plansData.status}
                            handleChange={handleSwitchChange}
                          />
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={2.5} xs={12} sm={12} md={6}>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Plan Desription"
                            multiline
                            rows={4.5}
                            value={plansData.description}
                            name="description"
                            handleChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Plan Name"
                            value={plansData.plan_name}
                            name="plan_name"
                            handleChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="HSN"
                            defaultValue={plansData.hsn_code}
                            name="hsn_code"
                            handleChange={handleChange}
                            readOnly
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <MoneyInputBRH
                            label="Security Amount"
                            value={plansData.security_amt}
                            name="security_amt"
                            handleChange={handleMoneyChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Extra Information"
                            multiline
                            rows={5}
                            value={plansData.extra_information}
                            name="extra_information"
                            handleChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <SwichInputBRH
                            label="Request Only Plan"
                            value={plansData.request_only}
                            name="request_only"
                            handleChange={handleresourceSwitchChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <SwichInputBRH
                            label="Admin Only Bokking"
                            value={plansData.admin_only}
                            handleChange={handleAdminSwitchChange}
                            name="admin_only"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid container sx={{ marginTop: '20px' }} rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TimeInputBRH
                          label="Working Hours From"
                          name="workingHoursStart"
                          value={plansData.workingHoursStart}
                          handleChange={(e) => setPlansData({ ...plansData, workingHoursStart: new Date(e) })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TimeInputBRH
                          label="Working Hours To"
                          name="workingHoursEnd"
                          value={plansData.workingHoursEnd}
                          handleChange={(e) => setPlansData({ ...plansData, workingHoursEnd: e })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Grid container sx={{ border: '1px solid #ccc', padding: '5px 10px' }}>
                          <Grid item xs={12}>
                            <Typography>Working Days</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Sun"
                              name="workingDaySun"
                              value={plansData.workingDaySun}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Mon"
                              name="workingDayMon"
                              value={plansData.workingDayMon}
                              handleChange={handleChange}
                            />
                          </Grid>
                          ` `
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Tue"
                              name="workingDayTue"
                              value={plansData.workingDayTue}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Wed"
                              name="workingDayWed"
                              value={plansData.workingDayWed}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Thu"
                              name="workingDayThu"
                              value={plansData.workingDayThu}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Fri"
                              name="workingDayFri"
                              value={plansData.workingDayFri}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <CheckBoxInputBRH
                              label="Sat"
                              name="workingDaySat"
                              value={plansData.workingDaySat}
                              handleChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                  
                    </Grid> */}
                    <Grid sx={{ marginTop: '20px' }} item xs={12} sm={12} md={12}>
                      <table className="table table-striped border">
                        <tbody>
                          {plansData.Days?.map((value, index) => {
                            const [hoursstart, minutesstart] = value.start_time.split(':').map(Number);

                            // Create a new Date object and set the hours and minutes
                            const localDatestart = new Date();
                            localDatestart.setHours(hoursstart);
                            localDatestart.setMinutes(minutesstart);
                            const [hoursend, minutesend] = value.end_time.split(':').map(Number);

                            // Create a new Date object and set the hours and minutes
                            const localDateend = new Date();
                            localDateend.setHours(hoursend);
                            localDateend.setMinutes(minutesend);
                            return (
                              <tr key={index}>
                                <td>
                                  <Form.Group className="p-0 m-0">
                                    <FormLabel>{value.day}</FormLabel>
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group className="p-0 m-0">
                                    <input
                                      type="checkbox"
                                      onChange={(e) => hanldeChangeDays(e, index)}
                                      name="working_id"
                                      value={value.enum}
                                      checked={value.open === 1}
                                    />
                                    <FormLabel>Open</FormLabel>
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group className="p-0 m-0">
                                    {/* <DatePicker
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    className="form-control"
                                    // disabled={value.open}
                                    name="start_time"
                                    minDate={value.end_time}
                                    onChange={(e) => handleChangeTIME(e, 'start_time', value.enum)}
                                    value={value.start_time}
                                  /> */}
                                    <TimeInputBRH
                                      label="Working Hours From"
                                      name="workingHoursStart"
                                      disabled={value.open !== 1}
                                      required="true"
                                      value={value.start_time === '' ? null : localDatestart}
                                      handleChange={(e) => handleChangeTIME(e, 'start_time', value.enum)}
                                    />
                                  </Form.Group>
                                </td>
                                <td>To</td>
                                <td>
                                  <Form.Group className="p-0 m-0">
                                    {/* <DatePicker
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    className="form-control"
                                    // disabled={value.open}
                                    name="end_time"
                                    onChange={(e) => handleChangeTIME(e, 'end_time', value.enum)}
                                    value={value.open ? '' : value.end_time}
                                  /> */}
                                    <TimeInputBRH
                                      label="Working Hours To"
                                      name="workingHoursEnd"
                                      disabled={value.open !== 1}
                                      required="true"
                                      value={value.end_time === '' ? null : localDateend}
                                      handleChange={(e) => handleChangeTIME(e, 'end_time', value.enum)}
                                    />
                                  </Form.Group>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </Grid>
                    <Grid
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                    >
                      <Button
                        type="button"
                        sx={{
                          height: '2.5rem',
                          width: 150,
                          mx: 1,
                          backgroundColor: 'grey',
                          color: 'white',
                          ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
                        }}
                        onClick={() => handleCancelsubmit()}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                      <LoadingButtonBRH
                        sx={{ height: '2.5rem', width: 150, mx: 1 }}
                        variant="contained"
                        type="submit"
                        label={edit === '' ? 'Save' : 'Update'}
                      />
                    </Grid>
                  </FormControl>{' '}
                </form>
              </Box>
            </Card>
          </Container>
        </AccordionDetails>
      </Accordion>

      <>
        {plansData.plansDataList.length > 0 && plansData.plansresList ? (
          <>
            <Container sx={{ marginTop: '20px' }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  Plan List
                </Typography>
              </Stack>
              <Card sx={{ p: 2 }}>
                <Scrollbar>
                  <MaterialReactTable
                    columns={columns}
                    data={plansData.plansresList}
                    enableRowActions
                    enableColumnActions={false}
                    muiTableBodyCellProps={{
                      sx: {
                        textAlign: 'center',
                      },
                    }}
                    muiTableHeadCellProps={{
                      sx: {
                        textAlign: 'center',
                      },
                      align: 'center',
                    }}
                    positionActionsColumn={'last'}
                    renderRowActions={({ row, table }) => (
                      <Box className="d-flex flex-row justify-content-start p-0 ">
                        <Tooltip arrow placement="left" title="Edit">
                          <IconButton
                            sx={{
                              width: 36,
                              height: 38,
                              border: 0.6,
                              borderColor: '#F36E24',
                              '& .MuiSvgIcon-root': {
                                fill: '#F36E24',
                              },
                              marginRight: '10px',
                            }}
                            onClickCapture={handleChangeAccordian('plans-form', row.original, 'Edit')}
                          >
                            <EditIcon
                              sx={{ fontSize: 14 }}
                              //  onClick={() => editUser(row)}
                            />
                          </IconButton>
                        </Tooltip>

                        <Tooltip arrow placement="right" title="Delete">
                          <IconButton
                            sx={{
                              width: 36,
                              height: 38,
                              border: 0.5,
                              borderColor: '#F36E24',
                              '& .MuiSvgIcon-root': {
                                fill: '#F36E24',
                              },
                            }}
                            onClick={() => {
                              setdeleteOpen(true);
                              setPlansData((prev) => ({ ...prev, deleteData: row.original }));
                            }}
                          >
                            <DeleteIcon
                              sx={{ fontSize: 14 }}
                              //  onClick={() => handleDeleteRow(row)}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  />
                </Scrollbar>
              </Card>
            </Container>
          </>
        ) : (
          <TableSkeleton />
        )}
      </>
      <Grid
        sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', marginTop: '30px', marginRight: '65px' }}
        item
        xs={12}
        sm={12}
        md={6}
      >
        {/* <Button sx={{ height: '2.5rem', width: 150, mx: 1 }} variant="contained">
                          Cancel
                        </Button> */}
        {/* <LoadingButtonBRH type="submit" variant="containeds" label="Save" color="grey" />
          <LoadingButtonBRH type="submit" variant="containeds" label="Save" /> */}
        <Button
          type="button"
          sx={{
            height: '2.5rem',
            width: 150,
            mx: 1,
            backgroundColor: 'grey',
            color: 'white',
            ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
          }}
          onClick={() => navigate(routeNames.PROSPECTPROPERTYLIST)}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          type="button"
          sx={{
            height: '2.5rem',
            width: 150,
            mx: 1,
            backgroundColor: 'grey',
            color: 'white',
            ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
          }}
          onClick={() => handlePrevious()}
          variant="contained"
        >
          Previous
        </Button>

        {plansData.propertyData.is_draft === 1 ? (
          <>
            {' '}
            <Button
              sx={{
                height: '2.5rem',
                width: 150,
                mx: 1,
                backgroundColor: 'grey',
                color: 'white',
                ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
              }}
              //  color="grey"
              variant="contained"
              onClick={() => setDraftOpen(true)}
            >
              Save as Draft
            </Button>
            <Button
              sx={{
                height: '2.5rem',
                width: 150,
                mx: 1,
                backgroundColor: 'grey',
                color: 'white',
                ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
              }}
              //  color="grey"
              variant="contained"
              onClick={() => handleSkip()}
            >
              Skip for now
            </Button>
          </>
        ) : (
          ''
        )}
        {localStorage.getItem('verified') === '1' || localStorage.getItem('verified') === '2' ? (
          <LoadingButtonBRH variant="containeds" label="Update" handleSubmit={handlePlans} />
        ) : (
          <LoadingButtonBRH variant="containeds" label="Save & Next" handleSubmit={handlePlans} />
        )}
      </Grid>

      <CommonAlert
        open={deleteOpen}
        setKycOpen={setdeleteOpen}
        message="Are you sure you want to delete this row?"
        proceedFunction={handleDelete}
        goBackFunction={handleDeleteBack}
      />
      <CommonAlert
        open={draftopen}
        setKycOpen={setDraftOpen}
        message="All Plans Data will be save as draft, do you wish to Proceed ?"
        proceedFunction={handleDraft}
        goBackFunction={handleDraftBack}
      />
    </>
  );
};

export default PlansAdd;
