import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react';

export const SelectInputBRH = ({
  name,
  label,
  value,
  handleChange,
  handleClickChange,
  required,
  sx,
  options,
  keyName,
  keyValue,
}) => {
  // use this for multiple select else use autocomplete
  // console.log(value);
  const [dummyValue, setDummyValue] = useState([]);
  // const handleClickChange = (ops) => {
  //   let laksh = [];
  //   // laksh.push(ops[keyValue]);
  //   // console.log(laksh);
  //   // console.log('clicked', ops, laksh, ops[keyName], ops[keyValue]);
  //   if (laksh.includes(ops[keyValue])) {
  //     console.log('true');
  //     const index = laksh.indexOf(ops[keyValue]);
  //     console.log(index);
  //     if (index > -1) {
  //       // only splice array when item is found
  //       // console.log(laksh.splice(index, 1));
  //       laksh = laksh.splice(index, 1); // 2nd parameter means remove one item only
  //     }
  //   } else {
  //     console.log('flase', ops[value]);
  //     laksh.push(ops[value]);
  //   }
  //   console.log(laksh);
  // };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <>
      <FormControl fullWidth required={required}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          sx={sx}
          labelId={label}
          name={name}
          value={value}
          label={label}
          onChange={handleChange}
          multiple
          renderValue={(selected) => {
            // hard coding here
            const laksh = [];
            options.forEach((opsEle) => {
              selected?.forEach((selEle) => {
                if (selEle === opsEle[keyValue]) {
                  // console.log(opsEle[keyValue], selEle, opsEle[keyName]);
                  laksh.push(opsEle[keyName]);
                }
              });
            });
            selected = [...laksh];
            return selected.join(', ');
            // return laksh.join(', ');
          }}
          MenuProps={MenuProps}
        >
          {options?.map((ops) => (
            <MenuItem value={ops[keyValue]} key={ops[keyValue]}>
              {/* onClick={() => handleClickChange(ops)} */}
              <Checkbox checked={value?.includes(ops[keyValue])} />
              <ListItemText>{ops[keyName]}</ListItemText>
            </MenuItem>
          ))}
          {/* <MenuItem value={0}>Mikro Grafeio</MenuItem>
          <MenuItem value={1}>Spinebiz</MenuItem>
          <MenuItem value={2}>A-Z company</MenuItem> */}
          <MenuItem sx={{ display: options.length === 0 ? 'block' : 'none' }}>No options</MenuItem>
        </Select>
      </FormControl>
    </>
  );
};
