import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
// sections
import { ForgotPassword, LoginForm, ResetPassword } from '../sections/auth/login';
// css
import './logIn.css';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.neutral,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const location = useLocation();

  const { pathname, search } = location;
  // console.log('pathname', pathname, 'search', search);

  return (
    <>
      <Helmet>
        <title>
          {pathname === '/forgot-password'
            ? 'Forgot Password'
            : pathname === '/reset-password'
            ? 'Reset Password'
            : 'Contract'}
          &nbsp; | BRH
        </title>
      </Helmet>
      <Box>
        <div className="container_bg">
          <StyledRoot>
            <Logo
              sx={{
                position: 'fixed',
                top: { xs: 16, sm: 24, md: 40 },
                left: { xs: 16, sm: 24, md: 40 },
              }}
            />

            {mdUp && (
              <StyledSection>
                <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5, textAlign: 'center' }}>
                  Welcome Back
                </Typography>
                <img src="/assets/illustrations/illustration_login.png" alt="login" />
              </StyledSection>
            )}

            <Container maxWidth="sm">
              <StyledContent>
                <Box sx={{ backgroundColor: '#F4F6F8', padding: '20px', borderRadius: '16px', textAlign: 'center' }}>
                  {pathname === '/forgot-password' ? (
                    <Typography sx={{ my: 5 }} variant="h5" gutterBottom>
                      Enter an email ID already registered in our app.
                      <br /> We will send a link to this email.
                    </Typography>
                  ) : pathname === '/reset-password' ? (
                    <Typography sx={{ my: 5 }} variant="h5" gutterBottom>
                      You have requested to reset your password. <br />
                      Please enter a new password
                    </Typography>
                  ) : (
                    <Typography sx={{ my: 5 }} variant="h4" gutterBottom>
                      Sign In
                    </Typography>
                  )}

                  {pathname === '/forgot-password' ? (
                    <ForgotPassword />
                  ) : pathname === '/reset-password' ? (
                    <ResetPassword />
                  ) : (
                    <LoginForm />
                  )}
                </Box>
              </StyledContent>
            </Container>
          </StyledRoot>
        </div>
      </Box>
    </>
  );
}
