import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Card, Container, Divider, Grid, Typography } from '@mui/material';
import styles from './Notification.module.css';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
import { CRSLIST } from '../../API_SERVICE_LAYER/ApiNames';
import NotificationItem from './NotificationItem';

export default function Notification() {
  const [expiringContracts, setExpiringContracts] = useState([]);
  useEffect(() => {
    API.getData(CRSLIST)
      .then((res) => {
        if (res.data.status === 'success') {
          const data = res.data.results;
          const contractList = data.filter((contract) => contract.is_contract === 1);
          const apiExpiringContracts = contractList.flatMap((contract) => {
            const endDate = new Date(contract.agreement_end_date);
            const finalDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 60, 0, 0, 0);
            console.log('dates', endDate, finalDate);
            // if (endDate >= finalDate) return contract;
            // condition 1.agree end date should be equal or less than final data
            // 2.agree end date less than final date(avoid expired contract)
            return endDate >= finalDate && new Date() > finalDate ? contract : [];
          });
          console.log('final', apiExpiringContracts);
          setExpiringContracts(apiExpiringContracts);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <Container sx={{ backgroundColor: '#fafbfb' }}>
        <Helmet>Contracts | BRH</Helmet>
        <Typography variant="h4">Notification</Typography>
        <Card>
          {/* <Box sx={{ flexGrow: 1, m: 4 }}>
        <Grid container  rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
        <Grid item>
        
        </Grid>
        </Grid>
    </Box> */}
          <Box className={styles.notificationContainer}>
            {expiringContracts.map((contract) => (
              <NotificationItem key={contract.id} contract={contract} />
            ))}
            {/* <Box sx={{ marginBottom: '20px' }}>
              <Grid container rowSpacing={2}>
                <Grid item md={12}>
                  <span className={styles.notificationTitle}>expiring soon</span>
                </Grid>
                <Grid item md={12}>
                  <p className={styles.notificationSummary}>contract is about to expire and take necessary action.</p>
                </Grid>
                <Grid item md={12}>
                  <p className={styles.notificationDetail}>
                    <span>contract : </span>BRH Ten Square Koyambedu | 08-Nov-2023 | 01-Dec-2023 (expires in 60 days)
                  </p>
                  <p className={styles.notificationDetail}>
                    <span>status : </span>contacted
                  </p>
                </Grid>
              </Grid>
              <Grid container marginTop={2} rowSpacing={2}>
                <Grid item md={3}>
                  <button type="button" disabled>
                    Contacted Client
                  </button>
                </Grid>
                <Grid item md={3}>
                  <button type="button">Awaiting Client Status</button>
                </Grid>
                <Grid item md={3}>
                  <button type="button"> Renewal Confirmed</button>
                </Grid>
                <Grid item md={3}>
                  <button type="button">Renewal Cancelled</button>
                </Grid>
                <Grid item md={12} marginTop={2}>
                  <Divider color="primary" />
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid item md={12}>
                <span className={styles.notificationTitle}>expiring soon</span>
              </Grid>
              <Grid item md={12}>
                <p className={styles.notificationSummary}>contract is about to expire and take necessary action.</p>
              </Grid>
              <Grid item md={12}>
                <p className={styles.notificationDetail}>
                  <span>contract : </span>BRH Ten Square Koyambedu | 08-Nov-2023 | 01-Dec-2023 (expires in 60 days)
                </p>
                <p className={styles.notificationDetail}>
                  <span>status : </span>contacted
                </p>
              </Grid>
            </Grid>
            <Grid container marginTop={2} justifyContent="center" alignItems="center">
              <Grid item md={3}>
                <button type="button" disabled>
                  Contacted Client
                </button>
              </Grid>
              <Grid item md={3}>
                <button type="button">Awaiting Client Status</button>
              </Grid>
              <Grid item md={3}>
                <button type="button"> Renewal Confirmed</button>
              </Grid>
              <Grid item md={3}>
                <button type="button">Renewal Cancelled</button>
              </Grid>
            </Grid> */}
          </Box>
        </Card>
      </Container>
    </>
  );
}
