import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { postMethod } from '../../../API_SERVICE_LAYER/ApiConfigs';

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const [email, setEmail] = useState();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/login', { replace: true });
  };
  const handleSubmit = () => {
    postMethod('user-management/api/v1/forgot-password', { email })
      .then((res) => {
        if (res?.data?.status) {
          setSuccess(res?.data?.message);
          setTimeout(() => {
            navigate('/login', { replace: true });
          }, 1000);
        }
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
      });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          onFocus={() => setError(null)}
          onChange={(e) => setEmail(e.currentTarget.value)}
          name="email"
          label="Email address"
        />
      </Stack>

      <Stack sx={{ my: 1 }}>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
      </Stack>

      <LoadingButton sx={{ my: 1 }} fullWidth size="large" type="submit" variant="contained" onClick={handleSubmit}>
        Send Password Reset Link
      </LoadingButton>
      <LoadingButton sx={{ my: 1 }} fullWidth size="large" type="submit" variant="outlined" onClick={handleClick}>
        Cancel
      </LoadingButton>
    </>
  );
}
