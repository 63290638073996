import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
  stepContentClasses,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, ContactPageSharp } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { MaterialReactTable } from 'material-react-table';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { SwichInputBRH } from '../../../components/forms/SwichInputBRH';
import { CommonAlert } from '../../../components/toasts/CommonAlert';
import { ToastBRH } from '../../../components/toasts/ToastBRH';
import Iconify from '../../../components/iconify';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';

const TermsAndConditions = ({ propertyId, onChange, onChangeNext }) => {
  const FILE_API = 'https://brhfiles.s3.ap-south-1.amazonaws.com/';
  const navigate = useNavigate();
  const [termsData, setTermsData] = useState([]);
  const [openAddplans, setOpenAddplans] = useState(false);
  const [deleteOpen, setdeleteOpen] = useState(false);
  const [draftopen, setDraftOpen] = useState(false);
  const [deleteData, setdeleteData] = useState(null);
  const [load, setload] = useState(false);
  const [propertyTermsData, setPropertyTermsData] = useState([]);
  const [filteredtermsData, setFilterdPTData] = useState([]);
  const [templatePath, setTempletPath] = useState([]);
  const [editTerms, setEditTerms] = useState(null);
  const [openDialog, setOpendialog] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [startTimeObj, setStarttimeObj] = useState(null);
  const [endTimeObj, setEndtimeobj] = useState(null);
  const [refundpercentage, setRefundPer] = useState('');
  const [CStatus, setCStatus] = useState(1);
  const [termstypedata, settermstype] = useState({ termsObj: null, noTemplate: false, fileError: '' });
  // const [etermstypedata, setetermstype] = useState({
  //   etermsObj: null,
  //   enoTemplate: false,
  //   efileError: '',
  //   eTempletPreview: null,
  //   etermId: '',
  // });
  const [cancellationpolicy, setCancellationPolicy] = useState({
    cancelData: false,
    cancellationData: [],
    cancelStatus: 0,
    filteredData: [],
    cancelId: '',
  });
  const [propertyData, setPropertyDetails] = useState({
    propertyData: null,
    isDraft: 0,
    iscancellationPolicy: null,
  });
  const [uploadFile, setUploadfile] = useState({
    fileError: '',
    termsFile: [],
    fileName: '',
    fileUrl: null,
  });
  // const [euploadFile, esetUploadfile] = useState({
  //   efileError: '',
  //   etermsFile: [],
  //   efileName: '',
  //   efileUrl: null,
  // });
  useEffect(() => {
    API.getData('api/termsandconditions?limit=100').then((result) => {
      console.log('termsdata', result.data.data, result.data.data.status);
      if (result.data.data.data) {
        console.log('termsdata', result.data.data);
        //  this.setState({ termsData: result.data.data });
        setTermsData(result.data.data.data);
      }
    });

    if (propertyId) {
      API.getData(`api/propertiesterms/${propertyId}?limit=100`).then((result) => {
        if (result.data.status === 'success') {
          console.log('ptd', result.data.data, propertyTermsData);
          // eslint-disable-next-line array-callback-return, consistent-return
          const propertyTermsData = result.data.data.filter((stat) => {
            if (stat.status !== 2) {
              return stat;
            }
          });
          // eslint-disable-next-line array-callback-return, consistent-return
          const filteredData = result.data.data.filter((value) => {
            if (value.status === 1) {
              return value;
            }
          });
          setPropertyTermsData(propertyTermsData);
          setFilterdPTData(filteredData);
          setPropertyTermsData(
            // eslint-disable-next-line array-callback-return, consistent-return
            result.data.data.filter((stat) => {
              if (stat.status !== 2) {
                return stat;
              }
            })
          );

          setTempletPath(
            result.data.data.map((val) => {
              return val.template_path;
            })
          );
        }
      });

      API.getData(`api/propertycancellationpolicy/${propertyId}?limit=50`).then((result) => {
        console.log(result.data.status === 'success', result.data.data);
        if (result.data.status === 'success') {
          const CData = result.data.data.data.filter((stat) => stat.status !== 2);
          console.log('result.data.data', result.data.data.data);
          console.log(CData, 'canceldfa');
          const FData = result.data.data.data.filter((stat) => stat.status === 1);
          setCancellationPolicy({
            cancelData: true,
            cancellationData: CData,
            filteredData: FData,
            cancelStatus: result.data.status,
          });
        }
      });

      API.getData(`api/property/${propertyId}`).then((result) => {
        console.log(result.data.data);
        setPropertyDetails({
          propertyData: result.data.data,
          isDraft: result.data.data.is_draft,
          iscancellationPolicy: result.data.data.is_cancellation_policy,
        });
        setcancellationValue(result.data.data.is_cancellation_policy === 1 ? 'Yes' : 'No');
        // this.setState({ isLoading: false });
        // this.setState({ propertyData: result.result });
        // this.setState({ is_draft: result.data.is_draft });
        // this.setState({
        //   is_cancellation_policy: result.data.is_cancellation_policy,
        // });
      });
    }
  }, []);
  console.log('can', cancellationpolicy.cancellationData);
  useEffect(() => {
    console.log('runned');
    API.getData(`api/propertiesterms/${propertyId}?limit=100`).then((result) => {
      if (result.data.status === 'success') {
        console.log('ptd', result.data.data);
        // eslint-disable-next-line array-callback-return, consistent-return
        const propertyTermsData = result.data.data.filter((stat) => stat.status !== 2);
        const filteredData = result.data.data.filter((value) => value.status === 1);
        //   setPropertyTermsData(propertyTermsData);
        setFilterdPTData(filteredData);
        setPropertyTermsData(
          // eslint-disable-next-line array-callback-return, consistent-return
          result.data.data.filter((stat) => {
            if (stat.status !== 2) {
              return stat;
            }
          })
        );

        setTempletPath(
          result.data.data.map((val) => {
            return val.template_path;
          })
        );
      }
    });
  }, [load]);
  console.log('tem', templatePath, propertyTermsData);

  const handleStatusUpdate = (e, value) => {
    console.log(e.target.checked, 'status');
    if (e.target.checked === true) {
      value.status = 1;
    } else {
      value.status = 0;
    }
    console.log(value.status, 'value');
    API.postMethod(`api/propertiestermsandconditions/${value.id}`, value)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          ToastBRH('success', res.data.message, '___', false);
          localStorage.setItem('message', res.data.message);
          API.getData(`api/propertiesterms/${propertyId}?limit=100`).then((result) => {
            if (result.data.status === 'success') {
              console.log('ptd', result.data.data);
              // eslint-disable-next-line array-callback-return, consistent-return
              const propertyTermsData = result.data.data.filter((stat) => stat.status !== 2);
              const filteredData = result.data.data.filter((value) => value.status === 1);
              //   setPropertyTermsData(propertyTermsData);
              setFilterdPTData(filteredData);
              setPropertyTermsData(
                // eslint-disable-next-line array-callback-return, consistent-return
                result.data.data.filter((stat) => {
                  if (stat.status !== 2) {
                    return stat;
                  }
                })
              );

              setTempletPath(
                result.data.data.map((val) => {
                  return val.template_path;
                })
              );
            }
          });
        } else if (res.status === 204) {
          setload(!load);
        }
      })
      .catch((e) => {
        console.log(e);
        setload(!load);
      });
  };
  const handleCancelStatusUpdate = (e, value) => {
    console.log(e.target.checked, 'status');
    if (e.target.checked === true) {
      value.status = 1;
    } else {
      value.status = 0;
    }
    console.log(value.status, 'valuecCandel');
    API.updateMethod(`api/cancellationpolicy/${value.id}`, value)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          ToastBRH('success', res.data.message, '___', false);
          localStorage.setItem('message', res.data.message);
          API.getData(`api/propertycancellationpolicy/${propertyId}?limit=50`).then((result) => {
            console.log(result.data.status === 'success', result.data.data);
            if (result.data.status === 'success') {
              const CData = result.data.data.data.filter((stat) => stat.status !== 2);
              console.log('result.data.data', result.data.data.data);
              console.log(CData, 'canceldfa');
              const FData = result.data.data.data.filter((stat) => stat.status === 1);
              setCancellationPolicy({
                cancelData: true,
                cancellationData: CData,
                filteredData: FData,
                cancelStatus: result.data.status,
              });

              // this.setState({ cancelchecked: 1 }, () => console.log('ok'));
              // this.setState({ cancellationData: result.data.data });
              // this.setState(
              //   {
              //     cancellationData: result.data.data.filter((stat) => {
              //       if (stat.status !== 2) {
              //         return stat;
              //       }
              //     }),
              //   },
              //   () => console.log('filteredItems', this.state.cancellationData)
              // );
              // this.setState({ cancel_status: result.data.status });
              // this.setState(
              //   {
              //     filteredData: result.data.data.filter((value) => value.status === 1),
              //   },
              //   () => console.log('filteredData', this.state.filteredData)
              // );
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
        // setload(!load);
      });
  };
  console.log('terms', termsData);
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'T&C Type',
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;

          return (
            <>
              {rowData.term &&
                rowData.term.map((value, key) => {
                  console.log('v', value.tc_type_name);
                  return <div key={key}>{value.tc_type_name}</div>;
                })}
            </>
          );
        },
      },
      {
        accessorKey: 'template_path',
        header: 'File Name',
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return cell.getValue() ? (
            <a download target="_blank" href={FILE_API + cell.getValue()} rel="noreferrer">
              <Button
                sx={{
                  height: '2.5rem',
                  width: 150,
                  mx: 1,
                  backgroundColor: '#fc9200',
                  color: 'white',
                  ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
                }}
              >
                {cell?.getValue()?.split('/').slice(-1)}
              </Button>
            </a>
          ) : (
            rowData.term &&
              rowData.term.map((value, key) => {
                console.log('v', value.tc_type_template_path);
                return (
                  <a download target="_blank" href={FILE_API + value.tc_type_template_path} rel="noreferrer">
                    <Button
                      sx={{
                        height: '2.5rem',
                        width: 150,
                        mx: 1,
                        backgroundColor: '#fc9200',
                        color: 'white',
                        ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
                      }}
                    >
                      {value.tc_type_template_path.split('/').slice(-1)}
                    </Button>
                  </a>
                );
              })
          );
        },
      },
      {
        accessorKey: 'status',
        header: 'Enable/Disable',
        size: 200,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div>
                <SwichInputBRH
                  name="Enable/diable"
                  // label="E/D"
                  value={cell.getValue() === 1}
                  handleChange={(e) => handleStatusUpdate(e, rowData)}
                />
              </div>
            </>
          );
        },
      },
    ],
    []
  );
  const cancellationColumns = useMemo(
    () => [
      {
        accessorKey: 'start_at',
        header: 'Start time',
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          console.log(rowData, 'rowdatra');
          return (
            <>
              <span>{`${cell.getValue()} Hrs`}</span>
            </>
          );
        },
      },
      {
        accessorKey: 'end_at',
        header: 'End Time',
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <span>{cell.getValue() === 0 ? 'Above' : `${cell?.getValue()} Hrs`}</span>;
        },
      },
      {
        accessorKey: 'refund_percentage',
        header: 'Refund Percentage',
        size: 150,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return <span>{`${cell?.getValue()} %`}</span>;
        },
      },
      {
        accessorKey: 'status',
        header: 'Enable/Disable',
        size: 200,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <div>
                <SwichInputBRH
                  name="Enable/diable"
                  // label="E/D"
                  value={cell.getValue() === 1}
                  handleChange={(e) => handleCancelStatusUpdate(e, rowData)}
                />
              </div>
            </>
          );
        },
      },
    ],
    []
  );
  // handle T&C
  const handleTandC = (e, newValue) => {
    console.log(e.target.value, '10');
    if (newValue.id === 10) {
      settermstype((prev) => ({ ...prev, noTemplate: false, termsObj: newValue }));
      setUploadfile((prev) => ({ ...prev, fileError: 'File uploading is mandatory' }));
    } else {
      setUploadfile((prev) => ({ ...prev, fileError: '', termsFile: [], fileName: '', fileUrl: null }));
      settermstype((prev) => ({ ...prev, noTemplate: true, fileError: '', termsObj: newValue }));
    }
  };
  console.log(termstypedata.termsObj, 'terObj');
  // handlefiles
  const handleFilesChange = (e) => {
    if (e.target.files[0]) {
      const type = e.target.files[0].type.split('/');
      const size = e.target.files[0].size;
      const filename = e.target.files[0].name;

      if (
        type[1] === 'docx' ||
        type[1] === 'pdf' ||
        type[1] === 'doc' ||
        type[1] === 'msword' ||
        type[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        type[1] === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        if (size > 1024) {
          console.log('size checking', size);
          const files = e.target.files[0];
          let fileUrl = '';

          const name = e.target.files[0].name;
          setUploadfile({ fileName: name, termsFile: files });

          fileUrl = URL.createObjectURL(e.target.files[0]);
          console.log('File', fileUrl);

          const formData = new FormData();

          formData.append('property_id', propertyId);
          formData.append('template_path', e.target.files[0]);

          API.fileupload('api/temp/propertiestermsandconditions', formData)
            .then((res) => {
              // console.log(res);
              if (res.status === 200) {
                // console.log(res.data.data.path);
                setUploadfile((prev) => ({ ...prev, fileUrl: res.data.data.path }));
                // this.setState({ fileUrl: res.data.data.path });
              }
            })
            .catch((e) => {
              console.log(e);
            });
          setUploadfile((prev) => ({ ...prev, fileError: '' }));
        } else {
          setUploadfile((prev) => ({ ...prev, fileUrl: '', fileError: 'File size should be greater than 1kb' }));

          console.log('file size exceed');
        }
      } else {
        setUploadfile((prev) => ({ ...prev, fileError: 'File type not valid', fileUrl: '' }));

        console.log('file size or type not valid', type);
      }
    }
  };
  const handleAddPlans = () => {
    setUploadfile({
      fileError: '',
      termsFile: [],
      fileName: '',
      fileUrl: null,
    });
    settermstype({ termsObj: null, noTemplate: false, fileError: '' });
    setOpenAddplans(false);
  };
  const handleBackAddplans = () => {
    setOpenAddplans(false);
  };
  const handleFormSubmit = () => {
    const formData = new FormData();

    // console.log(e.target.value);
    formData.append('template_path', uploadFile.termsFile);
    formData.append('template_type_id', termstypedata.termsObj.id);
    formData.append('property_id', propertyId);

    API.fileupload('api/propertiestermsandconditions', formData)
      .then((res) => {
        console.log(res);
        if (res.data.status === 'success') {
          //   this.setState({ alert: true, message: res.data.message });
          ToastBRH('success', res.data.message, '__', false);
          //   this.handlePropertyTabs("terms&conditions");
          setUploadfile({
            fileError: '',
            termsFile: [],
            fileName: '',
            fileUrl: null,
          });
          settermstype({ termsObj: null, noTemplate: false, fileError: '' });
          setload(!load);
          // window.location.reload();
        } else if (res.data.status === 'failed') {
          ToastBRH('error', res.data.message, '__', false);
          //  this.componentDidMount();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleDeleteRow = () => {
    const { id } = deleteData;
    API.deleteMethod(`api/propertiestermsandconditions/${id}`).then((result) => {
      if (result.data.status === 'success') {
        ToastBRH('success', result.data.message, '__', false);
        setload(!load);
        setdeleteOpen(false);
        // localStorage.setItem("message", result.message);
        // window.location.reload();
      } else {
        ToastBRH('error', result.data.message, '__', false);

        // localStorage.setItem("message", result.message);
        // window.location.reload();
      }
    });
  };
  const handleDeleteBack = () => {
    setdeleteOpen(false);
  };
  const handleDraftBack = () => {
    setDraftOpen(false);
  };
  const handleClose = () => {
    setOpendialog(false);
  };
  const handleDraft = () => {
    const tabsdata = {
      property_id: propertyId,
      tabs: 'terms&conditions',
    };

    API.postMethod('api/propertytabs', tabsdata).then((result) => {
      console.log(result);
      if (result.data.status === 'success') {
        navigate(routeNames.PROSPECTPROPERTYLIST);
      }
    });
  };
  // const handleEditTerms = (rowValue) => {
  //   console.log('rowValue', rowValue);
  //   setOpendialog(true);
  //   setetermstype((pre) => ({ ...pre, etermId: rowValue.id }));
  //   const templetdata = termsData && termsData.find((i) => i.id === rowValue.template_type_id);
  //   if (rowValue.template_path !== null) {
  //     setetermstype((prev) => ({
  //       ...prev,
  //       eTempletPreview: rowValue.template_path,
  //       etermsObj: templetdata,
  //     }));
  //   } else {
  //     // eslint-disable-next-line no-unused-expressions
  //     rowValue.term &&
  //       // eslint-disable-next-line array-callback-return
  //       rowValue.term.map((val) => {
  //         setetermstype((prev) => ({
  //           ...prev,
  //           eTempletPreview: val.tc_type_template_path,
  //           etermsObj: templetdata,
  //         }));
  //       });
  //   }
  // };
  // const handleeTandC = (e, newValue) => {
  //   console.log('value', e.target.value, newValue);
  //   if (newValue.id === 10) {
  //     setetermstype((prev) => ({
  //       ...prev,
  //       enoTemplate: false,
  //       etermsObj: newValue,
  //       eTempletPreview: null,
  //     }));
  //   } else {
  //     setetermstype((prev) => ({
  //       ...prev,
  //       etermsObj: newValue,
  //       eTempletPreview: newValue.tc_type_template_path ? newValue.tc_type_template_path : '',
  //       enoTemplate: true,
  //     }));
  //   }
  // };
  // console.log(etermstypedata, 'edit');
  // console.log(termstypedata, 'nonedit');
  const [cancellationvalue, setcancellationValue] = React.useState('No');

  const handleChangeCancelation = (event) => {
    if (event.target.value === 'Yes') {
      setcancellationValue('Yes');
      setCancellationPolicy((prev) => ({
        ...prev,
        cancelId: '',
        cancelData: true,
      }));
      setStarttimeObj(null);
      setEndtimeobj(null);
      setRefundPer('');
      setPropertyDetails((prev) => ({ ...prev, iscancellationPolicy: 1 }));
      const data = {
        property_id: propertyId,
        is_cancel: '1',
      };
      API.postMethod('api/propertycancel', data).then((result) => {
        if (result.data.status === 'success') {
          console.log(result);
        }
      });
    } else if (event.target.value === 'No') {
      setcancellationValue('No');
      setCancellationPolicy((prev) => ({
        ...prev,

        cancelData: true,
      }));
      setPropertyDetails((prev) => ({ ...prev, iscancellationPolicy: 0 }));
      const data = {
        property_id: propertyId,
        is_cancel: '0',
      };
      API.postMethod('api/propertycancel', data).then((result) => {
        if (result.data.status === 'success') {
          console.log(result);
        }
      });
    }
  };
  const handleChangestartAt = (e, newValue) => {
    console.log(e.target.id, newValue);
    setStarttimeObj(newValue);
  };
  const handleChangeendAt = (e, newValue) => {
    console.log(e.target.id, newValue);
    setEndtimeobj(newValue);
  };
  const handleRefund = (e) => {
    //  console.log(e.target.id, newValue);
    setRefundPer(e.target.value);
  };
  const handleStatus = (e) => {
    //  console.log(e.target.id, newValue);
    if (e.target.checked) {
      setCStatus(1);
    } else {
      setCStatus(0);
    }
  };
  const options = [
    { id: 1, start_at: '0 Hrs', value: '0' },
    { id: 2, start_at: '24 Hrs', value: '24' },
    { id: 3, start_at: '48 Hrs', value: '48' },
  ];
  const options2 = [
    { id: 1, end_at: '24 Hrs', value: '24' },
    { id: 2, end_at: '48 Hrs', value: '48' },
    { id: 3, end_at: 'Above', value: 'Above' },
  ];

  const handleAddCancel = (e) => {
    e.preventDefault();

    const data = {
      property_id: propertyId,
      start_at: startTimeObj.value,
      end_at: endTimeObj.value,
      refund_percentage: refundpercentage,
      status: CStatus,
    };

    console.log('formdata', data);

    if (cancellationpolicy.cancelId) {
      API.updateMethod(`api/cancellationpolicy/${cancellationpolicy.cancelId}`, data).then((result) => {
        console.log(result);
        if (result.data.status === 'success') {
          ToastBRH('success', result.data.message, '___', false);
          localStorage.setItem('message', result.message);
          setOpendialog(false);
          // this.handlePropertyTabs("terms&conditions");
          //  localStorage.setItem("property_tabs", "others");
          // window.location.reload();
          API.getData(`api/propertycancellationpolicy/${propertyId}?limit=50`).then((result) => {
            console.log(result.data.status === 'success', result.data.data);
            if (result.data.status === 'success') {
              const CData = result.data.data.data.filter((stat) => stat.status !== 2);
              console.log('result.data.data', result.data.data.data);
              console.log(CData, 'canceldfa');
              const FData = result.data.data.data.filter((stat) => stat.status === 1);
              setCancellationPolicy({
                cancelData: true,
                cancellationData: CData,
                filteredData: FData,
                cancelStatus: result.data.status,
              });
            }
          });
        }
      });
    } else {
      API.postMethod(`api/cancellationpolicy`, data).then((result) => {
        console.log(result);
        if (result.data.status === 'success') {
          ToastBRH('success', result.data.message, '___', false);
          setStarttimeObj(null);
          setEndtimeobj(null);
          setRefundPer('');
          setOpendialog(false);
          localStorage.setItem('message', result.message);
          API.getData(`api/propertycancellationpolicy/${propertyId}?limit=50`).then((result) => {
            console.log(result.data.status === 'success', result.data.data);
            if (result.data.status === 'success') {
              const CData = result.data.data.data.filter((stat) => stat.status !== 2);
              console.log('result.data.data', result.data.data.data);
              console.log(CData, 'canceldfa');
              const FData = result.data.data.data.filter((stat) => stat.status === 1);
              setCancellationPolicy({
                cancelData: true,
                cancellationData: CData,
                filteredData: FData,
                cancelStatus: result.data.status,
              });
            }
          });
        }
      });
    }
  };
  const handlePropertyTabs = (tab) => {
    const tabsdata = {
      property_id: propertyId,
      tabs: tab,
    };

    API.postMethod('api/propertytabs', tabsdata).then((result) => {
      console.log(result);
      if (result.data.status === 'success') {
        window.location.reload();
        // window.location.href = "/property-list";
      }
    });
  };
  const handleEditCancel = (r) => {
    setOpendialog(true);
    const startAt = options.find((i) => i.value === r.start_at);
    const endAt = options2.find((i) => i.value === r.end_at);
    console.log(startAt, endAt, r, 'Start_at');
    setStarttimeObj(startAt);
    setEndtimeobj(endAt);
    setCStatus(r.status);
    setRefundPer(r.refund_percentage);
    setCancellationPolicy((prev) => ({ ...prev, cancelId: r.id }));
  };
  // const handlePrevious = () => {
  //   localStorage.setItem('property_tabs', 'plans');
  //   handlePropertyTabs('plans');
  //   // window.location.reload();
  // };
  const handleContinue = (e) => {
    // let is_cancellation_policy = this.state.is_cancellation_policy;
    console.log('is_cancellation_policy-->', propertyData.iscancellationPolicy);
    if (
      (propertyData.iscancellationPolicy === null || propertyData.iscancellationPolicy === 0) &&
      filteredtermsData &&
      filteredtermsData.length >= 1
    ) {
      localStorage.setItem('property_tabs', 'others');
      console.log('ok');

      //  handlePropertyTabs('others');
      onChangeNext();
    } else if (
      propertyData.iscancellationPolicy === 1 &&
      cancellationpolicy.filteredData &&
      cancellationpolicy.filteredData.length > 0
    ) {
      localStorage.setItem('property_tabs', 'others');
      console.log('ok ok');

      // handlePropertyTabs('others');
      onChangeNext();
    } else {
      console.log(
        'status data:-',
        propertyData.iscancellationPolicy,
        'cancel data:-',
        cancellationpolicy.cancellationData,
        'cancel filter:-',
        cancellationpolicy.filteredData,
        'terms data:-',
        filteredtermsData,
        propertyTermsData
      );
      ToastBRH(
        'warning',
        'At least one Terms and Conditions or cancellation policy should be available',
        '____',
        false,
        4000
      );
    }
  };
  return (
    <>
      <Container>
        <Card variant="outlined" sx={{ p: 2 }}>
          <Box sx={{ flexGrow: 1, m: 4 }}>
            <p className="card-description">Add New T&C</p>
            <form noValidate={false}>
              <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                <Grid item xs={12} sm={12} md={6}>
                  <AutoCompleteInputBRH
                    label="T&C Type"
                    name="tc_type_name"
                    options={termsData}
                    value={termstypedata.termsObj}
                    handleChange={handleTandC}
                  />
                  {termstypedata.noTemplate ? (
                    ''
                  ) : (
                    <Grid item xs={12} sm={12} md={4} lg={6} className="mt-4">
                      <Button sx={{ display: 'flex', gap: '10px', alignItems: 'center', padding: 0 }} component="label">
                        <CloudUploadIcon size={50} />
                        Upload Terms and Conditions
                        <input
                          type="file"
                          id="terms_files"
                          accept=".pdf,.doc,.docx"
                          multiple
                          className="d-none"
                          name="terms_files"
                          onChange={(e) => handleFilesChange(e)}
                        />
                      </Button>
                      {!editTerms && (
                        <p>
                          {uploadFile.fileName ? uploadFile.fileName : ''}
                          {uploadFile.fileError ? <span className="text-danger">{uploadFile.fileError}</span> : null}
                        </p>
                      )}
                    </Grid>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Card sx={{ maxHeight: 345 }} variant="outlined">
                    <CardContent>
                      {uploadFile.fileUrl ? (
                        <iframe
                          title="local"
                          style={{ height: '30vh' }}
                          src={`https://docs.google.com/gview?url=${
                            FILE_API + uploadFile.fileUrl
                          }&embedded=true&zoom=0`}
                          className="col-sm-12 col-md-12"
                        />
                      ) : null}
                      {termstypedata.termsObj?.tc_type_template_path &&
                      termstypedata.noTemplate === true &&
                      termstypedata.termsObj?.tc_type_template_path ? (
                        <iframe
                          title="online"
                          style={{ height: '30vh' }}
                          src={`https://docs.google.com/gview?url=${
                            FILE_API + termstypedata.termsObj.tc_type_template_path
                          }&embedded=true&zoom=0`}
                          className="col-sm-12 col-md-12"
                        />
                      ) : null}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }} item xs={12} sm={12} md={6}>
                {' '}
                <Button
                  sx={{ height: '2.5rem', width: 150, mx: 1 }}
                  variant="contained"
                  onClick={() => {
                    setOpenAddplans(true);
                  }}
                >
                  Add New T&C
                </Button>
                {/* // {uploadFile.fileError?} */}
                <Button
                  sx={{ height: '2.5rem', width: 150, mx: 1 }}
                  disabled={uploadFile.fileError !== ''}
                  variant="contained"
                  onClick={() => handleFormSubmit()}
                >
                  Save
                </Button>
              </Grid>
            </form>
          </Box>
        </Card>
      </Container>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} />
        <Card sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Terms & Conditions List
          </Typography>
          <Scrollbar>
            <MaterialReactTable
              columns={columns}
              data={propertyTermsData}
              enableRowActions
              enableColumnActions={false}
              // initialState={{
              //   sorting: [
              //     // sort by state in ascending order by default
              //     // { id: 'resource_group_name', desc: true }, // then sort by city in descending order by default
              //     { id: 'resource_name', desc: true }, // then sort by city in descending order by default
              //   ],
              // }}
              muiTableBodyCellProps={{
                sx: {
                  textAlign: 'center',
                },
              }}
              muiTableHeadCellProps={{
                sx: {
                  textAlign: 'center',
                },
                align: 'center',
              }}
              positionActionsColumn={'last'}
              renderRowActions={({ row, table }) => (
                <Box className="d-flex flex-row justify-content-center p-0 ">
                  {/* <Tooltip arrow placement="left" title="Edit">
                    <IconButton
                      sx={{
                        width: 36,
                        height: 38,
                        border: 0.6,
                        borderColor: '#F36E24',
                        '& .MuiSvgIcon-root': {
                          fill: '#F36E24',
                        },
                        marginRight: '10px',
                      }}
                      onClickCapture={() => {
                        handleEditTerms(row.original);
                      }}
                    >
                      <EditIcon
                        sx={{ fontSize: 14 }}
                        //  onClick={() => editUser(row)}
                      />
                    </IconButton>
                  </Tooltip> */}

                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton
                      sx={{
                        width: 36,
                        height: 38,
                        border: 0.5,
                        borderColor: '#F36E24',
                        '& .MuiSvgIcon-root': {
                          fill: '#F36E24',
                        },
                      }}
                      onClick={() => {
                        setdeleteOpen(true);
                        setdeleteData(row.original);
                        // setWorkSpaceData((prev) => ({ ...prev, deleteData: row.original }));
                      }}
                    >
                      <DeleteIcon
                        sx={{ fontSize: 14 }}
                        onClick={() => {
                          setdeleteOpen(true);
                          setdeleteData(row.original);
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            />
          </Scrollbar>
        </Card>
        <div
          style={{
            marginTop: '5vh',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <span>
              Is Cancellation policy Applicable <span style={{ color: 'red' }}>*</span>
            </span>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={cancellationvalue}
              onChange={handleChangeCancelation}
            >
              <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </div>
          {cancellationvalue === 'Yes' && (
            <Button
              onClick={() => {
                setOpendialog(true);
                setCancellationPolicy((prev) => ({ ...prev, cancelId: null }));
                setStarttimeObj(null);
                setEndtimeobj(null);
                setRefundPer('');
                setCStatus(1);
              }}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add New Cancellation Policy
            </Button>
          )}
        </div>
        {cancellationvalue === 'Yes' ? (
          <Card sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Configure Cancellation Policy
            </Typography>

            <Scrollbar>
              <MaterialReactTable
                columns={cancellationColumns}
                data={cancellationpolicy.cancellationData}
                enableRowActions
                enableColumnActions={false}
                // initialState={{
                //   sorting: [
                //     // sort by state in ascending order by default
                //     // { id: 'resource_group_name', desc: true }, // then sort by city in descending order by default
                //     { id: 'resource_name', desc: true }, // then sort by city in descending order by default
                //   ],
                // }}
                muiTableBodyCellProps={{
                  sx: {
                    textAlign: 'center',
                  },
                }}
                muiTableHeadCellProps={{
                  sx: {
                    textAlign: 'center',
                  },
                  align: 'center',
                }}
                positionActionsColumn={'last'}
                renderRowActions={({ row, table }) => (
                  <Box className="d-flex flex-row justify-content-center p-0 ">
                    <Tooltip arrow placement="left" title="Edit">
                      <IconButton
                        sx={{
                          width: 36,
                          height: 38,
                          border: 0.6,
                          borderColor: '#F36E24',
                          '& .MuiSvgIcon-root': {
                            fill: '#F36E24',
                          },
                          marginRight: '10px',
                        }}
                        onClickCapture={() => {
                          handleEditCancel(row.original);
                        }}
                      >
                        <EditIcon
                          sx={{ fontSize: 14 }}
                          //  onClick={() => editUser(row)}
                        />
                      </IconButton>
                    </Tooltip>

                    <Tooltip arrow placement="right" title="Delete">
                      <IconButton
                        sx={{
                          width: 36,
                          height: 38,
                          border: 0.5,
                          borderColor: '#F36E24',
                          '& .MuiSvgIcon-root': {
                            fill: '#F36E24',
                          },
                        }}
                        onClick={() => {
                          setdeleteOpen(true);
                          setdeleteData(row.original);
                          // setWorkSpaceData((prev) => ({ ...prev, deleteData: row.original }));
                        }}
                      >
                        <DeleteIcon
                          sx={{ fontSize: 14 }}
                          onClick={() => {
                            setdeleteOpen(true);
                            setdeleteData(row.original);
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}
              />
            </Scrollbar>
          </Card>
        ) : null}
      </Container>
      <CommonAlert
        open={openAddplans}
        setKycOpen={setOpenAddplans}
        message="All the inputs will be discarded, do you wish to proceed?"
        proceedFunction={handleAddPlans}
        goBackFunction={handleBackAddplans}
      />
      <CommonAlert
        open={deleteOpen}
        setKycOpen={setdeleteOpen}
        message="Are you sure you want to delete this row?"
        proceedFunction={handleDeleteRow}
        goBackFunction={handleDeleteBack}
      />
      <CommonAlert
        open={draftopen}
        setKycOpen={setDraftOpen}
        message="All Terms&Conditions Data will be save as draft, do you wish to Proceed ?"
        proceedFunction={handleDraft}
        goBackFunction={handleDraftBack}
      />
      <Grid
        sx={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', marginTop: '30px', marginRight: '65px' }}
        item
        xs={12}
        sm={12}
        md={6}
      >
        {/* <Button sx={{ height: '2.5rem', width: 150, mx: 1 }} variant="contained">
                          Cancel
                        </Button> */}
        {/* <LoadingButtonBRH type="submit" variant="containeds" label="Save" color="grey" />
          <LoadingButtonBRH type="submit" variant="containeds" label="Save" /> */}
        <Button
          type="button"
          sx={{
            height: '2.5rem',
            width: 150,
            mx: 1,
            backgroundColor: 'grey',
            color: 'white',
            ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
          }}
          onClick={() => navigate(routeNames.PROSPECTPROPERTYLIST)}
          variant="contained"
        >
          Cancel
        </Button>
        <Button
          type="button"
          sx={{
            height: '2.5rem',
            width: 150,
            mx: 1,
            backgroundColor: 'grey',
            color: 'white',
            ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
          }}
          onClick={onChange}
          variant="contained"
        >
          Previous
        </Button>
        {propertyData.isDraft === 1 ? (
          <Button
            sx={{
              height: '2.5rem',
              width: 150,
              mx: 1,
              backgroundColor: 'grey',
              color: 'white',
              ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
            }}
            //  color="grey"
            variant="contained"
            onClick={() => setDraftOpen(true)}
          >
            Save as Draft
          </Button>
        ) : (
          ''
        )}
        {localStorage.getItem('verified') === '1' || localStorage.getItem('verified') === '2' ? (
          <LoadingButtonBRH variant="containeds" label="Update" handleSubmit={(e) => handleContinue(e)} />
        ) : (
          <LoadingButtonBRH variant="containeds" label="Save & Next" handleSubmit={(e) => handleContinue(e)} />
        )}
      </Grid>
      <Dialog open={openDialog} onClose={handleClose} fullWidth={fullWidth} maxWidth="sm">
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={{ fontWeight: 600, fontSize: '25px' }}>
            {cancellationpolicy.cancelId ? ' Edit Cancellation Policy' : 'Add Cancellation Policy'}
          </span>
          <Tooltip arrow placement="left" title="close">
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContentText style={{ padding: '20px' }}>
          <form onSubmit={(e) => handleAddCancel(e)}>
            <Grid container rowSpacing={4}>
              <Grid item xs={12}>
                <AutoCompleteInputBRH
                  label="Start Time"
                  name="start_at"
                  options={options}
                  value={startTimeObj}
                  handleChange={handleChangestartAt}
                  size="medium"
                  required="true"
                />
              </Grid>
              <Grid item xs={12}>
                <AutoCompleteInputBRH
                  label="End Time"
                  name="end_at"
                  options={options2}
                  value={endTimeObj}
                  handleChange={handleChangeendAt}
                  size="medium"
                  required="true"
                />
              </Grid>
              <Grid item xs={12}>
                <TextInputBRH
                  label="Refund percentage"
                  name="Refund_percentage"
                  value={refundpercentage}
                  handleChange={handleRefund}
                  required="true"
                />
              </Grid>
              <Grid item xs={12}>
                <SwichInputBRH
                  name="status"
                  label="Status"
                  value={CStatus === 1}
                  handleChange={(e) => handleStatus(e)}
                />
              </Grid>
            </Grid>
            <Grid className="d-flex justify-content-end mt-3">
              <Button
                type="button"
                sx={{
                  height: '2.5rem',
                  width: 150,
                  mx: 1,
                  backgroundColor: 'grey',
                  color: 'white',
                  ':hover': { color: '#fc9200', backgroundColor: '#fff7cd' },
                }}
                onClick={() => handleClose()}
                variant="contained"
              >
                Cancel
              </Button>
              <LoadingButtonBRH
                variant="containeds"
                label={cancellationpolicy.cancelId ? 'Update' : 'Save'}
                type="submit"
                style={{ width: 150 }}
                // handleSubmit={handleWorkSpace}
              />
            </Grid>
          </form>
        </DialogContentText>
      </Dialog>
    </>
  );
};

export default TermsAndConditions;
