import { Breadcrumbs, Button, Card, Container, Link, Stack, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import Iconify from '../../../components/iconify/Iconify';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { GETCLIENTS } from '../../../API_SERVICE_LAYER/ApiNames';
import { TableSkeleton } from '../../../components/skeleton/TableSkeleton';
import { NormalTable } from '../../../components/tables/NormalTable';
import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';

export default function ProspectPage() {
  const [prospectListData, setProspectListData] = useState({
    isFetched: false,
    prospectData: [],
  });
  const navigate = useNavigate();
  useEffect(() => {
    API.getMethod(GETCLIENTS)
      .then((resClient) => {
        if (resClient?.data?.status === 'success') {
          const apiClientData = resClient?.data?.results;
          const clientData = apiClientData?.flatMap((el) => (el.is_prospect === 1 ? el : []));
          const clientDataReversed = clientData.toReversed();
          setProspectListData({
            ...prospectListData,
            isFetched: true,
            prospectData: clientDataReversed,
          });
        }
      })
      .catch((errClient) => {
        console.log('API-GETCLIENTS', errClient);
        setProspectListData({
          ...prospectListData,
          isFetched: true,
        });
      });
  }, []);

  // const handleStatusChange = (event, prospect) => {
  //   const prospectID = prospect?.id;
  //   if (event.target.checked === true) {
  //     prospect.status = 1;
  //   } else {
  //     prospect.status = 0;
  //   }

  //   console.log('event', event.target.checked, 'prospect', prospect);
  // };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'company_name',
        header: 'Prospect Name',
        size: 50,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <Button
                variant="text"
                sx={{ background: 'none', cursor: 'pointer', border: 'none', color: '#3d464f' }}
                onClick={() => {
                  navigate(`${routeNames.PROSPECTS}/edit/${rowData?.id}`, {
                    state: { isUpdated: true, updatedId: rowData?.id },
                  });
                }}
              >
                {rowData.company_name}
              </Button>
            </>
          );
        },
      },
      {
        accessorKey: 'company_address',
        header: 'Prospect Address',
        size: 50,
      },
      {
        accessorKey: 'contact_person_name',
        header: 'Contact Person Name',
        size: 250,
      },
      {
        accessorKey: 'contact_person_email',
        header: 'Contact Person Email',
        size: 250,
      },
      {
        accessorKey: 'contact_person_number',
        header: 'Contact Person Number',
        size: 250,
      },
      // {
      //   accessorKey: 'city',
      //   header: 'City',
      //   size: 150,
      // },
      // {
      //   accessorKey: 'state',
      //   header: 'State',
      //   size: 150,
      // },
    ],
    []
  );

  // const handleEdit = ({ id }) => {
  //   console.log('id', id);
  //   navigate(`/masters/prospects/edit/${id}`);
  // };

  // const handleDelete = ({ id }) => {
  //   console.log('id', id);
  //   Swal.fire({
  //     title: 'Do you want to Delete client information?',
  //     showCancelButton: true,
  //     confirmButtonText: 'Delete',
  //     confirmButtonColor: '#fc9200',
  //   }).then((result) => {
  //     /* Read more about isConfirmed, isDenied below */
  //     if (result.isConfirmed) {
  //       deleteMethod(`/contract-management/api/v1/delete-client/${id}`)
  //         .then((res) => {
  //           if (res?.data?.status) {
  //             Swal.fire({
  //               position: 'center',
  //               icon: 'success',
  //               title: res?.data?.message,
  //               showConfirmButton: false,
  //               timer: 1500,
  //             });
  //             getProspectData('/contract-management/api/v1/get-all-client', controller.signal);
  //           }
  //         })
  //         .catch((err) => {
  //           Swal.fire({
  //             position: 'top-end',
  //             icon: 'error',
  //             title: err?.response?.data?.message,
  //             showConfirmButton: false,
  //             timer: 1500,
  //           });
  //         });
  //     }
  //   });
  // };

  return (
    <>
      {prospectListData.isFetched === true ? (
        <>
          <>
            <Helmet>
              <title> Prospects | BRH </title>
            </Helmet>
            <Container>
              <Breadcrumbs aria-label="breadcrumb">
                <Button onClick={() => navigate(routeNames.INDEX)} color="secondary">
                  Dashboard
                </Button>
                <Typography color="primary" variant="button">
                  {' '}
                  Prospects
                </Typography>
                {/* <Link underline="hover" color="inherit" href="/dashboard">
                  Dashboard
                </Link>
                <Link underline="hover" color="inherit" href="/masters/prospects">
                  Masters
                </Link>
                <Typography color="text.primary">Prospects</Typography> */}
              </Breadcrumbs>
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  Prospects
                </Typography>
                <Button
                  onClick={() => navigate(routeNames.PROSPECTSADD)}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Prospects
                </Button>
              </Stack>
              <Card sx={{ p: 2 }}>
                <Scrollbar>
                  <NormalTable data={prospectListData.prospectData} columns={columns} />
                </Scrollbar>
              </Card>
            </Container>
          </>
        </>
      ) : (
        <>
          <TableSkeleton />
        </>
      )}
    </>
  );
}
