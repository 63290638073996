import * as React from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  Link,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
} from '@mui/material';

import { useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { TextInputBRH } from '../../../components/forms/TextInputBRH';
import { TimeInputBRH } from '../../../components/forms/TimeInputBRH';
import { LoadingButtonBRH } from '../../../components/forms/LoadingButtonBRH';
import { NumberInputBRH } from '../../../components/forms/NumberInputBRH';
import TextDivider from '../../../components/text-divider/TextDivider';
import { SwichInputBRH } from '../../../components/forms/SwichInputBRH';
import { AutoCompleteInputBRH } from '../../../components/forms/AutoCompleteInputBRH';
import { CheckBoxInputBRH } from '../../../components/forms/CheckBoxInputBRH';
import { ToastBRH } from '../../../components/toasts/ToastBRH';
import { COUNTRYLIST, STATELIST, CITYLIST, COMPANYEDIT, COMPANYSHOW } from '../../../API_SERVICE_LAYER/ApiNames';
import Scrollbar from '../../../components/scrollbar/Scrollbar';
import * as API from '../../../API_SERVICE_LAYER/ApiConfigs';
import { FormSkeleton } from '../../../components/skeleton/FormSkeleton';
import { routeNames } from '../../../layouts/dashBoardBRH/nav/routeNames';
import { GmailInputBRH } from '../../../components/forms/GmailInputBRH';
import { MobileInputBRH } from '../../../components/forms/MobileInputBRH';
import { NormalTable } from '../../../components/tables/NormalTable';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/toastTimings';

export default function AddProspect() {
  const navigate = useNavigate();
  const [key, setKey] = useState(new Date().toTimeString());
  const { state } = useLocation();
  const [companyMasterData, setCompanyMasterData] = useState({
    isLoading: false,

    isUpdate: false,
    isDepUpdate: false,
    isModalUpdate: false,
    isDepModalUpdate: false,
    company_detail_id: 1,
    id: '',
    company_name: '',
    companyId: '',
    business_nature: '',
    company_signatory: '',
    mg_contact_person_name: '',
    contact_email: '',
    contact_number: '',
    mgPincode: '',
    mgAddress: '',
    countryList: [],
    mgCountryList: [],
    mgCountry: null,
    mgStateList: [],
    mgState: null,
    mgCityList: [],
    mgCity: null,
    workingHoursStart: new Date(2023, 1, 1, 10, 0, 0),
    workingHoursEnd: new Date(2023, 1, 1, 18, 0, 0),
    workingDayMon: true,
    workingDayTue: true,
    workingDayWed: true,
    workingDayThu: true,
    workingDayFri: true,
    workingDaySat: false,
    workingDaySun: false,
    companyAddressList: [],
    allStateList: [],
    cpPincode: null,
    isOpenDialog: false,
    isDepartMentDialog: false,
    cpName: null,
    cpMail: null,
    cpNumber: null,
    cpAddress: null,
    cpDesignatiion: null,
    cpSignatory: null,
    cpCountryList: [],
    cpCountryId: 101,
    cpCountryObject: null,
    cpStateList: [],
    cpStateId: null,
    cpStateObject: null,
    cpCityList: [],
    cpCityId: null,
    cpCityObject: null,
    cpGST: null,
    dep_name: '',
    dep_id: '',
    dep_code: '',
    status: 1,
    departmentsList: [],
    limit: 100,
    isSelectDataloaded: true,
    isFetched: false,
    depNameList: [],
    depName: null,
    depId: null,
  });

  console.log(companyMasterData.depNameList);
  useEffect(() => {
    // LIST ALLA STATES
    setCompanyMasterData((prevData) => ({ ...prevData, isFetched: false }));
    API.getMethod(STATELIST)
      .then((resSt) => {
        if (resSt?.data?.status === true) {
          const dataState = resSt?.data?.data;
          // COMPANY MASTER DATA
          API.getData(`${COMPANYSHOW}/1`)
            .then((resCM) => {
              const apiData = resCM.data.results;
              console.log(resCM);
              const APICompanyTimings = apiData.company_timings;

              const [fromHours = 10, fromMinutes = 0, fromSeconds = 0] = apiData?.from_time.split(':');
              const [toHours = 19, toMinutes = 0, toSeconds = 0] = apiData?.to_time.split(':');

              const ApiSunSelected = APICompanyTimings[0].is_selected;
              const ApiMonSelected = APICompanyTimings[1].is_selected;
              const ApiTueSelected = APICompanyTimings[2].is_selected;
              const ApiWedSelected = APICompanyTimings[3].is_selected;
              const ApiThuSelected = APICompanyTimings[4].is_selected;
              const ApiFriSelected = APICompanyTimings[5].is_selected;
              const ApiSatSelected = APICompanyTimings[6].is_selected;
              const apiCompanyAdddressList = apiData.company_addresses;
              const stateAddedCompanyAddress = apiCompanyAdddressList?.map((ca, i) => {
                let stateObject;
                dataState?.forEach((eleSt) => {
                  if (Number(ca?.state_id) === eleSt.id) {
                    stateObject = eleSt;
                  }
                });
                return { ...ca, stateData: stateObject };
              });
              API.getData(COUNTRYLIST)
                .then((resCountry) => {
                  if (resCountry?.data?.status === true) {
                    const dataCountry = resCountry?.data?.data;
                    const mgCountryData = dataCountry.find((cou) => cou.id === apiData.country_id);
                    // STATE DATA AGAINST CP
                    API.getData(`${STATELIST}/${companyMasterData.cpCountryId}`)
                      .then((resState) => {
                        if (resState?.data?.status === true) {
                          const dataStateList = resState?.data?.data;
                          API.getData(`${STATELIST}/${apiData.country_id}`)
                            .then((apiMgCountry) => {
                              if (apiMgCountry.data.status === true) {
                                const mgStateDataList = apiMgCountry.data.data;
                                const mgStateData = mgStateDataList.find((state) => state.id === apiData.state_id);
                                API.getData(`${CITYLIST}/${apiData.state_id}`)
                                  .then((resCity) => {
                                    if (resCity.data.status === true) {
                                      const mgCityDataList = resCity.data.data;
                                      const mgCityData = mgCityDataList.find((city) => city.id === apiData.city_id);
                                      API.getData(`${'api/alldepartment?limit='}${companyMasterData.limit}`)
                                        .then((resDepData) => {
                                          console.log(resDepData);
                                          if (resDepData.data.status === 'success') {
                                            const apiDepData = resDepData?.data?.data?.data;
                                            const depNameOptions = apiDepData.map((department) => ({
                                              depName: department.dep_name,
                                              depId: department.id,
                                            }));
                                            console.log(depNameOptions);
                                            console.log(apiData);
                                            console.log(apiDepData);
                                            setCompanyMasterData({
                                              ...companyMasterData,
                                              isFetched: true,
                                              company_name: apiData.company_name,
                                              departmentsList: apiDepData,
                                              depNameList: depNameOptions,
                                              business_nature: apiData.business_nature,
                                              company_signatory: apiData.company_signatory,
                                              mgAddress: apiData.address,
                                              mgPincode: apiData.pincode,
                                              mg_contact_person_name: apiData.contact_person_name,
                                              contact_email: apiData.contact_person_email,
                                              contact_number: apiData.contact_number,
                                              workingHoursStart: new Date(
                                                2023,
                                                1,
                                                1,
                                                fromHours,
                                                fromMinutes,
                                                fromSeconds
                                              ),
                                              workingHoursEnd: new Date(2023, 1, 1, toHours, toMinutes, toSeconds),
                                              workingDaySun: ApiSunSelected,
                                              workingDayMon: ApiMonSelected,
                                              workingDayTue: ApiTueSelected,
                                              workingDayWed: ApiWedSelected,
                                              workingDayThu: ApiThuSelected,
                                              workingDayFri: ApiFriSelected,
                                              workingDaySat: ApiSatSelected,
                                              companyAddressList: stateAddedCompanyAddress,
                                              allStateList: dataState,
                                              cpCountryList: dataCountry,
                                              cpCountryObject: dataCountry[0],
                                              cpStateList: dataStateList,
                                              mgCountryList: dataCountry,
                                              mgCountry: mgCountryData,
                                              mgStateList: mgStateDataList,
                                              mgCityList: mgCityDataList,
                                              mgState: mgStateData,
                                              mgCity: mgCityData,
                                            });
                                          }
                                        })
                                        .cath((errDepN) => {
                                          console.log(errDepN);
                                        });
                                    }
                                  })
                                  .catch((errCity) => console.log(errCity));
                              }
                            })
                            .catch((apiMgErr) => console.log(apiMgErr));
                        }
                      })
                      .catch((errstate) => {
                        setCompanyMasterData({
                          ...companyMasterData,
                          isFetched: true,
                          company_name: apiData.company_name,
                          business_nature: apiData.business_nature,
                          company_signatory: apiData.company_signatory,
                          mgAddress: apiData.address,
                          mgPincode: apiData.pincode,
                          mg_contact_person_name: apiData.contact_person_name,
                          contact_email: apiData.contact_person_email,
                          contact_number: apiData.contact_number,
                          workingHoursStart: new Date(2023, 1, 1, fromHours, fromMinutes, fromSeconds),
                          workingHoursEnd: new Date(2023, 1, 1, toHours, toMinutes, toSeconds),
                          workingDaySun: ApiSunSelected,
                          workingDayMon: ApiMonSelected,
                          workingDayTue: ApiTueSelected,
                          workingDayWed: ApiWedSelected,
                          workingDayThu: ApiThuSelected,
                          workingDayFri: ApiFriSelected,
                          workingDaySat: ApiSatSelected,
                          companyAddressList: stateAddedCompanyAddress,
                          allStateList: dataState,
                          cpCountryList: dataCountry,
                          cpCountryObject: dataCountry[0],
                        });
                        console.log('API-STATELIST', errstate);
                      });
                  }
                })
                .catch((errCountry) => {
                  console.log('API-COUNTRYLIST', errCountry);
                  setCompanyMasterData({
                    ...companyMasterData,
                    isFetched: true,
                    company_name: apiData.company_name,
                    business_nature: apiData.business_nature,
                    company_signatory: apiData.company_signatory,
                    mg_contact_person_name: apiData.contact_person_name,
                    mgAddress: apiData.address,
                    mgPincode: apiData.pincode,
                    contact_email: apiData.contact_person_email,
                    contact_number: apiData.contact_number,
                    workingHoursStart: new Date(2023, 1, 1, fromHours, fromMinutes, fromSeconds),
                    workingHoursEnd: new Date(2023, 1, 1, toHours, toMinutes, toSeconds),
                    workingDaySun: ApiSunSelected,
                    workingDayMon: ApiMonSelected,
                    workingDayTue: ApiTueSelected,
                    workingDayWed: ApiWedSelected,
                    workingDayThu: ApiThuSelected,
                    workingDayFri: ApiFriSelected,
                    workingDaySat: ApiSatSelected,
                    companyAddressList: stateAddedCompanyAddress,
                    allStateList: dataState,
                    // stateList: resultState?.data.data,
                    // countryList: dataCountry,
                    // countryObject: dataCountry[0],
                  });
                });
            })
            .catch((errCM) => {
              console.log('API-COMPANYSHOW', errCM);
              setCompanyMasterData({ ...companyMasterData, isFetched: true, allStateList: dataState });
            });
        }
      })
      .catch((errSt) => {
        console.log('API-STATELIST', errSt);
        setCompanyMasterData({ ...companyMasterData, isFetched: true });
      });
  }, [key]);

  // useEffect(() => {
  //   API.getData(`${'api/alldepartment?limit='}${companyMasterData.limit}`)
  //     .then((resDepData) => {
  //       console.log(resDepData);
  //       if (resDepData.data.status === 'success') {
  //         const apiData = resDepData?.data?.data?.data;
  //         console.log(apiData);
  //         setCompanyMasterData({
  //           ...companyMasterData,
  //           departmentsList: apiData,
  //           isFetched: true,
  //         });
  //       }
  //     })
  //     .catch((errDep) => {
  //       console.log(errDep);
  //       setCompanyMasterData({
  //         ...companyMasterData,
  //         isFetched: true,
  //       });
  //     });
  // }, []);

  useEffect(() => {
    console.log(companyMasterData);
  }, []);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (
      name === 'workingDaySun' ||
      name === 'workingDayMon' ||
      name === 'workingDayTue' ||
      name === 'workingDayWed' ||
      name === 'workingDayThu' ||
      name === 'workingDayFri' ||
      name === 'workingDaySat'
    ) {
      setCompanyMasterData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else if (type === 'checkbox') {
      if (name === 'is_default_1' && checked) {
        setCompanyMasterData({
          ...companyMasterData,
          is_default_1: 1,
          is_default_2: 0,
        });
      } else if (name === 'is_default_2' && checked) {
        setCompanyMasterData({
          ...companyMasterData,
          is_default_1: 0,
          is_default_2: 1,
        });
      } else {
        setCompanyMasterData({
          ...companyMasterData,
          is_default_1: 0,
          is_default_2: 0,
        });
      }
    } else if (name === 'cpGST') {
      setCompanyMasterData({
        ...companyMasterData,
        cpGST: value?.toUpperCase(),
      });
    } else {
      setCompanyMasterData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setCompanyMasterData({ ...companyMasterData, isSelectDataloadedCountry: false });
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            setCompanyMasterData({
              ...companyMasterData,
              cpCountryId: id,
              cpCountryObject: newValue,
              isSelectDataloaded: true,
              cpStateList: res.data.data,
              cpStateObject: null,
              cpCityObject: null,
            });
          }
        })
        .catch((err) => {
          setCompanyMasterData({
            ...companyMasterData,
            cpCountryId: id,
            cpStateList: [],
            isSelectDataloaded: true,
          });
        });
    }
  };
  const handleStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    if (name === 'name') {
      setCompanyMasterData({ ...companyMasterData, isSelectDataloadedState: false });
      API.getData(`${CITYLIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            setCompanyMasterData({
              ...companyMasterData,
              isSelectDataloaded: true,
              cpStateObject: newValue,
              cpStateId: id,
              cpCityList: res.data.data,
              cpCityObject: null,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setCompanyMasterData({ ...companyMasterData, isSelectDataloaded: true, cpCityList: [], cpStateId: id });
        });
    }

    // setCompanyMasterData({ ...companyMasterData, state_id1: id });
  };
  const handleCityChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    setCompanyMasterData({ ...companyMasterData, cpCityId: id, cpCityObject: newValue });
  };
  const handleMgCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setCompanyMasterData({ ...companyMasterData, isSelected: false });
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setCompanyMasterData({
              ...companyMasterData,
              mgCountry: newValue,
              isSelected: true,
              mgStateList: res.data.data,
              // stateObject: null,
              // cityList: [],
              // cityObject: null,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setCompanyMasterData({
            ...companyMasterData,
            // country_id: id,
            // stateList: [],
            isSelected: true,
          });
        });
    }
  };
  const handleMgStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    if (name === 'name') {
      setCompanyMasterData({ ...companyMasterData, isSelected: false });
      API.getData(`${CITYLIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            console.log('API-CITYLIST');
            setCompanyMasterData({
              ...companyMasterData,
              isSelected: true,
              mgState: newValue,
              mgCityList: res.data.data,
              // cityObject: null,
              // state_id: id,
            });
          }
        })
        .catch((err) => {
          console.log('API-CITYLIST', err);
          // ToastBRH('error', `${err?.response?.data?.message}`, '_', false);
          ToastBRH('error', 'Something Went Wrong! Try Again Later', TOAST_ERROR);
          setCompanyMasterData({ ...companyMasterData, isSelected: true, mgCityList: [] });
        });
    }
  };
  const handleMgCityChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    setCompanyMasterData({ ...companyMasterData, mgCity: newValue });
  };
  const handleMgSubmit = (e) => {
    e.preventDefault();
    setCompanyMasterData({ ...companyMasterData, isLoading: true });
    if (!companyMasterData.isLoading) {
      const formData = {
        id: companyMasterData.company_detail_id,
        company_name: companyMasterData.company_name,
        business_nature: companyMasterData.business_nature,
        company_signatory: companyMasterData.company_signatory,
        contact_person_name: companyMasterData.mg_contact_person_name,
        contact_person_email: companyMasterData.contact_email,
        contact_number: Number(companyMasterData.contact_number),
        from_time: companyMasterData.workingHoursStart.toLocaleTimeString('en-IN', { hour12: false }),
        to_time: companyMasterData.workingHoursEnd.toLocaleTimeString('en-IN', { hour12: false }),
        timings: [
          {
            day_code: 'Sun',
            day_id: 1,
            is_selected: companyMasterData.workingDaySun ? 1 : 0,
          },
          {
            day_code: 'Mon',
            day_id: 2,
            is_selected: companyMasterData.workingDayMon ? 1 : 0,
          },
          {
            day_code: 'Tue',
            day_id: 3,
            is_selected: companyMasterData.workingDayTue ? 1 : 0,
          },
          {
            day_code: 'Wed',
            day_id: 4,
            is_selected: companyMasterData.workingDayWed ? 1 : 0,
          },
          {
            day_code: 'Thu',
            day_id: 5,
            is_selected: companyMasterData.workingDayThu ? 1 : 0,
          },
          {
            day_code: 'Fir',
            day_id: 6,
            is_selected: companyMasterData.workingDayFri ? 1 : 0,
          },
          {
            day_code: 'Sat',
            day_id: 7,
            is_selected: companyMasterData.workingDaySat ? 1 : 0,
          },
        ],
        address: companyMasterData.mgAddress,
        pincode: companyMasterData.mgPincode,
        country_id: companyMasterData.mgCountry.id,
        state_id: companyMasterData.mgState.id,
        city_id: companyMasterData.mgCity.id,
      };
      console.log(formData);
      API.updateMethod(`${COMPANYEDIT}`, formData)
        .then((res) => {
          if (res?.data?.status === 'success') {
            setCompanyMasterData({ ...companyMasterData, isLoading: false });
            ToastBRH('success', 'Company Master  Updated SuccesFully',TOAST_SUCCESS);
            setTimeout(() => setKey(new Date().toTimeString()), TOAST_SUCCESS);
          }
        })
        .catch((err) => {
          console.log(err);
          setCompanyMasterData({ ...companyMasterData, isLoading: false });
        });
    }
  };

  const handleStatusChange = (e, newValue) => {
    console.log(newValue);
    const changedStatus = newValue.status === 1 ? 0 : 1;
    // let status = '';
    // if (newValue.status === 1) {
    //   status = 1;
    // } else {
    //   status = 0;
    // }
    const formData = {
      status: changedStatus,
      dep_name: newValue.dep_name,
      dep_code: newValue.dep_code,
    };

    const id = newValue.id;
    API.updateMethod(`${'api/departments/'}${id}`, formData)
      .then((resUP) => {
        console.log(resUP);
        console.log('update', formData);
        if (resUP.data.status === 'success') {
          ToastBRH('success', 'Company Master Updated SuccesFully',TOAST_SUCCESS);
          setTimeout(() => setKey(new Date().toTimeString()), TOAST_SUCCESS);
        }
      })
      .cath((err) => {
        console.log(err);
      });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: 'contact_person_name',
        header: 'Person Name',
        size: 140,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          console.log(rowData);
          return (
            <>
              <Button
                variant="text"
                sx={{ background: 'none', cursor: 'pointer', border: 'none', color: '#3d464f' }}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setCompanyMasterData((prevData) => ({
                    ...prevData,
                    isModalUpdate: true,
                    isOpenDialog: true,
                  }));

                  API.getData(COUNTRYLIST)
                    .then((resCou) => {
                      if (resCou?.data?.status === true) {
                        const dataCountry = resCou?.data?.data;
                        let apiCountryObject;
                        dataCountry?.forEach((cou) => {
                          if (cou.id === Number(rowData?.country_id)) {
                            apiCountryObject = cou;
                          }
                        });
                        API.getData(`${STATELIST}/${rowData?.country_id}`)
                          .then((resSt) => {
                            if (resSt?.data?.status === true) {
                              const dataState = resSt?.data?.data;
                              let apiStateObject;
                              dataState?.forEach((eleSt) => {
                                if (eleSt.id === Number(rowData?.state_id)) {
                                  apiStateObject = eleSt;
                                }
                              });
                              API.getData(`${CITYLIST}/${rowData?.state_id}`).then((resCt) => {
                                if (resCt.data?.status === true) {
                                  const dataCity = resCt?.data?.data;
                                  let apiCityObject;
                                  dataCity?.forEach((eleCt) => {
                                    if (eleCt.id === Number(rowData?.city_id)) {
                                      apiCityObject = eleCt;
                                    }
                                  });
                                  API.getData(`${'api/alldepartment?limit='}${companyMasterData.limit}`).then(
                                    (resData) => {
                                      console.log(resData);
                                      if (resData.data.status === 'success') {
                                        const apiData = resData?.data?.data?.data;
                                        // const depNameOptions = apiData.map((department) => ({
                                        //   depName: department.dep_name,
                                        // }));
                                        let apiDepNameObject;
                                        apiData?.forEach((eleDep) => {
                                          if (eleDep.id === Number(rowData?.department_id)) {
                                            apiDepNameObject = eleDep;
                                          }
                                        });
                                        console.log(apiDepNameObject);
                                        setCompanyMasterData((prevData) => ({
                                          ...prevData,
                                          isModalUpdate: false,
                                          isUpdate: true,
                                          isOpenDialog: true,
                                          id: Number(rowData?.id),
                                          cpName: rowData?.contact_person_name,
                                          cpMail: rowData?.contact_person_email,
                                          cpNumber: rowData?.contact_number,
                                          cpAddress: rowData?.address,
                                          cpDesignatiion: rowData?.designation,
                                          cpPincode: rowData?.pincode,
                                          depId: rowData?.department_id,
                                          depName: apiDepNameObject,
                                          cpSignatory: rowData?.signatory,
                                          cpCountryList: dataCountry,
                                          cpCountryId: rowData?.country_id,
                                          cpCountryObject: apiCountryObject,
                                          cpStateList: dataState,
                                          cpStateId: rowData?.state_id,
                                          cpStateObject: apiStateObject,
                                          cpCityList: dataCity,
                                          cpCityObject: apiCityObject,
                                          cpCityId: rowData?.city_id,
                                          cpGST: rowData.gst,
                                        }));
                                      }
                                    }
                                  );
                                }
                              });
                            }
                          })
                          .catch((errSt) => {
                            console.log(errSt);
                          });
                      }
                    })
                    .catch((errCou) => {
                      console.log(errCou);
                    });
                }}
              >
                {rowData.contact_person_name}
              </Button>
            </>
          );
        },
      },
      {
        accessorKey: 'contact_person_email',
        header: 'Person Email',
        size: 140,
      },
      {
        accessorKey: 'contact_number',
        header: 'Person Number',
        size: 140,
      },
      {
        accessorKey: 'designation',
        header: 'Designation',
        size: 140,
      },
      {
        accessorKey: 'state_id',
        header: 'State',
        size: 140,
        Cell: ({ cell }) => {
          const rowData = cell?.row?.original;
          return (
            <>
              <span>{rowData?.stateData?.name}</span>
            </>
          );
        },
      },

      // {
      //   accessorKey: 'country_id',
      //   header: 'Country',
      //   size: 140,
      //   muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      //     ...getCommonEditTextFieldProps(cell),
      //   }),
      // },
    ],
    []
  );
  const EditBox = (value) => {
    console.log(value);
    setCompanyMasterData({
      ...companyMasterData,
      dep_code: value.dep_code,
      dep_name: value.dep_name,
      status: value.status,
      dep_id: value.id,
    });
  };
  const departColumns = useMemo(() => [
    {
      accessorKey: 'dep_name',
      header: 'Department',
      size: 140,
      Cell: ({ cell }) => {
        const rowData = cell?.row?.original;
        return (
          <>
            <Button
              variant="text"
              sx={{ background: 'none', cursor: 'pointer', border: 'none', color: '#3d464f' }}
              type="button"
              onClick={(e) => {
                console.log(rowData);
                e.preventDefault();
                EditBox(rowData);
                setCompanyMasterData((prevData) => ({
                  ...prevData,
                  isDepModalUpdate: true,
                  isDepartMentDialog: true,
                }));
              }}
            >
              {rowData.dep_name}
            </Button>
          </>
        );
      },
    },
    {
      accessorKey: 'dep_code',
      header: 'Department Code',
      size: 140,
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 140,
      Cell: ({ cell }) => {
        const rowData = cell?.row?.original;
        return (
          <>
            <div>
              <SwichInputBRH
                name="status"
                value={rowData?.status}
                handleChange={(e) => handleStatusChange(e, rowData)}
              />
            </div>
          </>
        );
      },
    },
  ]);

  const handleStatusUpdate = (e, value) => {
    e.preventDefault();
    if (e.target.checked === true) {
      value.status = 1;
    }
  };
  const handleClose = () => {
    setCompanyMasterData({
      ...companyMasterData,
      isOpenDialog: false,
      isUpdate: false,
      isModalUpdate: false,
      cpName: null,
      cpMail: null,
      cpNumber: null,
      cpAddress: null,
      cpPincode: null,
      depId: null,
      cpGST: null,
      cpDesignatiion: null,
      cpSignatory: null,
      cpCityObject: null,
      cpStateObject: null,
      cpCountryId: 101,
      cpCountryObject: companyMasterData?.cpCountryList[0],
      dep_code: null,
      dep_name: null,
      depName: null,
    });
  };
  const handleDepartmentClose = () => {
    setCompanyMasterData({
      ...companyMasterData,
      isDepartMentDialog: false,
      dep_name: null,
      dep_code: null,
      status: 1,
    });
  };
  const handleCpSubmit = (e) => {
    e.preventDefault();
    setCompanyMasterData({ ...companyMasterData, isLoading: true });
    const formData = {
      id: companyMasterData.company_detail_id,
      addresses: [
        {
          // id:Number(companyMasterData?.id),
          contact_person_name: companyMasterData.cpName,
          contact_person_email: companyMasterData.cpMail,
          contact_number: Number(companyMasterData.cpNumber),
          designation: companyMasterData.cpDesignatiion,
          signatory: companyMasterData.cpSignatory,
          address: companyMasterData.cpAddress,
          pincode: Number(companyMasterData.cpPincode),
          city_id: Number(companyMasterData.cpCityId),
          department_id: Number(companyMasterData.depId),
          state_id: Number(companyMasterData.cpStateId),
          country_id: Number(companyMasterData.cpCountryId),
          gst: Number(companyMasterData.cpGST),
          is_default: companyMasterData.is_default_1 ? 1 : 0,
        },
      ],
    };
    if (companyMasterData.isUpdate === true) {
      formData.addresses[0].id = Number(companyMasterData.id);
    }
    API.updateMethod(`${COMPANYEDIT}`, formData)
      .then((res) => {
        if (res?.data?.status === 'success') {
          setCompanyMasterData({ ...companyMasterData, isLoading: false,  isOpenDialog: false });
          // ToastBRH('success', 'Company Master Updated SuccesFully', routeNames.COMPANYMASTER);
          ToastBRH('success', 'Company Master Updated SuccesFully',TOAST_SUCCESS);
          setTimeout(() => setKey(new Date().toTimeString()), TOAST_SUCCESS);
         
                }
      })
      .catch((err) => {
        console.log(err);
        setCompanyMasterData({ ...companyMasterData, isLoading: false });
      });
  };
  const handleDepartmentSubmit = (e) => {
    e.preventDefault();

    const data = {
      dep_name: companyMasterData.dep_name,
      dep_code: companyMasterData.dep_code,
      dep_desc: companyMasterData.dep_desc,
      status: companyMasterData.status,
    };
    if (companyMasterData.dep_id) {
      API.updateMethod(`${'api/departments/'}${companyMasterData.dep_id}`, data)
        .then((resUP) => {
          console.log(resUP);
          console.log('update', data);
          if (resUP.data.status === 'success') {
            // ToastBRH('success', 'Updated Successfully', routeNames.COMPANYMASTER);
            ToastBRH('success', 'Company Master Updated SuccesFully',TOAST_SUCCESS);
            setTimeout(() => setKey(new Date().toTimeString()), TOAST_SUCCESS);
          }
        })
        .cath((err) => {
          console.log(err);
        });
    } else {
      API.postMethod('api/departments', data)
        .then((resData) => {
          console.log(resData);
          if (resData?.data?.status === 'success') {
            API.getData(`${'api/alldepartment?limit='}${companyMasterData.limit}`)
              .then((resDepData) => {
                console.log(resDepData);
                if (resDepData.data.status === 'success') {
                  const apiDepData = resDepData?.data?.data?.data;

                  // ToastBRH('success', 'Department Added Successfully', '___', false);
                  ToastBRH('success', 'Company Master Updated SuccesFully',TOAST_SUCCESS);
                  setTimeout(() => navigate(routeNames.COMPANYMASTER), TOAST_SUCCESS);
                  setCompanyMasterData((prev) => ({
                    ...prev,
                    dep_name: '',
                    dep_id: '',
                    dep_code: '',
                    isDepartMentDialog: false,
                    departmentsList: apiDepData,
                    status: 1,
                  }));
                }
              })
              .cath((errDepN) => {
                console.log(errDepN);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onToggle = (e) => {
    console.log(e.target.checked, e.target.name);
    if (e.target.name === 'status') {
      setCompanyMasterData((prev) => ({
        ...prev,
        status: e.target.checked ? 1 : 0,
      }));
    }
  };
  // setCompanyMasterData({
  //   ...companyMasterData,
  //   depName: newValue,
  // });

  const handleDepChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    console.log(newValue);
    console.log(newValue.id);
    setCompanyMasterData({ ...companyMasterData, depName: newValue, depId: newValue.id });
  };
  return (
    <>
      {companyMasterData.isFetched ? (
        <>
          <Helmet>
            <title> Add MG Master | BRH </title>
          </Helmet>

          <Container>
            <Breadcrumbs sx={{ mb: 2 }} aria-label="breadcrumb">
              <Button onClick={() => navigate(routeNames.INDEX)} color="secondary">
                Dashboard
              </Button>
              <Typography color="primary" variant="button">
                MG Master
              </Typography>
            </Breadcrumbs>

            <Card sx={{ p: 2 }}>
              <Scrollbar>
                <Box sx={{ flexGrow: 1, m: 4 }}>
                  <form noValidate={false} onSubmit={handleMgSubmit}>
                    <FormControl sx={{ flexGrow: 1, width: '100%' }} sub>
                      <Grid container rowSpacing={2.5} columnSpacing={{ xs: 2, sm: 2, md: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Company Name (Client)"
                            name="company_name"
                            handleChange={handleChange}
                            value={companyMasterData.company_name || ''}
                            required
                          />{' '}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Company Nature of Business"
                            name="business_nature"
                            handleChange={handleChange}
                            value={companyMasterData.business_nature}
                            required
                          />{' '}
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="Company Signatory"
                            name="company_signatory"
                            handleChange={handleChange}
                            value={companyMasterData.company_signatory}
                            required
                          />{' '}
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                          <TextInputBRH
                            label="Contact Person Name"
                            name="mg_contact_person_name"
                            value={companyMasterData.mg_contact_person_name}
                            handleChange={handleChange}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={4}>
                          <GmailInputBRH
                            label="Contact Person Email"
                            name="contact_email"
                            handleChange={handleChange}
                            value={companyMasterData.contact_email}
                            isGmail
                            required
                          />{' '}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                          <MobileInputBRH
                            label="Contact Person Number"
                            name="contact_number"
                            handleChange={handleChange}
                            value={companyMasterData.contact_number}
                            required
                          />{' '}
                        </Grid>
                        {/*  Address */}
                        <Grid item xs={12} sm={12} md={12}>
                          <TextInputBRH
                            label="Address"
                            name="mgAddress"
                            value={companyMasterData.mgAddress}
                            handleChange={handleChange}
                            rows={20}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <NumberInputBRH
                            label="Pincode"
                            name="mgPincode"
                            value={companyMasterData.mgPincode}
                            key="pincode"
                            handleChange={handleChange}
                            required
                          />
                        </Grid>
                        {/* country */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="Country"
                            name="name"
                            value={companyMasterData.mgCountry}
                            options={companyMasterData.mgCountryList}
                            handleChange={handleMgCountryChange}
                            isSelectDataloaded={companyMasterData.isSelected}
                            required
                          />
                        </Grid>
                        {/* state */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="State"
                            name="name"
                            value={companyMasterData.mgState}
                            options={companyMasterData.mgStateList}
                            handleChange={handleMgStateChange}
                            isSelectDataloaded={companyMasterData.isSelected}
                            required
                          />
                        </Grid>
                        {/* city */}
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="City"
                            name="name"
                            value={companyMasterData.mgCity}
                            options={companyMasterData.mgCityList}
                            handleChange={handleMgCityChange}
                            isSelectDataloaded={companyMasterData.isSelected}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <Grid container sx={{ border: '1px solid #ccc', padding: '5px 10px' }}>
                            <Grid item xs={12}>
                              <Typography>Working Days</Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Sun"
                                name="workingDaySun"
                                value={companyMasterData.workingDaySun}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Mon"
                                name="workingDayMon"
                                value={companyMasterData.workingDayMon}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Tue"
                                name="workingDayTue"
                                value={companyMasterData.workingDayTue}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Wed"
                                name="workingDayWed"
                                value={companyMasterData.workingDayWed}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Thu"
                                name="workingDayThu"
                                value={companyMasterData.workingDayThu}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Fri"
                                name="workingDayFri"
                                value={companyMasterData.workingDayFri}
                                handleChange={handleChange}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <CheckBoxInputBRH
                                label="Sat"
                                name="workingDaySat"
                                value={companyMasterData.workingDaySat}
                                handleChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                          <Grid container spacing={1}>
                            <Grid item sm={12}>
                              <TimeInputBRH
                                sx={{ width: '100%', mb: 1.5 }}
                                label="Working Hours From *"
                                // value={value}
                                ampm={false}
                                minutesStep={30}
                                handleChange={(e) =>
                                  setCompanyMasterData({ ...companyMasterData, workingHoursStart: e })
                                }
                                value={companyMasterData.workingHoursStart}
                              />
                            </Grid>
                            <Grid item sm={12}>
                              <TimeInputBRH
                                label="Working Hours To *"
                                ampm={false}
                                minutesStep={30}
                                value={companyMasterData.workingHoursEnd}
                                handleChange={(e) => setCompanyMasterData({ ...companyMasterData, workingHoursEnd: e })}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sx={{ marginTop: '16px' }}>
                        <Grid item xs={12} md={12}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'end',
                            }}
                          >
                            <LoadingButtonBRH type="submit" isLoading={companyMasterData.isLoading} label="Update" />
                          </Box>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </form>
                </Box>
                <Grid item sm={12} md={12}>
                  <TextDivider>Contact Person</TextDivider>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'end', marginRight: '35px' }}>
                    <Button
                      variant="outlined"
                      onClick={() => setCompanyMasterData({ ...companyMasterData, isOpenDialog: true })}
                      style={{
                        backgroundColor: '#fc9200',
                        color: 'white',
                        fontSize: '1.10rem',
                      }}
                    >
                      + Add Authorised Signatory
                    </Button>
                  </Grid>
                </Grid>
                <Box>
                  <NormalTable columns={columns} data={companyMasterData.companyAddressList} />
                </Box>

                <Dialog open={companyMasterData.isOpenDialog} maxWidth="md">
                  {companyMasterData?.isModalUpdate ? (
                    <>
                      <Box>
                        <FormSkeleton />
                      </Box>
                    </>
                  ) : (
                    <>
                      <form onSubmit={handleCpSubmit}>
                        <DialogContent>
                          <FormControl>
                            <DialogActions style={{ padding: '0px 0px 8px 0px' }}>
                              <Box>
                                <Button variant="outlined" onClick={() => handleClose()} size="medium">
                                  X
                                </Button>
                              </Box>
                            </DialogActions>
                            <Grid container spacing={3}>
                              {/* <Grid item xs={12} md={12}>
                                <Typography variant="overline">{`${
                                  companyMasterData.isUpdate === true ? 'Add' : 'Edit'
                                } Contact Person`}</Typography>
                              </Grid> */}
                              <Grid item xs={12} md={6}>
                                <TextInputBRH
                                  label="Contact Person Name"
                                  name="cpName"
                                  value={companyMasterData.cpName}
                                  handleChange={handleChange}
                                  required
                                  size="small"
                                />
                              </Grid>
                              {/* <Grid item xs={12} md={12}></Grid> */}
                              <Grid item xs={12} sm={12} md={6}>
                                <GmailInputBRH
                                  label="Contact Person Mail"
                                  name="cpMail"
                                  value={companyMasterData.cpMail}
                                  handleChange={handleChange}
                                  required
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <MobileInputBRH
                                  label="Contact Person Number"
                                  name="cpNumber"
                                  value={companyMasterData.cpNumber}
                                  handleChange={handleChange}
                                  required
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextInputBRH
                                  label="Contact Person Signatory"
                                  name="cpSignatory"
                                  value={companyMasterData.cpSignatory}
                                  handleChange={handleChange}
                                  required
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <TextInputBRH
                                  label="Contact Person Address"
                                  name="cpAddress"
                                  value={companyMasterData.cpAddress}
                                  handleChange={handleChange}
                                  required
                                  multiline
                                  rows={3}
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <NumberInputBRH
                                  label="Pincode"
                                  name="cpPincode"
                                  key="cpPincode"
                                  value={companyMasterData.cpPincode}
                                  handleChange={handleChange}
                                  required
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextInputBRH
                                  label="Designation"
                                  name="cpDesignatiion"
                                  value={companyMasterData.cpDesignatiion}
                                  handleChange={handleChange}
                                  required
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <AutoCompleteInputBRH
                                  label="Department"
                                  name="dep_name"
                                  options={companyMasterData.departmentsList}
                                  value={companyMasterData.depName}
                                  handleChange={handleDepChange}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <AutoCompleteInputBRH
                                  label="Country"
                                  name="name"
                                  handleChange={handleCountryChange}
                                  options={companyMasterData.cpCountryList}
                                  value={companyMasterData.cpCountryObject}
                                  isSelectDataloaded={companyMasterData.isSelectDataloaded}
                                  required
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <AutoCompleteInputBRH
                                  label="State"
                                  name="name"
                                  handleChange={handleStateChange}
                                  options={companyMasterData.cpStateList}
                                  value={companyMasterData.cpStateObject}
                                  isSelectDataloaded={companyMasterData.isSelectDataloaded}
                                  required
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <AutoCompleteInputBRH
                                  label="City"
                                  name="name"
                                  handleChange={handleCityChange}
                                  options={companyMasterData.cpCityList}
                                  value={companyMasterData.cpCityObject}
                                  isSelectDataloaded={companyMasterData.isSelectDataloaded}
                                  required
                                  size="small"
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <TextInputBRH
                                  label="GST"
                                  value={companyMasterData.cpGST}
                                  name="cpGST"
                                  handleChange={handleChange}
                                  
                                  // InputProps={{
                                  //   endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  // }}
                                  size="small"
                                />
                              </Grid>
                            </Grid>
                          </FormControl>
                        </DialogContent>
                        <DialogActions sx={{ marginBottom: '16px' }}>
                          <Box sx={{ marginLeft: '8px' }}>
                            <Button
                              variant="outlined"
                              onClick={() => handleClose()}
                              size="medium"
                              sx={{ padding: '8px 16px' }}
                            >
                              Cancel
                            </Button>
                          </Box>
                          <Box sx={{ marginLeft: '16px' }}>
                            <LoadingButtonBRH
                              type="submit"
                              variant="contained"
                              label={`${companyMasterData.isUpdate ? 'Update' : 'Add'}`}
                            />
                          </Box>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
                
                <Grid item sm={12} md={12}>
                  <TextDivider>Department</TextDivider>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'end', marginRight: '35px' }}>
                    <Button
                      variant="outlined"
                      onClick={() => setCompanyMasterData({ ...companyMasterData, isDepartMentDialog: true })}
                      style={{
                        backgroundColor: '#fc9200',
                        color: 'white',
                        fontSize: '1.10rem',
                      }}
                    >
                      + Department
                    </Button>
                  </Grid>
                </Grid>
                <Box>
                  <NormalTable columns={departColumns} data={companyMasterData.departmentsList} />
                </Box>
                <Dialog open={companyMasterData.isDepartMentDialog} maxWidth="md">
                  {' '}
                  <form onSubmit={handleDepartmentSubmit}>
                    <DialogContent>
                      <FormControl>
                        {/* <DialogActions style={{ padding: '0px 0px 8px 0px' }}>
                          <Box>
                            <Button variant="outlined" onClick={() => handleDepartmentClose()} size="small">
                              X
                            </Button>
                          </Box>
                        </DialogActions> */}
                        <Grid container spacing={3} sx={{ padding: '20px' }}>
                          <Grid item xs={12} md={12}>
                            <TextInputBRH
                              label="Department Name"
                              size="medium"
                              name="dep_name"
                              value={companyMasterData.dep_name}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <TextInputBRH
                              label="Department Code"
                              size="medium"
                              name="dep_code"
                              value={companyMasterData.dep_code}
                              handleChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <SwichInputBRH
                              name="status"
                              label="Status"
                              value={companyMasterData.status === 1}
                              handleChange={onToggle}
                            />
                          </Grid>
                        </Grid>
                      </FormControl>
                    </DialogContent>
                    <DialogActions sx={{ marginBottom: '16px' }}>
                      <Box sx={{ marginLeft: '8px' }}>
                        <Button
                          variant="outlined"
                          onClick={() => handleDepartmentClose()}
                          size="medium"
                          sx={{ padding: '8px 16px' }}
                        >
                          {' '}
                          Cancel
                        </Button>
                      </Box>
                      <Box sx={{ marginLeft: '16px' }}>
                        <LoadingButtonBRH
                          type="submit"
                          variant="contained"
                          label={companyMasterData.dep_id ? 'Update' : 'Add'}
                        />
                      </Box>
                    </DialogActions>
                  </form>
                </Dialog>
              </Scrollbar>
            </Card>
          </Container>
        </>
      ) : (
        <>
          <FormSkeleton />
        </>
      )}
    </>
  );
}
