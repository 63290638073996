import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Card,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Box,
  Button,
  Stack,
  Drawer,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import axios from 'axios';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import moment from 'moment';
import Swal from 'sweetalert2';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import MuiAppBar from '@mui/material/AppBar';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import Scrollbar from '../../components/scrollbar/Scrollbar';
import * as API from '../../API_SERVICE_LAYER/ApiConfigs';
// import routeNames from '../../layouts/dashBoardBRH/nav/routeNames';
import { routeNames } from '../../layouts/dashBoardBRH/nav/routeNames';
import palette from '../../theme/palette';
import { ToastModalBRH } from '../../components/toasts/ToastModalBRH';
import { FormSkeleton } from '../../components/skeleton/FormSkeleton';
import { LoadingButtonBRH } from '../../components/forms/LoadingButtonBRH';
import { GmailInputBRH } from '../../components/forms/GmailInputBRH';
import { MobileInputBRH } from '../../components/forms/MobileInputBRH';
import { DatePickerInputBRH } from '../../components/forms/DatePickerInputBRH';
import { TextInputBRH } from '../../components/forms/TextInputBRH';
import { COUNTRYLIST, STATELIST, CITYLIST } from '../../API_SERVICE_LAYER/ApiNames';
import { AutoCompleteInputBRH } from '../../components/forms/AutoCompleteInputBRH';
import { ToastBRH } from '../../components/toasts/ToastBRH';

const ViewProfile = () => {
  const [editStaffData, setEditStaffData] = useState({
    userName: '',
    email: null,
    phone: null,
    dob: new Date(),
    localData: [],
    countryList: [],
    countryObject: null,
    country_id: 101,
    stateList: [],
    stateObject: null,
    state_id: null,
    cityList: [],
    cityObject: null,
    city_id: null,
    dataList: [],
  });
  const navigate = useNavigate();

  const { state } = useLocation();
  const { id, token } = state;
  console.log(state, id);

  useEffect(() => {}, []);
  console.log('init', editStaffData);
  const getData = async () => {
    await axios
      .get(`${API.API_URL}user-management/api/v1/user/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then((json) => {
        console.log(json);
        console.log(json.data.status);
        if (json?.data?.status === true) {
          console.log('api', json?.data?.data);
          const apiData = json?.data?.data;
          console.log(apiData.email);
          // setEditStaffData((prev) => ({
          //   ...prev,
          //   dataList: apiData,
          //   name: apiData.name,
          //   phone: apiData.mobile_no,
          //   dob: new Date(apiData?.dob),
          //   email: apiData.email,
          //   country_id: apiData.country_id,
          //   state_id: apiData.state_id,
          //   city_id: apiData.city_id,
          //   countryObject: {
          //     id: 101,
          //     name: 'India',
          //   },
          // }));
          API.getData(COUNTRYLIST)
            .then((resCountry) => {
              if (resCountry?.data?.status === true) {
                const dataCountry = resCountry?.data?.data;
                let apiCountryObject;
                dataCountry?.forEach((cou) => {
                  if (cou.id === editStaffData?.country_id) {
                    apiCountryObject = cou;
                  }
                });
                API.getData(`${STATELIST}/${apiData?.country_id}`)
                  .then((resSt) => {
                    if (resSt?.data?.status === true) {
                      const dataState = resSt?.data?.data;
                      let apiStateObject;
                      dataState?.forEach((st) => {
                        if (st.id === apiData?.state_id) {
                          apiStateObject = st;
                        }
                      });
                      API.getData(`${CITYLIST}/${apiData?.state_id}`)
                        .then((resCT) => {
                          if (resCT?.data?.status === true) {
                            const dataCity = resCT?.data?.data;
                            let apiCityObject;
                            dataCity?.forEach((ct) => {
                              if (ct.id === apiData?.city_id) {
                                apiCityObject = ct;
                              }
                            });
                            setEditStaffData({
                              ...editStaffData,
                              isFetched: true,
                              dataList: apiData,
                              name: apiData.name,
                              phone: apiData.mobile_no,
                              dob: new Date(apiData?.dob),
                              email: apiData.email,
                              // country_id: apiData.country_id,
                              // state_id: apiData.state_id,
                              // city_id: apiData.city_id,

                              country_id: apiData?.country_id,
                              countryObject: apiCountryObject,
                              countryList: dataCountry,
                              stateList: dataState,
                              stateObject: apiStateObject,
                              state_id: apiData?.state_id,
                              cityList: dataCity,
                              cityObject: apiCityObject,
                              city_id: apiData?.city_id,
                            });
                          }
                        })
                        .catch((errCT) => {
                          console.log(errCT);
                          setEditStaffData({
                            ...editStaffData,
                            isFetched: true,
                            countryList: dataCountry,
                            country_id: apiData?.country_id,
                            countryObject: apiCountryObject,
                            stateList: dataState,
                            stateObject: apiStateObject,
                            state_id: apiData?.state_id,
                          });
                        });
                    }
                  })
                  .catch((errSt) => {
                    console.log(errSt);
                    setEditStaffData({
                      ...editStaffData,
                      isFetched: true,
                      countryList: dataCountry,
                      country_id: apiData?.country_id,
                      countryObject: apiCountryObject,
                    });
                  });
              }
            })
            .catch((errCountry) => {
              console.log(errCountry);
              setEditStaffData({
                ...editStaffData,
                isFetched: true,
              });
            });
        }
      })
      .catch((showErr) => {
        console.log(showErr);
        setEditStaffData({
          ...editStaffData,
          isFetched: true,
        });
      });
  };
  useEffect(() => {
    getData();
  }, []);
  const handleCountryChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    if (name === 'name') {
      setEditStaffData({ ...editStaffData, isSelectDataloadedCountry: false });
      API.getData(`${STATELIST}/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            setEditStaffData({
              ...editStaffData,
              country_id: id,
              countryObject: newValue,
              stateList: res.data.data,
              stateObject: null,
              cityList: [],
              cityObject: null,
              isSelectDataloadedCountry: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setEditStaffData({
            ...editStaffData,
            country_id: id,
            stateList: [],
            isSelectDataloadedCountry: true,
          });
        });
    }
  };
  const handleStateChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;

    if (name === 'name') {
      setEditStaffData({ ...editStaffData, isSelectDataloadedState: false });
      API.getData(`${CITYLIST}/${id}`)
        .then((res) => {
          if (res.data.status) {
            console.log('API-CITYLIST');
            setEditStaffData({
              ...editStaffData,
              isSelectDataloadedState: true,
              stateObject: newValue,
              state_id: id,
              cityList: res.data.data,
              cityObject: null,
            });
          }
        })
        .catch((err) => {
          console.log('API-CITYLIST', err);
          ToastBRH('error', `${err?.response?.data?.message}`, '_', false);
          setEditStaffData({ ...editStaffData, isSelectDataloadedState: true, cityList: [], state_id: id });
        });
    }

    // setCompanyMasterData({ ...companyMasterData, state_id1: id });
  };
  const handleCityChange = (event, newValue) => {
    const [name] = event.target.id.split('-');
    const value = newValue[name];
    const id = newValue.id;
    setEditStaffData({ ...editStaffData, city_id: id, cityObject: newValue });
  };
  const handleDateChange = (name, newValue) => {
    setEditStaffData({ ...editStaffData, [name]: newValue });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(' name, value', name, value);
    setEditStaffData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  console.log(editStaffData.email);
  const handleSubmit = (e) => {
    // const localUser = localStorage.getItem('user');
    // const userData = JSON.parse(localUser);
    // console.log('mansoor', userData);

    e.preventDefault();
    const formData = {
      name: editStaffData.name,
      // email: editStaffData.email,
      mobile_no: editStaffData.phone,
      dob: moment(editStaffData.dob).format('YYYY-MM-DD'),
      country_id: editStaffData.country_id,
      state_id: editStaffData.state_id,
      city_id: editStaffData.city_id,
    };
    // API.getData(`user-management/api/v1/user/${userData.id}`)
    axios
      .put(`${API.API_URL}user-management/api/v1/user/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.status === true) {
          ToastBRH('success', 'Profile Updated Successfully');
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // API.updateMethod(`user-management/api/v1/user/${userData.id}`, formData)
    //   .then((res) => {
    //     console.log(res);
    //     console.log(res.data.status);
    //     if (res.data.status === true) {
    //       ToastBRH('success', 'Profile Updated Successfully');
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  return (
    <>
      {editStaffData.isFetched ? (
        <>
          <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mt={2}>
              <Typography variant="h4">Profile</Typography>
            </Stack>
            <Card sx={{ p: 2 }}>
              <Scrollbar>
                <Box sx={{ flexGrow: 1 }}>
                  <form onSubmit={handleSubmit}>
                    <FormControl sx={{ flexGrow: 1, width: '100%' }} sub="true">
                      <Grid container rowSpacing={2.5} columnSpacing={2} sx={{ marginTop: '10px' }}>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInputBRH
                            label="User Name"
                            value={editStaffData.name}
                            name="name"
                            handleChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <DatePickerInputBRH
                            label="Date Of Birth *"
                            value={editStaffData.dob}
                            handleChange={(value) => {
                              setEditStaffData({ ...editStaffData, dob: value });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <MobileInputBRH
                            label="Contact Number"
                            name="phone"
                            value={editStaffData.phone}
                            handleChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <GmailInputBRH
                            label="Email"
                            name="email"
                            value={editStaffData.email}
                            handleChange={handleChange}
                            disabled
                            readOnly
                            key="Email"
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="country"
                            name="name"
                            value={editStaffData.countryObject}
                            options={editStaffData.countryList}
                            handleChange={handleCountryChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="State"
                            name="name"
                            value={editStaffData.stateObject}
                            options={editStaffData.stateList}
                            handleChange={handleStateChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <AutoCompleteInputBRH
                            label="City"
                            name="name"
                            value={editStaffData.cityObject}
                            options={editStaffData.cityList}
                            handleChange={handleCityChange}
                          />
                        </Grid>
                      </Grid>
                      <div style={{ display: 'flex', alignSelf: 'flex-end', marginTop: '30px' }}>
                        <LoadingButtonBRH variant="containeds" label="Update" type="submit" />
                      </div>
                    </FormControl>
                  </form>
                </Box>
              </Scrollbar>
            </Card>
          </Container>
        </>
      ) : (
        <>
          <FormSkeleton />
        </>
      )}
    </>
  );
};

export default ViewProfile;
