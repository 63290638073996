import { AddBasicDetails, AddSelectedData, AddSelectedImages, setFormImcomplete } from '../reducers/BasicDetailsSlice';
import { store } from '../store/Store';

const { dispatch } = store;

export function setBascisDetailsRedux(data) {
  dispatch(AddBasicDetails(data));
}
export function setSelectedData(data) {
  dispatch(AddSelectedData(data));
}
export function setSelectedImagesRedux(data) {
  dispatch(AddSelectedImages(data));
}
export function setFormImcompleteRedux(data) {
  dispatch(setFormImcomplete(data));
}
