import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import WorkSpaceList from './EditWorkSpaceList';
import PlansAdd from './EditPlansAdd';
import EditBasicDetails from './EditBasicDetails';
import EditAmenities from './EditAmenities';
import { API_URL } from '../../../API_SERVICE_LAYER/ApiConfigs';

const EditProspectListPage = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const [value, setValue] = React.useState('1');
  const [isBasicDetailsComplete, setIsBasicDetailsComplete] = useState(false);
  const [isAmenitiesComplete, setIsAmenitiesComplete] = useState(false);
  const id = queryParameters.get('id');
  console.log('id', id);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleBasicDetailsSubmit = () => {
    // Perform validation or any necessary checks on the Basic Details form data.
    // If the form is valid, mark the Basic Details as complete.
    setIsBasicDetailsComplete(true);
    setValue('2');
  };

  const handleAmenitiesSubmit = () => {
    // Perform validation or any necessary checks on the Amenities form data.
    // If the form is valid, mark the Amenities as complete.
    setIsAmenitiesComplete(true);
    setValue('3');
  };
  // useEffect(()=>{
  // API_URL.getData('api/property/' + )
  //   .then((res) => res.json())
  //   .then((result) => {
  //     console.log('result', result.data.property_tabs);
  //     this.setState({ property_tabs: result.data.property_tabs });
  //     if (result.data.skip_plans == 1) {
  //       this.setState({ skip_plans: result.data.skip_plans });
  //     }
  //   });
  // },[])
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <h1>Edit Property</h1>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Basic Details" value="1" />
              <Tab label="Amenities" value="2" disabled={!isBasicDetailsComplete} />
              <Tab label="WorkSpace" value="3" disabled={!isAmenitiesComplete} />
              <Tab label="Plans" value="4" />
              <Tab label="Terms and Conditions" value="5" />
              <Tab label="Others" value="6" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <EditBasicDetails onSubmit={handleBasicDetailsSubmit} />
          </TabPanel>
          <TabPanel value="2">
            <EditAmenities onSubmit={handleAmenitiesSubmit} />
          </TabPanel>
          <TabPanel value="3">
            <WorkSpaceList />
          </TabPanel>
          <TabPanel value="4">
            <PlansAdd />
          </TabPanel>
          <TabPanel value="5">Item Five</TabPanel>
          <TabPanel value="6">Item Six</TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default EditProspectListPage;
